import axios from 'axios'
import { getUrlRedirects } from './getUrlRedirects'

export const deleteUrl = (input) => {
  return async (dispatch) => {
    try {
      const params = {
        'url_redirects_id': input.url_redirects_id,
      }
      const response = await axios.delete(`${process.env.REACT_APP_GARCON_API}/cms/url_redirects/${input.url_redirects_id}`, params)
      if (response){
        dispatch(getUrlRedirects())
        return response.status
      }
    } catch (error) {
      dispatch({ type: 'SHOW ERROR', payload: { message: 'An error occured deleting url.' } })
      throw error
    }
  }
}
