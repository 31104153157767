import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCopy, faTrash, faWrench } from '@fortawesome/free-solid-svg-icons'
import ViewTreatmentBucket from '../Treatments/ViewTreatmentBucket'
import RuleLink from './RuleLink'
import RuleConditionModal from './RuleConditionModal'
import ViewVariations from '../Experiments/ViewVariations'

const Rule = ({
  index,
  id,
  isEdit,
  removeRule,
  predicates,
  rule,
  ruleConfig,
  ruleset_id,
  ruleset_config,
  sortRules,
  type,
  addRuleCondition,
  removeRuleCondition,
  updateRuleConditions
}) => {
  const [collapsed, setCollapsed] = useState(true)
  const [showModal, setShowModal] = useState(false)

  const getConditionsList = (rule) => {
    const { conditions } = rule
    if (!conditions.length) {
      return 'No conditions'
    } else {
      const conditionsList = conditions.map(condition => {
        return `${condition.predicate} ${condition.rule_type && condition.rule_type} ${condition.object && condition.object[0]}`
      })
      return conditionsList.join(', ')
    }
  }

  const indexOptions = () => {
    let options = []
    ruleset_config.length &&
      // not including last resort so slicing it off
      ruleset_config.slice(0, -1)
        .forEach((rule, _index) => {
          if (_index !== index) {
            options.push(_index + 1)
          }
        })
    return options
  }

  return (
    <>
      <div className="mb-2 d-flex align-self-start">
        {isEdit ?
          <select
            className="form-control rounded mr-3"
            style={{ width: '75px' }}
            value={index + 1}
            onChange={(e) => sortRules(index, Number(e.target.value) - 1)}
          >
            <option disabled value={index + 1}>{index + 1}</option>
            {indexOptions().map((option, index) => {
              return <option key={index} value={option}>{option}</option>
            })}
          </select>
          : <span className='pt-2'>{`${index + 1}.`}</span>
        } <span className='pt-2 pl-1'>{getConditionsList(rule)} - {type.display_name}</span>
        {isEdit &&
          <>
            <a
              className="ml-4 pt-2"
              onClick={() => setShowModal(true)}>
              <FontAwesomeIcon
                className="mr-2"
                icon={faWrench}
                style={{ width: '12px', color: '#B31066' }}
              />
            </a>
            <a className="ml-4 pt-2" onClick={() => removeRule(index)}>
              <FontAwesomeIcon
                className="mr-2"
                icon={faTrash}
                style={{ width: '12px', color: '#B31066' }}
              />
            </a>
          </>
        }
        <a className="pt-2 ml-4" onClick={() => setCollapsed(!collapsed)}>
          <FontAwesomeIcon
            className="mr-2"
            icon={collapsed ? 'chevron-up' : 'chevron-down'}
            style={{ width: '12px', color: '#B31066' }}
          />
        </a>
      </div>
      {!collapsed &&
        <>
          <div className="mt-2">
            {rule[type.lookup][type.lookup_name]}
            <RuleLink displayName={type.display_name} rule={rule} /><br />
            <div>
              {id}
              <Link
                className="text-primary ml-2"
                onClick={() => { navigator.clipboard.writeText(id) }}
                to={`/rulesets/${ruleset_id}`}
              >
                <FontAwesomeIcon icon={faCopy} />
              </Link>
            </div>
          </div>
          {
            type.display_name === 'Treatment' ?
              <>
                {ruleConfig.map((bucket, index) => {
                  return (
                    <ViewTreatmentBucket
                      bucket={bucket}
                      index={index}
                      key={index}
                    />
                  )
                })}
              </> :
              <>
                {rule.experiment.control &&
                  <ViewVariations experiment={rule.experiment} />}
              </>
          }
        </>
      }
      <RuleConditionModal
        closeModal={() => setShowModal(false)}
        showModal={showModal}
        index={index}
        predicates={predicates}
        rule={rule}
        type={type}
        addRuleCondition={addRuleCondition}
        removeRuleCondition={removeRuleCondition}
        updateRuleConditions={updateRuleConditions}
      />
    </>
  )
}

export default Rule
