import axios from 'axios'
import checkData from '../../utils/checkData'

export const createCaseType = (input) => {
  return async(dispatch) => {
    try {
      const params = {
        category_name: input.category_name,
        case_type_code: input.case_type_code,
        case_type_description: input.case_type_description,
        department_code: input.department_code.value,
        user_creatable: input.user_creatable.value,
        definition: input.definition,
        example: input.example,
        notify_channels_on_new: checkData(input.notify_channels_on_new, 'value'),
        notify_channels_on_working: checkData(input.notify_channels_on_working, 'value'),
        notify_channels_on_close: checkData(input.notify_channels_on_close, 'value'),
        is_active: input.is_active.value,
        role_names: checkData(input.role_names, 'name'),
      }
      return await axios.post(`${process.env.REACT_APP_GARCON_API}/cases/category`, params)
    } catch (error) {
      dispatch({ type: 'SHOW ERROR', payload: { message: 'An error occured creating case type.' } })
      throw error
    }
  }
}
