import axios from 'axios'
import { getPTCForMarket } from './getPTC'

export const destroyPTC = (ptcId, marketCode) => {
  return async (dispatch) => {
    try {
      await axios.post(`${process.env.REACT_APP_GARCON_API}/pricing/rates/ptc/${ptcId}/destroy`)
        dispatch({ type: 'SHOW_ERROR', payload: { headline: 'SUCCESS!', message: `PTC Rate: ${ptcId} is now invalid`} })
        dispatch(getPTCForMarket(marketCode))
    } catch (error) {
        dispatch({ type: 'SHOW_ERROR', payload: { response: error.response, message: 'An error occured making ptc invalid.' } })
        throw error
    }
  }
}
