import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { createRedirect } from '../../actions/urlRedirects/createRedirect'
import { getUrlRedirects } from '../../actions/urlRedirects/getUrlRedirects'

class CreateRedirect extends Component {
  constructor() {
    super()
    this.defaultState = {
      url: '',
      destination: '',
      message: '',
      validationMessage: '',
    }
    this.state = this.defaultState
  }

  handleSave = async (e) => {
    e.preventDefault()
    if (this.validateUrl()) {
      const response = await this.props.createRedirect(this.state)
      if (response.status === 200) {
        this.setState(this.defaultState)
        this.setState({ message: 'URL Redirect Created!' })
        this.props.getUrlRedirects()
        setTimeout(() => window.location.reload(), 3000)
      }
    } else {
      this.setState({ validateUrl: false, message: '' })
    }
  }

  validateUrl = () => {
    const regex = /(^\/\w+)*?\/\S*?$/
    if (!regex.test(this.state.url) || !regex.test(this.state.destination)) {
      this.setState({ validationMessage: 'URL format not valid.' })
      return false
    } else {
      this.setState({ validationMessage: 'URL format valid.' })
      return true
    }
  }

  render() {
    return (
      <div className="col-md-12 mx-auto">
        <div className="d-flex">
          <h2 className="text-left w-100">Create New URL Redirect</h2>
        </div>
        <form>
          <div className="row">
            <div className="col-md-4 mb-3">
              <label>URL</label>
              <input required className="form-control" type="url" value={this.state.url} onChange={e => this.setState({ url: e.target.value })} />
            </div>
            <div className="col-md-6 mb-3">
              <label>Destination</label>
              <input required className="form-control" type="url" value={this.state.destination} onChange={e => this.setState({ destination: e.target.value })} />
            </div>
            <div className="col-md-2 mb-3">
              <label />
              <button className="btn btn-primary btn-md btn-block" style={{ marginTop: '28px' }} onClick={this.handleSave}>Save</button>
            </div>
            <div className="valid-feedback d-block text-center mt-2">
              {this.state.message}
              {this.state.validationMessage}
            </div>
          </div>
        </form>
      </div>
    )
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ createRedirect, getUrlRedirects }, dispatch)
}

export default connect(null, mapDispatchToProps) (CreateRedirect)
