import React, { Component } from 'react'
import Select from 'react-select'
import { Link } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSignInAlt, faTrash } from '@fortawesome/free-solid-svg-icons'

class Segment extends Component {
  onUpdateSegment = (event) => {
    this.props.onUpdateSegment(this.props.segment, parseInt(event.value, 10), this.props.isNew)
  }

  onUpdateSegmentValue = (event) => {
    const value = event.value === 'in' ? true : false
    this.props.onUpdateSegmentValue(this.props.segment.segment_assignment_id, value, this.props.isNew)
  }

  onDeleteSegment = () => {
    this.props.onDeleteSegment(this.props.segment.segment_assignment_id, this.props.isNew)
  }

  render() {
    return (
      <div className="mb-2 row align-items-center" style={{ marginLeft: -30 }}>
        <div className="col-11 d-flex align-items-center">
          <Select
            className="segment-style col-4"
            value={this.props.segment.condition ? 'in' : 'not_in'}
            disabled={!this.props.writeAccess}
            onChange={this.onUpdateSegmentValue}
            options={[
              { value: 'in', label: 'In' },
              { value: 'not_in', label: 'Not in' }
            ]}
          />
          <Select
            className="segment-style col"
            value={this.props.segment.segment_assignment_id}
            disabled={!this.props.writeAccess}
            onChange={this.onUpdateSegment}
            options={this.props.segments.map(segment => ({ label: segment.segment_description, value: segment.segment_assignment_id })).sort((a, b) => a.label.localeCompare(b.label))}
          />
        </div>
        <div className="col-1 d-flex" style={{ marginLeft: -35 }}>
          <span className="mr-3"><Link className="text-primary" to={`/segments/active/${this.props.segment.segment_assignment_id}/edit`}><FontAwesomeIcon icon={faSignInAlt} /></Link></span>
          {this.props.writeAccess && <a className="text-primary" onClick={this.onDeleteSegment}><FontAwesomeIcon icon={faTrash} /></a>}
        </div>
      </div>
    )
  }
}

export default Segment
