import React, { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import Select from 'react-select'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import getCompAttributeValues from '../../../actions/Compensation/getCompAttributeValues'

const DEFAULT_PREDICATES = [
  { value: 'has', description: 'is', types: ['bool', 'string', 'integer'] },
  { value: 'has', description: 'has', types: ['array'] },
  { value: 'less', description: 'Before', types: ['date'] },
  { value: 'greater', description: 'On or After', types: ['date'] },
  { value: 'less', description: '<', types: ['integer'] },
  { value: 'greater', description: '>=', types: ['integer'] },
]

const Conditions = ({ rules, uniqueId, deleteCondition, predicates = DEFAULT_PREDICATES, updateCondition, readOnly = false }) => {
  const stateCompAttributeValues = useSelector(state => state.compRunSchedules.compAttributeValues)
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(getCompAttributeValues())
  }, [])

  const compAttributeValues = Object.entries(stateCompAttributeValues)

  const isInt = (value) => {
    if (isNaN(value)) {
      return false
    }
    const x = parseFloat(value)
    return (x | 0) === x
  }

  const updateConditionWrapper = (ruleId, key, value) => {
    if (value === 'true' || value === 'false') {
      value = value === 'true'
    } else {
      value = isInt(value) ? parseInt(value, 10) : value
    }
    updateCondition(ruleId, key, value)
  }

  const showDropdown = (rule, ruleId) => {
    const attr = stateCompAttributeValues
    const type = rule.subject && attr[rule.subject] && attr[rule.subject].type
    if (type === 'array') {
      return (
        <Select
          multi
          disabled={readOnly}
          options={attr[rule.subject].options}
          placeholder={rule.subject}
          value={rule.object}
          onChange={(option) => updateConditionWrapper(ruleId, 'object', option.map(o => o.value))}
        />
      )
    } else if (type === 'date') {
      return <input className="custom-select w-100" disabled={readOnly} placeholder="Date" type="date" value={rule.object} onChange={(e) => updateConditionWrapper(ruleId, 'object', e.target.value)} />
    } else if (type === 'integer') {
      return <input className="custom-select w-100" disabled={readOnly} placeholder="Null" pattern="^[0-9]*$" type="number" value={rule.object} onChange={(e) => updateConditionWrapper(ruleId, 'object', e.target.value)} />
    } else if (type === 'bool' || type === 'string') {
      return (
        <select className="form-control" disabled={readOnly} value={rule.object} onChange={(e) => updateConditionWrapper(ruleId, 'object', e.target.value)}>
          <option value="" />
          {attr[rule.subject].options.map((opt, id) => {
            return <option key={id} value={opt.value}>{opt.label}</option>
          })}
        </select>
      )
    }

    return <input className="form-control" disabled={readOnly} value={rule.object} onChange={(e) => updateConditionWrapper(ruleId, 'object', e.target.value)} />
  }

  const renderAnd = (ruleId) => (rules.length > 0) && (ruleId < rules.length - 1) ? 'AND' : null

  return (
    <>
      <div>Conditions:</div>
      {rules.sort((a, b) => a.subject.localeCompare(b.subject)).map((rule, ruleId) => {
        const attr = stateCompAttributeValues
        const type = rule.subject && attr[rule.subject] && attr[rule.subject].type
        const statePredicates = predicates.filter(predicate => predicate.types.includes(type))
        return (
        <div key={`paymentRule-${uniqueId}-compRule-${ruleId}`}>
          <div className="mb-2 d-flex">
            <div title="Subject">
              <select
                className="form-control"
                disabled={readOnly}
                value={rule.subject || ''}
                style={{ width: 'auto' }}
                onChange={(e) => updateConditionWrapper(ruleId, 'subject', e.target.value)}
              >
                <option />
                {
                  compAttributeValues.map((subject, idx) => (
                    <option
                      key={`paymentRule-${uniqueId}-compRule-${ruleId}-subject-${idx}`}
                      value={subject.value}
                    >
                      {subject[0] == null ? 'null' : subject[0]}
                    </option>
                  ))
                }
              </select>
            </div>
            <div className="ml-4" title="Verb">
              <select
                className="form-control"
                disabled={readOnly}
                value={rule.predicate || ''}
                style={{ width: 'auto' }}
                onChange={(e) => updateConditionWrapper(ruleId, 'predicate', e.target.value)}
              >
                <option />
                {
                  statePredicates.map((predicate) => (
                      <option
                        key={`paymentRule-${uniqueId}-compRule-${ruleId}-predicate-${predicate.description}`}
                        value={predicate.value}
                      >
                        {predicate.description}
                      </option>
                    )
                  )
                }
              </select>
            </div>
            <div className="ml-4 flex-grow" title="Object">
              {showDropdown(rule, ruleId)}
            </div>
            { !readOnly &&
              <button
                className="btn btn-danger ml-2 mt-1 mb-1 pt-0 pb-0"
                style={{ height: '30px' }}
                onClick={() => deleteCondition(ruleId)}
              >
                <FontAwesomeIcon className="feather" icon="trash" size="2x" />
              </button>
            }
          </div>
          <div className="d-block mt-2">
            {renderAnd(ruleId)}
          </div>
        </div>
      )})}
    </>
  )
}

export default Conditions
