import React from 'react'
import Rodal from 'rodal'

export default function Modal({ visible, closeModal, title, children, overflowStyle, customStyles }) {
  return (
    <Rodal
      customStyles={customStyles}
      visible={visible}
      onClose={closeModal}
    >
      <div className="container-fluid h-100" style={overflowStyle}>
        <h4>{title}</h4>
        {children}
      </div>
    </Rodal>
  )
}

Modal.defaultProps = {
  overflowStyle: { overflow: 'scroll' },
  customStyles: { width: '65%', height: '75%' },
}
