import axios from 'axios'

export default function getMarkets() {
  return function(dispatch) {
    dispatch({ type: 'GETTING_MARKETS', payload: true })
    axios.get(`${process.env.REACT_APP_GARCON_API}/offers/markets`)
      .then((response) => {
        const payload = response.data
        dispatch({ type: 'GET_MARKETS', payload })
        dispatch({ type: 'GETTING_MARKETS', payload: false })
      }).catch((error) => {
        dispatch({ type: 'SHOW_ERROR', payload: { response: error.response, message: 'An error occured getting markets.' } })
        throw error
      })
  }
}
