import axios from 'axios'

export const getRewardStatusCodes = async (callback) => {
  try {
    const response = await axios.get(`${process.env.REACT_APP_GARCON_API}/rewards/reward_status_codes`)
    callback(response.data)
  } catch (error) {
    throw error
  }
}

export const updateRewardStatusCodes = async (reward_ids, reward_status_code) => {
  try {
    return await axios.put(`${process.env.REACT_APP_GARCON_API}/rewards/reward_status_codes`, { reward_ids, reward_status_code })
  } catch (error) {
    throw error
  }
}
