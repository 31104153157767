import axios from 'axios'

export const getCaseTypes = () => {
  return function(dispatch){
    axios.get(`${process.env.REACT_APP_GARCON_API}/cases/categories`)
      .then((response) => {
        dispatch({ type: 'GET_CASE_TYPES', payload: response.data })
      })
      .catch((error) => {
        dispatch({ type: 'SHOW ERROR', payload: { response: error.response, message: 'An error occured getting case types.' } })
        throw error
      })
  }
}
