import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux'
import ReactTable from 'react-table'
import  moment from 'moment'
import EditPTCEndDate from './EditPTCEndDate'
import InvalidPTCModal from './InvalidPTCModal'
import { destroyPTC } from '../../actions/pricing/destroyPTC';

const HistoricalPricesTables = ({ marketCode }) => {
  const marketPTCRates = useSelector(state => state.ptc.market)
  const [showModal, setShowModal] = useState(false)
  const [ptcId, setPtcId] = useState('')
  const dispatch = useDispatch()

  const getData = (revenueClassCode) => {
    if (marketPTCRates.length > 0) {
      return marketPTCRates.filter(ptc => ptc.revenue_class_code === revenueClassCode).reduce((dataArray, price) => {
        dataArray.push({
          price_to_compare_id: price.price_to_compare_id,
          price: `$${price.rate}/${price.rate_units}`,
          effective_date: price ? `${moment(price.from_dt).utcOffset(true).format('l')}` : 'N/A',
          end_date: price ? `${moment(price.to_dt).utcOffset(true).format('l')}` : null,
          source: price.source,
          notes: price.notes
        })
        return dataArray
      }, [])
    }
  }

  const showPopUpConfirmation = (e) => {
    setPtcId(e.target.name)
    setShowModal(!showModal)
  }

  const handleInvalidatePTC = () => {
    dispatch(destroyPTC(ptcId, marketCode))
    setShowModal(false)
    setPtcId('')
  }

  const columns = [
    {
      Header: 'Price',
      accessor: 'price',
    }, {
      Header: 'Effective Date',
      accessor: 'effective_date',
    }, {
      Header: 'End Date',
      accessor: 'end_date',
      Cell: (props) => (
        <>
        {<EditPTCEndDate endDate={props.value} ptc={props.original} marketCode={marketCode}/>}
        </>
      ),
      style: {cursor: 'pointer'},
      width: 150
    }, {
      Header: 'Status',
      accessor: 'price_to_compare_id',
      Cell: (props) => (
        <div className="row justify-content-center">
          {<button name={props.value} className="btn btn-danger btn-md rounded px-2" onClick={(e) => showPopUpConfirmation(e)}>Make PTC Invalid  </button> }
        </div>),
      width: 200
    }, {
      Header: 'Source',
      accessor: 'source',
    }, {
      Header: 'Notes',
      accessor: 'notes',
      maxWidth: 300,
    }]

  return (
    <div className="row">
      <div className="col-md-6">
        <h5>Historical Prices to Compare (RESI)</h5>
        <ReactTable
          className="-striped -highlight mb-3"
          columns={columns}
          data={getData('RESI')}
          style={{ width: '100%', overflowX: 'scroll' }}
        />
      </div>
      <div className="col-md-6">
        <h5>Historical Prices to Compare (SMALL-COMM)</h5>
        <ReactTable
          className="-striped -highlight mb-3"
          columns={columns}
          data={getData('SMALL-COM')}
          style={{ width: '100%', overflowX: 'scroll' }}
        />
      </div>
      {showModal &&
        <InvalidPTCModal
          closeModal={() => setShowModal(false)}
          show={showModal}
          handleInvalidatePTC={handleInvalidatePTC}
        />
      }
    </div>
  )
}

export default HistoricalPricesTables;
