import axios from 'axios'

export default function getNewMarketRequiredFields() {
  return function(dispatch) {
    axios.get(`${process.env.REACT_APP_GARCON_API}/offers/markets/import/required_fields`)
      .then((response) => {
        dispatch({ type: 'GET_NEW_MARKET_REQUIRED_FIELDS', payload: response.data })
      })
      .catch((error) => {
        dispatch({ type: 'SHOW_ERROR', payload: { response: error.response, message: error.response.data.error } })
        throw error
      })
  }
}
