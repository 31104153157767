import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { changeModule } from '../../actions/changeModule'
import startECLImportJob from '../../actions/accountOperations/startECLImportJob'

const ImportECL = () => {
  const dispatch = useDispatch()
  const [ignoreThresholdAlert, setIgnoreThresholdAlert] = useState(false)
  const [importStarted, setImportStarted] = useState(false)
  const loadingJob = useSelector(state => state.eclImportJob.loading)

  useEffect(() => {
    dispatch(changeModule('Import ECLs'))
  }, [])

  useEffect(() => {
    if (loadingJob && !importStarted) {
      setImportStarted(true)
    }
  })

  const startImport = () => {
    dispatch(startECLImportJob(ignoreThresholdAlert))
  }

  return (
    <div>
      <h1>Import ECLs</h1>
      <button
        className="btn btn-primary col-2"
        disabled={importStarted}
        onClick={startImport}
      >
        {importStarted ? 'Import Started!' : 'Start Import'}
      </button>
      <label className="font-weight-bold col-2 text-right">Ignore Threshold Alert?</label>
      <input
        style={{ transform: 'scale(2)' }}
        type="checkbox"
        value={ignoreThresholdAlert}
        onChange={(event) => setIgnoreThresholdAlert(event.target.checked) }
      />
      <small className="text-danger m-3">Warning! This will ignore alerting for all currently uploaded ECLs</small>

      <hr />

      <h4>Instructions</h4>
      <ol>
        <li>Upload ECLs to s3: <code>inbound/ecl/staged/&lt;MARKET_CODE&gt;</code></li>
        <li>Click <code>Start Import</code></li>
      </ol>

      <h4>Notes</h4>
      <ul>
        <li>This will process <b>ALL</b> uploaded ECLs so if you only want to process one at a time, upload and then import them separately</li>
        <li>When the job starts and finishes, a slack message will be posted to <code>#inspa-ops</code></li>
        <li>Do <b>NOT</b> attempt to re-run the job until it either shows an error or says it has completed</li>
      </ul>
    </div>
  )
}

export default ImportECL
