import React from 'react'
import ReactDOM from 'react-dom'
import Raven from 'raven-js'
import './index.css'
// import '@helloinspire/melodic/dist/css/melodic.min.css'
import AxiosInterceptor from './AxiosInterceptor'
import { makeMainRoutes } from './routes'
import { Auth0Provider } from '@auth0/auth0-react'

if (process.env.NODE_ENV !== 'development') {
  Raven.config(`${process.env.REACT_APP_SENTRY_DSN}`).install()
}

AxiosInterceptor()

const routes = makeMainRoutes()

ReactDOM.render(
  <Auth0Provider
    clientId={process.env.REACT_APP_AUTH0_CLIENT_ID}
    domain={process.env.REACT_APP_AUTH0_DOMAIN}
    redirectUri={window.location.origin+'/callback'}
    responseType="token id_token"
    scope="openid profile email offline_access"
  >
    {routes}
  </Auth0Provider>,
  document.getElementById('root')
)
