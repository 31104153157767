import React, { useState, useEffect } from 'react'
import { changeModule } from '../../actions/changeModule'
import { useDispatch } from 'react-redux'
import { getCustomerIoResultsCsv } from '../../apis/customerIoArchive'
import { readRemoteFile } from 'react-papaparse'
import ReactTableV7 from '../ReactTableV7'
import { DatePicker } from 'antd'
import {
  columns,
  mapCsvData,
  sleep
} from './helpers'

const { RangePicker } = DatePicker

const CustomerIoArchive = () => {
  const dispatch = useDispatch()
  const [csvData, setCsvData] = useState([])
  const [loading, setLoading] = useState(false)
  const [recipient, setRecipient] = useState('')
  const [pickedDates, setPickedDates] = useState({})
  const [presignedUrl, setPresignedUrl] = useState(null)
  const [error, setError] = useState(null)


  useEffect(() => {
    dispatch(changeModule('CustomerIO Archive'))
  }, [])

  useEffect(() => {
    if (presignedUrl) {
      sleep(5000).then(() => {
        readRemoteFile(presignedUrl, {
          complete: (results) => {
            const parsedData = mapCsvData(results.data)
            if (parsedData.length === 0) {
              setError('No results found')
            } else {
              setCsvData(parsedData)
            }
          }
        })
        setLoading(false)
        setError(null)
      })
    }
  }, [presignedUrl])

  const handleDateChange = (dates, dateString) => {
    if (dates) {
      setPickedDates({startDate: dateString[0], endDate: dateString[1]})
    }
  }

  const submitForm = () => {
    setCsvData([])
    setPresignedUrl(null)
    setError(null)
    if (recipient === '') {
      setError('Please enter a recipient')
    } else if (!pickedDates.startDate || !pickedDates.endDate) {
      setError('Please select a date range')
    }

    setLoading(true)

    getCustomerIoResultsCsv(
      recipient,
      'email',
      pickedDates.startDate,
      pickedDates.endDate,
      setPresignedUrl,
      setError
    )
  }

  return (
    <>
      <h1>CustomerIO Archive</h1>
      <div className="input-group mb-3">
        <RangePicker
          showTime={{
            format: 'HH:mm',
          }}
          format="YYYY-MM-DD HH:mm"
          onChange={handleDateChange}
        />
        <input className="form-control" placeholder="Search Email" type="search" value={recipient} onChange={e =>  setRecipient(e.target.value)} />
        <div className="input-group-append">
          <button className="btn btn-primary rounded" type="button" onClick={() => {submitForm(recipient, pickedDates.startDate, pickedDates.endDate)}}>Search</button>
        </div>
      </div>
      {csvData.length > 0 &&
        <ReactTableV7
          columns={columns}
          data={csvData}
          canHover={false}
        />
      }
      {error && <div className="text-danger">{error}</div>}
      {loading && <div className="text-center"><i className="fa fa-spinner fa-spin" style={{ fontSize: '24px' }}></i></div>}
    </>
  )
}

export default CustomerIoArchive
