import React, { useState, useEffect } from 'react'
import axios from 'axios'
import Select from 'react-select'

let options
if (process.env.REACT_APP_VOLT === 'production') {
  options = [
    { label: 'Draft', value: 'DRAFT' },
  ]
} else {
  options = [
    { label: 'Draft', value: 'DRAFT' },
    { label: 'Real', value: 'NEW' },
  ]
}

const RunCompRunScheduleForm = ({ compRunScheduleCode }) => {
  const [initCompRunStatus, setInitCompRunStatus] = useState()
  const [runStarted, setRunStarted] = useState(false)
  const [responseMessage, setResponseMessage] = useState()

  useEffect(() => {
    if (options.length === 1) {
      setInitCompRunStatus(options[0])
    }
  })


  const runCompRunSchedule = () => {
    setRunStarted(true)
    axios.post(`${process.env.REACT_APP_GARCON_API}/compensation/comp_runs/${compRunScheduleCode}`, { init_comp_run_status_code: initCompRunStatus.value })
      .then(() => {
        setResponseMessage('Successfully started!')
      })
      .catch((error) => {
        setResponseMessage(error.response.data.error)
        setRunStarted(false)
      })
  }

  const buttonText = responseMessage || `Start ${initCompRunStatus?.label || ''} Comp Run`

  return (
    <div className="input-group mb-3">
      <Select
        disabled={options.length === 1}
        options={options}
        placeholder="Type of Comp Run"
        style={{ width: '200px' }}
        value={initCompRunStatus}
        onChange={option => setInitCompRunStatus(option)}
      />
      <div className="input-group-append">
        <button
          className="btn btn-primary"
          disabled={runStarted || !initCompRunStatus}
          onClick={runCompRunSchedule}
        >
          {buttonText}
        </button>
      </div>
    </div>
  )
}


export default RunCompRunScheduleForm
