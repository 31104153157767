import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { clearAgent, updateAgent, saveUpdateSalesAgent, updateAgentDropDowns } from '../../actions/agents/updateAgent'
import history from '../../history'
import getSourceOffices from '../../actions/schedules/getSourceOffices'
import { getAgent } from '../../actions/agents/getAgent'
import { getUserByID } from '../../actions/Users/updateUser'
import { searchAgent } from '../../actions/agents/searchAgent'
import Select from 'react-select'

const UpdateAgent = () => {
  const salesAgent = useSelector(state => state.salesAgents.agent)
  const sources = useSelector(state => state.sources.sources)
  const offices = useSelector(state => state.sources.offices)
  const channels = useSelector(state => state.channels)
  const salesRoles = useSelector(state => state.salesAgents.roles)
  const [message, setMessage] = useState('')
  const [userRoles, setUserRoles] = useState([])
  const dispatch = useDispatch()
  const enrollmentVendors = useSelector(state => state.salesAgents.enrollmentVendors)

  const selectAgent = async () => {
    const selectedAgent = await history.location.state.edit
    const response = await dispatch(getAgent(selectedAgent.agent_id))
    dispatch(getSourceOffices(response.source_code))
    const userInfo = await dispatch(getUserByID(response.user_id))
    setUserRoles(userInfo.roles)
  }

  useEffect(() => {
    selectAgent()

    return () => {
      dispatch(clearAgent())
    }
  }, [])

  const officeDeactivated = () => {
    if (Object.keys(offices).length !== 0) {
      const agentOffice = offices[Object.keys(offices).filter(office => office === salesAgent.office_code)]
      if (agentOffice) {
        return !agentOffice.is_active
      }
      else {
        return false
      }
    }
  }

  const sourceOptions = () => {
    return sources.map(source => ({
      value: source.source_code,
      label: source.source_code,
    }))
  }

  const selectSource = (value) => {
    dispatch(getSourceOffices(value))
    dispatch(updateAgent('source_code', value))
  }

  const channelOptions = () => {
    return channels.map(channel => ({
      value: channel.channel_code,
      label: channel.channel_code,
    }))
  }

  const cancelUpdate = () => {
    history.push('/agents')
  }

  const handleUpdate = async (e) => {
    e.preventDefault()
    const response = await dispatch(saveUpdateSalesAgent(salesAgent, userRoles))
    if (response.status === 200) {
      dispatch(searchAgent(salesAgent.agent_id))
      setMessage('Agent successfully updated!')
      dispatch(searchAgent(salesAgent.agent_id))
      setTimeout(() => history.push('/agents'), 3000)
    }
  }

  return (
    <div className="container-fluid col-md-10 mx-auto">
      { salesAgent &&
      <form>
        <h4 className="text-center">Update Agent</h4>
        <div className="row">
          <div className="col-md-6 mb-3">
            <label>Agent ID</label>
            <input
              required
              className="form-control"
              disabled={salesAgent.order_id !== null}
              value={salesAgent.agent_id}
              onChange={(e) => dispatch(updateAgent('agent_id', e.target.value))}
            />
          </div>
          <div className="col-md-2 mb-3">
            <label>Status</label>
            <Select
              disabled={officeDeactivated()}
              options={[
                { value: 'Active', label: 'Active' },
                { value: 'Inactive', label: 'Inactive' },
              ]}
              value={salesAgent.status}
              onChange={(e) => dispatch(updateAgentDropDowns('status', e))}
            />
          </div>
          <div className="col-md-4 mb-3">
            <label>Email</label>
            <input required className="form-control" placeholder={salesAgent.email} value={salesAgent.email} onChange={(e) => dispatch(updateAgent('email', e.target.value)) } />
            <div className="invalid-feedback">Required</div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-4 mb-3">
            <label>First Name</label>
            <input required className="form-control" value={salesAgent.first_name} onChange={(e) => dispatch(updateAgent('first_name', e.target.value)) } />
            <div className="invalid-feedback">Required</div>
          </div>
          <div className="col-md-4 mb-3">
            <label>Last Name</label>
            <input required className="form-control" value={salesAgent.last_name} onChange={(e) => dispatch(updateAgent('last_name', e.target.value)) } />
            <div className="invalid-feedback">Required</div>
          </div>
          <div className="col-md-4 mb-3">
            <label>Phone Number</label>
            <input required className="form-control" value={salesAgent.phone} onChange={(e) => dispatch(updateAgent('phone_number', e.target.value)) } />
            <div className="invalid-feedback">Required</div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-4 mb-3">
            <label>Role(s)</label>
            <Select
              multi
              options={salesRoles.map(role => ({
                role_id: role.role_id, label: role.name, value: role.name, name: role.name,
              }))}
              value={userRoles.map(userRole => ({
                role_id: userRole.role_id, label: userRole.name, value: userRole.name, name: userRole.name,
              }))}
              onChange={(e) => setUserRoles(e)}
            />
          </div>
          <div className="col-md-4 mb-3">
            <label>Date Created</label>
            <input className="form-control" disabled={true} value={salesAgent.created_at ? salesAgent.created_at.slice(0 , 10) : ''} />
            <div className="invalid-feedback">Required</div>
          </div>
          <div className="col-md-4 mb-3">
            <label>Last Modified</label>
            <input className="form-control" disabled={true} value={salesAgent.updated_at ? salesAgent.updated_at.slice(0 , 10) : ''} />
            <div className="invalid-feedback">Required</div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-3 mb-3">
            <label>Source</label>
            <Select
              options={sourceOptions()}
              value={salesAgent.source_code}
              onChange={(e) => e ? selectSource(e.value) : dispatch(updateAgent('source_code', ''))}
            />
          </div>
          <div className="col-md-3 mb-3">
            <label>Channel Code</label>
            <Select
              options={channelOptions()}
              value={salesAgent.channel_code}
              onChange={(e) => dispatch(updateAgentDropDowns('channel_code', e))}
            />
          </div>
          <div className="col-md-3 mb-3">
            <label>Office</label>
            <Select
              options={Object.keys(offices).map(key => ({
                value: key, label: key,
              }))}
              value={salesAgent.office_code}
              onChange={(e) => dispatch(updateAgentDropDowns('office_code', e))}
            />
          </div>
          <div className="col-md-3 mb-3">
            <label>Enrollment App Vendor</label>
            <Select
              options={enrollmentVendors.map(vendor => ({
                label: vendor.vendor_code, value: vendor.vendor_code,
              }))}
              value={{label: salesAgent.vendor_code, value: salesAgent.vendo_code}}
              onChange={(e) => dispatch(updateAgentDropDowns('vendor_code', e))}
            />
          </div>
        </div>
        <div className="row">
          <div className="col-md-4">
            <button className="btn btn-secondary btn-md btn-block mt-4" type="button" onClick={cancelUpdate}>Cancel Agent Update</button>
          </div>
          <div className="col-md-8">
            <button className="btn btn-primary btn-md btn-block mt-4" type="button" onClick={handleUpdate}>Update Agent</button>
            <div className="valid-feedback d-block text-center mt-2">
              {message}
            </div>
          </div>
        </div>
      </form>
      }
    </div>
  )
}

export default UpdateAgent
