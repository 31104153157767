import axios from 'axios'

export default function createVoltData(orderHash) {
  return function(dispatch) {
    dispatch({ type: 'CREATING_VOLT_DATA', payload: true })
    axios.post(`${process.env.REACT_APP_GARCON_API}/accounts/volt`, orderHash)
      .then((response) => {
        dispatch({ type: 'VOLT_DATA_CREATED', payload: response.data })
        dispatch({ type: 'CREATING_VOLT_DATA', payload: false })
        dispatch({ type: 'DEFAULT_DATA_GENERATED', payload: false })
      }).catch((error) => {
        dispatch({ type: 'SHOW_ERROR', payload: { response: error.response, message: 'An error occured creating volt data.' } })
        throw error
      })
  }
}
