import React, { useState } from 'react'
import moment from 'moment'

const OfferContextItem = ({rowIndex, generationPayload}) => {
  const [showBody, setShowBody] = useState(false)

  const formatDate = (date) => {
    const momentDate = moment(date)
    return `${momentDate.format('M/D/YY')} ${momentDate.format('h:mm:ss.SSS a')}`
  }

  const JSONstringifyOrder = (object) => {
    const allKeys = new Set()
    JSON.stringify(object, (key, value) => (allKeys.add(key), value))
    return JSON.stringify(object, Array.from(allKeys).sort(), 2)
  }

  return (
    <div className="card mb-3">
      <div className="card-header" onClick={_ => setShowBody(!showBody)}>
        {rowIndex + 1}. <strong>{generationPayload.event_name}</strong>
        <div className="float-right">
          {formatDate(generationPayload.request_dt)} { showBody ? <span>&#9650;</span> : <span>&#9660;</span>}
        </div>
      </div>
      { showBody ?
        <kbd className="card-body">
          <div className="row">
            <div className="col-7" style={{borderRight: '1px solid silver'}}>
              <strong>Payload</strong>
              <div style={jsonStyle}>{JSONstringifyOrder(generationPayload.payload)}</div>
            </div>
            <div className="col-5">
              <strong>Metadata</strong>
              <div style={jsonStyle}>{JSONstringifyOrder(generationPayload.metadata)}</div>
            </div>
          </div>
        </kbd>
      : null }
    </div>
  )
}

const jsonStyle = {
  whiteSpace: 'pre-wrap',
  maxHeight: '400px',
  overflowY: 'auto'
}

export default OfferContextItem
