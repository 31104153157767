const RulesetDetails = ({ ruleset }) => {
  return (
    <div className="card-body">
      <div className="row">
        <div className="col-4">
          <span className="mr-2"><strong>State</strong></span>
          <input
            value={ruleset.state_code}
            disabled={true}
          />
        </div>
        <div className="col-4">
          <span className="mr-2"><strong>Structure</strong></span>
          <input
            value={ruleset.contract_type_code}
            disabled={true}
          />
        </div>
        <div className="col-4">
          <span className="mr-2"><strong>Type</strong></span>
          <input
            value={ruleset.product_type_code}
            disabled={true}
          />
        </div>
      </div>
      <div className="row mt-4">
        <div className="col-4">
          <span className="mr-2"><strong>Created Date</strong></span>
          <input
            value={ruleset.created_at}
            disabled={true}
          />
        </div>
        <div className="col-4">
          <span className="mr-2"><strong>Updated Date</strong></span>
          <input
            value={ruleset.updated_at}
            disabled={true}
          />
        </div>
      </div>
    </div>
  )
}

export default RulesetDetails
