import React, { Component } from 'react'
import { connect } from 'react-redux'
import { SEGMENTS } from '../../../../utils/roles'
import history from '../../../../history'
import getSegments from '../../../../actions/segments/getSegments'
import { changeModule } from '../../../../actions/changeModule'
import SegmentsTable from './SegmentsTable'
import CreateSegment from './CreateSegment'


class SegmentsContainer extends Component {

  constructor(props) {
    super(props)
    this.state = {
      tab: props.tab,
      showCreateSegment: false,
    }
  }

  componentDidMount() {
    this.props.changeModule('Segments')
    this.props.getSegments()
  }

  writeAccess() {
    return this.props.roles.some(role => SEGMENTS.WRITE.includes(role.name))
  }

  onChangeTab = (tab) => {
    history.push(`/segments/${tab}`)
    this.setState({ tab })
  }
  onToggleCreateSegment = () => {
    this.setState({ showCreateSegment: !this.state.showCreateSegment })
  }

  render() {
    if (this.props.loading) return null
    return (
      <div className="container-fluid">
        <div className="d-flex">
          <div />
          <h2 className="text-left w-100">Segments</h2>
          <button className="btn btn-primary rounded mb-3 mr-3" type="button" onClick={this.onToggleCreateSegment}>{this.state.showCreateSegment ? 'Close' : 'Create New Segment'}</button>
        </div>
        <br />
        {this.state.showCreateSegment && (
          <div className="card-body">
            <h4 className="text-left ml-5">Create New Segment</h4>
            <CreateSegment onCloseCreateSegment={this.onToggleCreateSegment} />
          </div>
        )}
        <br />
        <SegmentsTable />
      </div>
    )
  }
}

function mapStateToProps(state) {
  return {
    segments: state.segments.info,
    roles: state.user.roles,
    loading: state.user.loading,
  }
}

const mapDispatchToProps = {
  changeModule,
  getSegments,
}

export default connect(mapStateToProps, mapDispatchToProps)(SegmentsContainer)
