import axios from 'axios'
import mapKeys from 'lodash/mapKeys'

export const getPlans = async (callback) => {
  const params = { searchQuery: null, activeOnly: true, attributes: {} }
  try {
    const response = await axios.get(`${process.env.REACT_APP_GARCON_API}/offers/plans/all`, { params })
    const payload = mapKeys(response.data, (plan) => plan.plan_code)
    callback(payload)
  } catch (error) {
    throw error
  }
}
