import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import { createPTC } from '../../actions/pricing/createPTC'
import SectionInput from '../Shared/SectionInput'
import isEmpty from 'lodash/isEmpty'

const CreatePriceToCompare = ({ marketCode }) => {
  const dispatch = useDispatch()

  const defaultFormData =  {
    rate: '',
    rate_currency: 'dollars',
    rate_units: '',
    market_code: marketCode,
    from_dt: '',
    to_dt: '',
    revenue_class_code: '',
    source: '',
    notes: ''
  }

  const [formData, setFormData] = useState(defaultFormData)
  const [errorMessage, setErrorMessage] = useState(null)

  const requiredKeys = ['rate', 'rate_units', 'from_dt', 'to_dt', 'revenue_class_code', 'source']
  const requiredKeyInputs = {
    rate: 'Rate',
    rate_units: 'Rate Units',
    from_dt: 'Effective Date',
    to_dt: 'End Date',
    revenue_class_code: 'Revenue Class',
    source: 'Source'
  }

  const handleChangeInput = (key, value) => {
    setFormData({...formData, [key]: value })
  }

  const handleSubmitPTC = (e) => {
    e.preventDefault()
    const formErrors = requiredKeys.reduce((errorsObj, key) => {
      if (formData[key] === '') {
        errorsObj[key] = <div key={key}>{requiredKeyInputs[key]} is required<br /></div>
      }
      return errorsObj
    }, {})

    if (!isEmpty(formErrors)) {
      setErrorMessage(Object.values(formErrors))
    } else {
      setErrorMessage(null)
      dispatch(createPTC(formData))
      setFormData(defaultFormData)
    }
  }

  return (
    <div className="card mb-4">
      <div className="card-header">
        <strong>Create New Price To Compare</strong>
      </div>
      <div className="card-body">
        <form>
          <div className="row">
            <div className="col-5">
              <div className="input-group ml-5">
                <p className="ml-4 mr-3"><b>Rate</b></p>
                <input
                  name="rate"
                  type="text"
                  className="form-control"
                  aria-label="Text input with dropdown button"
                  value={formData.rate}
                  required={true}
                  onChange={(e) => handleChangeInput('rate', e.target.value)}
                />
                <div className="input-group-append">
                  <select className="custom-select" value={formData.rate_units} onChange={(e) => handleChangeInput('rate_units', e.target.value)}>
                    <option>Choose units of measure</option>
                    <option>kWh</option>
                    <option>CCF</option>
                    <option>MCF</option>
                    <option>Therms</option>
                  </select>
                </div>
              </div>
            </div>
            <div className="col-5 mr-3">
            <SectionInput
              label="Revenue Class"
              type="select"
              value={formData.revenue_class_code}
              required={true}
              options={[
                {label: 'RESI', value: 'RESI'},
                {label: 'SMALL-COM', value: 'SMALL-COM'}
              ]}
              onChange={(e) => handleChangeInput('revenue_class_code', e.value)}
            />
            </div>
          </div>
          <div className="row">
            <div className="col-5">
              <label className="mx-2"><b>Effective Date</b></label>
              <input
                className="custom-select w-50"
                name="from_dt"
                placeholder="Start Date"
                type="date"
                value={formData.from_dt}
                onChange={(e) => handleChangeInput('from_dt', e.target.value)}
              />
            </div>
            <div className="col-5 mr-3">
              <SectionInput
                label="Source"
                type="select"
                value={formData.source}
                required={true}
                options={[
                  {label: 'utility', value: 'utility'},
                  {label: 'Inspire Forecasted', value: 'Inspire Forecasted'}
                ]}
                onChange={(e) => handleChangeInput('source', e.value)}
              />
            </div>
          </div>
          <div className="row">
            <div className="col-5">
            <label className="ml-5 mx-1"><b>End Date</b></label>
              <input
                className="custom-select w-50"
                name="to_dt"
                placeholder="End Date"
                type="date"
                value={formData.to_dt}
                onChange={(e) => handleChangeInput('to_dt', e.target.value)}
              />
            </div>
            <div className="col-5 mr-3">
              <SectionInput
                label="Notes"
                type="textarea"
                value={formData.notes}
                required={false}
                onChange={(e) => handleChangeInput('notes', e.target.value)}
              />
            </div>
          </div>
          {errorMessage &&
            <div className="alert alert-danger mt-3">
              {errorMessage}
            </div>
          }
          <div className="text-right mb-2 mr-4">
            <button className="btn btn-primary mr-2" onClick={(e) => handleSubmitPTC(e)}>Save</button>
            <button className="btn btn-secondary" onClick={() => setFormData(defaultFormData)}>Cancel</button>
          </div>
        </form>
      </div>
    </div>
  )
}

export default CreatePriceToCompare;
