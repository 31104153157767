import React, { useEffect, useMemo, useState } from 'react'
import { useParams } from 'react-router-dom'
import moment from 'moment'
import history from '../../../history'
import {
  getRolloffTreatment,
  getRolloffTreatmentImplementations
} from '../../../apis/rolloffTreatments'
import TreatmentImplementations from './TreatmentImplementations'
import Loading from '../../Shared/Loading'
import ViewTreatmentConfig from './ViewTreatmentConfig'
import {
  calcTypes,
  deconstructConfigForUI,
  hasRateCapCheck,
  setupRateCapForUI
} from './helpers'

const ViewTreatment = () => {
  const [treatment, setTreatment] = useState(null)
  const [hasRateCap, setHasRateCap] = useState(false)
  const [rateCapData, setRateCapData] = useState({
    rateCap: '',
    rateCapModifier: null,
    plan: null,
    planChoice: null
  })
  const [buckets, setBuckets] = useState(null)
  const [bucketDelta, setBucketDelta] = useState({ bucket_type: '', delta_to: '', delta_from: '' })
  const [implementations, setImplementations] = useState(null)
  const [loading, setLoading] = useState(true)
  const { rolloff_treatment_id } = useParams()

  useEffect(() => {
    const getData = async () => {
      await getRolloffTreatment(rolloff_treatment_id, setTreatment)
      await getRolloffTreatmentImplementations(rolloff_treatment_id, setImplementations)
      setLoading(false)
    }

    getData()
  }, [])

  useEffect(() => {
    if (treatment) {
      const hasRateCap = hasRateCapCheck(treatment.treatment_config)
      if (hasRateCap) {
        const _rateCapData = setupRateCapForUI(treatment)
        setHasRateCap(true)
        setRateCapData(_rateCapData)
      }


      const dataForConfig = hasRateCap ? treatment.treatment_config?.array[1] : treatment.treatment_config
      const configuredBuckets = deconstructConfigForUI(dataForConfig)
      setBuckets(configuredBuckets)

      const baseForTreatmentMethodology = hasRateCap ? treatment.treatment_config.array[1] : treatment.treatment_config
      if (baseForTreatmentMethodology.delta_to) {
        const deltaTo = calcTypes.find(calcType => calcType.label === baseForTreatmentMethodology.delta_to).name
        const deltaFrom = calcTypes.find(calcType => calcType.label === baseForTreatmentMethodology.delta_from).name
        setBucketDelta(
          { bucket_type: baseForTreatmentMethodology.type, delta_to: deltaTo, delta_from: deltaFrom }
        )
      }
    }
  }, [treatment])

  return (
    <div className="container-fluid">
      {treatment && !loading &&
        <>
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <h4 className="my-3">{treatment.treatment_name}</h4>

            <div>
              <button
                className="btn btn-primary btn-md mb-2 mr-2"
                onClick={() => history.push(`/rolloff_treatments/clone/${rolloff_treatment_id}`)}
              >
                Clone Treatment
              </button>
              <button
                className="btn btn-primary btn-md mb-2"
                onClick={() => history.push(`/rolloff_treatments/edit/${rolloff_treatment_id}`)}
              >
                Edit
              </button>
            </div>
          </div>

          <div className="card mb-4">
            <div className="card-header" style={{ display: 'flex', justifyContent: 'space-between' }}>
              <strong>Details</strong>
            </div>
            <div className="card-body">
              <div className="row">
                <div className="col-4">ID: {treatment?.rolloff_treatment_id}</div>
                <div className="col-4">Created Date: {moment(treatment?.created_at).format("MM/DD/YYYY")}</div>
                <div className="col-4">Status: {treatment.active ? 'Active' : 'Inactive'}</div>
              </div>
              <div className="row mt-4">
                <div className="col-12">Description: {treatment?.notes}</div>
              </div>
            </div>
          </div>

          <ViewTreatmentConfig
            hasRateCap={hasRateCap}
            rateCapData={rateCapData}
            treatment_config={buckets}
            deltaData={bucketDelta}
          />

          {implementations && <TreatmentImplementations implementations={implementations} />}
        </>
      }
      {loading && <Loading />}
    </div>
  )
}

export default ViewTreatment
