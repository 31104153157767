import axios from 'axios'

export default function (includeBonusRuleValues = false){
  return function(dispatch){
    axios.get(`${process.env.REACT_APP_GARCON_API}/compensation/comp_attribute_values?include_bonus_rule_values=${includeBonusRuleValues}`)
      .then((response) => {
        dispatch({ type: 'COMP_ATTRIBUTE_VALUES', payload: response.data })
      })
      .catch((error) => {
        dispatch({ type: 'SHOW_ERROR', payload: { response: error.response, message: error.response.data.error, headline: 'Failed to get comp attributes' } })
      })
  }
}
