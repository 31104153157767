import React, { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import history from '../../../history'
import { getRolloffRuleset, getRolloffRulesetActivity } from '../../../apis/rolloffRulesets'
import Loading from '../../Shared/Loading'
import RulesetConfig from './RulesetConfig'
import RulesetDetails from './RulesetDetails'
import RulesetActivity from './RulesetActivity'

const Ruleset = () => {
  const [ruleset, setRuleset] = useState(null)
  const [rulesetActivity, setRulesetActivity] = useState(null)
  const [loading, setLoading] = useState(false)
  const [tab, setTab] = useState('details')
  const { ruleset_id } = useParams()

  useEffect(() => {
    const getData = async () => {
      await getRolloffRuleset(ruleset_id, setRuleset)
      await getRolloffRulesetActivity(ruleset_id, setRulesetActivity)
      setLoading(false)
    }
    if (!ruleset) {
      getData()
    }
  }, [ruleset_id])

  return (
    <div className="container-fluid">
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <h4 className="my-3">Ruleset {ruleset_id}</h4>
        <button
          className="btn btn-primary btn-md mb-2"
          onClick={() => history.push(`/rulesets/edit/${ruleset_id}`)}
        >
          Edit
        </button>
      </div>
      {ruleset &&
        <>
          <div className="card mb-4">
            <div className="card-header">
              <span className={tab === 'details' ? 'font-weight-bold' : ''} onClick={() => setTab('details')}><a>Details</a></span>
              <span className={tab === 'activity' ? 'font-weight-bold ml-5' : 'ml-5'} onClick={() => setTab('activity')}><a>Activity</a></span>
            </div>
            {tab === 'details' && <RulesetDetails ruleset={ruleset} />}
            {tab === 'activity' && <RulesetActivity rulesetActivity={rulesetActivity} />}
          </div>
          <div className="card mb-4">
            <div className="card-header">
              <strong>Rules</strong>
            </div>
            <div className="card-body">
              <RulesetConfig
                hasLastRule
                ruleset_id={ruleset.rolloff_ruleset_id}
                ruleset_config={ruleset.ruleset_config}
              />
            </div>
          </div>
        </>
      }
      {loading && <Loading />}
    </div >
  )
}

export default Ruleset
