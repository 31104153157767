import axios from "axios"

export const getMarketGeos = () => {
  return function(dispatch){
    axios.get(`${process.env.REACT_APP_GARCON_API}/offers/market_geos`)
    .then((response) => {
      dispatch({ type: 'GET_MARKET_GEOS', payload: response.data})
    })
    .catch((error) => {
      dispatch({ type: 'SHOW_ERROR', payload: { response: error.response, message: 'An error occured getting market geos.' } })
      throw error
    })
  }
}
