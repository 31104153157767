import React, { useState, useEffect, useMemo } from 'react'
import { createFilter } from './filterHelpers'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTrashAlt, faCheck, faPlus } from '@fortawesome/free-solid-svg-icons'

const Filter = ({ compRules, setFilterData, filterData, filterId }) => {
	const [availableSubjects, setAvailableSubjects] = useState(null)
	const [availablePredicates, setAvailablePredicates] = useState(null)
	const [availableObjects, setAvailableObjects] = useState(null)
	const [selectedSubject, setSelectedSubject] = useState('')
	const [selectedPredicate, setSelectedPredicate] = useState('')
	const [selectedObject, setSelectedObject] = useState('')
	const [filterAdded, setFilterAdded] = useState(false)

	const conditionLookup = useMemo(() => createFilter(compRules), [compRules])

	useEffect(() => {
		setAvailableSubjects(Object.keys(conditionLookup))
	}, [conditionLookup])

	useEffect(() => {
		setFilterAdded(false)
	}, [selectedSubject, selectedObject, selectedPredicate])

	const selectConditionFilter = (e) => {
		setSelectedSubject(e.target.value)
		setAvailablePredicates(Object.keys(conditionLookup[e.target.value]))
		setSelectedPredicate('')
		setAvailableObjects(null)
		setSelectedObject('')
	}

	const selectPredicateFilter = (e) => {
		setSelectedPredicate(e.target.value)
		setAvailableObjects(conditionLookup[selectedSubject][e.target.value])
		setSelectedObject('')
	}

	const selectObjectFilter = (e) => {
		setSelectedObject(e.target.value)
	}

	const deleteFilter = () => {
		setFilterData(
			filterData.filter((data, idx) => filterId !== idx)
		)
	}

	const applyFilter = () => {
		const filteredRules = []
		compRules.forEach((compRule, idx) => { // each compRule contains a key of "rules" that contains an array of conditions
			const foundRule = compRule.rules.find((condition) => { // search rules array to find conditions that match selected state values
				if (!selectedPredicate && !selectedObject) 
					return condition.subject === selectedSubject // return match for subject selection only

				const conditionMatch = condition.subject === selectedSubject
				const predicateMatch = condition.predicate === selectedPredicate
				
				const objectMatch = Array.isArray(condition.object) 
					? condition.object.includes(selectedObject) 
					: String(condition.object) === selectedObject // object data types in compRules.rules can be an array, string, boolean, number, or null

				return conditionMatch && predicateMatch && objectMatch
			})
			
			foundRule && filteredRules.push(idx) // if we find a rule that contains a matching condition, push rule index into array
		})

		const newFilters = [...filterData]
		newFilters[filterId] = filteredRules
		
		setFilterData(newFilters)
		setFilterAdded(true)
	}

	const disableAddBtn = (!selectedSubject)
		|| (selectedPredicate && !selectedObject)
		|| (!selectedPredicate && selectedObject)

	const addBtnColor = filterAdded && !disableAddBtn ? "btn-primary" : "btn-dark"
	const addBtnIcon = filterAdded && !disableAddBtn ? faCheck : faPlus

	return (
		<div className="d-flex container m-1 mt-2">
			<div className="row w-100">

				<select className="col-sm m-1 py-1" value={selectedSubject} onChange={(e) => selectConditionFilter(e)}>
					<option value="">Select Condtion</option>
					{availableSubjects?.sort().map((value, idx) => (
						<option key={`${value}-${idx}`} value={value}>{value}</option>
					))}
				</select>

				<select className="col-sm m-1 py-1" value={selectedPredicate} onChange={(e) => selectPredicateFilter(e)}>
					<option value="">Select Predicate</option>
					{availablePredicates?.sort().map((value, idx) => (
						<option key={`${value}-${idx}`} value={value}>{value}</option>
					))}
				</select>

				<select className="col-sm m-1 py-1" value={selectedObject} onChange={(e) => selectObjectFilter(e)}>
					<option value="">Select Value</option>
					{availableObjects?.sort().map((value, idx) => (
						<option key={`${value}-${idx}`} value={value}>{value}</option>
					))}
				</select>
			</div>

			<div className="col-sm d-flex">
				<button className={`btn px-2 py-0 m-1 ${addBtnColor}`} disabled={disableAddBtn} onClick={applyFilter}><FontAwesomeIcon icon={addBtnIcon} /></button>
				<button className="btn btn-danger px-2 py-0 m-1" type="button" onClick={() => deleteFilter()}><FontAwesomeIcon icon={faTrashAlt} /></button>
			</div>
		</div>
	)
}

export default Filter
