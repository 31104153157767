import React, { useEffect, useState } from 'react'
import SectionSelect from '../../Shared/SectionSelect'
import PlanSection from './PlanSection'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTrash } from '@fortawesome/free-solid-svg-icons'
import { bucketAmountToDisplay, planChoices, staticRateModifiers } from './helpers'

const RateCap = ({
  hasRateCap,
  rateCapData,
  setHasRateCap,
  updateRateCap
}) => {
  const [isSelected, setIsSelected] = useState(false)
  const [planChoice, setPlanChoice] = useState(null)
  const [plan, setPlan] = useState(null)
  const [rateCap, setRateCap] = useState('')
  const [rateCapModifier, setRateCapModifier] = useState(null)

  useEffect(() => {
    if (hasRateCap) setIsSelected(hasRateCap)
    if (rateCapData) {
      setRateCap(rateCapData.rateCap)
      setRateCapModifier(rateCapData.rateCapModifier)
      setPlanChoice(rateCapData.planChoice)
      if (rateCapData.planChoice === 'Renew on different plan') setPlan(rateCapData.plan)
    }
  }, [])

  const updateInput = (field, value, setLocalState) => {
    setLocalState(value)
    updateRateCap({ [field]: value })
  }

  const setRateCapSelected = () => {
    setIsSelected(!isSelected)
    setHasRateCap(!isSelected)
  }

  const definePlan = (plan) => {
    setPlan(plan)
    updateRateCap(plan)
  }

  return (
    <>
      <div className="mb-4">
        {!isSelected &&
          <div className="mb-2">
            <input checked={isSelected} type="checkbox" onChange={() => setRateCapSelected()} /> Set Rate Cap
          </div>
        }
        {isSelected &&
          <>
            <div className="d-flex justify-content-start">
              <strong>RATE CAP</strong>
              <div className="col-3">
                <SectionSelect
                  labelKey="name"
                  options={planChoices}
                  type="text"
                  value={planChoice}
                  valueKey="name"
                  onChange={(e) => updateInput('planChoice', e.target.value, setPlanChoice)}
                />
              </div>
              <div className="col-3">
                <SectionSelect
                  labelKey="name"
                  options={staticRateModifiers}
                  type="text"
                  value={rateCapModifier}
                  valueKey="name"
                  onChange={(e) => updateInput('rateCapModifier', e.target.value, setRateCapModifier)}
                />
              </div>
              <div className="col-3">
                <input
                  className="form-control rounded"
                  type="number"
                  value={rateCap}
                  onChange={(e) => updateInput('rateCap', e.target.value, setRateCap)}
                />
                {rateCap && bucketAmountToDisplay(rateCapModifier, rateCap)}
              </div>
              <div className="col-2">
                <a className="text-primary ml-2" onClick={() => setRateCapSelected()}>
                  <FontAwesomeIcon icon={faTrash} />
                </a>
              </div>
            </div>
            {planChoice === 'Renew on different plan' &&
              <PlanSection
                plan={plan}
                updateBuckets={definePlan}
              />
            }
          </>}
      </div>
    </>
  )
}

export default RateCap
