import axios from 'axios'
import { getOffices } from './getOffices'

export const createOffice = (input) => {
  return async(dispatch) => {
    try {
      const params = {
        'source_code': input.source_code,
        'office_code': input.office_code,
        'call_recording_required': input.call_recording_required,
        'is_active': input.is_active,
      }
      const response = await axios.post(`${process.env.REACT_APP_GARCON_API}/sales/office`, params)
      if (response){
        dispatch(getOffices())
      }
      return response
    } catch (error) {
      dispatch({ type: 'SHOW ERROR', payload: { message: 'An error occured creating office.' } })
      throw error
    }
  }
}
