import axios from 'axios'

export const updateCaseType = (input) => {
  return async(dispatch) => {
    try {
      const params = {
        'case_type_category_id': input.case_type_category_id,
        'definition': input.definition,
        'is_active': input.is_active,
      }
      return await axios.put(`${process.env.REACT_APP_GARCON_API}/cases/category/${input.case_type_category_id}`, params)
    } catch (error) {
      dispatch({ type: 'SHOW ERROR', payload: { message: 'An error occured updating case type.' } })
      throw error
    }
  }
}
