import React, { useState } from 'react'
import Modal from '../../Modal'
import SectionSelect from '../../Shared/SectionSelect'
import RuleValueInput from '../../Audience/Segments/SegmentsContainer/RuleValueInput'

const RuleConditionModal = ({
  closeModal,
  showModal,
  predicates,
  index,
  rule,
  type,
  addRuleCondition,
  removeRuleCondition,
  updateRuleConditions
}) => {
  const getPredicateOptions = () => {
    return predicates ? Object.values(predicates)
      .map(predicate => predicate.segment_predicate)
      .filter(predicate => predicate.display_in_rule_builder) : []
  }

  const updatePredicate = (conditionIndex, value) => {
    updateRuleConditions(index, conditionIndex, value, 'predicate')
  }

  const updateRuleValue = (conditionIndex, value) => {
    updateRuleConditions(index, conditionIndex, value, 'rule value')
  }

  const ruleTitle = type.display_name === 'Experiment' ?
    <span>Edit Conditions for Rule <br />{`${index + 1}. ${rule.experiment.experiment_id} ${type.display_name}`}</span> :
    <span>Edit Conditions for Rule  <br />{`${index + 1}. ${rule.rolloff_treatment.rolloff_treatment_id} ${type.display_name}`}</span>

  return (
    <Modal closeModal={closeModal}
      title={ruleTitle}
      visible={showModal}
    >
      <div className="row mt-4">
        {predicates && rule
          && rule.conditions.map((condition, i) => {
            return (
              <React.Fragment key={i}>
                <div className="col-5">
                  <label>Predicate</label>
                  <SectionSelect
                    labelKey="description"
                    options={getPredicateOptions()}
                    type="text"
                    value={condition.predicate}
                    valueKey="predicate"
                    onChange={(event) => updatePredicate(i, event.target.value)}
                  />
                  <div className="row mb-4">
                    <div className="col-6">
                      <a className="text-primary" onClick={(e) => removeRuleCondition(e, index, i)}>
                        Remove Rule
                      </a>
                    </div>
                  </div>
                </div>
                <div className="col-5">
                  <label>Rule</label>
                  <RuleValueInput
                    predicate={predicates[condition.predicate]}
                    rule={condition}
                    onChange={(rule) => updateRuleValue(i, rule)}
                  />
                </div>
              </React.Fragment>
            )
          })
        }
      </div>
      <div className="row">
        <div className="col-5">
          <button
            className="btn btn-info mb-2"
            onClick={(e) => addRuleCondition(e, index)}
          >
            Add rule
          </button>
        </div>
      </div>
    </Modal>
  )
}

export default RuleConditionModal
