import startNewMarketImportJob from '../../actions/accountOperations/startNewMarketImportJob'
import getNewMarketRequiredFields from '../../actions/accountOperations/getNewMarketRequiredFields'
import getNewMarketValidationOptions from '../../actions/accountOperations/getNewMarketValidationOptions'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { changeModule } from '../../actions/changeModule'
import DatabaseTableView from './DatabaseTableView'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const ImportMarkets = () => {
  const dispatch = useDispatch()
  const [marketCode, setMarketCode] = useState(null)
  const [validationOptions, setValidationOptions] = useState({})
  const [importStarted, setImportStarted] = useState(false)
  const [displayedTables, setDisplayedTables] = useState({})

  const loadingJob = useSelector(state => state.newMarketImportJob.loading)
  const newMarketRequiredFields = useSelector(state => state.newMarketRequiredFields.tables)
  const validationOptionDefaults = useSelector(state => state.newMarketImportJob.validationOptions)

  useEffect(() => {
    dispatch(changeModule('New Market Setup'))
    dispatch(getNewMarketRequiredFields())
    dispatch(getNewMarketValidationOptions())

  }, [])

  useEffect(() => {
    if (loadingJob && !importStarted) {
      setImportStarted(true)
    }
  })

  const startImport = () => {
    dispatch(startNewMarketImportJob(marketCode, validationOptions))
  }

  const tableColumns = () => {
    return [
      {
        Header: 'Column Name',
        id: 'column_name',
        accessor: 'column_name',
        width: '200'
      },
      {
        Header: 'Type',
        id: 'type',
        accessor: 'type',
        width: '75'
      },
      {
        Header: 'Default Value',
        id: 'default',
        accessor: 'default',
        width: '75'
      },
      {
        Header: 'Required?',
        id: 'required',
        accessor: 'required',
        width: '75'
      },
      {
        Header: 'Description',
        id: 'description',
        accessor: 'description',
        width: '1000'
      },
    ]
  }


  const renderValidationOptions = () => {
    return Object.keys(validationOptionDefaults).map((option) => {
      return (
        <div className="form-check" key={option}>
          <input
            className="form-check-input"
            type="checkbox"
            checked={validationOptions[option] || false}
            id={option}
            onChange={(event) => setValidationOptions({ ...validationOptions, [option]: event.target.checked })}
          />
          <label className="form-check-label" htmlFor={option}>
            {option}
          </label>
        </div>
      )
    })
  }

  return (
    <div>
      <h1>New Market Setup</h1>
      <input required className="form-control form-control-sm col-2" id="market_code_input" size="10" type="text" placeholder="market_code" onChange={(event) => setMarketCode(event.target.value)} />
      <div className="border p-2 mt-2 mb-5 d-inline-block">
        <b>Validation Options</b>
        <br />
        <small className="text-danger">All validations are run by default. Validations should only be skipped once the possibly invalid data is manually checked. #inspa-ops in slack will show the name/description of the validation that failed.</small>
        {renderValidationOptions()}
      </div>
      <div>
        <button
          className="btn btn-primary margin-left-lg"
          onClick={startImport}
          disabled={!marketCode || importStarted}
        >
          {importStarted ? <><FontAwesomeIcon pulse color="#fff" icon="spinner" /> <span>Import Started...</span></> : 'Start Import'}
        </button>
      </div>

      <hr />

      <h4>Instructions</h4>
      <ol>
        <li>Create files in format: market_code_table_name.csv (ex. <code>PECO_market_geos.csv</code>)
          <ul><li>Must include header row in file</li></ul></li>
        <li>Upload files to s3 - <code>inbound/inspire/new_market_setup/staged</code> (They will get archived to <code>inbound/inspire/new_market_setup/processed/market_code</code>)</li>
      </ol>

      <hr />

      <h4>Tables</h4>
      <div className="align-items-center">
        {newMarketRequiredFields.map((table, key) => (
          <div key={key} className="align-items-center">
            <h5>
              {table.table_name}<span> - </span>
              <a onClick={() => setDisplayedTables({...displayedTables, [key]: !displayedTables[key] }) }>
                <strong className="text-primary"><u>{displayedTables[key] ? 'Collapse' : 'Show'}</u></strong>
              </a>
            </h5>
            {displayedTables[key] && (
              <DatabaseTableView columns={tableColumns()} data={table.columns} />
            )}
          </div>
        ))}
      </div>
    </div>
  )
}

export default ImportMarkets
