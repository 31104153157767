import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { getRoles } from '../../actions/Users/createUser'
import { createCaseType } from '../../actions/caseTypes/createCaseType'
import Select from 'react-select'
import history from '../../history'

class CreateCaseType extends Component {
  constructor() {
    super()
    this.defaultState = {
      category_name: '',
      case_type_code: '',
      case_type_description: '',
      department_code: '',
      user_creatable: { value: true, label: 'Yes' },
      definition: '',
      example: '',
      notify_channels_on_new: [],
      notify_channels_on_working: [],
      notify_channels_on_close: [],
      role_names: [],
      is_active: { value: true, label: 'Active' },
    }
    this.state = this.defaultState
  }

  componentDidMount = async () => {
    this.props.getRoles()
  }

  cancelUpdate = () => {
    history.push('/case_types')
  }

  handleSave = async(e) => {
    e.preventDefault()
    const response = await this.props.createCaseType(this.state)
    if (response.status === 200) {
      this.setState({ message: 'Case type created!' })
      this.setState(this.defaultState)
      setTimeout(() => history.push('/case_types'), 3000)
    }
  }

  render() {
    const deptOptions = [{ value: 'MX', label: 'Member Support' }, { value: 'OPS', label: 'Operations' }, { value: 'SALES', label: 'Sales' }, { value: 'SALES_OPS', label: 'Sales Ops' }, { value: 'SUPPLY_CHAIN', label: 'Supply Chain' }]
    const slackChannelOptions = [{ value: '#inspa-ops-cases', label: '#inspa-ops-cases' }, { value: '#inspa-subscription', label: '#inspa-subscription' }, { value: '#inspa-sales-ops-cases', label: '#inspa-sales-ops-cases' }]

    return (
      <div className="col-md-12 mx-auto">
        <div className="d-flex">
          <h2 className="text-left w-100">Create New Case Type Category</h2>
        </div>
        <form>
          <div className="row">
            <div className="col-md-6 mb-3">
              <label>Case Type Code</label>
              <input required className="form-control" value={this.state.case_type_code} onChange={e => this.setState({ case_type_code: e.target.value })} />
              <div className="invalid-feedback">Required</div>
            </div>
            <div className="col-md-6 mb-3">
              <label>Case Type Name</label>
              <input required className="form-control" value={this.state.category_name} onChange={e => this.setState({ category_name: e.target.value })} />
              <div className="invalid-feedback">Required</div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-4 mb-3">
              <label>Department Code</label>
              <Select
                options={deptOptions}
                value={this.state.department_code}
                onChange={e => this.setState({ department_code: e })}
              />
            </div>
            <div className="col-md-4 mb-3">
              <label>Roles</label>
              <Select
                multi
                options={this.props.roles.map(option => ({
                  name: option.name, label: option.name, value: option.name,
                }))}
                value={this.state.role_names}
                onChange={e => this.setState({ role_names: e })}
              />
              <div className="invalid-feedback">Required</div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-10 mb-3">
              <label>Description</label>
              <input required className="form-control" value={this.state.case_type_description} onChange={e => this.setState({ case_type_description: e.target.value })} />
              <div className="invalid-feedback">Required</div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-6 mb-3">
              <label>Definition</label>
              <input required className="form-control" value={this.state.definition} onChange={e => this.setState({ definition: e.target.value })} />
              <div className="invalid-feedback">Required</div>
            </div>
            <div className="col-md-6 mb-3">
              <label>Example</label>
              <input required className="form-control" value={this.state.example} onChange={e => this.setState({ example: e.target.value })} />
              <div className="invalid-feedback">Required</div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-2 mb-3">
              <label>Status</label>
              <Select
                options={[
                  { value: true, label: 'Active' },
                  { value: false, label: 'Inactive' },
                ]}
                value={this.state.is_active}
                onChange={e => this.setState({ is_active: e.value })}
              />
            </div>
            <div className="col-md-2 mb-3">
              <label>User Creatable?</label>
              <Select
                options={[
                  { value: true, label: 'Yes' },
                  { value: false, label: 'No' },
                ]}
                value={this.state.user_creatable}
                onChange={e => this.setState({ user_creatable: e.value })}
              />
            </div>
            <div className="col-md-2 mb-3">
              <label>Notify On Create?</label>
              <Select
                multi
                options={slackChannelOptions}
                value={this.state.notify_channels_on_new}
                onChange={e => this.setState({ notify_channels_on_new: e })}
              />
            </div>
            <div className="col-md-2 mb-3">
              <label>Notify On Working?</label>
              <Select
                multi
                options={slackChannelOptions}
                value={this.state.notify_channels_on_working}
                onChange={e => this.setState({ notify_channels_on_working: e })}
              />
            </div>
            <div className="col-md-2 mb-3">
              <label>Notify On Close?</label>
              <Select
                multi
                options={slackChannelOptions}
                value={this.state.notify_channels_on_close}
                onChange={e => this.setState({ notify_channels_on_close: e })}
              />
            </div>
          </div>
          <div className="row">
            <div className="col-md-4">
              <button className="btn btn-secondary btn-md btn-block mt-4" type="button" onClick={this.cancelUpdate}>Cancel Update</button>
            </div>
            <div className="col-md-8 mb-3">
              <label />
              <button className="btn btn-primary btn-md btn-block" style={{ marginTop: '28px' }} onClick={this.handleSave}>Save</button>
              <div className="valid-feedback d-block text-center mt-2">
                {this.state.message}
              </div>
            </div>
          </div>
        </form>
      </div>
    )
  }
}

function mapStateToProps(state) {
  return {
    roles: state.roles,
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ getRoles, createCaseType }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps) (CreateCaseType)
