/* global Blob: false */ // eslint-disable-line
import React, { Component, Fragment } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import cloneDeep from 'lodash/cloneDeep'
import axios from 'axios'
import getEarlyCancellationFees from '../../actions/plans/getEarlyCancellationFees'
import getPlans from '../../actions/plans/getPlans'
import isEmpty from 'lodash/isEmpty'
import Autocomplete from './Autocomplete'

const correspondenceTypeMapping = {
  'EXPIRE_OPTIONS_NOTICE': 'contract_expiration',
  'EXPIRE_INITIAL_NOTICE': 'contract_expiration',
  'EXPIRE_RENEWAL_NOTICE': 'contract_expiration',
  'TERMS': 'terms',
  'ENVIRONMENTAL_DISCLOSURE': 'environmental_disclosure',
  'INELIGIBLE_DROP_NOTICE': 'ineligible_drop_notice',
  'REWARDS_TERMS': 'rewards_terms',
  'CANCELLATION_NOTICE': 'cancellation_notice',
  'PRICE_DISCLOSURE': 'price_disclosure',
  'TRIAL_ENDING_NOTICE': 'trial_ending_notice',
  'WELCOME': 'welcome_letter',
  'MANDATORY_TERMS_CHANGE': 'mandatory_terms_change_letter',
  'OFFER_TERMS_CHANGE': 'offer_terms_change_letter',
  'REJECT_NOTICE': 'reject_notice',
  'REDEEM_REWARD': 'redeem_reward',
  'WINBACK_OFFER': 'winback_offer',
  'INVOICE_PRICE_NOTICE': 'invoice_price_notice',
}

const previewUrl = `${process.env.REACT_APP_GARCON_API}/correspondence/correspondence_templates/general/preview`
const previewMapping = {
  'contract_expiration': {
    'url': previewUrl,
    'options': {
      'contract_type_code': ['fixed', 'variable', 'subscription'],
      'early_cancellation_fee_code': [],
      'intro_rate_enabled': [true, false],
      'plan_code': []
    },
  },
  'terms': {
    'url': `${process.env.REACT_APP_GARCON_API}/terms/preview`,
    'options': {
      'contract_type_code': ['fixed', 'variable', 'subscription'],
      'early_cancellation_fee_code': [],
      'include_order': [true, false],
      'include_incentives': [true, false],
      'intro_rate_enabled': [true, false],
      'plan_code': []
    },
  },
  'environmental_disclosure': {
    'url': previewUrl,
    'options': {},
  },
  'rewards_terms': {
    'url': `${process.env.REACT_APP_GARCON_API}/rewards/terms/preview`,
    'options': {
      'multiple_incentives': [true, false],
      'reward_type': ['promo', 'loyalty_program', 'both'],
    },
  },
  'cancellation_notice': {
    'url': `${process.env.REACT_APP_GARCON_API}/terms/cancellation_notice/preview`,
    'options': {
      'include_order': [true, false],
    },
  },
  'price_disclosure': {
    'url': previewUrl,
    'options': {},
  },
  'trial_ending_notice': {
    'url': previewUrl,
    'options': {
      'contract_type_code': ['fixed', 'subscription'],
    },
  },
  'welcome_letter': {
    'url': previewUrl,
    'options': {
      'has_promos': [true, false],
      'contract_type_code': ['fixed', 'subscription'],
    },
  },
  'mandatory_terms_change_letter': {
    'url': previewUrl,
    'options': {},
  },
  'offer_terms_change_letter': {
    'url': previewUrl,
    'options': {},
  },
  'reject_notice': {
    'url': previewUrl,
    'options': {},
  },
  'redeem_reward': {
    'url': previewUrl,
    'options': {},
  },
  'winback_offer': {
    'url': previewUrl,
    'options': {},
  },
  'ineligible_drop_notice': {
    'url': previewUrl,
    'options': {},
  },
  'invoice_price_notice': {
    'url': previewUrl,
    'options': {},
  },
}

function assignParamOptions(param, value) {
  Object.keys(previewMapping).forEach(function(type) {
    Object.keys(previewMapping[type].options).forEach(function(key) {
      if (param === key) {
        previewMapping[type].options[key] = value
      }
    })
  })
}

class CorrespondenceTemplatePreview extends Component {
  constructor(props) {
    super(props)
    assignParamOptions('early_cancellation_fee_code', this.props.earlyCancellationFees.map(ecf => ecf.early_cancellation_fee_code))
    const typeMapping = correspondenceTypeMapping[this.props.correspondenceTemplate.correspondence_type_code]
    const mapping = {...previewMapping[typeMapping], options: {...previewMapping[typeMapping].options, account_id: ''}}
    if (mapping) {
      this.state = {
        previewTemplate: { ...mapping, params: {} },
        previewUrl: null,
        previewFailedMessage: null,
      }
    } else {
      this.state = {
        previewTemplate: { url: null, options: {} },
        previewFailedMessage: 'No preview template setup',
      }
    }
  }

  componentDidMount() {
    const templateRequiresPlanCodeOptions = correspondenceTypeMapping[this.props.correspondenceTemplate.correspondence_type_code] === 'contract_expiration'
    if (this.hasPreviewConfigured()) {
      this.getPreview()
    }
    if (this.state.previewTemplate.options.plan_code && !this.props.plans.plan_code) {
      this.props.getPlans({ searchQuery: null, activeOnly: true, attributes: null })
    }
  }

  componentDidUpdate(prevProps) {
    if (this.hasPreviewConfigured() && prevProps.version !== this.props.version) {
      this.getPreview(this.state.previewTemplate.params)
    }
    if (!isEmpty(this.props.plans)) {
      assignParamOptions('plan_code', Object.keys(this.props.plans))
      this.renderOptions()
    }
  }

  resetPreview = () => {
    const previewTemplate = cloneDeep(this.state.previewTemplate)
    previewTemplate.params = {}
    this.setState({ previewTemplate })
    this.getPreview()
  }

  updateParams = (param, value) => {
    // remove any whitespace from value string
    let valueWhiteSpaceRemoved = value.replace(/\s/g, '' )
    const previewTemplate = cloneDeep(this.state.previewTemplate)
    if (value === null) {
      delete previewTemplate.params[param]
    } else {
      previewTemplate.params[param] = valueWhiteSpaceRemoved
    }
    this.setState({ previewTemplate })
    if (param === 'account_id' && value.length < 3) return
    // do not fetch a preview account unless there is a matching plan code
    const matchingPlanCode = Object.keys(this.props.plans).find(plan => plan === valueWhiteSpaceRemoved)
    if ((param === 'plan_code' && value === '#') || (param === 'plan_code' && !matchingPlanCode)) return
    this.getPreview(previewTemplate.params)
  }

  accountValueExists = () => {
    return this.state.previewTemplate.params.account_id?.length > 0 ? true : false
  }

  showPendingVersion = () => {
    return this.props.version === 'PENDING'
  }

  hasPendingChanges() {
    return !!this.props.correspondenceTemplate.pending_liquid_html
  }

  hasPreviewConfigured = () => {
    return !!this.state.previewTemplate.url
  }

  previewUrl = (payload) => {
    const base = new URL(this.state.previewTemplate.url)
    const queryParams = { correspondence_template_id: this.props.correspondenceTemplate.correspondence_template_id, use_pending: this.showPendingVersion(), ...payload }
    Object.keys(queryParams).forEach(param => {
      base.searchParams.append(param, queryParams[param])
    })
    return base.toString()
  }

  getPreview = (payload = {}) => {
    this.setState({ previewFailedMessage: null })
    axios.get(this.previewUrl(payload)).then(response => {
      const file = new Blob([response.data], { type: 'text/html' })
      const url = URL.createObjectURL(file)
      this.setState({ previewUrl: url })
    }).catch(error => {
      this.setState({ previewFailedMessage: `${error.response.data.error} ${error.response.data.description}` })
    })
  }

  downloadPreview = () => {
    axios.get(`${process.env.REACT_APP_GARCON_API}/correspondence/correspondence_templates/${this.props.correspondenceTemplate.correspondence_template_id}/download`, { params: { preview_url: this.previewUrl(this.state.previewTemplate.params) }, responseType: 'arraybuffer' }).then(response => {
      const file = new Blob([response.data], { type: 'application/pdf' })
      const fileURL = URL.createObjectURL(file)
      const a = document.createElement('a')
      a.href = fileURL
      const paramsString = new URLSearchParams(this.state.previewTemplate.params).toString()
      a.download = `${this.props.correspondenceTemplate.description}_${paramsString}_${(new Date()).toLocaleDateString('en-US')}.pdf`
      document.body.appendChild(a)
      a.click()
      document.body.removeChild(a)
    })
  }

  renderOptions = () => {
    const options = this.state.previewTemplate.options
    return Object.keys(options).map(param => {
      if (param === 'account_id') {
        return (
          <div key={param} className="col-3">
          <label>{param}</label>
          <input className="form-control" value={this.state.previewTemplate.params[param] || ''} onChange={e => this.updateParams(param, e.target.value)}>
          </input>
        </div>
        )
      }
      const optionsHtml = options[param].map(option => {
        return <option key={option} value={option}>{option.toString()}</option>
      })

      if (param == 'plan_code' && !this.accountValueExists()) {
        return (
          <div key={param} className="col-8">
          <label>{param}</label>
          <Autocomplete
            planCodes={Object.keys(this.props.plans)}
            value={this.state.previewTemplate.params[param] || ''} 
            onChange={e => this.updateParams(param, e.target.value)} 
            disabled={() => this.accountValueExists()}
            />
          </div>
        )
      }
      return this.accountValueExists() ?
        ''
      :
        (
          <div key={param} className="col-3">
          <label>{param}</label>
          <select
            className="form-control"
            value={this.state.previewTemplate.params[param] || ''}
            onChange={e => this.updateParams(param, e.target.value)}
          >
            <option />
            {optionsHtml}

          </select>
          </div>
        )
    })
  }

  render() {
    const errorMessage = this.state.previewFailedMessage ? <div className="alert alert-danger mt-3">Error: {this.state.previewFailedMessage}</div> : null

    return (<Fragment>
      <div className="row">
        {this.renderOptions()}
        <div className="col-3">
          <br />
          <button className="btn btn-sm btn-default" disabled={!this.hasPreviewConfigured()} onClick={this.resetPreview}>Reset</button>
          <button className="btn btn-link" disabled={!this.hasPreviewConfigured()} onClick={this.downloadPreview}>Download PDF</button>
        </div>
      </div>
      {!this.hasPendingChanges() && this.showPendingVersion() ? <div className="alert alert-info mt-3">No pending changes</div> : null}
      {errorMessage}
      <div className="embed-responsive embed-responsive-1by1">
        <iframe className="embed-responsive-item" src={this.state.previewUrl} title="preview" />
      </div>
    </Fragment>)
  }
}

function mapStateToProps({ planFeatures, correspondenceTemplates, plans }) {
  return {
    earlyCancellationFees: planFeatures.earlyCancellationFees,
    correspondenceTemplate: correspondenceTemplates.currentCorrespondenceTemplate,
    plans: plans.info
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ getEarlyCancellationFees, getPlans }, dispatch)
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CorrespondenceTemplatePreview)
