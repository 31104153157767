import axios from 'axios'

export default function cloneCorrespondenceTemplate(
  correspondence_template_id,
  product_type_code,
  state_code
) {
  return function(dispatch, getState){
    const user_id = getState().user.userId
    dispatch({ type: 'SAVING_CORRESPONDENCE_TEMPLATE', loading: true })
    axios.post(
      `${process.env.REACT_APP_GARCON_API}/correspondence/correspondence_templates/${correspondence_template_id}/clones`,
      {
        correspondence_template_id,
        product_type_code,
        state_code,
        user_id,
      }
    )
      .then((response) => {
        dispatch({ type: 'CLONE_CORRESPONDENCE_TEMPLATE', payload: response.data })
      })
      .catch((error) => {
        dispatch({ type: 'SHOW_ERROR', payload: { response: error.response, message: error.response.data.description, headline: 'Failed to clone correspondence template' } })
      })
  }
}
