import axios from 'axios'
import getDisclosureDetail from './getDisclosureDetail'

export const sendBatch = (data) => {
  if (data.ready_to_send) {
    return async (dispatch, getState) => {
      dispatch({ type: 'SET_SENDING', payload: true })
      try {
        const user = getState().user
        const params = {
          sent_by_user_id: user.userId,
          sent_dt: new Date(),
        }
        const response = await axios.post(`${process.env.REACT_APP_GARCON_API}/correspondence/disclosures/${data.disclosure_id}/batches`, params)
        if (response){
          dispatch(getDisclosureDetail(data.disclosure_id))
          dispatch({ type: 'SET_SENDING', payload: false })
        }
      } catch (error) {
        dispatch({ type: 'SHOW_ERROR', payload: { response: error.response, message: 'An error occured getting disclosure.' } })
        dispatch({ type: 'SET_SENDING', payload: false })
        throw error
      }
    }
  } else {
    return async (dispatch) => {
      dispatch({ type: 'SHOW_ERROR', payload: { response: { headers: {} }, message: 'This disclosure is not ready to send.' } })
    }
  }
}
