import React, { useState, useEffect } from 'react'
import ReactTable from 'react-table'
import matchSorter from 'match-sorter'
import { useDispatch } from 'react-redux'
import history from '../../../history'
import { getRolloffRulesets } from '../../../apis/rolloffRulesets'
import Loading from '../../Shared/Loading'
import { changeModule } from '../../../actions/changeModule'

const Rulesets = () => {
  const dispatch = useDispatch()
  const [rulesets, setRulesets] = useState(null)
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    dispatch(changeModule('Rulesets'))
    getRolloffRulesets(setRulesets)
      .then(() => {
        setLoading(false)
      })
  }, [])

  const columns = [
    {
      Header: 'ID',
      accessor: 'rolloff_ruleset_id',
      filterMethod: (filter, rows) => (
        matchSorter(rows, filter.value, { keys: ['rolloff_ruleset_id'] })
      ),
      filterAll: true,
      minWidth: 200
    },
    {
      Header: 'Segments',
      accessor: 'state_code',
      filterMethod: (filter, rows) => (
        matchSorter(rows, filter.value, { keys: ['state_code'] })
      ),
      filterAll: true,
    },
    {
      Header: '',
      accessor: 'contract_type_code',
      filterMethod: (filter, rows) => (
        matchSorter(rows, filter.value, { keys: ['contract_type_code'] })
      ),
      filterAll: true,
    },
    {
      Header: '',
      accessor: 'product_type_code',
      filterMethod: (filter, rows) => (
        matchSorter(rows, filter.value, { keys: ['product_type_code'] })
      ),
      filterAll: true,
    },
    {
      Header: 'Date Created',
      id: 'created_at',
      accessor: props => new Date(props.created_at).toISOString().slice(0, 10),
      filterMethod: (filter, rows) => (
        matchSorter(rows, filter.value, { keys: ['created_at'] })
      ),
      filterAll: true,
    },
    {
      Header: 'Date Updated',
      id: 'updated_at',
      accessor: props => new Date(props.updated_at).toISOString().slice(0, 10),
      filterMethod: (filter, rows) => (
        matchSorter(rows, filter.value, { keys: ['updated_at'] })
      ),
      filterAll: true,
    },
  ]

  const handleRowSelect = (ruleset) => {
    history.push(`/rulesets/${ruleset.rolloff_ruleset_id}`)
  }

  return (
    <div className="container-fluid">
      <h4 className="my-3">
        Rolloff Rulesets
      </h4>
      {rulesets &&
        <ReactTable
          filterable
          className="-striped -highlight mb-3"
          columns={columns}
          data={rulesets}
          defaultPageSize={100}
          keyField="rolloff_ruleset_id"
          style={{ height: '500px' }}
          getTdProps={(s, r, c) => {
            if (c.id !== '_selector') {
              return {
                onClick: () => handleRowSelect(r.original),
                style: {
                  cursor: 'pointer',
                },
              }
            } else {
              return {}
            }
          }}
        />
      }
      {loading && <Loading />}
    </div>
  )
}

export default Rulesets
