import React from 'react'
import ReactTextareaAutocomplete from '@webscopeio/react-textarea-autocomplete'

const Item = ({ entity: { planCode }, selected }) => {
  if (selected){
    return (
      <div className="autocomplete-select" style={{ backgroundColor: '#F5F5F5' }}>
        <b>{planCode}</b>
      </div>
    )
  }
  return (
    <div className="autocomplete-select">
      <b>{planCode}</b>
    </div>
  )
}

export default ({ planCodes, value, onChange }) => {
  return (
    <ReactTextareaAutocomplete
      className="form-control mb-2 w-100"
      listStyle={{
        listStyleType: 'none',
        padding: 0,
      }}
      loadingComponent={() => <span>Loading</span>}
      textAreaComponent="input"
      placeholder="Type `#` followed by desired plan code"
      trigger={{
        '#': {
          dataProvider: token => {
            return planCodes.filter(plan => {
              if (plan.includes(token)){
                return true
              }
              return false
            }).map(plan => {
              return {planCode: plan}
            })
          },
          component: Item,
          output: (item) => `${item.planCode}`,
        },
      }}
      value={value}
      onChange={e => onChange(e)}
    />
  )
}
