import axios from 'axios'

export const getApprovers = async (callback) => {
  try {
    const response = await axios.get(`${process.env.REACT_APP_GARCON_API}/internal/account_payable/approvers`)
    callback(response.data)
  } catch (error) {
    throw error
  }
}

export const updateApprover = async (accountPayableApproverId, userId) => {
  try {
    return await axios.put(`${process.env.REACT_APP_GARCON_API}/internal/account_payable/approvers/${accountPayableApproverId}?user_id=${userId}`)
  } catch (error) {
    throw error
  }
}

export const getApproverUser = async (email) => {
  try {
    const response = await axios.get(`${process.env.REACT_APP_GARCON_API}/users/?email=${email}`)
    return response.data
  } catch (error) {
    throw error
  }
}
