import axios from 'axios'

export default function clonePlan(planCode, cloneSMR) {
  return function(dispatch) {
    dispatch({ type: 'CLONING_PLAN', payload: true })
    const params = {
      clone_static_market_rates: cloneSMR,
    }
    axios.post(`${process.env.REACT_APP_GARCON_API}/offers/plans/${planCode}/clone`, params)
      .then((response) => {
        dispatch({ type: 'SAVE_PLAN', payload: response.data })
        dispatch({ type: 'CLONING_PLAN', payload: false })
      })
      .catch((error) => {
        dispatch({ type: 'SHOW_ERROR', payload: { response: error.response, message: error.response.data } })
        dispatch({ type: 'CLONING_PLAN', payload: false })
        throw error
      })
  }
}
