import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import ReactTable from 'react-table'
import matchSorter from 'match-sorter'
import { changeModule } from '../../../../actions/changeModule'
import addCampaignPhoneNumber from '../../../../actions/campaigns/addCampaignPhoneNumber'
import getCampaignPhoneNumbers from '../../../../actions/campaigns/getCampaignPhoneNumbers'
import deleteCampaignPhoneNumber from '../../../../actions/campaigns/deleteCampaignPhoneNumber'
import WarningModal from '../../../WarningModal'
import SectionInput from '../../../Shared/SectionInput'
import { faTrashAlt } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import history from '../../../../history'

const CampaignPhoneNumbers = () => {

  const [phoneNumber, setPhoneNumber] = useState('')
  const [confirmPhoneNumber, setConfirmPhoneNumber] = useState('')
  const [removePhone, setRemovePhone] = useState({})
  const [show, setShow] = useState(false)
  const saving = useSelector(state => state.campaignPhoneNumbers.saving)
  const campaignPhoneNumbers = useSelector(state => state.campaignPhoneNumbers.all)
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(getCampaignPhoneNumbers(false))
    dispatch(changeModule('Campaign Phone Numbers'))
  } , [])

  const raw = (data) => {
    return data.startsWith('1') ? data.slice(1).replace(/[^0-9]/g,'') : data.replace(/[^0-9]/g,'')
  }

  const addPhoneNumber = () => {
    const phoneOne = raw(phoneNumber)
    const phoneTwo = raw(confirmPhoneNumber)
    if (phoneOne === phoneTwo){
      dispatch(addCampaignPhoneNumber(phoneOne))
      clearInputFields()
    } else {
      dispatch(addCampaignPhoneNumber(false))
      clearInputFields()
    }
  }

  const clearInputFields = () => {
    setPhoneNumber('')
    setConfirmPhoneNumber('')
  }

  const removeNumber = (data) => {
    dispatch(deleteCampaignPhoneNumber(data))
    closeModal()
  }

  const showModal = (data) => {
    setShow(true)
    setRemovePhone(data.original)
  }

  const closeModal = () => {
    setShow(false)
  }

  const columns =[{
    Header: 'Phone Number',
    accessor: 'phone_number_raw',
    filterMethod: (filter, rows) => (
      matchSorter(rows, filter.value, { keys: ['phone_number_raw'] })
    ),
    filterAll: true,
    Cell: (e) => (
      <div className="row justify-content-md-center mt-2">
        {e.value ? <p> ({e.value.substring(0,3)}) {e.value.substring(3,6)}-{e.value.substring(6,11)} </p> : <p>No Number</p> }
      </div>),
  },{
    Header: 'Manage',
    accessor: 'campaign_id',
    Cell: (e) =>
      (<div className="row justify-content-md-center">
        {e.value ? <a className="mt-2" href={`/campaigns/${e.value}`}> View Campaign </a> : <button className="btn btn-primary btn-md mt-1" type="button">Remove <FontAwesomeIcon icon={faTrashAlt} /></button>}
      </div>),
  }]

  return (
    <div className="container-fluid">
      <div>
        <a className="text-primary" onClick={() => history.push('/campaigns')}>← Back to Campaigns</a>
      </div>
      <div className="col-md-8 mx-auto">
        <h2 className="text-center mb-5">Campaign Phone Numbers</h2>
        <div className="row">
          <div className="col-md-11 mb-2">
            <SectionInput label="Add New Phone Number"  type="text" value={phoneNumber} onChange={e => setPhoneNumber(e.target.value)} />
            <SectionInput label="Confirm New Phone Number"  type="text" value={confirmPhoneNumber} onChange={e => setConfirmPhoneNumber(e.target.value)} />
          </div>
        </div>
        <div className="text-center">
          <button className="btn btn-block btn-primary btn-md mb-5" onClick={() => addPhoneNumber()}>{saving ? 'Adding Number!' : 'Add Number'} </button>
        </div>
        <ReactTable
          filterable
          className="-striped -highlight mb-3"
          columns={columns}
          data={campaignPhoneNumbers}
          defaultPageSize={20}
          getTdProps={(state, rowInfo, column) => {
            if (column.id === 'campaign_id') {
              return {
                onClick: () => !rowInfo.row.campaign_id ? showModal(rowInfo) : null,
                style: {
                  cursor: 'pointer',
                },
              }
            } else {
              return {}
            }
          }}
          keyField="phone_number_raw"
        />
        {show &&
        <WarningModal
          cancelAction={closeModal}
          confirmAction={removeNumber}
          show={show}
          warningData={removePhone}
          warningMessage={'You are about to remove this phone number'}
        /> }
      </div>
    </div>
  )
}

export default CampaignPhoneNumbers
