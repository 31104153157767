export function markets(state = { all: [], saving: false }, action) {
  switch (action.type) {
  case 'GETTING_MARKETS':
    return { ...state, loading: action.payload }
  case 'GET_MARKETS':
    return { ...state, all: action.payload }
  case 'CLEAR_MARKETS':
    return { ...state, all: [] }
  default:
    return state
  }
}

export default markets
