import axios from 'axios'

export default (from_dt, status, department) => {
  return async (dispatch) => {
    let query = ''
    for (let i = 0; i < status.length; i++) {
      query += `show_${status[i].value.toLowerCase()}=true&`
    }
    try {
      const response = await axios.get(`${process.env.REACT_APP_GARCON_API}/cases/list?filterObj={}&page=1&per_page=100&${query}department_code=${department}&from_date=${JSON.stringify({ date: from_dt })}`)
      if (response.data){
        dispatch({ type: 'GET_CASES', payload: response.data.data })
      }
    } catch (error) {
      dispatch({ type: 'SHOW_ERROR', payload: { response: error.response, message: 'An error occured getting cases.' } })
      throw error
    }
  }
}
