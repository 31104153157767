import axios from 'axios'

export  const updateUrl = (data) => {
  return async (dispatch) => {
    try {
      const params = {
        'url': data.url,
        'destination': data.destination,
      }
      const response = await axios.put(`${process.env.REACT_APP_GARCON_API}/cms/url_redirects/${data.url_redirects_id}/edit`, params)
      return response
    } catch (error) {
      dispatch({ type: 'SHOW ERROR', payload: { message: 'An error occured updating URL.' } })
      throw error
    }
  }
}
