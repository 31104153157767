import axios from 'axios'

export const getDefaultData = (contractType, market, channelCode, revenueClass, dynamicPricingAlgorithm) => {
  return function(dispatch) {
    const params = {
      market_code: market,
      contract_type_code: contractType.toLowerCase(),
    }
    if (channelCode) {
      params.channel_code = channelCode
    }
    if (revenueClass) {
      params.revenue_class_code = revenueClass
    }
    if (dynamicPricingAlgorithm) {
      params.dynamic_pricing_algorithm_code = dynamicPricingAlgorithm
    }

    dispatch({ type: 'GENERATING_DEFAULT_DATA', payload: true })
    axios.get(`${process.env.REACT_APP_GARCON_API}/accounts/volt/default_order_data/`, { params })
      .then((response) => {
        dispatch({ type: 'DEFAULT_DATA', payload: response.data })
        dispatch({ type: 'GENERATING_DEFAULT_DATA', payload: false })
        dispatch({ type: 'DEFAULT_DATA_GENERATED', payload: true })
      }).catch((error) => {
        dispatch({ type: 'GENERATING_DEFAULT_DATA', payload: false })
        dispatch({ type: 'SHOW_ERROR', payload: { response: error.response, message: error.response.data.error } })
        throw error
      })
  }
}

export const clearDefaultData = () => {
  return { type: 'CLEAR_DEFAULT_DATA' }
}

export const updateData = (key, input) => {
  return { type: 'UPDATE_DATA', payload: { [key]: input } }
}
