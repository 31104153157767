import React from 'react'
import ReactTable from 'react-table'
import matchSorter from 'match-sorter'
import moment from 'moment'
import { Link } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSignInAlt } from '@fortawesome/free-solid-svg-icons'

const RecentOffersTable = ({ campaigns, recentOffers }) => {

  const findCampaignName = (value) => {
    const matchedCampaign = campaigns.find(campaign => campaign.campaign_id === value)
    if (matchedCampaign){
      return `${matchedCampaign.campaign_name} - `
    }
  }

  const columns =[{
    Header: 'Prospect Address Offer Id',
    accessor: 'prospect_address_offer_id',
    filterMethod: (filter, rows) => (
      matchSorter(rows, filter.value, { keys: ['prospect_address_offer_id'] })
    ),
    filterAll: true,
    width:150,
    Cell: (e) =>(
      <div className="row justify-content-md-center">
        { e.value ? e.value : null }
      </div>
    ),
  },{
    Header: 'Offer Code',
    accessor: 'offer_code',
    width: 150,
    Cell: (e) =>(
      <div className="row justify-content-md-center">
        { e.value ? e.value : null }
      </div>
    ),
  },{
    Header: 'Offered Date',
    accessor: 'offered_dt',
    width: 200,
    filterMethod: (filter, rows) => (
      matchSorter(rows, filter.value, { keys: ['created_at'] })
    ),
    filterAll: true,
    Cell: (e) =>(
      <div className="row justify-content-md-center">
        { e.value ? <span>{moment(e.value).format('MM-DD-YYYY')}</span> : null }
      </div>
    ),
  }, {
    Header: 'Campaign Info',
    accessor: 'campaign_id',
    width: 800,
    Cell: (e) =>
      (<div className="row justify-content-md-center">
        { e.value ? <Link className="text-primary" target="_blank" to={`/campaigns/${e.value}`}> {findCampaignName(e.value)} {e.value} <FontAwesomeIcon icon={faSignInAlt} /> </Link> : <p>No Campaign ID for offer</p> }
      </div>),
  }]

  return (
    <div>
      <ReactTable
        filterable
        className="-striped -highlight"
        columns={columns}
        data={recentOffers}
        minRows={0}
      />
    </div>
  )
}

export default RecentOffersTable
