import React, { useEffect, useState } from 'react'
import { changeModule } from '../../actions/changeModule'
import { useDispatch, useSelector } from 'react-redux'
import { getMarketGeos } from '../../actions/marketGeos/getMarketGeos'
import MarketGeosContainer from './MarketGeosContainer'
import { Form, Modal, Row, Col } from 'react-bootstrap'
import uniqBy from 'lodash/uniqBy'
import { createMarketGeo } from '../../actions/marketGeos/createMarketGeo'
import getMarkets from '../../actions/getMarkets'

const MarketGeosIndex = () => {
  const dispatch = useDispatch()
  const [viewModal, setViewModal] = useState(false)
  const [states, setStates] = useState([])
  const marketInfo = useSelector(state => state.markets.all)
  const [formInfo, setFormInfo] = useState({})

  useEffect(() => {
    dispatch(changeModule('Market Geos'))
    dispatch(getMarketGeos())
    dispatch(getMarkets())
  }, [])

  useEffect(() => {
    const marketStates = uniqBy(marketInfo?.map(market => ({ label: market.state_code, value: market.state_code })), 'label')
    setStates(marketStates)
  }, [marketInfo])

  const handleViewModal = () => {
    setViewModal(true)
  }

  const handleCloseModal = () => {
    setViewModal(false)
  }

  const handleSave = async (e) => {
    e.preventDefault()
    const response = await dispatch(createMarketGeo(formInfo))
   if (response) {
    setViewModal(false)
    dispatch(getMarketGeos())
   }
  }

  return (
    <div className="container-fluid">
      <div className="row mt-3 align-items-right">
        <div className="col-11 text-right">
          <button className="btn btn-primary" onClick={() => {handleViewModal()}}>Add Market Geo</button>
        </div>
      </div>
      <MarketGeosContainer />

      { viewModal &&
        <>
          <Modal show={handleViewModal} onHide={handleCloseModal} size="lg" >
            <Modal.Header closeButton>Create New Market Geo</Modal.Header>
            <Modal.Body>
              <div className="d-flex mt-3">
                <Form>
                  <Row className='mb-3'>
                    <Col>
                      <Form.Label>Market Code</Form.Label>
                      <Form.Select className='Select-control' name="market_code" onChange={e => setFormInfo({ ...formInfo, market_code: e.target.value})}>
                      <option>Start here...</option>
                        {marketInfo.map((market, index) => {
                          return <option key={index} value={market.market_code}>{market.market_code} - {market.market_name}</option>
                        })}
                      </Form.Select>
                    </Col>
                    <Col>
                      <Form.Label>State</Form.Label>
                      <Form.Select className='Select-control' name="state_code" onChange={e => setFormInfo({...formInfo, state_code: e.target.value})}>
                      <option>Select one...</option>
                        {states.map((state, index) => {
                          return <option key={index} value={state.key}>{state.label}</option>
                        })}
                      </Form.Select>
                    </Col>
                    <Col>
                      <Form.Label>Status</Form.Label>
                      <Form.Select className='Select-control' name='verification_status' onChange={e => setFormInfo({...formInfo, verification_status: e.target.value})}>
                        <option>Choose...</option>
                        <option value='VERIFIED'>Verified</option>
                        <option value='DISPUTED'>Disputed</option>
                        <option value='PENDING'>Pending</option>
                      </Form.Select>
                    </Col>
                  </Row>
                  <Row className='mb-3'>
                    <Col>
                        <Form.Label>Zip Code</Form.Label>
                        <Form.Control
                          type='number'
                          name='zip_code'
                          required
                          pattern="[0-9]{5}"
                          onChange={e => setFormInfo({...formInfo, zip_code: e.target.value})}
                        />
                    </Col>
                    <Col>
                        <Form.Label>County</Form.Label>
                        <Form.Control
                          type='text'
                          name='county'
                          isInvalid={'^[a-zA-Z\s]+$'}
                          required
                          onChange={e => setFormInfo({...formInfo, county: e.target.value})}
                        />
                    </Col>
                    <Col>
                        <Form.Label>City</Form.Label>
                        <Form.Control
                          type='text'
                          name='city'
                          required
                          onChange={e => setFormInfo({...formInfo, city: e.target.value})}
                        />
                    </Col>
                  </Row>
                  <Row className="text-right">
                    <div className="col-12 text-right">
                      <button className="btn btn-primary" onClick={handleSave}>Save</button>
                    </div>
                  </Row>
                </Form>
              </div>
            </Modal.Body>
          </Modal>
        </>
      }
    </div>
  )
}

export default MarketGeosIndex
