import React, { useEffect, useState } from 'react'
import SectionSelect from '../../Shared/SectionSelect'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTrash } from '@fortawesome/free-solid-svg-icons'
import PlanSection from './PlanSection'
import TreatmentConfig from './TreatmentConfig'

const planChoices = [
  { name: 'Renew on different plan' },
  { name: 'Renew on same plan' },
  { name: 'Renew on default plan' },
  { name: 'Do not renew' },
]

const TreatmentBucket = ({
  amountOfBuckets,
  bucket,
  removeBucket,
  updateBuckets
}) => {
  const [planChoice, setPlanChoice] = useState(null)
  const [plan, setPlan] = useState(null)
  const [min, setMin] = useState(null)
  const [max, setMax] = useState(null)
  const [collapsed, setCollapsed] = useState(true)

  useEffect(() => {
    setMin(bucket.min)
    setMax(bucket.max)
    setPlanChoice(bucket.planChoice)
    if (bucket.planChoice === 'Renew on different plan') setPlan(bucket.plan)
  }, [])

  const updateRow = (field, value, minMaxBuckets = null) => {
    if (field === 'planChoice' && value !== 'Do not renew') setCollapsed(false)
    updateBuckets({ [field]: value }, bucket.index, minMaxBuckets)
  }

  useEffect(() => {
    setMin(bucket.min)
    setMax(bucket.max)
    setPlanChoice(bucket.planChoice)
    if (bucket.planChoice === 'Renew on different plan') {
      setPlan(bucket.plan)
    } else {
      setPlan(null)
    }
  }, [bucket])

  const amountInput = (field, value) => {
    return (
      <div className="col-4 d-inline-block">
        <input
          className="form-control rounded"
          type="number"
          value={value}
          onChange={(event) => updateRow(field, event.target.value)}
        />
      </div>
    )
  }

  const displayBucketRange = () => {
    const { index, min, max } = bucket
    if (index === 1 && amountOfBuckets === 1) return '-Infinity : Infinity'
    if (index === 1) return <span>{amountInput('max', max)} : Infinity</span>
    if (index === amountOfBuckets) return <span>-Infinity : {amountInput('min', min)}</span>
    return <span>{amountInput('max', max)} : {amountInput('min', min)}</span>
  }

  return (
    <>
      <div className="row">
        <div className="col-4">Bucket {bucket.index} {displayBucketRange()}</div>
        <div className="col-6">
          <SectionSelect
            labelKey="name"
            options={planChoices}
            type="text"
            value={planChoice}
            valueKey="name"
            onChange={(e) => updateRow('planChoice', e.target.value)}
          />
        </div>
        <div className="col-2">
          <a className="text-primary ml-2" onClick={(e) => removeBucket(e, bucket.index)}>
            <FontAwesomeIcon icon={faTrash} />
          </a>
          {planChoice !== 'Do not renew' &&
            <a className="ml-4" onClick={() => setCollapsed(!collapsed)}>
              <FontAwesomeIcon className="mr-2" icon={collapsed ? 'chevron-up' : 'chevron-down'} style={{ width: '12px', color: '#B31066' }} />
            </a>
          }
        </div>
      </div>

      {!collapsed &&
        planChoice === 'Renew on different plan' &&
        <PlanSection
          index={bucket.index}
          plan={plan}
          updateBuckets={updateBuckets}
        />
      }
      {!collapsed && planChoice !== 'Do not renew' &&
        <TreatmentConfig bucket={bucket} updateRow={updateRow} />
      }
    </>
  )
}

export default TreatmentBucket
