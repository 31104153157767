import axios from 'axios'

export const getAddressOfferCounts = async () => {
  try {
    const response = await axios.get(`${process.env.REACT_APP_GARCON_API}/internal/prospects/address_offer_counts`)
    return response?.data
  } catch (error) {
    throw error
  }
}
