const CompRunDataColumns = [
  { Header: 'Payment Period End Date', accessor: 'comp_period_end_dt' },
  { Header: 'Comp Date', accessor: 'comp_trigger_dt' },
  { Header: 'Channel', accessor: 'channel_partner_code' },
  { Header: 'Type', accessor: 'comp_type_code' },
  { Header: 'Sale Date', accessor: 'sale_dt' },
  { Header: 'Sales Rep ID', accessor: 'rep_id' },
  { Header: 'Avg Annual Usage', accessor: 'acq_avg_annual_usage' },
  { Header: 'Amount', accessor: 'comp_amt' },
  { Header: 'Status', accessor: 'comp_status_code' },
  { Header: 'Reason', accessor: 'comp_status_reason' },
  { Header: 'Account Offer Reason', accessor: 'account_offer_reason' },
  { Header: 'Account Status', accessor: 'status_group' },
  { Header: 'Status Details', accessor: 'account_status_message' },
  { Header: 'Account Status2', accessor: 'account_status' },
  { Header: 'Payable Reason', accessor: 'payable_reason' },
  { Header: 'Not Payable Reason', accessor: 'notpayable_reason' },
  { Header: 'Start Date', accessor: 'confirmed_start_dt' },
  { Header: 'End Date', accessor: 'confirmed_drop_dt' },
  { Header: 'Drop Request Date', accessor: 'comp_clawback_dt' },
  { Header: 'Account ID', accessor: 'account_id' },
  { Header: 'First Name', accessor: 'first_name' },
  { Header: 'Last Name', accessor: 'last_name' },
  { Header: 'Business Name', accessor: 'business_name' },
  { Header: 'Primary Phone', accessor: 'primary_phone' },
  { Header: 'Market', accessor: 'market_code' },
  { Header: 'Revenue Class', accessor: 'revenue_class_code' },
  { Header: 'Rate Sub Class', accessor: 'rate_class' },
  { Header: 'Plan Name', accessor: 'plan_code' },
  { Header: 'Internal Comp Run Comp ID', accessor: 'comp_run_comp_id' },
]

export default CompRunDataColumns
