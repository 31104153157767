import React, { useEffect } from 'react'
import { changeModule } from '../../actions/changeModule'
import DisclosuresContainer from './DisclosuresContainer'
import { getDisclosures } from '../../actions/disclosures/getDisclosures'
import { useDispatch } from 'react-redux'
const Disclosures = () => {

  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(changeModule('Disclosures'))
    dispatch(getDisclosures())
  }, [])

  return (
    <div className="container-fluid">
      <DisclosuresContainer />
    </div>
  )
}

export default Disclosures
