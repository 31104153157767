import axios from 'axios'

export const getUrlRedirects = () => {
  return function(dispatch){
    axios.get(`${process.env.REACT_APP_GARCON_API}/cms/url_redirects`)
      .then((response) => {
        dispatch({ type: 'GET_URL_REDIRECTS', payload: response.data })
      })
      .catch((error) => {
        dispatch({ type: 'SHOW_ERROR', payload: { response: error.response, message: 'An error occured getting URL redirects.' } })
        throw error
      })
  }
}
