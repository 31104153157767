import React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { updateData } from '../../actions/volt/defaultData'
import createVoltData from '../../actions/volt/createVoltData'

const VoltDataForm = ({ accountStatus }) => {

  const editableData = useSelector(state => state.defaultData.editable)
  const uneditableData = useSelector(state => state.defaultData.uneditable)
  const dispatch = useDispatch()

  const createData = () => {
    const orderHash = {
      ...editableData,
      ...uneditableData,
      account_status_group: accountStatus,
    }
    dispatch(createVoltData(orderHash))
  }

  return (
    <div className="data-form mt-4">
      <form>
        <div className="row">
          <div className="col-3">
            <div className="form-group">
              <label htmlFor="First Name">Customer First Name</label>
              <input className="form-control" id="name" value={editableData.first_name}  onChange={(e) => dispatch(updateData('first_name', e.target.value)) } />
            </div>
          </div>
          <div className="col-3">
            <div className="form-group">
              <label htmlFor="Last Name">Customer Last Name</label>
              <input className="form-control" id="lastName" value={editableData.last_name} onChange={(e) => dispatch(updateData('last_name', e.target.value)) } />
            </div>
          </div>
          <div className="col-3">
            <div className="form-group">
              <label htmlFor="Primary Phone">Primary Phone</label>
              <input className="form-control" id="phone" type="phone_number" value={editableData.primary_phone} onChange={(e) => dispatch(updateData('primary_phone', e.target.value)) }  />
            </div>
          </div>
          <div className="col-3">
            <div className="form-group">
              <label htmlFor="Email">Email</label>
              <input className="form-control" id="email" type="email" value={editableData.email} onChange={(e) => dispatch(updateData('email', e.target.value))} />
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-4">
            <div className="form-group">
              <label htmlFor="Offer Code">Offer Code</label>
              <input className="form-control" disabled={true} id="offerCode"  type="text" value={editableData.offer_code} />
            </div>
          </div>
          <div className="col-4">
            <div className="form-group">
              <label htmlFor="Channel Code">Channel Code</label>
              <input className="form-control" disabled={true} id="channelCode" type="text" value={editableData.channel_code} />
            </div>
          </div>
          <div className="col-4">
            <div className="form-group">
              <label htmlFor="Channel Partner Code">Channel Partner Code</label>
              <input className="form-control" id="channelPartnerCode" type="text" value={editableData.channel_partner_code} onChange={(e) => dispatch(updateData('channel_partner_code', e.target.value))} />
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-3">
            <div className="form-group">
              <label htmlFor="Market">Market</label>
              <input className="form-control" disabled={true} id="sourceCode" type="text" value={editableData.market_code} />
            </div>
          </div>
          <div className="col-3">
            <div className="form-group">
              <label htmlFor="Source Code">Source Code</label>
              <input className="form-control" id="sourceCode" type="text" value={editableData.source_code} onChange={(e) => dispatch(updateData('source_code', e.target.value))} />
            </div>
          </div>
          <div className="col-3">
            <div className="form-group">
              <label htmlFor="Sale Date">Sale Date</label>
              <input className="form-control" disabled={true} id="saleDate" type="date" value={editableData.sale_dt.substring(0,10)} />
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-4">
            <div className="form-group">
              <label htmlFor="Street Address">Street Address</label>
              <input className="form-control" id="street" type="text" value={editableData.service_address_line_1} onChange={(e) => dispatch(updateData('service_address_line_1', e.target.value))}  />
            </div>
          </div>
          <div className="col-3">
            <div className="form-group">
              <label htmlFor="City">City</label>
              <input className="form-control" id="city" type="text" value={editableData.service_city} onChange={(e) => dispatch(updateData('service_city', e.target.value))} />
            </div>
          </div>
          <div className="col-2">
            <div className="form-group">
              <label htmlFor="County">County</label>
              <input className="form-control" id="zip" type="text" value={editableData.service_county} onChange={(e) => dispatch(updateData('service_county', e.target.value))}  />
            </div>
          </div>
          <div className="col-1">
            <div className="form-group">
              <label htmlFor="State">State</label>
              <input className="form-control" id="state" type="text" value={editableData.service_state} onChange={(e) => dispatch(updateData('service_state', e.target.value))} />
            </div>
          </div>
          <div className="col-2">
            <div className="form-group">
              <label htmlFor="Zip">Zip Code</label>
              <input className="form-control" id="zip" value={editableData.service_zip_code} onChange={(e) => dispatch(updateData('service_zip_code', e.target.value))} />
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-4">
            <div className="form-group">
              <label htmlFor="Offer Code">Account Number</label>
              <input className="form-control" id="accountNumber" type="number" value={editableData.account_number} onChange={(e) => dispatch(updateData('account_number', e.target.value))} />
            </div>
          </div>
          <div className="col-4">
            <div className="form-group">
              <label htmlFor="Offer Code">Shopper Guid</label>
              <input className="form-control" disabled={true} id="shopperGuid" value={uneditableData.shopper_guid} />
            </div>
          </div>
          <div className="col-4">
            <div className="form-group">
              <label htmlFor="Offer Code">Prospect Guid</label>
              <input className="form-control" disabled={true} id="prospectGuid" value={uneditableData.prospect_guid} />
            </div>
          </div>
        </div>
      </form>
      <button className="btn btn-block btn-primary" onClick={() => createData()}>Create Data</button>
    </div>
  )
}

export default VoltDataForm
