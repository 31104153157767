import axios from 'axios'
import history from './history'

export default () => {
  axios.defaults.headers.common['Authorization'] = 'Bearer ' + localStorage.getItem('idToken')
  axios.defaults.headers.common['X-Inspire-Client'] = 'web-lighthouse'
  axios.defaults.headers.common['X-Heroku-App-Name'] = process.env.REACT_APP_HEROKU_APP_NAME || 'unknown-web-lighthouse'

  axios.interceptors.response.use(response => {
    return response
  }, error => {
    if (error.response && error.response.status === 401 || error.response.status === 403) {
      history.replace('/callback')
      return
    }
    throw error
  })
}
