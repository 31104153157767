import axios from 'axios'

export const getRolloffExperiments = async (callback) => {
  try {
    const response = await axios.get(`${process.env.REACT_APP_GARCON_API}/internal/experiments`)
    callback(response?.data.filter(experiment => experiment.experiment_type === 'ROLLOFF_TEST'))
  } catch (error) {
    throw error
  }
}

export const getRolloffExperiment = async (experimentId, callback) => {
  try {
    const response = await axios.get(`${process.env.REACT_APP_GARCON_API}/experiments/${experimentId}`)
    callback(response.data)
  } catch (error) {
    throw error
  }
}

export const createRolloffExperiment = async (experiment) => {
  try {
    const response = await axios.post(`${process.env.REACT_APP_GARCON_API}/experiments`, experiment)
    return response?.data
  } catch (error) {
    throw error
  }
}

export const updateRolloffExperiment = async (experimentId, experiment) => {
  try {
    await axios.post(`${process.env.REACT_APP_GARCON_API}/experiments/${experimentId}`, experiment)
  } catch (error) {
    throw error
  }
}
