import React, { useEffect, useState } from 'react'
import { Link, useLocation } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCopy, faTrash } from '@fortawesome/free-solid-svg-icons'
import { deconstructConfigForUI } from '../Treatments/helpers'
import { getPredicates } from '../../../apis/predicates'
import ViewTreatmentBucket from '../Treatments/ViewTreatmentBucket'
import Rule from './Rule'
import RuleLink from './RuleLink'
import SelectRule from './SelectRule'

const getType = (data) => {
  return data['rolloff_treatment'] ?
    {
      display_name: 'Treatment',
      lookup: 'rolloff_treatment',
      lookup_name: 'treatment_name',
      id: 'rolloff_treatment_id'
    } :
    {
      display_name: 'Experiment',
      lookup: 'experiment',
      lookup_name: 'experiment_name',
      id: 'experiment_id'
    }
}

const RulesetConfig = ({
  ruleset_config,
  hasLastRule = null,
  ruleset_id,
  addRule = null,
  removeRule = null,
  sortRules = null,
  addRuleCondition = null,
  removeRuleCondition = null,
  updateRuleConditions = null,
  setHasLastRule = null
}) => {
  const [lastRuleCollapsed, setLastRuleCollapsed] = useState(true)
  const [predicates, setPredicates] = useState(null)

  useEffect(() => {
    getPredicates(setPredicates)
  }, [])

  const location = useLocation()
  const isEdit = location.pathname.includes('edit')

  const rules = hasLastRule ? ruleset_config.slice(0, -1) : ruleset_config
  const showLastResortDropdown = ruleset_config.length === 0
  const lastRule = ruleset_config[ruleset_config.length - 1]
  const lastRuleType = lastRule && getType(lastRule)
  const lastRuleTypeId = lastRule && lastRule[lastRuleType.lookup][lastRuleType.id]
  const lastResortConfig =
    lastRule && lastRuleType.display_name === 'Treatment' &&
    deconstructConfigForUI(lastRule.rolloff_treatment.treatment_config)

  const lastRuleDelete = () => {
    removeRule(ruleset_config.length - 1)
    setHasLastRule(false)
  }

  return (
    <>
      <div className="mb-3">
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <h6 className="font-weight-bold">Rules</h6>
          {isEdit && !showLastResortDropdown &&
            <SelectRule addRule={addRule} />
          }
        </div>
        {rules.map((rule, index) => {
          const type = getType(rule)
          const ruleConfig =
            type.display_name === 'Treatment' && deconstructConfigForUI(rule.rolloff_treatment.treatment_config)
          return (
            <React.Fragment key={index}>
              <Rule
                index={index}
                id={rule[type.lookup][type.id]}
                isEdit={isEdit}
                removeRule={removeRule}
                sortRules={sortRules}
                predicates={predicates}
                rule={rule}
                ruleConfig={ruleConfig}
                ruleset_id={ruleset_id}
                ruleset_config={ruleset_config}
                type={type}
                addRuleCondition={addRuleCondition}
                removeRuleCondition={removeRuleCondition}
                updateRuleConditions={updateRuleConditions}
              />
            </React.Fragment>
          )
        })}
      </div>

      <h6 className="font-weight-bold">Last Resort Rule</h6>
      {hasLastRule && lastRule ?
        <div>
          {lastRule[lastRuleType.lookup][lastRuleType.lookup_name]} - {lastRuleType.display_name}
          {isEdit && hasLastRule &&
            <a className="ml-4" onClick={() => lastRuleDelete()}>
              <FontAwesomeIcon
                className="mr-2"
                icon={faTrash}
                style={{ width: '12px', color: '#B31066' }}
              />
            </a>
          }
          <a className="ml-4"
            onClick={() => setLastRuleCollapsed(!lastRuleCollapsed)}
          >
            <FontAwesomeIcon
              className="mr-2"
              icon={lastRuleCollapsed ? 'chevron-up' : 'chevron-down'}
              style={{ width: '12px', color: '#B31066' }}
            />
          </a>
        </div> :
        <div>
          <SelectRule
            addRule={addRule}
            lastResort
            showLastResortDropdown={!hasLastRule}
            setHasLastRule={setHasLastRule}
          />
        </div>
      }

      {hasLastRule && lastRule && !lastRuleCollapsed &&
        <>
          <div className="mb-2">
            {lastRuleTypeId}
            <Link
              className="text-primary ml-2"
              onClick={
                () => {
                  navigator.clipboard.writeText(lastRuleTypeId)
                }
              }
              to={`/rulesets/${ruleset_id}`}
            >
              <FontAwesomeIcon icon={faCopy} />
            </Link>
            <RuleLink displayName={lastRuleType.display_name} rule={lastRule} />
          </div>
          {lastResortConfig &&
            <>
              {lastResortConfig.map((bucket, index) => {
                return (
                  <ViewTreatmentBucket
                    bucket={bucket}
                    index={index}
                    key={index}
                  />
                )
              })}
            </>
          }
        </>
      }
    </>
  )
}

export default RulesetConfig
