import React, { Component } from 'react'
import { deleteUrl } from '../../actions/urlRedirects/deleteUrl'
import { updateUrl } from '../../actions/urlRedirects/updateUrl'
import history from '../../history'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

class ManageUrl extends Component {
  constructor() {
    super()
    this.defaultState = {
      url: '',
      destination: '',
      message: '',
      validationMessage: '',
    }
    this.state = this.defaultState
  }

  componentDidMount = () => {
    const urlInfo = history.location.state.manage
    this.setState(urlInfo)
  }

  cancelUpdate = () => {
    history.push('/url_redirects')
  }

  handleUpdate = async(e) => {
    e.preventDefault()
    if (this.validateUrl()) {
      const response = await this.props.updateUrl(this.state)
      if (response.status === 200) {
        this.setState(this.defaultState)
        this.setState({ message: 'URL updated!' })
        setTimeout(() => history.push('/url_redirects'), 3000)
      }
    }
  }

  validateUrl = () => {
    const regex = /(^\/\w+)*?\/\S*?$/
    if (!regex.test(this.state.url) || !regex.test(this.state.destination)) {
      this.setState({ validationMessage: 'URL format not valid.' })
      return false
    } else {
      this.setState({ validationMessage: 'URL format valid.' })
      return true
    }
  }

  render(){
    return (
      <div className="col-md-12 mx-auto">
        <div className="d-flex">
          <h2 className="text-left w-100">Manage URL Redirect</h2>
        </div>
        <form>
          <div className="row">
            <div className="col-md-4 mb-3">
              <label>URL</label>
              <input required className="form-control" value={this.state.url} onChange={(e) => this.setState({ url: e.target.value })} />
            </div>
            <div className="col-md-6 mb-3">
              <label>Destination</label>
              <input required className="form-control" value={this.state.destination} onChange={(e) => this.setState({ destination: e.target.value })} />
            </div>
          </div>
          <div className="valid-feedback d-block text-center mt-2">
            {this.state.validationMessage}
          </div>
          <div className="row">
            <div className="col-md-2 mb-3">
              <label />
              <button className="btn btn-secondary btn-md btn-block" style={{ marginTop: '28px' }} onClick={this.cancelUpdate}>Cancel</button>
            </div>
            <div className="col-md-2 mb-3">
              <label />
              <button className="btn btn-primary btn-md btn-block" style={{ marginTop: '28px' }} onClick={this.handleUpdate}>Save Changes</button>
              <div className="valid-feedback d-block text-center mt-2">
                {this.state.message}
              </div>
            </div>
          </div>
        </form>
      </div>
    )
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ updateUrl, deleteUrl }, dispatch)
}

export default connect(null, mapDispatchToProps) (ManageUrl)
