import React, { useEffect, useState } from 'react'
import SectionSelect from '../../Shared/SectionSelect'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTrash } from '@fortawesome/free-solid-svg-icons'
import { bucketAmountToDisplay, modifiers, staticRateModifiers } from './helpers'

const minMaxBucketChoices = [
  { name: 'Current Price' },
  { name: 'Current PTC' },
  { name: 'Next Contract CTS' },
  { name: 'Static Rate' },
]

const MinMaxConfig = ({
  minMaxBucket,
  removeRow,
  updateMinMaxBuckets
}) => {
  const [minMaxBucketChoice, setMinMaxBucketChoice] = useState(null)
  const [minMaxModifier, setMinMaxModifer] = useState(null)
  const [minMaxBucketAmount, setMinMaxBucketAmount] = useState(null)

  useEffect(() => {
    setMinMaxBucketChoice(minMaxBucket.bucketChoice)
    setMinMaxModifer(minMaxBucket.modifier)
    if (minMaxBucket.bucketAmount) setMinMaxBucketAmount(minMaxBucket.bucketAmount)
  }, [])

  useEffect(() => {
    if (minMaxBucket) {
      setMinMaxBucketChoice(minMaxBucket.bucketChoice)
      setMinMaxModifer(minMaxBucket.modifier)
      if (minMaxBucket.bucketAmount) setMinMaxBucketAmount(minMaxBucket.bucketAmount)
      if (minMaxBucketAmount && !minMaxBucket.bucketAmount) setMinMaxBucketAmount(null)
    }
  }, [minMaxBucket])

  const bucketModifiers = minMaxBucketChoice && minMaxBucketChoice == 'Static Rate' ? staticRateModifiers : modifiers

  return (
    <>
      <div className="row mb-4">
        <div className="ml-3 mr-3">
          <SectionSelect
            labelKey="name"
            options={minMaxBucketChoices}
            type="text"
            value={minMaxBucketChoice}
            valueKey="name"
            onChange={(e) => updateMinMaxBuckets('bucketChoice', e.target.value, minMaxBucket.index)}
          />
        </div>
        <SectionSelect
          labelKey="name"
          options={bucketModifiers}
          type="text"
          value={minMaxModifier}
          valueKey="name"
          onChange={(e) => updateMinMaxBuckets('modifier', e.target.value, minMaxBucket.index)}
        />
        {minMaxModifier && minMaxModifier !== 'no modifier' &&
          <div className="col-3">
            <input
              className="form-control rounded"
              type="number"
              value={minMaxBucketAmount}
              onChange={(e) => updateMinMaxBuckets('bucketAmount', e.target.value, minMaxBucket.index)}
            />
            {minMaxBucketAmount && bucketAmountToDisplay(minMaxModifier, minMaxBucketAmount)}
          </div>
        }
        <a className="text-primary ml-4" onClick={(e) => removeRow(e, minMaxBucket.index)}>
          <FontAwesomeIcon icon={faTrash} />
        </a>
      </div>
    </>
  )
}

export default MinMaxConfig
