import moment from 'moment'
import { Link } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSignInAlt } from '@fortawesome/free-solid-svg-icons'

const TreatmentImplementations = ({ implementations }) => {
  return (
    <div className="card mb-4">
      <div className="card-header" style={{ display: 'flex', justifyContent: 'space-between' }}>
        <strong>Implementations</strong>
      </div>
      <div className="card-body">
        <p><strong>Experiments</strong></p>
        {implementations.experiments.map((experiment, index) => {
          const { experiment_id, experiment_name, created_at } = experiment
          return (
            <div className="row" key={index}>
              <div className="col-4">Name: {experiment_name}</div>
              <div className="col-4">ID: {experiment_id} <Link
                className="text-primary ml-2"
                target="_blank"
                to={`/rolloff_experiments/experiment/${experiment_id}`}
              >
                <FontAwesomeIcon icon={faSignInAlt} />
              </Link></div>
              <div className="col-4">Created Date: {moment(created_at).format("MM/DD/YYYY")}</div>
            </div>
          )
        })}
        <p className='mt-3'><strong>Rulesets</strong></p>
        {implementations.rolloff_rulesets.map((ruleset, index) => {
          const { rolloff_ruleset_id, product_type_code, contract_type_code, state_code } = ruleset
          return (
            <div className="row" key={index}>
              <div className="col-4">ID: {rolloff_ruleset_id}</div>
              <div className="col-4">Conditions: {state_code} {contract_type_code} {product_type_code}</div>
              <div className="col-4">Created Date: {moment(ruleset.created_at).format("MM/DD/YYYY")}</div>
            </div>
          )
        })}
      </div>
    </div>
  )
}

export default TreatmentImplementations
