import React from 'react'
import { DatePicker } from 'antd'

export default ({ rule, onChange }) => {
  const ruleType = rule.rule_type || 'after'
  const ruleValue = rule.object ? rule.object : [0]

  const options = [
    { value: 'after', label: 'After' },
    { value: 'before', label: 'Before' },
  ]

  const onChangeRuleType = (e) => {
    onChangeRule(e.target.value, null)
  }

  const onChangeRuleValue = (e) => {
    // Truncate from unix timestamp to just date
    onChangeRule(null, new Date((new Date(e)).toDateString()))
  }

  const onChangeRule = (newRuleType, newRuleValue) => {
    onChange({
      ...rule,
      rule_type: newRuleType || ruleType,
      object: newRuleValue ? [newRuleValue] : ruleValue,
      predicate: rule.predicate,
    })
  }

  return (
    <div>
      <select
        className="mx-3"
        options={options}
        placeholder=""
        value={ruleType}
        onChange={onChangeRuleType}
      >
        {options.map(({ value, label }) => <option key={value} value={value}>{label}</option>)}
      </select>

      <DatePicker
        className={'border rounded'}
        dateFormat="yyyy-MM-dd"
        onChange={onChangeRuleValue}
      />
    </div>
  )
}
