import axios from "axios"

export const createMarketGeo = (formData) => {
  return async(dispatch) => {
    try {
      const response = await axios.post(`${process.env.REACT_APP_GARCON_API}/offers/market_geos`, formData)
      if(response.status === 200) {
        return response
    }
    } catch (error) {
      dispatch({ type: 'SHOW_ERROR', payload: { message: 'An error occured creating market geo.' } })
      throw error
    }
  }
}
