import React, { useEffect, useState } from 'react'
import history from '../../../history'
import moment from 'moment'
import { createRolloffExperiment } from '../../../apis/rolloffExperiments'
import SectionInput from '../../Shared/SectionInput'
import RolloffVariationsContainer from './RolloffVariationsContainer'
import {
  getVariationsTotal,
  variationsErrors,
  saveIsDisabled
} from './helpers'

const CreateExperiment = () => {
  const [formData, setFormData] = useState({
    name: '',
    startDate: '',
    endDate: '',
    description: ''
  })
  const [error, setError] = useState(null)
  const [control, setControl] = useState({ amount: 0, treatment_id: '', name: '' })
  const [variations, setVariations] = useState([{ amount: 0, index: 1, treatment_id: '', name: '' }])
  const [total, setTotal] = useState(0)

  useEffect(() => {
    const runningTotal = getVariationsTotal(control, variations)
    setTotal(runningTotal)
  }, [control, variations])

  const onChange = (key, value) => {
    // verify start & end dates
    if (key === 'startDate' && value) {
      let isValidStartDate
      isValidStartDate = value.startOf('day') >= moment().startOf('day')
      if (formData.endDate && isValidStartDate) {
        isValidStartDate = value.startOf('day') < formData.endDate.startOf('day')
      }
      if (isValidStartDate) {
        if (error && error.message.includes('start')) setError(null)
      } else {
        setError({ message: 'Invalid start date' })
      }
    }
    if (key === 'endDate' && value) {
      let isValidEndDate
      isValidEndDate = value.startOf('day') >= moment().startOf('day')
      if (formData.startDate && isValidEndDate) {
        isValidEndDate = value.startOf('day') > formData.startDate.startOf('day')
      }
      if (isValidEndDate) {
        if (error && error.message.includes('end')) setError(null)
        const isValidStartDate = formData.startDate ? formData.startDate.startOf('day') >= moment().startOf('day') : true
        if (!isValidStartDate) setError({ message: 'Invalid start date' })
      } else {
        setError({ message: 'Invalid end date' })
      }
    }

    setFormData({ ...formData, [key]: value })
  }

  const onSubmit = (e) => {
    e.preventDefault()

    const controlData = {
      config: { rolloff_treatment_id: control.treatment_id },
      percent_allocation: control.amount,
      variation_name: control.name
    }
    const variationsData = variations.map((variant) => {
      return {
        config: { rolloff_treatment_id: variant.treatment_id },
        percent_allocation: variant.amount,
        variation_name: variant.name
      }
    })

    const experimentPayload = {
      experiment_name: formData.name,
      experiment_slug: null,
      experiment_type: "ROLLOFF_TEST",
      experiment_desc: formData.description,
      experiment_start_dt: formData.startDate,
      experiment_end_dt: formData.endDate,
      control: controlData,
      variations: variationsData
    }

    createRolloffExperiment(experimentPayload)
      .then((response) => {
        history.push(`/rolloff_experiments/experiment/${response.experiment_id}`)
      })
      .catch((error) => {
        setError(error)
      })
  }

  const errors = variationsErrors({ control, total, variations, formData })

  return (
    <div className="container-fluid">
      <form>

        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <h4 className="my-3">
            New Experiment
          </h4>
          <button
            className="btn btn-primary btn-md mb-2"
            disabled={saveIsDisabled(errors) || error}
            onClick={(e) => onSubmit(e)}
          >
            Save Experiment
          </button>
        </div>

        <div className="card mb-4">
          <div className="card-header">
            <strong>Details</strong>
          </div>
          <div className="card-body">
            <p style={{ color: 'red' }}>{error?.message}</p>
            <div className="col-8">
              <SectionInput
                label="Experiment Name"
                type="text"
                value={formData.name}
                onChange={(e) => onChange('name', e.target.value)}
              />
              <SectionInput
                label="Start Date"
                type="date"
                value={formData.startDate}
                onChange={(date) => onChange('startDate', date)}
              />
              <SectionInput
                label="End Date"
                type="date"
                value={formData.endDate}
                onChange={(date) => onChange('endDate', date)}
              />
              <SectionInput
                label="Description"
                type="textarea"
                value={formData.description}
                onChange={(e) => onChange('description', e.target.value)}
              />
            </div>
          </div>
          <RolloffVariationsContainer
            control={control}
            controlEditable
            variations={variations}
            variationsErrors={errors}
            setControl={setControl}
            setVariations={setVariations}
            total={total}
          />
        </div>
      </form>
    </div>
  )
}

export default CreateExperiment
