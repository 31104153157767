import axios from 'axios'

export default function getVendorFulfillmentChannels() {
  return function (dispatch) {
    axios.get(`${process.env.REACT_APP_GARCON_API}/rewards/vendor_fulfillment_channels`)
      .then((response) => {
        dispatch({ type: 'GET_VENDOR_FULFILLMENT_CHANNELS', payload: response.data })
      })
      .catch((error) => {
        dispatch({ type: 'SHOW_ERROR', payload: { response: error.response, message: 'An error occurred getting vendor fulfillment channels' } })
      })
  }
}
