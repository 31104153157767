import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { useSelector } from 'react-redux'
import Loading from './Shared/Loading'
import { useAuth0 } from '@auth0/auth0-react'
import { getUser } from '../actions/Users/getUser'
import ErrorModal from './ErrorModal'

const Main = ({ children }) => {
  const dispatch = useDispatch()
  const { isAuthenticated, isLoading, user, loginWithRedirect } = useAuth0()
  const error = useSelector(state => state.error)

  useEffect(() => {
    if (user && !isLoading){
      dispatch(getUser(user.email))
    } else if (!user && !isLoading) {
      loginWithRedirect()
    }
  }, [user, isLoading])

  if (isAuthenticated) {
    return (
      <main className="main col-md-10 ml-sm-auto my-5 px-4" role="main">
        <div className="mt-3">
          {children}
          {error.show && <ErrorModal />}
        </div>
      </main>
    )
  } else {
    return <Loading />
  }
}

export default Main
