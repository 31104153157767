import axios from 'axios'

export const getCustomerIoResultsCsv = async (recipient, type, start_date, end_date, setPresignedUrl, setError) => {
  try {
    const response = await axios.get(`${process.env.REACT_APP_GARCON_API}/correspondence/customer_io/records`, {
      params: {
        recipient,
        type,
        start_date,
        end_date
      }
    })
    setPresignedUrl(response.data.replace(/\u0026/, '&' ))
  } catch (error) {
    setError('Error: Request failed with status code 500')
    throw error
  }
}
