import React, { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { getSources } from '../../actions/getSources'
import ReactTable from 'react-table'
import history from '../../history'

const AgentsTable = () => {

  const dispatch = useDispatch()
  const agents = useSelector(state => state.salesAgents.all)

  useEffect(() => {
    dispatch(getSources())

  } , [])

  const goToUpdate = (input) => {
    history.push('/agents/update', {
      edit: input,
    })
  }

  const filterData = ((filter, row) => row[filter.id] ? row[filter.id].toLowerCase().includes(filter.value.toLowerCase()) : false)

  const columns = [{
    Header: 'Agent ID',
    accessor: 'agent_id',
    filterMethod: (filter, row) => filterData(filter,row),
  }, {
    Header: 'First Name',
    accessor: 'first_name',
    filterMethod: (filter, row) => filterData(filter,row),
  }, {
    Header: 'Last Name',
    accessor: 'last_name',
    filterMethod: (filter, row) => filterData(filter,row),
  }, {
    Header: 'Channel Code',
    accessor: 'channel_code',
    filterMethod: (filter, row) => filterData(filter,row),
  }, {
    Header: 'Source Code',
    accessor: 'source_code',
    filterMethod: (filter, row) => filterData(filter,row),
  },{
    Header: 'Office Code',
    accessor: 'office_code',
    filterMethod: (filter, row) => filterData(filter,row),
  }, {
    Header: 'Status',
    accessor: 'status',
    filterMethod: (filter, row) => filterData(filter,row),
  }, {
    Header: 'Enrollment App Vendor',
    accessor: 'vendor_code',
    filterMethod: (filter, row) => filterData(filter,row),
  }, {
    Header: 'Update Agent',
    accessor: 'edit',
    Cell: row => (
      <div className="row justify-content-md-center">
        <button className="btn btn-primary btn-md mr-2" onClick={() => goToUpdate(row.original)}>Edit</button>
      </div>
    ),
  },
  ]

  return (
    <div className="office-table col-md-12 mx-auto">
      <ReactTable
        filterable
        className="-striped -highlight mb-3"
        columns={columns}
        data={agents}
        defaultPageSize={20}
        getTdProps={(c, r) => {
          if (c.id === 'edit') {
            return {
              onClick: () => this.goToUpdate(r.original),
              style: {
                cursor: 'pointer',
              },
            }
          } else {
            return {}
          }
        }}
        keyField="agent_id"
      />
    </div>
  )
}

export default AgentsTable
