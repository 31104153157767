import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import getPredicates from '../../../../actions/segments/getPredicates'
import getPredicate from '../../../../actions/segments/getPredicate'
import getSegmentCampaigns from '../../../../actions/campaigns/getSegmentCampaigns'
import createSegment from '../../../../actions/segments/createSegment'
import updateSegment from '../../../../actions/segments/updateSegment'
import SectionSelect from '../../../Shared/SectionSelect'
import RuleValueInput from './RuleValueInput'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSignInAlt } from '@fortawesome/free-solid-svg-icons'

class CreateSegment extends Component {
  constructor(props) {
    super(props)
    this.state = {
      index: 0,
      segmentDescription: '',
      segmentRules: [],
    }
  }
  componentDidMount() {
    this.props.getPredicates()
    if (this.props.segmentToEdit.segment_assignment_id) {
      this.setState({ segmentDescription: this.props.segmentToEdit.segment_description })
      this.props.getSegmentCampaigns(this.props.segmentToEdit.segment_assignment_id)
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.segmentToEdit.segment_assignment_id && prevProps.predicates !== this.props.predicates) {
      const segmentRules = this.props.segmentToEdit.segment_rules_attributes
      this.setState({ segmentRules, index: segmentRules.length })
    }
    if (this.props.segmentToEdit && prevProps.segmentToEdit !== this.props.segmentToEdit) {
      this.setState({ segmentDescription: this.props.segmentToEdit.segment_description })
      this.props.getSegmentCampaigns(this.props.segmentToEdit.segment_assignment_id)
    }
  }

  onChangeSegmentDesc = (event) => {
    this.setState({ segmentDescription: event.target.value })
  }

  onAddRule = () => {
    this.setState({
      index: this.state.index + 1,
      segmentRules: [...this.state.segmentRules, { predicate: this.state.index, object: [] }],
    })
  }

  onChangeRulePredicate = (index, predicate) => {
    this.props.getPredicate(predicate)
    const newSegmentRules = this.state.segmentRules.slice(0)
    newSegmentRules[index] = { predicate, object: [] }
    this.setState({ segmentRules: newSegmentRules })
  }

  onChangeRuleValue = (index, rule) => {
    const newSegmentRules = this.state.segmentRules.slice(0)
    newSegmentRules[index] = rule
    this.setState({ segmentRules: newSegmentRules })
  }

  onCreateSegment = () => {
    if (this.props.segmentToEdit.segment_assignment_id) {
      this.props.updateSegment(this.props.segmentToEdit.segment_assignment_id, this.state.segmentDescription, this.state.segmentRules)
    } else {
      this.props.createSegment(this.state.segmentDescription, this.state.segmentRules)
    }
    this.setState({ message: 'Segment success!', segmentRules: [], segmentDescription: '' })
    setTimeout(() => {
      this.props.onCloseCreateSegment()
    }, 3000)
  }

  onDeleteRule = async (segment_rule_id, i) => {
    const newSegmentRules = [...this.state.segmentRules]
    if (!segment_rule_id) {
      delete newSegmentRules[i]
      const filteredRules = newSegmentRules.filter(rule => Object.keys(rule).length)
      await this.setState({ segmentRules: filteredRules })
    } else {
      newSegmentRules[i]._delete = true
      this.setState({ segmentRules: newSegmentRules })
    }
  }

  predicates() {
    return Object.values(this.props.predicates)
      .map(predicate => predicate.segment_predicate)
      .filter(predicate => predicate.display_in_rule_builder)
  }

  segmentRules() {
    return this.state.segmentRules.filter(r => !r._delete)
  }

  segmentRuleOptions(predicate) {
    if (!this.props.predicates[predicate]) return null
    return this.props.predicates[predicate].options
  }

  disableCreate() {
    this.state.segmentRules.filter(r => !r._delete)
    return this.state.segmentRules.length <= 0
  }

  render() {
    const segmentCampaigns = this.props.segmentCampaigns

    return (
      <div className="col-md-10 mx-auto">
        <br />
        <div className="row">
          <label>Segment Description</label>
          <input className="form-control" label="Segment Description" type="text" value={this.state.segmentDescription} onChange={this.onChangeSegmentDesc} />
        </div>
        {this.segmentRules().map((segmentRule, i) => (
          <div key={`segmentRule-${segmentRule.predicate}`} className="row mb-2 justify-content-between">
            <div className="col-5">
              <br />
              <label>Predicate</label>
              <SectionSelect
                labelKey="description"
                options={this.predicates()}
                type="text"
                value={segmentRule.predicate}
                valueKey="predicate"
                onChange={(event) => this.onChangeRulePredicate(i, event.target.value)}
              />
              <div className="row">
                <div className="col-6">
                  <a className="text-primary" onClick={() => this.onDeleteRule(segmentRule.segment_rule_id, i)}>Remove Rule</a>
                </div>
              </div>
            </div>
            <div className="col-5">
              <br />
              <label>Rule</label>
              <RuleValueInput
                predicate={this.props.predicates[segmentRule.predicate]}
                rule={segmentRule}
                onChange={(rule) => this.onChangeRuleValue(i, rule)}
              />
            </div>

          </div>
        ))}
        <div className="row mb-3">
          <div className="col-4 mr-0 text-right" style={{ padding: '0' }}>
            <button className="btn btn-info btn-md btn-block mt-4" onClick={this.onAddRule}>Add Rule</button>
          </div>
        </div>
        <div className="row">
          <a className="btn btn-primary btn-md btn-block mt-4" disabled={this.disableCreate()} onClick={this.onCreateSegment}>Save</a>
        </div>
        <div className="valid-feedback d-block text-center mt-2">
          {this.state.message}
        </div>
        {this.props.segmentToEdit.segment_assignment_id &&
          <div className="col mt-4">
            <h6 className="mb-3">Segment Campaigns ({segmentCampaigns.length} total)</h6>
            <ul className="list-group list-unstyled mb-5">
              {segmentCampaigns.length ? segmentCampaigns.map((campaign, index) => {
                const { campaign_id, campaign_name } = campaign
                return (
                  <li className="mb-3" key={`${campaign_id}-${index}`}>
                    {campaign_name} - {campaign_id} <Link className="text-primary" rel="noopener noreferrer" target="_blank" to={`/campaigns/${campaign_id}`}><FontAwesomeIcon icon={faSignInAlt} /></Link>
                  </li>
                )
              }) : <li>No campaigns for this segment.</li>}
            </ul>
          </div>
        }
      </div>
    )
  }
}

function mapStateToProps(state) {
  return {
    segmentCampaigns: state.segmentCampaigns.all,
    predicates: state.predicates.info,
  }
}

const mapDispatchToProps = {
  getPredicates,
  getPredicate,
  getSegmentCampaigns,
  createSegment,
  updateSegment,
}

export default connect(mapStateToProps, mapDispatchToProps)(CreateSegment)

CreateSegment.defaultProps = {
  segmentToEdit: {},
}
