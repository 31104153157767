import { Link } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSignInAlt } from '@fortawesome/free-solid-svg-icons'

const RuleLink = ({ displayName, rule }) => {
  const generateLink = () => {
    if (displayName === 'Treatment') {
      return `/rolloff_treatments/treatment/${rule.rolloff_treatment.rolloff_treatment_id}`
    } else {
      return `/rolloff_experiments/experiment/${rule.experiment.experiment_id}`
    }
  }

  return (
    <Link
      className="text-primary ml-2"
      target="_blank"
      to={generateLink()}
    >
      <FontAwesomeIcon icon={faSignInAlt} />
    </Link>
  )

}

export default RuleLink
