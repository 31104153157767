import axios from 'axios'

export function getEnrollmentVendors() {
  return function(dispatch){
    axios.get(`${process.env.REACT_APP_GARCON_API}/sales/enrollment_vendors`)
      .then((response) => {
        response.data.push({ vendor_code: 'NONE', vendor_name: '' })
        dispatch({ type: 'GET_ENROLLMENT_VENDOR_APPS', payload: response.data })
      })
      .catch((error) => {
        dispatch({ type: 'SHOW_ERROR', payload: { response: error.response, message: 'There was an error getting agents' } })
        throw error
      })
  }
}
