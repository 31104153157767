import React, { useState } from 'react'

const SegmentsContext = ({ loading, segments }) => {
  const [show, setShow] = useState(false)

  const toggleShow = () => {
    setShow(!show)
  }

  const renderSegments = () => {
    return (
      <div>
        <div className="row">
          {segments.map(segment => (
            <div key={`segment-${segment.segment_assignment_id}`} className="col-4 mb-2">
              {segment.segment_description}
            </div>
          ))}
        </div>
      </div>
    )
  }


  return (
    <div className="card mb-3">
      <div className="card-header d-flex justify-content-between">
        <strong>Segments</strong>
        <a className="text-primary" onClick={toggleShow}>{show ? 'Hide' : 'Show'}</a>
      </div>
      { show ? <div className="card-body">
        {loading ? (<div>Loading Segments...</div>) : (renderSegments())}
      </div> : null}
    </div>
  )
}

export default SegmentsContext
