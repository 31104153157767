import React from 'react'
import startCase from 'lodash/startCase'
import ReactTable from 'react-table'
import LoyaltyProgramsSelect from '../../Shared/LoyaltyProgramsSelect'
import { Link } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCopy, faSignInAlt } from '@fortawesome/free-solid-svg-icons'

const PlanLoyaltyPrograms = ({
  readonly,
  plan,
  onUpdateLoyaltyPrograms,
}) => {
  const getPlanLoyaltyPrograms = () => {
    if (plan.plan_loyalty_programs && plan.plan_loyalty_programs.length > 0) {
      return plan.plan_loyalty_programs
    } else {
      return []
    }
  }

  const planLoyaltyPrograms = getPlanLoyaltyPrograms()

  const renderLoyaltyProgramCode = (cellInfo) => {
    return (
      <div>
        {cellInfo ?
          <span>
            {cellInfo.original.loyalty_program_code}
            <Link className="ml-2 text-primary" onClick={() => { navigator.clipboard.writeText(cellInfo.original.loyalty_program_code) }} to={`/plans/${plan.plan_code}`}>
              <FontAwesomeIcon icon={faCopy} />
            </Link>
            <Link className="ml-2 text-primary" target="_blank" to={`/rewards/editLoyaltyProgram/${cellInfo.original.loyalty_program_code}`}>
              <FontAwesomeIcon icon={faSignInAlt} />
            </Link>
          </span>
          : null
        }
      </div>
    )
  }

  const tableColumns = () => {
    const columns = []
    Object.keys(planLoyaltyPrograms[0]).forEach(column => {
      const tableColumn = {
        Header: startCase(column),
        id: column,
        accessor: column,
      }
      if (column === 'loyalty_program_code') tableColumn.Cell = renderLoyaltyProgramCode
      columns.push(tableColumn)
    })
    return columns
  }

  const renderLoyaltyPrograms = () => {
    if (!readonly) {
      return (
        <div className="row">
          <div className="col-6">
            <LoyaltyProgramsSelect
              value={planLoyaltyPrograms}
              onUpdateLoyaltyPrograms={(value) => onUpdateLoyaltyPrograms('plan_loyalty_programs', value)}
            />
          </div>
        </div>
      )
    }

    if (planLoyaltyPrograms.length === 0) return <p>None</p>

    return (
      <ReactTable
        className="-striped -highlight mb-3"
        columns={tableColumns()}
        data={planLoyaltyPrograms}
        defaultPageSize={5}
        keyField="loyalty_program_name"
        style={{ height: '300px' }}
      />
    )
  }

  return (
    <div>
      <div className="mb-2">
        <h5 className="mb-0">Loyalty Programs</h5>
        <small>Loyalty programs attached to this plan</small>
      </div>
      {renderLoyaltyPrograms()}
    </div>
  )
}

export default PlanLoyaltyPrograms
