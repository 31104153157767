import React, { useState }  from 'react'
import { useDispatch } from 'react-redux'
import  Modal from '../Modal'
import Select from 'react-select'
import moment from 'moment'
import { updateCompRunStatus, submitCompRunStatusUpdate } from '../../actions/Compensation/updateCompRunStatus'

const CompStatusHistoryModal = ({ closeStatusHistoryModal, compRun, errorMessage, message, show, successMessage, updateMessage }) => {

  const dispatch = useDispatch()
  const [feedback, setFeedback] = useState('valid-feedback d-block mt-2')

  const checkUserName = (data) => {
    return data.user_name ? data.user_name : 'Unknown'
  }

  const statusOptions = () => {
    return compRun.next_comp_run_status_transitions.map(status => ({ value: status.to_comp_run_status_code, label: status.to_comp_run_status_code }))
  }

  const handleUpdateStatus = async () => {
    setFeedback('valid-feedback d-block mt-2')
    updateMessage()
    const response = await dispatch(submitCompRunStatusUpdate(compRun.comp_run_id, compRun.comp_run_status_code, compRun.comp_run_status_notes))
    if (response.status === 200){
      successMessage()
      dispatch(updateCompRunStatus('comp_run_status_notes', ''))
    } else {
      setFeedback('invalid-feedback d-block mt-2')
      errorMessage()
    }
  }

  const disableUpdate = () => {
    if (compRun.next_comp_run_status_transitions.length === 0){
      return true
    }
  }

  return (
    <Modal  closeModal={() => closeStatusHistoryModal()} title="Comp Status History" visible={show}>
      <div className="mt-3">
        <div className="mr-2">
          <hr />
          <h5>Status History for Comp Run {compRun.comp_run_id}:</h5>
          <div className="mt-3">
            {compRun.comp_run_status_histories.map((history, index) => (
              <div key={index}>
                <h6>{history.comp_run_status_name}</h6>
                <h8>{history.comp_run_status_code}</h8>
                <p>Updated by: {checkUserName(history)} on {moment(history.comp_run_status_change_dt).format('MMMM Do YYYY')}</p>
                <hr />
              </div>
            ))}
          </div>
          <form className="ml-3 mb-5 mt-4">
            <h5>Update Comp Run Status</h5>
            <div className="row">
              <div className="col-md-12 mb-2">
                <label> New Status </label>
                <Select
                  options={statusOptions()}
                  value={compRun.comp_run_status_code}
                  onChange={(e) => e ? dispatch(updateCompRunStatus('comp_run_status_code', e.value)) :  dispatch(updateCompRunStatus('comp_run_status_code', ''))}
                />
              </div>
            </div>
            <div className="row">
              <div className="col-md-12">
                <label> Status Notes </label>
                <textarea
                  className="form-control"
                  value={compRun.comp_run_status_notes}
                  onChange={(e) => dispatch(updateCompRunStatus('comp_run_status_notes', e.target.value))}
                />
              </div>
            </div>
          </form>
          <button className="btn btn-primary btn-md mr-2" onClick={() => closeStatusHistoryModal()}>
            Close
          </button>
          <button className="btn btn-primary btn-md" disabled={disableUpdate()} onClick={() => handleUpdateStatus()}>
            Update Status
          </button>
          <div className={feedback}>
            {message}
          </div>
        </div>
      </div>
    </Modal>
  )}

export default CompStatusHistoryModal
