import React from 'react'
import ReactTable from 'react-table'
import { useSelector } from 'react-redux'
import matchSorter from 'match-sorter'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faWrench } from '@fortawesome/free-solid-svg-icons'
import history from '../../../../history'

const SegmentsTable = () => {
  const segments = useSelector(state => state.segments.info)
  const goToEdit = (input) => {
    history.push(`/segments/active/${input.segment_assignment_id}/edit`)
  }

  const columns = [{
    Header: 'Segment Description',
    accessor: 'segment_description',
    filterMethod: (filter, rows) => (
      matchSorter(rows, filter.value, { keys: ['segment_description'] })
    ),
    filterAll: true,
  },{
    Header: 'Segment Rules Attributes',
    accessor: 'segment_rules_attributes',
    filterMethod: (filter, rows) => (
      matchSorter(rows, filter.value, { keys: ['segment_rules_attributes.*.predicate', 'segment_rules_attributes.0.object' ] })
    ),
    Cell: row => row.original.segment_rules_attributes !== null ?
      (row.original.segment_rules_attributes.map(rule =>
        (<span key={rule.segment_rule_id}>{rule.predicate} : {rule.rule_type} {rule.object.map((object, i) =>
          <span key={i}>{object} </span>)}<br /></span>)
      )) : (<span>No segment rules</span>),
    filterAll: true,
  }, {
    id: 'active',
    Header: 'Status',
    width: 100,
    accessor: row => { return row.active ? 'Active' : 'Inactive' },
    filterMethod: (filter, rows) => (
      matchSorter(rows, filter.value, { keys: [''] })
    ),
    filterAll: true,
  }, {
    Header: 'Edit Segment',
    accessor: 'edit',
    width: 120,
    Cell: row => (
      <div className="row justify-content-md-center">
        <button className="btn btn-primary btn-md mr-2" type="button" onClick={() => goToEdit(row.original)}><FontAwesomeIcon icon={faWrench} /></button>
      </div>
    ),
  }]

  return (
    <div className="col-md-12 mx-auto">
      <ReactTable
        filterable
        className="-striped -highlight mb-3"
        columns={columns}
        data={segments}
        defaultPageSize={20}
        getTdProps={(c, r) => {
          if (c.id === 'edit') {
            return {
              onClick: () => this.goToEdit(r.original),
              style: {
                cursor: 'pointer',
              },
            }
          } else {
            return {}
          }
        }}
        keyField="segment_assignment_id"
      />
    </div>
  )
}

export default SegmentsTable
