import React, { useState } from 'react'
import { updateApprover } from '../../../apis/approvers'
import { getApproverUser } from '../../../apis/approvers'
import { startCase, toLower } from 'lodash'

export default function ApproverSearch({ approver }) {
  const [email, setEmail] = useState("")
  const [error, setError] = useState(null)
  const [currentApproverEmail, setCurrentApproverEmail] = useState(approver.user.email)
  const sanitizedType = startCase(toLower(approver.type));
  const submitSearch = async (event) => {
    event.preventDefault()

    const user = await getApproverUser(email)

    if (Object.keys(user).length === 0) {
      setError({ message: `No user found with email: ${email}` })
      return
    }

    updateApprover(approver.account_payable_approver_id, user.user_id)
      .catch((error) => {
        setError(error)
      })
      .finally(() => {
        setCurrentApproverEmail(email)
        setError(null)
        setEmail("")
      })
  }

  return (
    <div className="mt-2 mb-2">
      <h3 className="mt-4">{sanitizedType}</h3>
      <form>
        <div className="input-group">
          <input
            className="form-control"
            type="search"
            placeholder="Type an email"
            value={email}
            onChange={event => setEmail(event.target.value)}
          />
          <span className="input-group-btn">
            <button className="btn btn-primary" onClick={submitSearch}>Update Approver</button>
          </span>
        </div>
      </form>
      <h6 className="mt-2">Current Approver: {currentApproverEmail}</h6>
      {error ? <span>Error: {error.message}</span> : null}
    </div>
  )
}
