import React, { useState } from 'react'
import isEmpty from 'lodash/isEmpty'
import PlanCampaignsTable from './PlanCampaignsTable'
import RecentOffersTable from './RecentOffersTable'

const PlanReferences= ({ plan }) => {

  const [showCampaigns, setShowCampaigns] = useState(true)
  const [showRecentOffers, setShowRecentOffers] = useState(true)

  const showCampaignsTable = () => {
    setShowCampaigns(!showCampaigns)
  }

  const showRecentOffersTable = () => {
    setShowRecentOffers(!showRecentOffers)
  }

  return (
    <div className="card">
      <div className="card-header">
        <strong>Uses</strong>
      </div>
      <div className="card-body">
        <div className="card mb-3">
          <div className="card-header d-flex justify-content-between">
            <h5 className="mb-0">Campaigns ({plan.campaigns.length})</h5>
            <a className="text-primary" onClick={showCampaignsTable}>{showCampaigns ? 'Hide' : 'Show'}</a>
          </div>
          { showCampaigns &&
            <div className="card-body">
              {isEmpty(plan.campaigns) ?
                <p>No campaigns are associated with this plan </p>
                :
                <PlanCampaignsTable
                  campaigns={plan.campaigns}
                />
              }
            </div>
          }
        </div>
        <div className="card mb-3">
          <div className="card-header d-flex justify-content-between">
            <h5 className="mb-0">Offers ({plan.recent_offers.length})</h5>
            <a className="text-primary" onClick={showRecentOffersTable}>{showRecentOffers ? 'Hide' : 'Show'}</a>
          </div>
          { showRecentOffers &&
            <div className="card-body">
              {isEmpty(plan.recent_offers) ?
                (<p>No recent offers are associated with this plan </p>)
                :
                (
                  <div>
                    <p> The last {plan.recent_offers.length} offers generated for this plan</p>
                    <RecentOffersTable
                      campaigns={plan.campaigns}
                      recentOffers={plan.recent_offers}
                    />
                  </div>
                )
              }
            </div>
          }
        </div>
      </div>
    </div>

  )
}

export default PlanReferences
