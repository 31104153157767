// Add compStatusCode to each rule
// Add include_default_rules t

import React, { useState, useCallback, useEffect } from 'react'
import cloneDeep from 'lodash/cloneDeep'
import lowerCase from 'lodash/lowerCase'
import startCase from 'lodash/startCase'
import CompRuleForm from './CompRuleForm'
import { DndProvider } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'
import Filter from './Filter'

const CompRunScheduleFormPaymentRules = ({ sourceOrChannelPartner, defaultPaymentRules, paymentRules, updateCompRunSchedulePaymentRules, formIsSaving }) => {
  const [ruleIds, setRuleIds] = useState({
    paymentRuleIds: [],
    compRuleIds: [],
  })
  const [ruleWasDragged, setRuleWasDragged] = useState(false)
  const [filterData, setFilterData] = useState([])
  const [isFilterOn, setIsFilterOn] = useState(null)

  const defaultCompTypes = ['ORDER', 'RESIDUAL', 'ORDER_RESIDUAL', 'INSTALLATION', 'BONUS', 'PARTIAL']
  const channelParterCompTypes = ['CHANNEL_PARTNER_INVOICE_RESIDUAL', 'CHANNEL_PARTNER_ORDER', 'CHANNEL_PARTNER_ORDER_RESIDUAL']
  const displayCompTypes = sourceOrChannelPartner === 'channelPartnerCode' ? channelParterCompTypes : defaultCompTypes

  useEffect(() => {
    formIsSaving && setRuleWasDragged(false)
  }, [formIsSaving])

  useEffect(() => {
    !Object.keys(filterData).length && setIsFilterOn(null)
  }, [filterData])

  const addPaymentRule = () => {
    const newPaymentRule =
    {
      compTypeCode: 'ORDER',
      compRules: [],
    }
    const newPaymentRules = cloneDeep(paymentRules)
    newPaymentRules.push(newPaymentRule)
    updateCompRunSchedulePaymentRules(newPaymentRules)
  }

  const addCompRule = (paymentRuleId) => {
    const newPaymentRules = cloneDeep(paymentRules)
    const newCompRule = {
      compCurrency: 'dollars',
      compStatusCode: 'PAYABLE',
      compStatusReason: '',
      rules: [],
    }
    if (newPaymentRules[paymentRuleId].compTypeCode === 'RESIDUAL') {
      newCompRule.compCoefficient = 0.0
      newCompRule.compVariable = 'usage_total_num'
    }
    newPaymentRules[paymentRuleId].compRules.push(newCompRule)

    updateCompRunSchedulePaymentRules(newPaymentRules)
  }

  const addDefaultPaymentRules = (paymentRuleId, compTypeCode) => {
    const defaultPaymentRulesCopy = cloneDeep(defaultPaymentRules)
    const newPaymentRules = cloneDeep(paymentRules)
    newPaymentRules[paymentRuleId].compRules = defaultPaymentRulesCopy[lowerCase(compTypeCode)] || []

    updateCompRunSchedulePaymentRules(newPaymentRules)
  }

  const updatePaymentRule = (paymentRuleId, key, event) => {
    const newPaymentRules = cloneDeep(paymentRules)
    newPaymentRules[paymentRuleId][key] = event.target.value
    updateCompRunSchedulePaymentRules(newPaymentRules)
  }

  const deletePaymentRule = (paymentRuleId) => {
    const newPaymentRules = cloneDeep(paymentRules)
    newPaymentRules.splice(paymentRuleId, 1)
    updateCompRunSchedulePaymentRules(newPaymentRules)
  }

  const showTab = (id, key) => {
    const arr = ruleIds[key]
    if (!arr?.includes(id)) {
      arr.push(id)
    } else {
      const index = arr.indexOf(id)
      arr.splice(index, 1)
    }
    setRuleIds({ ...ruleIds, [key]: arr })
  }

  const expandAllCompRules = (paymentRuleId, compRulesLength) => {
    const ids = []
    for (let i = 0; i < compRulesLength; i++) {
      ids.push(`${paymentRuleId}-${i}`)
    }
    setRuleIds({ ...ruleIds, compRuleIds: ids })
  }

  const collapseAllCompRules = () => {
    setRuleIds({ ...ruleIds, compRuleIds: [] })
  }

  const reorderCompRules = useCallback(
    (dragIndex, hoverIndex, paymentRuleId) => {
      const newPaymentRules = cloneDeep(paymentRules)

      const dragItem = newPaymentRules[paymentRuleId].compRules[dragIndex];
      const hoverItem = newPaymentRules[paymentRuleId].compRules[hoverIndex]

      newPaymentRules[paymentRuleId].compRules[dragIndex] = hoverItem
      newPaymentRules[paymentRuleId].compRules[hoverIndex] = dragItem

      updateCompRunSchedulePaymentRules(newPaymentRules)
      setRuleWasDragged(true)
    })

  const addFilter = (paymentRuleId) => {
    setFilterData([ ...filterData, [] ])
    setIsFilterOn(paymentRuleId)
  }

  const clearFilter = () => {
    setFilterData([])
    setIsFilterOn(null)
  }

  const findRules = (compRuleId) => {
    return filterData.every(filter => ( // only find compRuleId that exist in all applied
      !filter.length ? true : filter.includes(compRuleId) // a filter is empty if it has not been applied/added by user
    ))
  }

  return (
    <div>
      <div className="mb-2 card">
        <div className="card-header d-flex justify-content-between align-items-center">
          <h4 className="mb-0">Rulesets</h4>
          <button className="btn btn-dark mx-2" onClick={addPaymentRule}>+ Add Ruleset</button>
        </div>

        <DndProvider backend={HTML5Backend}>
          {paymentRules?.map((paymentRule, paymentRuleId) =>
          (<div key={paymentRuleId} className="border-bottom">
            <button className="bg-light border-0 w-100 d-flex justify-content-between align-items-center" onClick={() => showTab(paymentRuleId, 'paymentRuleIds')}>
              <h5 className="text-left p-3 m-0">{paymentRule?.ruleDesc || ""}</h5>
              {ruleIds?.paymentRuleIds?.includes(paymentRuleId) ? <i className="fa fa-chevron-up pr-3" /> : <i className="fa fa-chevron-down pr-3" />}
            </button>
            <div className={!ruleIds?.paymentRuleIds?.includes(paymentRuleId) && 'collapse'} id={`${paymentRuleId}`}>
              <div className="m-2 d-flex justify-content-between align-items-center">
                <div className="d-flex justify-content-start align-items-center">
                  <div className="px-3">Comp Type:</div>
                  <select className="ml-2 form-control" disabled={paymentRule.compRules?.length > 0} value={paymentRule.compTypeCode} onChange={(e) => updatePaymentRule(paymentRuleId, 'compTypeCode', e)}>
                    {displayCompTypes?.map((compType, idx) =>
                      <option key={`compTypes-${idx}`} value={compType}>{startCase(lowerCase(compType))}</option>
                    )}
                  </select>
                </div>
                <div className="d-flex">
                  <button className="btn btn-secondary mx-2" onClick={() => expandAllCompRules(paymentRuleId, paymentRule.compRules.length)}>Expand All</button>
                  <button className="btn btn-secondary mx-2" onClick={collapseAllCompRules}>Collapse All</button>
                </div>
              </div>


              {isFilterOn === paymentRuleId && filterData.map((rules, idx) => (
                <Filter key={`filter-${idx}`} filterId={idx} compRules={paymentRule?.compRules} setFilterData={setFilterData} filterData={filterData} />
              ))}

              <div>
                <button className="btn btn-dark py-0 m-2" onClick={() => addFilter(paymentRuleId)}>+ Add Filter</button>
                {isFilterOn === paymentRuleId && <button className="btn btn-danger py-0 m-2" onClick={clearFilter}>- Clear Filters</button>}
              </div>

              {paymentRule?.compRules?.map((compRule, compRuleId) =>
                isFilterOn === paymentRuleId && !findRules(compRuleId)
                  ? null
                  : <CompRuleForm
                    key={`compRule${compRuleId}`}
                    compRule={compRule}
                    compRuleId={compRuleId}
                    compRuleIds={ruleIds.compRuleIds}
                    paymentRules={paymentRules}
                    paymentRule={paymentRule}
                    paymentRuleId={paymentRuleId}
                    showTab={showTab}
                    reorderCompRules={reorderCompRules}
                    updateCompRunSchedulePaymentRules={updateCompRunSchedulePaymentRules}
                    collapseAllCompRules={collapseAllCompRules}
                  />
              )}

              <div className="bg-light p-3 d-flex justify-content-end align-items-center">
                {ruleWasDragged && <p className="text-danger text-lg p-3">We've detected a potential reordering of the rules. If this was not intended, please press Back to discard any changes. Otherwise, please confirm your changes and press Save.</p>}
                <button className="btn btn-dark mr-3" onClick={() => addCompRule(paymentRuleId)}>+ Add Rule</button>
                {paymentRule.compRules.length === 0 && (<button className="btn btn-dark mr-3" onClick={() => addDefaultPaymentRules(paymentRuleId, paymentRule.compTypeCode)}>+ Add Default Rules for {startCase(lowerCase(paymentRule.compTypeCode))}</button>)}
                <button className="btn btn-danger" onClick={() => deletePaymentRule(paymentRuleId)}>- Delete {startCase(lowerCase(paymentRule.compTypeCode))} Ruleset</button>
              </div>
            </div>
          </div>)
          )}
        </DndProvider>
      </div>
    </div>
  )
}

export default CompRunScheduleFormPaymentRules
