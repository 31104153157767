import React, { useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import DisclosureModal from './DisclosureModal'
import ReactTable from 'react-table'
import matchSorter from 'match-sorter'
import moment from 'moment'
import getDisclosureDetail from '../../actions/disclosures/getDisclosureDetail'
import  { getMemberCount } from '../../actions/disclosures/getMemberCount'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faWrench } from '@fortawesome/free-solid-svg-icons'

const DisclosuresContainer = () => {

  const disclosures = useSelector(state => state.disclosures.all)
  const recipientCount = useSelector(state => state.disclosures.memberCount)
  const dispatch = useDispatch()
  const [show, setShow] = useState(false)
  const [disclosure, setDisclosure] = useState(false)

  const dismissModal = () => {
    setShow(false)
    dispatch({ type: 'CLEAR_DISCLOSURE', payload: {} })
  }

  const manageDisclosure = (data) => {
    if (data){
      setDisclosure(data['original'])
      dispatch(getMemberCount(data['original']['state_code']))
      showModal(data['original']['disclosure_id'])
    }
  }

  const showModal = async (disclosureId) => {
    dispatch(getDisclosureDetail(disclosureId))
    setShow(true)
  }

  const columns = [{
    Header: 'Type',
    accessor: 'correspondence_type.correspondence_type_name',
    filterMethod: (filter, rows) => (
      matchSorter(rows, filter.value, { keys: ['type'] })
    ),
    filterAll: true,
  },{
    Header: 'State',
    accessor: 'state_code',
    filterMethod: (filter, rows) => (
      matchSorter(rows, filter.value, { keys: ['state_code'] })
    ),
    filterAll: true,
  }, {
    Header: 'Recurrence',
    accessor: 'recurrence',
    filterMethod: (filter, rows) => (
      matchSorter(rows, filter.value, { keys: ['recurrence'] })
    ),
    filterAll: true,
  }, {
    Header: 'Status',
    accessor: 'last_sent_dt',
    filterMethod: (filter, rows) => (
      matchSorter(rows, filter.value, { keys: ['last_sent_dt'] })
    ),
    filterAll: true,
    Cell: (e) =>(
      <div className="row justify-content-md-center">
        {e ?
          <span className="last-sent-span"> Sent {moment(e.value).fromNow(true)} ago </span>
          :
          <p>No Date Detected for 'last_sent_dt'</p>
        }
      </div>
    ),
  },{
    Header: 'Preview',
    accessor: 'url',
    filterMethod: (filter, rows) => (
      matchSorter(rows, filter.value, { keys: ['url'] })
    ),
    filterAll: true,
    Cell: (e) => (
      <a href={e.value}> {e.value} </a>
    ),
  },{
    Header: 'Manage Disclosure',
    accessor: 'manage',
    Cell: () => (
      <div className="row justify-content-md-center">
        <button className="btn btn-primary btn-md mr-2" type="button">Manage <FontAwesomeIcon icon={faWrench} /> </button>
      </div>
    ),
  }]

  return (
    <div className="col-md-12 mx-auto">
      <div className="d-flex">
        <div />
        <h2 className="text-center w-100">Disclosures</h2>
      </div>
      <ReactTable
        filterable
        className="-striped -highlight mb-3"
        columns={columns}
        data={disclosures}
        defaultPageSize={8}
        getTdProps={(s,r,c) => {
          if (c.id === 'manage') {
            return {
              onClick: () => manageDisclosure(r),
              style: {
                cursor: 'pointer',
              },
            }
          } else {
            return {}
          }
        }}
        keyField="url"
      />
      {
        disclosure &&
      <DisclosureModal
        customStyles={{ width: '40%', height: '75%' }}
        deleteModal={dismissModal}
        disclosure={disclosure}
        recipientCount={recipientCount}
        show={show}
      />
      }
    </div>
  )
}

export default DisclosuresContainer
