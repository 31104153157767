import React, { useState, useEffect } from 'react'
import SectionSelect from '../../Shared/SectionSelect'
import SectionInput from '../../Shared/SectionInput'
import FuzzySelect from '../../Shared/FuzzySelect'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTrash } from '@fortawesome/free-solid-svg-icons'

const RolloffVariation = ({
  controlEditable,
  isControl,
  index = null,
  treatments,
  removeVariant,
  updateVariation,
  variant
}) => {
  const [selectedTreatment, setSelectedTreatment] = useState(null)
  const [amount, setAmount] = useState(0)

  const updateRow = (field, value) => {
    field === 'treatment_id' ? setSelectedTreatment(value) : setAmount(value)
    updateVariation({ [field]: value }, index)
  }

  useEffect(() => {
    setSelectedTreatment(variant['treatment_id'])
    setAmount(variant['amount'])
  })

  const getTreatmentOptions = () => {
    return treatments.map(treatment => {
      return { value: treatment.rolloff_treatment_id, label: treatment.treatment_name }
    })
  }

  const showDeleteIcon = !isControl && index !== 1
  const notEditable = isControl && !controlEditable

  return (
    <>
      <div className="col-5">
        <FuzzySelect
          disabled={notEditable}
          options={getTreatmentOptions()}
          value={selectedTreatment}
          onChange={(e) => updateRow('treatment_id', e.value)}
        />
      </div>
      <SectionInput
        readonly={notEditable}
        label="Apply to"
        type="number"
        value={amount}
        onChange={(event) => updateRow('amount', event.target.value)}
      />
      {showDeleteIcon &&
        <a className="text-primary ml-2" onClick={(e) => removeVariant(e, index)}>
          <FontAwesomeIcon icon={faTrash} />
        </a>
      }
    </>
  )
}

export default RolloffVariation
