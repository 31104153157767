export function defaultData (state = { editable: {}, uneditable: {}, generatingData: false, dataGenerated: false }, action) {
  switch (action.type) {
  case 'DEFAULT_DATA':
    return { ...state, editable: action.payload.editable, uneditable: action.payload.uneditable }
  case 'GENERATING_DEFAULT_DATA':
    return { ... state, generatingData: action.payload }
  case 'DEFAULT_DATA_GENERATED':
    return { ... state, dataGenerated: action.payload }
  case 'UPDATE_DATA':
    return { ...state, editable: { ...state.editable, ...action.payload } }
  case 'CLEAR_DEFAULT_DATA':
    return { ...state, editable: {}, uneditable: {}, generatingData: false, dataGenerated: false }
  default:
    return state
  }
}


export function voltData (state = { data: [], creatingData: false, dataCreated: false }, action ) {
  switch (action.type) {
  case 'CREATING_VOLT_DATA':
    return { ...state, creatingData: action.payload }
  case 'VOLT_DATA_CREATED':
    return { ...state, data: action.payload, dataCreated: true }
  case 'CLEAR_VOLT_DATA':
    return { ...state, data: {}, creatingData: false, dataCreated: false  }
  default:
    return state
  }
}
