import axios from 'axios'

export const getMemberCount = (stateCode) => {
  return function(dispatch){
    axios.get(`${process.env.REACT_APP_GARCON_API}/accounts/count`, {
      params: {
        state_code: stateCode,
        status_group: 'Active',
      },
    })
      .then((response) => {
        dispatch({ type: 'GET_MEMBERCOUNT', payload: response.data })
      })
      .catch((error) => {
        dispatch({ type: 'SHOW_ERROR', payload: { response: error.response, message: 'An error occured getting member count.' } })
        throw error
      })
  }
}
