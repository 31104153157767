import axios from 'axios'
import { default as FormData } from 'form-data'

export const createShopper = async (file, setResponse, setError) => {
  try {
    let formData = new FormData()
    formData.append('file', file)
    const response = await axios.post(`${process.env.REACT_APP_GARCON_API}/shoppers/create_shoppers`, formData)

    setResponse(response.data)
  } catch (error) {
    setError(`An error occured. Please try again or contact an engineer. ${error.message}`)
    throw error
  }
}
