import React, { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import history from '../../../history'
import {
  getRolloffRuleset,
  updateRolloffRuleset
} from '../../../apis/rolloffRulesets'
import RulesetConfig from './RulesetConfig'
import RulesetDetails from './RulesetDetails'

const EditRuleset = () => {
  const [ruleset, setRuleset] = useState(null)
  const [hasLastRule, setHasLastRule] = useState(true)
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(null)
  const { ruleset_id } = useParams()

  useEffect(() => {
    if (!ruleset) {
      getRolloffRuleset(ruleset_id, setRuleset)
        .then(() => {
          setLoading(false)
        })
    }
  }, [ruleset_id])

  const addRule = (type, rule, lastResort = false) => {
    let rulesetConfig = ruleset.ruleset_config
    const index = lastResort ? rulesetConfig.length : rulesetConfig.length - 1
    if (type === 'experiment') {
      rulesetConfig.splice(index, 0, { conditions: [], experiment: rule, ruleset_rule_id: ruleset_id })
    } else {
      rulesetConfig.splice(index, 0, { conditions: [], rolloff_treatment: rule, ruleset_rule_id: ruleset_id })
    }
    setRuleset({ ...ruleset, ruleset_config: rulesetConfig })
  }

  const removeRule = (index) => {
    let rulesetArray =
      ruleset.ruleset_config.filter((ruleset, _index) => _index !== index)
    setRuleset({ ...ruleset, ruleset_config: rulesetArray })
  }

  const sortRules = (currentIndex, newIndex) => {
    const move = (list, from, to) => {
      list.splice(to, 0, list.splice(from, 1)[0])
      return list
    }
    const updatedRuleset = move(ruleset.ruleset_config, currentIndex, newIndex)

    setRuleset({ ...ruleset, ruleset_config: updatedRuleset })
  }

  const addRuleCondition = (e, ruleIndex) => {
    e.preventDefault()

    let rules = ruleset.ruleset_config
    let conditionsArr = rules[ruleIndex]['conditions']
    conditionsArr.push({ predicate: '' })
    rules[ruleIndex]['conditions'] = conditionsArr
    setRuleset({ ...ruleset, ruleset_config: rules })
  }

  const removeRuleCondition = (e, ruleIndex, conditionIndex) => {
    e.preventDefault()

    let rules = ruleset.ruleset_config
    let conditions = rules[ruleIndex]['conditions']
    const updatedConditions =
      conditions.filter((_, index) => index !== conditionIndex)
    rules[ruleIndex]['conditions'] = updatedConditions
    setRuleset({ ...ruleset, ruleset_config: rules })
  }

  const updateRuleConditions = (ruleIndex, conditionIndex, value, type) => {
    let rules = ruleset.ruleset_config
    let updatedRuleCondition = {}

    type === 'predicate' ?
      updatedRuleCondition = { predicate: value } :
      updatedRuleCondition = value

    rules[ruleIndex]['conditions'][conditionIndex] = updatedRuleCondition
    setRuleset({ ...ruleset, ruleset_config: rules })
  }

  const onSubmit = async (e) => {
    e.preventDefault()
    await setError(null)

    const updatedRulesetConfig = ruleset.ruleset_config.map((config) => {
      if (config['rolloff_treatment']) {
        return {
          ruleset_rule_id: ruleset.rolloff_ruleset_id,
          rolloff_treatment_id: config.rolloff_treatment.rolloff_treatment_id,
          conditions: config.conditions || []
        }
      } else {
        return {
          ruleset_rule_id: ruleset.rolloff_ruleset_id,
          experiment_id: config.experiment.experiment_id,
          conditions: config.conditions || []
        }
      }
    })
    const updatedRuleset = { ruleset_config: updatedRulesetConfig }

    // Don't check last resort for missing rule conditions
    let missingCondition
    const conditionsToCheck = updatedRulesetConfig.slice(0, -1)
    for (let i = 0; i < conditionsToCheck.length; i++) {
      conditionsToCheck[i].conditions.forEach(conditionsSet => {
        if ((Object.keys(conditionsSet).length !== 3)
          || (conditionsSet.object && conditionsSet.object.length < 1)) {
          missingCondition = true
        }
      })
    }

    if (missingCondition) {
      setError({ message: 'Missing rule condition' })
    } else {
      try {
        await updateRolloffRuleset(ruleset_id, updatedRuleset)
        history.push(`/rulesets/${ruleset_id}`)
      } catch (error) {
        setError(error)
      }
    }
  }

  return (
    <div className="container-fluid">
      <form>
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <h4 className="my-3">Ruleset {ruleset_id}</h4>
          <button
            className="btn btn-primary btn-md mb-2"
            disabled={(ruleset && !ruleset.ruleset_config.length) || !hasLastRule}
            onClick={(e) => onSubmit(e)}
          >
            Save Changes
          </button>
        </div>
        {ruleset && !loading &&
          <>
            <div className="card mb-4">
              <div className="card-header">
                <strong>Details</strong>
              </div>
              <RulesetDetails ruleset={ruleset} />
            </div>
            <div className="card mb-4">
              <div className="card-header">
                <strong>Rules</strong>
              </div>
              <div className="card-body">
                <p style={{ color: 'red' }}>{error?.message}</p>
                <RulesetConfig
                  ruleset_id={ruleset.rolloff_ruleset_id}
                  ruleset_config={ruleset.ruleset_config}
                  hasLastRule={hasLastRule}
                  addRule={addRule}
                  removeRule={removeRule}
                  sortRules={sortRules}
                  addRuleCondition={addRuleCondition}
                  removeRuleCondition={removeRuleCondition}
                  updateRuleConditions={updateRuleConditions}
                  setHasLastRule={setHasLastRule}
                />
              </div>
            </div>
          </>
        }
        {loading && <Loading />}
      </form>
    </div>
  )
}

export default EditRuleset
