import React, { useState, useEffect } from 'react'
import { getRolloffTreatments } from '../../../apis/rolloffTreatments'
import Loading from '../../Shared/Loading'
import RolloffVariation from './RolloffVariation'

const RolloffVariationsContainer = ({
  control,
  controlEditable,
  variations,
  variationsErrors,
  setControl,
  setVariations,
  total
}) => {
  const [treatments, setTreatments] = useState(null)
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    getRolloffTreatments()
      .then((treatments) => {
        setTreatments(treatments)
        setLoading(false)
      })
  }, [])

  const addVariant = (e) => {
    e.preventDefault()
    setVariations(variations.concat({ amount: 0, index: variations.length + 1, treatment_id: '', name: '' }))
  }

  const removeVariant = (e, index) => {
    e.preventDefault()
    const updatedVariations =
      variations.filter((variant) => variant.index !== index)
        .map((variant, _index) => {
          return { ...variant, index: _index + 1 }
        })
    setVariations(updatedVariations)
  }

  const updateControl = (payload) => {
    let _payload = payload
    if (payload.treatment_id) {
      _payload.name = treatments.find(treatment => treatment.rolloff_treatment_id === payload.treatment_id).treatment_name
    }
    setControl({ ...control, ..._payload })
  }

  const updateVariations = (payload, index) => {
    let _payload = payload
    const updatedVariations = variations.map((variant) => {
      if (variant.index === index) {
        if (payload.treatment_id) {
          _payload.name = treatments.find(treatment => treatment.rolloff_treatment_id === payload.treatment_id).treatment_name
        }
        return { ...variant, ..._payload }
      } else {
        return variant
      }
    })
    setVariations(updatedVariations)
  }

  return (
    <>
      <div className="card-header">
        <strong>Variations</strong>
      </div>
      {treatments &&
        <div className="card-body">
          {
            variationsErrors.map((error, index) => {
              if (error.condition) {
                return <p style={{ color: 'red' }} key={index}>{error.message}</p>
              }
            })
          }
          <div className="row">
            <div className="col-2"><h6>Champion</h6></div>
            <RolloffVariation
              isControl
              controlEditable={controlEditable}
              updateVariation={updateControl}
              treatments={treatments}
              variant={control}
            />
          </div>
          {variations.map((variant, _index) => {
            const { index } = variant
            return (
              <div className="row" key={_index}>
                <div className="col-2">Variant {index}</div>
                <RolloffVariation
                  index={index}
                  removeVariant={removeVariant}
                  updateVariation={updateVariations}
                  treatments={treatments}
                  variant={variant}
                />
              </div>
            )
          })}
          <div className="row">
            <div className="col-2"><h6>Total: {total}</h6></div>
          </div>
          <div className="row">
            <div className="col-1">
              <button
                className="btn btn-primary btn-md mb-2"
                onClick={(e) => addVariant(e)}
              >
                Add Variant
              </button>
            </div>
          </div>
        </div>
      }
      {loading && <Loading />}
    </>
  )
}

export default RolloffVariationsContainer
