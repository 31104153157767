import React from 'react'
import { useSelector } from 'react-redux'
import Card from 'react-bootstrap/Card'

const VoltAccountCard = () => {

  const voltData = useSelector(state => state.voltData.data)
  const editableData = useSelector(state => state.defaultData.editable)

  return (
    <div className="volt-account">
      <Card border="secondary" className="text-center">
        <Card.Header as="h4">New Account Created!</Card.Header>
        <Card.Body>
          <Card.Title> Account ID: {voltData.account_id} </Card.Title>
          <Card.Text>
            <>
              Order ID: {voltData.order_id}<br />
              Prospect Guid: {voltData.prospect_guid}<br />
              Shopper Guid: {voltData.shopper_guid}<br />
              Customer First Name: {editableData.first_name}<br />
              Customer Last Name: {editableData.last_name}<br />
              Market: {voltData.market_code}<br />
              { voltData.usage_data &&
                voltData.usage_data.map((usage, index) => (
                  <div  key={index} className="usages">
                    Usage_ID: {usage.usage_id} <br />
                    Invoice_ID: {usage.invoice_id}
                  </div>
                ))
              }
              <a href={voltData.crm_url}> CRM Account Page </a>
            </>
          </Card.Text>
        </Card.Body>
      </Card>
    </div>
  )
}

export default VoltAccountCard
