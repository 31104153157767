import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import axios from 'axios'
import { connect } from 'react-redux'
import cloneDeep from 'lodash/cloneDeep'
import keyBy from 'lodash/keyBy'
import isEmpty from 'lodash/isEmpty'
import isEqual from 'lodash/isEqual'
import createPromo from '../../../actions/rewards/createPromo'
import createLoyaltyProgram from '../../../actions/rewards/createLoyaltyProgram'
import history from '../../../history'
import RewardSearchResults from './RewardSearchResults'
import RewardField from './RewardField'
import defaultFieldConfigs from './defaultRewardFieldConfigs'

const defaultFields = {
  both: {
    approval_required: true,
    redemption_required: true,
    notify_on_fulfillment: false,
    fulfillment_process: 'AUTOMATIC',
  },
  promo: {
    can_adhoc: true,
    promo_type: 'Bonus',
    promo_measure_from_field: 'confirmed_start_dt',
  },
  loyaltyProgram: {
    loyalty_program_bonus: '0',
  },
}

class CreateRewardForm extends Component {
  constructor() {
    super()
    this.state = {
      searching: false,
      existingRewards: [],
      fields: {},
      fieldConfigs: cloneDeep(defaultFieldConfigs),
    }
  }

  componentDidMount = () => {
    this.setState({ fields: { ...defaultFields.both, ...defaultFields[this.props.rewardType] } }, this.initializeDynamicFieldConfigs)
  }

  componentDidUpdate = (prevProps) => {
    if (prevProps.rewardType !== this.props.rewardType) {
      this.resetForm()
    }

    if (prevProps.promos.length !== this.props.promos.length) {
      const promoCode = this.props.promos[this.props.promos.length - 1].promo_code
      history.push(`/rewards/editPromo/${promoCode}`)
    } else if (prevProps.loyaltyPrograms.length !== this.props.loyaltyPrograms.length) {
      const loyaltyProgramCode = this.props.loyaltyPrograms[this.props.loyaltyPrograms.length - 1].loyalty_program_code
      history.push(`/rewards/editLoyaltyProgram/${loyaltyProgramCode}`)
    }
  }

  initializeDynamicFieldConfigs = () => {
    const fieldConfigs = cloneDeep(this.state.fieldConfigs)

    // Async options
    fieldConfigs[this.fieldConfigIndexByColumn('channel_partner_code')].options = this.channelPartnerCodesToOptions(Object.keys(this.props.channelPartners))
    fieldConfigs[this.fieldConfigIndexByColumn('product_code')].options = this.productsToOptions(this.props.products)

    // State based configs
    fieldConfigs[this.fieldConfigIndexByColumn('channel_partner_code')].required = () => {
      if (!this.state.fields.fulfillment_vendor_code || !this.state.fields.fulfillment_channel) return false

      // If a list of specific allowed channel partners is included, we need to require one of those
      const channelPartnerCodes = this.props.vendorFulfillmentChannels[this.state.fields.fulfillment_vendor_code][this.state.fields.fulfillment_channel]
      return channelPartnerCodes ? channelPartnerCodes.length > 0 : this.state.fields.fulfillment_channel === 'BOTH'
    }

    this.setState({ fieldConfigs })
  }

  resetForm = () => {
    const fieldConfigs = cloneDeep(this.state.fieldConfigs)
    fieldConfigs.forEach(fieldConfig => {
      if (['fulfillment_channel', 'channel_partner_code', 'product_code'].includes(fieldConfig.column)) {
        fieldConfig.options = []
      }
    })
    this.setState({ existingRewards: [], fields: { ...defaultFields.both, ...defaultFields[this.props.rewardType] }, fieldConfigs }, this.initializeDynamicFieldConfigs)
  }

  fieldConfigIndexByColumn = (column) => {
    return this.state.fieldConfigs.findIndex(field => field.column === column)
  }

  fulfillmentChannelsToOptions = (fulfillmentChannels) => {
    const modifiedFulfillmentChannels = fulfillmentChannels
    if (isEqual(fulfillmentChannels.sort(), ['EMAIL', 'OFFLINE'])) {
      modifiedFulfillmentChannels.push('BOTH') // Will get sent to api as null
    }

    return modifiedFulfillmentChannels.map(fulfillmentChannel => {
      return { label: fulfillmentChannel, value: fulfillmentChannel }
    })
  }

  channelPartnerCodesToOptions = (channelPartnerCodes) => {
    return channelPartnerCodes.map(channelPartnerCode => {
      return { label: this.props.channelPartners[channelPartnerCode].channelPartnerName, value: channelPartnerCode }
    })
  }

  productsToOptions = (products) => {
    return products.map(product => {
      return { label: `${product.product_name} (${product.product_code})`, value: product.product_code }
    })
  }

  fieldConfigsForRewardType() {
    return this.state.fieldConfigs.filter(fieldConfig => {
      return fieldConfig.rewardType === 'both' || this.props.rewardType === fieldConfig.rewardType
    })
  }

  searchExistingRewards = async () => {
    const params = this.rewardPayload()
    this.setState({ searching: true })
    const path = this.props.rewardType === 'promo' ? 'rewards/promos/search' : 'loyalty_programs/search'
    const existingRewards = (await axios.get(`${process.env.REACT_APP_GARCON_API}/${path}`, { params })).data
    this.setState({ existingRewards, searching: false })
    return existingRewards
  }

  rewardPayload = () => {
    const payload = { ...this.state.fields }

    if (payload.fulfillment_channel === 'BOTH') payload.fulfillment_channel = null
    if (payload.promo_measure) payload.promo_measure = `P${payload.promo_measure}M`
    if (payload.loyalty_program_measure) payload.loyalty_program_measure = `P${payload.loyalty_program_measure}M`

    return payload
  }

  onSubmit = async (event, forceSubmit = false) => {
    event.preventDefault() // Keep form validation, stop form from auto submitting
    const payload = this.rewardPayload()
    const existingRewards = await this.searchExistingRewards()

    if (forceSubmit || existingRewards.length === 0) {
      this.props.rewardType === 'promo' ? this.props.createPromo(payload) : this.props.createLoyaltyProgram(payload)
    }
  }

  onChangeField = (field, value) => {
    const state = cloneDeep(this.state)
    state.fields[field] = value

    const fieldConfigs = state.fieldConfigs
    const fulfillmentChannelFieldConfig = fieldConfigs[this.fieldConfigIndexByColumn('fulfillment_channel')]
    const channelPartnerFieldConfig = fieldConfigs[this.fieldConfigIndexByColumn('channel_partner_code')]
    const productFieldConfig = fieldConfigs[this.fieldConfigIndexByColumn('product_code')]
    const allChannelPartnerCodes = Object.keys(this.props.channelPartners)
    const allProducts = this.props.products

    switch (field) {
    case 'fulfillment_vendor_code':
      fulfillmentChannelFieldConfig.options = value ? this.fulfillmentChannelsToOptions(Object.keys(this.props.vendorFulfillmentChannels[value])) : []
      channelPartnerFieldConfig.options = this.channelPartnerCodesToOptions(allChannelPartnerCodes)
      productFieldConfig.options = this.productsToOptions(allProducts)

      delete state.fields.fulfillment_channel
      delete state.fields.channel_partner_code
      delete state.fields.product_code
      break
    case 'fulfillment_channel':
      if (value) {
        let channelPartnerCodes
        if (value === 'BOTH') {
          channelPartnerCodes = ['BRAND'] // For rewards that can only be fulfilled via OFFLINE and EMAIL
        } else {
          // If no specific channel partner codes required, allow setting any (or none)
          channelPartnerCodes = this.props.vendorFulfillmentChannels[state.fields.fulfillment_vendor_code][value]
          channelPartnerCodes = isEmpty(channelPartnerCodes) ? allChannelPartnerCodes : channelPartnerCodes
        }

        const products = this.props.products.filter(product => value === 'POS' ? true : product.product_code === 'DOLLARS') // Limited just to make it easier on the user (this could be opened up more in the future)
        channelPartnerFieldConfig.options = this.channelPartnerCodesToOptions(channelPartnerCodes)
        productFieldConfig.options = this.productsToOptions(products)
      } else {
        channelPartnerFieldConfig.options = this.channelPartnerCodesToOptions(allChannelPartnerCodes)
        productFieldConfig.options = this.productsToOptions(allProducts)
      }

      delete state.fields.channel_partner_code
      delete state.fields.product_code
      break
    default:
      break
    }

    this.setState(state)
  }

  render() {
    return (<form onSubmit={(event) => this.onSubmit(event, this.state.existingRewards.length > 0)}>
      <div className="row">
        {this.fieldConfigsForRewardType().map(fieldConfig =>
          (<RewardField
            key={fieldConfig.column}
            fieldConfig={fieldConfig}
            value={this.state.fields[fieldConfig.column]}
            onChangeField={this.onChangeField}
          />)
        )}
      </div>
      {this.state.existingRewards.length > 0 && <div className="row">
        <div className="col-12">
          <RewardSearchResults rewardType={this.props.rewardType} rewards={this.state.existingRewards} />
        </div>
      </div>}
      <div className="row">
        <div className="col-12">
          {this.state.existingRewards.length > 0 && <button className="btn btn-md btn-primary float-right mt-2" disabled={this.state.searching || this.props.creatingReward} type="button" onClick={this.searchExistingRewards}>Re-Search Existing Rewards</button>}
          <button className="btn btn-md btn-primary float-right mt-2" disabled={this.state.searching || this.props.creatingReward} type="submit">Create</button>
        </div>
      </div>
    </form>)
  }
}

function mapStateToProps({ channelPartners, rewards, planFeatures }) {
  return {
    creatingReward: planFeatures.creatingReward,
    promos: planFeatures.promos,
    loyaltyPrograms: planFeatures.loyaltyPrograms,
    products: rewards.products,
    channelPartners: keyBy(channelPartners, channelPartner => channelPartner.channelPartnerCode),
    vendorFulfillmentChannels: rewards.vendorFulfillmentChannels,
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ createPromo, createLoyaltyProgram }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(CreateRewardForm)
