export function ptc(state = {all: [], market: []}, action) {
  switch (action.type) {
  case 'GET_PTC':
    return {...state, all: action.payload}
  case 'GET_PTC_FOR_MARKET':
    return {...state, market: action.payload}
  default:
    return state
  }
}
