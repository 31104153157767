import ViewTreatmentBucket from './ViewTreatmentBucket'

const ViewTreatmentConfig = ({
  deltaData,
  hasRateCap,
  rateCapData,
  treatment_config
}) => {
  const bucketData = !treatment_config.buckets ? treatment_config : treatment_config.buckets
  const methodologyInfo = deltaData.bucket_type ?
    (deltaData.bucket_type === 'percent_bucket' ? `/ ${deltaData.delta_to}` : '')
    : ''

  return (
    <div className="card mb-4">
      <div className="card-header">
        <strong>Treatment Buckets</strong>
      </div>
      <div className="card-body">
        {bucketData.length > 1 &&
          <div className="mb-3">
            <strong>METHODOLOGY</strong> {deltaData.delta_from} - {deltaData.delta_to} {methodologyInfo}
          </div>
        }

        {hasRateCap &&
          <div className="mb-3">
            <div><strong>RATE CAP</strong> {rateCapData.planChoice} {rateCapData.rateCapModifier} {rateCapData.rateCap}</div>
            {rateCapData.plan && <div className="mt-1">{rateCapData.plan}</div>}
          </div>
        }

        {bucketData.map((bucket, index) => {
          return (
            <ViewTreatmentBucket
              bucket={bucket}
              index={index}
              key={index}
            />
          )
        })}
      </div>
    </div>
  )
}

export default ViewTreatmentConfig
