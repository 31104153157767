import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { getOffers, getOfferKeys, searchOffers } from '../../../apis/offers'
import { changeModule } from '../../../actions/changeModule'
import SectionSelect from '../../Shared/SectionSelect'
import OfferContextItem from './OfferContextItem'

const OfferContextContainer = () => {
  const dispatch = useDispatch()

  const [context, setContext] = useState('')
  const [searchValue, setSearchValue] = useState('')
  const [offerKeySearchValue, setOferKeySearchValue] = useState('')
  const [offerKeys, setOfferKeys] = useState([])
  const [selectedOfferKey, setSelectedOfferKey] = useState('')
  const [loading, setLoading] = useState(false)
  const [failMessage, setFailMessage] = useState(null)
  const [generationPayloads, setGenerationPayloads] = useState([])

  useEffect(() => {
    dispatch(changeModule('Offer Debug Tool'))
    getOfferKeys(setOfferKeys)
  }, [])

  const setBeforeApiCall = (_context) => {
    setContext(_context)
    setFailMessage(null)
    setGenerationPayloads([])
    setLoading(true)
  }

  const searchRequestId = async () => {
    setBeforeApiCall('requestId')
    try {
      await searchOffers(setGenerationPayloads, { key_name: selectedOfferKey, key_value: offerKeySearchValue })
    } catch (error) {
      setFailMessage(error.response.data.error)
    }
    setLoading(false)
  }

  const searchOfferCompiler = async () => {
    setBeforeApiCall('offerDebug')
    try {
      await getOffers(setGenerationPayloads, { search: searchValue })
    } catch (error) {
      setFailMessage(error.response.data.error)
    }
    setLoading(false)
  }

  const displayError = (_context) => {
    return context === _context && failMessage ?
      <div className="text-danger"><strong>Error:</strong> {failMessage}</div>
      : null
  }

  return (
    <>
      <h4>Offer Debug Tool</h4>
      <div className="d-flex mb-3">
        <div className="col-6">
          <div className="card ">
            <div className="card-header">
              <strong>Request ID Finder</strong>
            </div>
            <div className="card-body">
              <div className="d-flex">
                {offerKeys.length ?
                  <SectionSelect
                    labelKey="name"
                    options={offerKeys}
                    type="text"
                    value={selectedOfferKey}
                    valueKey="name"
                    onChange={(e) => setSelectedOfferKey(e.target.value)}
                  /> : null}
              </div>
              <div className="input-group mb-3">
                <input
                  type="text"
                  value={offerKeySearchValue}
                  onChange={e => setOferKeySearchValue(e.target.value)}
                  className="form-control"
                  placeholder={selectedOfferKey ? `Search by ${selectedOfferKey}` : ''}
                />
                <div className="input-group-append">
                  <button className="btn" type="button" onClick={searchRequestId}>Search</button>
                </div>
              </div>
              {displayError("requestId")}
            </div>
          </div>
        </div>

        <div className="col-6 ml-3">
          <div className="card">
            <div className="card-header">
              <strong>Offer Compiler</strong>
            </div>
            <div className="card-body">
              <div className="input-group mb-3">
                <input
                  type="text"
                  value={searchValue}
                  onChange={e => setSearchValue(e.target.value)}
                  className="form-control"
                  placeholder="Search by request_id, short_request_id, job_id or iteration_id..."
                />
                <div className="input-group-append">
                  <button className="btn" type="button" onClick={searchOfferCompiler}>Search</button>
                </div>
              </div>
              {displayError("offerDebug")}
            </div>
          </div>
        </div>
      </div>

      {loading ? <div>Searching...</div> : null}

      {(generationPayloads.length && context === "requestId" && !loading) ?
        <div className="text-right">
          <small>Click on a result to populate the offer compiler with the corresponding request ID.</small>
        </div>
        : null
      }

      {
        generationPayloads.map((generationPayload, index) => {
          return context === "offerDebug" ?
            <OfferContextItem
              key={generationPayload.offer_generation_payload_id}
              rowIndex={index}
              generationPayload={generationPayload}
            />
            : <div className="card mb-3">
              <kbd className="card-body" onClick={() => { setSearchValue(generationPayload.request_id) }}>
                <div>Request Date: {`${generationPayload.request_dt}`}</div>
                <div>Request ID: {`${generationPayload.request_id}`}</div>
                <div>JID: {`${generationPayload.jid}`}</div>
                <div>Iteration ID: {`${generationPayload.iteration_id}`}</div>
              </kbd>
            </div>
        }
        )
      }
    </>
  )
}

export default OfferContextContainer
