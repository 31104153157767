import React, { useEffect, useState } from 'react'
import { useLocation, useParams } from 'react-router-dom'
import history from '../../../history'
import SectionInput from '../../Shared/SectionInput'
import TreatmentImplementations from './TreatmentImplementations'
import Loading from '../../Shared/Loading'
import TreatmentBuckets from './TreatmentBuckets'
import {
  createRolloffTreatment,
  getRolloffTreatment,
  getRolloffTreatmentImplementations,
  updateRolloffTreatment
} from '../../../apis/rolloffTreatments'
import {
  calcTypes,
  checkBuckets,
  configureTreatmentBucketsForApi,
  deconstructConfigForUI,
  hasRateCapCheck,
  setupRateCapForApi,
  setupRateCapForUI,
  validateRateCapData
} from './helpers'

const EditTreatment = () => {
  const [treatment, setTreatment] = useState(null)
  const [hasRateCap, setHasRateCap] = useState(false)
  const [rateCapData, setRateCapData] = useState({
    rateCap: '',
    rateCapModifier: null,
    plan: null,
    planChoice: null
  })
  const [formData, setFormData] = useState({
    treatment_name: '',
    notes: ''
  })
  const [buckets, setBuckets] = useState(null)
  const [bucketDelta, setBucketDelta] = useState({ bucket_type: '', delta_to: '', delta_from: '' })
  const [implementations, setImplementations] = useState(null)
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState(null)
  const { rolloff_treatment_id } = useParams()
  const path = useLocation()
  const isClonedTreatment = path.pathname.includes('clone')

  useEffect(() => {
    const getData = async () => {
      await getRolloffTreatment(rolloff_treatment_id, setTreatment)
      if (!isClonedTreatment) {
        await getRolloffTreatmentImplementations(rolloff_treatment_id, setImplementations)
      }

      setLoading(false)
    }

    getData()
  }, [])

  useEffect(() => {
    if (treatment) {
      setFormData({
        treatment_name: isClonedTreatment ? '' : treatment.treatment_name,
        notes: treatment.notes,
      })

      const hasRateCap = hasRateCapCheck(treatment.treatment_config)
      if (hasRateCap) {
        const _rateCapData = setupRateCapForUI(treatment)
        setHasRateCap(true)
        setRateCapData(_rateCapData)
      }

      const dataForConfig = hasRateCap ? treatment.treatment_config.array[1] : treatment.treatment_config
      const configuredBuckets = deconstructConfigForUI(dataForConfig)
      setBuckets(configuredBuckets)
      const baseForTreatmentMethodology = hasRateCap ? treatment.treatment_config.array[1] : treatment.treatment_config
      const treatmentMethodology = baseForTreatmentMethodology.delta_to
      if (treatmentMethodology) {
        const deltaTo = calcTypes.find(calcType => calcType.label === baseForTreatmentMethodology.delta_to).name
        const deltaFrom = calcTypes.find(calcType => calcType.label === baseForTreatmentMethodology.delta_from).name
        setBucketDelta(
          { bucket_type: baseForTreatmentMethodology.type, delta_to: deltaTo, delta_from: deltaFrom }
        )
      }
    }
  }, [treatment])

  const onChange = (key, value) => {
    setFormData({ ...formData, [key]: value })
  }

  const updateRateCap = (data) => {
    setRateCapData({ ...rateCapData, ...data })
  }

  const onSubmit = async (e) => {
    e.preventDefault()

    const bucketError = checkBuckets(buckets)
    const rateCapDataIsValid = hasRateCap ? validateRateCapData(rateCapData) : true
    const missingBucketDelta = buckets.length > 1 && (!bucketDelta.bucket_type || !bucketDelta.delta_to || !bucketDelta.delta_from)

    if (bucketError || missingBucketDelta || !rateCapDataIsValid) {
      setError({ message: 'Invalid bucket data' })
    } else {
      setError(null)
      const { treatment_name, notes } = formData
      try {
        let treatment_config = configureTreatmentBucketsForApi(buckets, bucketDelta)
        if (hasRateCap) {
          const rateCapArrayObj = setupRateCapForApi(rateCapData)

          treatment_config = {
            type: 'min',
            array: [
              rateCapArrayObj,
              { ...treatment_config }
            ]
          }
        }

        if (isClonedTreatment) {
          const createdTreament = await createRolloffTreatment({
            treatment_name,
            notes,
            treatment_config
          }, setTreatment)

          history.push(`/rolloff_treatments/treatment/${createdTreament.rolloff_treatment_id}`)
        } else {
          await updateRolloffTreatment(rolloff_treatment_id, {
            treatment_name,
            notes,
            treatment_config
          })

          history.push(`/rolloff_treatments/treatment/${rolloff_treatment_id}`)
        }
      } catch (error) {
        setError(error)
      }
    }
  }

  return (
    <div className="container-fluid">
      {treatment && !loading &&
        <>
          <form>
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <h4 className="my-3">
                {isClonedTreatment ? `Cloned Treatment - ${treatment.treatment_name}` : treatment.treatment_name}
              </h4>
              <button
                className="btn btn-primary btn-md mb-2"
                onClick={(e) => onSubmit(e)}
              >
                {isClonedTreatment ? 'Save Treatment' : 'Save Changes'}
              </button>
            </div>

            <div className="card mb-4">
              <div className="card-header">
                <strong>Details</strong>
              </div>
              <div className="card-body">
                <p style={{ color: 'red' }}>{error?.message}</p>
                <div className="col-8">
                  <SectionInput
                    label="Treatment Name"
                    type="text"
                    value={formData.treatment_name}
                    onChange={(e) => onChange('treatment_name', e.target.value)}
                  />
                  <SectionInput
                    label="Notes"
                    type="textarea"
                    value={formData.notes}
                    onChange={(e) => onChange('notes', e.target.value)}
                  />
                </div>
              </div>
            </div>

            <TreatmentBuckets
              bucketDelta={bucketDelta}
              buckets={buckets}
              hasRateCap={hasRateCap}
              rateCapData={rateCapData}
              setHasRateCap={setHasRateCap}
              setRateCapData={setRateCapData}
              updateRateCap={updateRateCap}
              setBuckets={setBuckets}
              setBucketDelta={setBucketDelta}
            />
          </form>

          {implementations && !isClonedTreatment &&
            <TreatmentImplementations implementations={implementations} />
          }
        </>
      }
      {loading && <Loading />}
    </div>
  )
}

export default EditTreatment
