import axios from 'axios'
import getCampaignPhoneNumbers from './getCampaignPhoneNumbers'

export default function deleteCampaignPhoneNumber(data) {
  return async (dispatch) => {
    try {
      const response = await axios.delete(`${process.env.REACT_APP_GARCON_API}/campaigns/phone_numbers/${data.campaign_phone_number_id}`)
      if (response){
        dispatch(getCampaignPhoneNumbers())
      }
    } catch (error) {
      dispatch({ type: 'SHOW_ERROR', payload: { response: error.response, message: 'An error occured removing this phone number.' } })
      throw error
    }
  }
}
