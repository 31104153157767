import axios from 'axios'
import getSegments from './getSegments'

export default function updateSegment(segment_assignment_id, segment_description, segmentRules) {
  return function(dispatch) {
    dispatch({ type: 'SAVING_SEGMENT', payload: true })

    const data = {
      segment_description,
      segment_rules_attributes: segmentRules,
    }
    axios.put(`${process.env.REACT_APP_GARCON_API}/segments/${segment_assignment_id}`, data)
      .then(() => {
        dispatch(getSegments())
        dispatch({ type: 'SAVING_SEGMENT', payload: false })
      })
      .catch((error) => {
        dispatch({ type: 'SHOW_ERROR', payload: { response: error.response, message: 'An error occured updating segment.' } })
        dispatch({ type: 'SAVING_SEGMENT', payload: false })
        throw error
      })
  }
}
