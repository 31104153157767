import React, { useRef, useEffect } from "react";
import Conditions from "./Conditions"
import cloneDeep from 'lodash/cloneDeep'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBars } from '@fortawesome/free-solid-svg-icons'
import { useDrag, useDrop } from 'react-dnd'

const CompRuleForm = (props) => {
	const {
		showTab,
		compRule,
		compRuleId,
		paymentRules,
		paymentRule,
		paymentRuleId,
		compRuleIds,
		reorderCompRules,
		updateCompRunSchedulePaymentRules,
		collapseAllCompRules
	} = props
	const ref = useRef(null)

	const duplicateCompRule = (paymentRuleId, compRuleId) => {
    const newPaymentRules = cloneDeep(paymentRules)
    const oldCompRule = cloneDeep(newPaymentRules[paymentRuleId].compRules[compRuleId])
    newPaymentRules[paymentRuleId].compRules.splice(compRuleId, 0, oldCompRule)

    updateCompRunSchedulePaymentRules(newPaymentRules)
  }

	const updatePaymentRuleCondition = (paymentRuleId, compRuleId, ruleId, key, value) => {
    const newPaymentRules = cloneDeep(paymentRules)
    newPaymentRules[paymentRuleId].compRules[compRuleId].rules[ruleId][key] = value
    updateCompRunSchedulePaymentRules(newPaymentRules)
  }

  const addCondition = (paymentRuleId, compRuleId) => {
    const newCondition = {
      subject: '',
      object: '',
      predicate: '',
    }
    const newPaymentRules = cloneDeep(paymentRules)
    newPaymentRules[paymentRuleId].compRules[compRuleId].rules.push(newCondition)

    updateCompRunSchedulePaymentRules(newPaymentRules)
  }

  const updateCompRule = (paymentRuleId, compRuleId, key, event) => {
    const newPaymentRules = cloneDeep(paymentRules)
    let value = event.target.value
    if (key === 'compAmount' && value) { value = parseFloat(value || '0') }
    if (key === 'compCoefficient' && value && parseFloat(value) !== 0) { value = parseFloat(value || '0.0') }
    if (key === 'compStatusCode' && value == 'NOT_PAYABLE') { newPaymentRules[paymentRuleId].compRules[compRuleId]['compAmount'] = 0 }
    newPaymentRules[paymentRuleId].compRules[compRuleId][key] = value
    updateCompRunSchedulePaymentRules(newPaymentRules)
  }

	const deletePaymentRuleCondition = (paymentRuleId, compRuleId, ruleId) => {
    const newPaymentRules = cloneDeep(paymentRules)
    newPaymentRules[paymentRuleId].compRules[compRuleId].rules.splice(ruleId, 1)
    updateCompRunSchedulePaymentRules(newPaymentRules)
  }

  const deleteCompRule = (paymentRuleId, compRuleId) => {
    const newPaymentRules = cloneDeep(paymentRules)
    newPaymentRules[paymentRuleId].compRules.splice(compRuleId, 1)
    updateCompRunSchedulePaymentRules(newPaymentRules)
  }

	const [{ isDragging }, dragRef] = useDrag({
		type: 'item',
		item: { compRuleId },
		collect: (monitor) => ({
			isDragging: monitor.isDragging(),
		}),
	})

	useEffect(() => {
		isDragging && collapseAllCompRules()
	}, [isDragging])

	const [options, dropRef] = useDrop({
		accept: 'item',
		hover: (item, monitor) => {
			if (!ref.current) return

			const dragIndex = item.compRuleId
			const hoverIndex = compRuleId

			if (dragIndex === hoverIndex) return
	
			const hoverBoundingRect = ref.current?.getBoundingClientRect()
			const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2
			const hoverClientY = monitor.getClientOffset().y - hoverBoundingRect.top

			if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) return
			if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) return

			reorderCompRules(dragIndex, hoverIndex, paymentRuleId)

			item.compRuleId = hoverIndex
		},
	})

	const dragDropRef = dragRef(dropRef(ref))
	const opacity = isDragging ? 0 : 1
	const compAmount = (compRule) => {
		if (compRule.compStatusCode == 'NOT_PAYABLE') return 0
		else return compRule.compAmount || ""
	}

	return (
		<div ref={dragDropRef} className="border m-2 d-flex" style={{ opacity }} >
			<FontAwesomeIcon className="feather reorder p-3 mt-1" icon={faBars} size="2x"/>
			<div className="w-100">
				<button className="bg-light border-0 w-100 d-flex justify-content-between align-items-center p-3" onClick={() => showTab(`${paymentRuleId}-${compRuleId}`, 'compRuleIds')}>
					<div>
						<p className="text-left m-0"><b>Rule {compRuleId + 1}: {compRule.compStatusReason}</b></p>
						<p className="text-left m-0">Comp Amount: ${compRule.compAmount}; Comp Status: {compRule.compStatusCode}</p>
					</div>
					{compRuleIds?.includes(`${paymentRuleId}-${compRuleId}`) ? <i className="fa fa-chevron-up pr-3" /> : <i className="fa fa-chevron-down pr-3" />}
				</button>

				<div className={!compRuleIds?.includes(`${paymentRuleId}-${compRuleId}`) && 'collapse'} id={`${paymentRuleId}-${compRuleId}`}>
					<div className="p-3">
						<div className="row form-group">
							<div className="col-2 text-right align-self-center pr-0">Comp Amount: $</div>
							<div className="col pl-0">
								<input className="ml-2 form-control col-sm-2" type="text" value={compAmount(compRule)} onChange={(e) => updateCompRule(paymentRuleId, compRuleId, 'compAmount', e)} />
							</div>
						</div>
						{paymentRule.compTypeCode === 'RESIDUAL' &&
							<div className="row form-group">
								<div className="col-2 text-right align-self-center pr-0">Comp Coefficient: </div>
								<div className="col pl-0">
									<input className="ml-2 form-control col-sm-2" type="text" value={compRule.compCoefficient || ""} onChange={(e) => updateCompRule(paymentRuleId, compRuleId, 'compCoefficient', e)} />
								</div>
							</div>
						}
						{paymentRule.compTypeCode === 'RESIDUAL' &&
							<div className="row form-group">
								<div className="col-2 text-right align-self-center pr-0">Coefficient Multiplier Field: </div>
								<div className="col pl-0">
									<input className="ml-2 form-control col-sm-2" disabled={true} type="text" value={compRule.compVariable || ""} />
								</div>
							</div>
						}
						<div className="row form-group">
							<div className="col-2 text-right align-self-center pr-0">Comp Status:</div>
							<div className="col pl-0">
								<select className="ml-2 form-control col-sm-2" value={compRule.compStatusCode || ""} onChange={(e) => updateCompRule(paymentRuleId, compRuleId, 'compStatusCode', e)}>
									<option key="PAYABLE" value="PAYABLE">Payable</option>
									<option key="NOT_PAYABLE" value="NOT_PAYABLE">Not Payable</option>
								</select>
							</div>
						</div>
						<div className="row form-group">
							<div className="col-2 text-right align-self-center pr-0">Comp Status Description:</div>
							<div className="col pl-0">
								<input className="ml-2 form-control col-sm-2" type="text" value={compRule.compStatusReason || ""} onChange={(e) => updateCompRule(paymentRuleId, compRuleId, 'compStatusReason', e)} />
							</div>
						</div>
						<Conditions
							deleteCondition={(ruleId) => deletePaymentRuleCondition(paymentRuleId, compRuleId, ruleId)}
							rules={compRule.rules}
							uniqueId={compRuleId}
							updateCondition={(ruleId, key, value) => updatePaymentRuleCondition(paymentRuleId, compRuleId, ruleId, key, value)}
						/>
						<button className="btn btn-dark ml-2 mt-4 pt-0 pb-0" onClick={() => addCondition(paymentRuleId, compRuleId)}>+ Add Condition</button>
						<button className="btn btn-dark ml-2 mt-4 pt-0 pb-0" onClick={() => duplicateCompRule(paymentRuleId, compRuleId)}>+ Duplicate Rule</button>
						<button className="btn btn-danger mt-4 ml-1 pt-0 pb-0" onClick={() => deleteCompRule(paymentRuleId, compRuleId)}><FontAwesomeIcon className="feather" icon="trash" size="2x" /> Rule</button>
					</div>
				</div>
			</div>
		</div>
	);
};


export default CompRuleForm