import axios from 'axios'
import { getPTCForMarket } from './getPTC'

export const createPTC = (formData) => {
  return async (dispatch) => {
    try {
      await axios.post(`${process.env.REACT_APP_GARCON_API}/pricing/rates/ptc`, formData)
        dispatch({ type: 'SHOW_ERROR', payload: { headline: 'SUCCESS!', message: 'New PTC Successfully created for market'} })
        dispatch(getPTCForMarket(formData.market_code))
    } catch (error) {
        dispatch({ type: 'SHOW_ERROR', payload: { response: error.response, message: `Error: ${error.response.data.error}` } })
        throw error
    }
  }
}
