// Configure data for Create/Edit API payloads

export const planChoices = [
  { name: 'Renew on different plan' },
  { name: 'Renew on same plan' },
  { name: 'Renew on default plan' },
  { name: 'Do not renew' },
]

export const calcTypes = [
  { name: 'Current Price', label: 'current_rate' },
  { name: 'Current PTC', label: 'ptc' },
  { name: 'Next Contract CTS', label: 'cts' },
  { name: 'Static Rate', label: 'static_rate' },
]

export const modifiers = [
  { name: '+ rate' },
  { name: '+ dollar' },
  { name: 'x' },
  { name: 'no modifier' },
]

export const staticRateModifiers = [
  { name: '+ rate' },
  { name: '+ dollar' },
]

export const modifiersForCalc = [
  { name: '+ rate', label: 'rate_adder' },
  { name: '+ dollar', label: 'dollar_adder' },
  { name: 'x', label: 'multiplier' },
  { name: 'no modifier', label: '' },
]

export const modifiersForStaticRateCalc = [
  { name: '+ rate', label: 'rate_adder' },
  { name: '+ dollar', label: 'dollar_adder' },
]

export const configurePlan = (bucket) => {
  let plan_treatment_rule
  if (bucket.planChoice === 'Renew on same plan') {
    plan_treatment_rule = 'SAME_PLAN'
  }
  if (bucket.planChoice === 'Renew on default plan') {
    plan_treatment_rule = 'DEFAULT_FOR_STRUCTURE'
  }
  if (bucket.planChoice === 'Renew on different plan') {
    plan_treatment_rule = bucket.plan
  }
  return plan_treatment_rule
}

const getBase = (bucketData) => {
  const base = calcTypes.find(calcType => calcType.name === bucketData.bucketChoice)
  return base ? base.label : null
}

const getModifer = (bucketData) => {
  const modifiers = bucketData.bucketChoice === 'Static Rate' ? modifiersForStaticRateCalc : modifiersForCalc
  const modifier = modifiers?.find(modifier => modifier.name === bucketData.modifier)
  return modifier ? modifier.label : null
}

const configureMinMaxArray = (minMaxArray, plan_treatment_rule) => {
  let updatedMinMaxArray = []

  minMaxArray.forEach(item => {
    const base = getBase(item)
    const modifier = getModifer(item)
    let updatedItem = {
      plan_treatment_rule,
      base,
      type: 'calc'
    }
    if (modifier) {
      updatedItem = { ...updatedItem, [modifier]: parseFloat(Number(item.bucketAmount)) }
    }
    updatedMinMaxArray.push(updatedItem)
  })

  return updatedMinMaxArray
}

export const configureTreatmentBucketsForApi = (buckets, bucketDelta) => {
  let last_resort_treatment
  let updatedBuckets = []

  buckets.forEach((bucket, index) => {
    let _bucket
    const { bucketData } = bucket
    const { bucketChoice } = bucketData
    const isMinMax = bucketChoice &&
      (bucketChoice.type == 'MIN' || bucketChoice.type == 'MAX') ||
      (bucketChoice == 'MIN' || bucketChoice == 'MAX')

    const plan_treatment_rule = configurePlan(bucket)
    if (plan_treatment_rule && !isMinMax) _bucket = { plan_treatment_rule }

    if (bucket.planChoice === 'Do not renew') {
      _bucket = { ..._bucket, type: 'dnr' }
    } else if (isMinMax) {
      const minMaxArray = configureMinMaxArray(bucketChoice.array || bucketData.array, plan_treatment_rule)
      _bucket = {
        type: (bucketChoice.type || bucketChoice).toLowerCase(),
        array: minMaxArray
      }
    } else {
      const base = getBase(bucketData)
      const modifier = getModifer(bucketData)
      _bucket = {
        ..._bucket,
        base,
        type: 'calc'
      }
      if (modifier) {
        _bucket = { ..._bucket, [modifier]: parseFloat(Number(bucketData.bucketAmount)) }
      }
    }

    if (buckets.length === index + 1) {
      last_resort_treatment = { ..._bucket }
    }
    else {
      updatedBuckets.push({ threshold: parseFloat(Number(bucket.max)), treatment: _bucket })
    }
  })

  if (updatedBuckets.length === 0) {
    return last_resort_treatment
  } else {
    return {
      type: bucketDelta.bucket_type,
      'buckets': updatedBuckets,
      last_resort_treatment,
      delta_to: calcTypes.find(calcType => calcType.name === bucketDelta.delta_to).label,
      delta_from: calcTypes.find(calcType => calcType.name === bucketDelta.delta_from).label
    }
  }
}

// Configure data for UI on Edit & View

export const formatPlanData = (planTreatmentRule) => {
  let planData = {}
  if (planTreatmentRule === 'SAME_PLAN') {
    planData = { planChoice: 'Renew on same plan' }
  } else if (planTreatmentRule === 'DEFAULT_FOR_STRUCTURE') {
    planData = { planChoice: 'Renew on default plan' }
  } else {
    planData = { planChoice: 'Renew on different plan', plan: planTreatmentRule }
  }
  return planData
}
const getPlanData = (bucket, isMinMax = false) => {
  if (bucket.type == 'dnr') {
    return { planChoice: 'Do not renew' }
  } else if (isMinMax) {
    return formatPlanData(bucket.array[0].plan_treatment_rule)
  } else {
    return formatPlanData(bucket.plan_treatment_rule)
  }
}

const parseModifier = (bucket, bucketChoice) => {
  const modifiers =
    bucketChoice === 'Static Rate' ? ['rate_adder', 'dollar_adder'] : ['rate_adder', 'dollar_adder', 'multiplier']
  const bucketKeys = Object.keys(bucket)
  let modifier
  bucketKeys.forEach(key => {
    modifiers.forEach(_modifier => {
      if (key === _modifier) modifier = _modifier
    })
  })
  const bucketAmount = bucket[modifier] ? bucket[modifier] : null

  const completeModifiers = [
    { name: '+ rate', label: 'rate_adder' },
    { name: '+ dollar', label: 'dollar_adder' },
    { name: 'x', label: 'multiplier' }
  ]
  if (modifier) {
    modifier = completeModifiers.find(mod => mod.label === modifier).name
  } else {
    modifier = 'no modifier'
  }

  return { modifier, bucketAmount }
}

const getBucketData = (bucket, index = null) => {
  const bC = calcTypes.find(calcType => calcType.label === bucket.base)
  const bucketChoice = bC && bC.name
  const data = parseModifier(bucket, bucketChoice)

  let bucketData = {
    bucketChoice,
    modifier: data.modifier,
    bucketAmount: data.bucketAmount
  }
  if (index) bucketData.index = index

  return bucketData
}

const configureThresholdData = (configForUIWithIndices, treatment_config) => {
  const bucketsWithThresholdData = treatment_config.buckets
  const configuredThreshold = configForUIWithIndices.map((bucket, index) => {
    if (index === 0) {
      return {
        ...bucket,
        min: null,
        max: bucketsWithThresholdData[index].threshold
      }
    } else if (index === bucketsWithThresholdData.length) {
      return {
        ...bucket,
        min: bucketsWithThresholdData[index - 1].threshold,
        max: null
      }
    } else {
      return {
        ...bucket,
        min: bucketsWithThresholdData[index - 1].threshold,
        max: bucketsWithThresholdData[index].threshold
      }
    }
  })
  return configuredThreshold
}

const parseBucket = (bucket) => {
  if (!bucket.array) {
    let planData = getPlanData(bucket)
    let bucketData = getBucketData(bucket)
    return { ...planData, bucketData }
  } else {
    // min/max bucket
    let planData = getPlanData(bucket, true)
    if (planData.planChoice === 'Renew on different plan') {
      planData.plan = bucket.array[0].plan_treatment_rule
    }

    let bucketDataArray = bucket.array.map((item, index) => getBucketData(item, index))
    return {
      ...planData,
      bucketData: { bucketChoice: bucket.type.toUpperCase(), array: bucketDataArray }
    }
  }
}

const configureBuckets = (treatment_config) => {
  let configForUI = treatment_config.buckets.map(bucket => {
    return parseBucket(bucket.treatment)
  })
  configForUI.push(
    parseBucket(treatment_config.last_resort_treatment)
  )
  const configForUIWithIndices = configForUI.map((bucket, index) => {
    return { ...bucket, index: index + 1 }
  })
  const configureForUIWithThresholds =
    configureThresholdData(configForUIWithIndices, treatment_config)
  return configureForUIWithThresholds
}

export const deconstructConfigForUI = (treatment_config) => {
  // last resort only, aka only 1 bucket
  if (treatment_config.type !== 'buckets' && treatment_config.type !== 'percent_bucket') {
    let lastResortBucket = parseBucket(treatment_config)
    return [{ ...lastResortBucket, index: 1, min: null, max: null }]
  } else {
    return configureBuckets(treatment_config)
  }
}

// Check buckets before processing for create or edit
const checkBucket = (bucket) => {
  const { bucketData } = bucket
  const checkBucketRow = (bucketData) => {
    return (
      bucketData == {}
      || !bucketData.bucketChoice
      || !bucketData.modifier
      || (bucketData.modifier !== 'no modifier' && !bucketData.bucketAmount)
    )
  }

  const checkMinMaxData = (minMaxArr) => {
    const arr = minMaxArr.array ? minMaxArr.array : minMaxArr.bucketChoice.array
    let _error = false
    arr.forEach(bucketData => {
      if (!_error) {
        _error = checkBucketRow(bucketData)
      }
    })
    return _error
  }

  const isMinMax = () => {
    return (
      bucketData.bucketChoice
      && (
        bucketData.bucketChoice === 'MIN' || bucketData.bucketChoice === 'MAX'
        || bucketData.bucketChoice.type === 'MIN' || bucketData.bucketChoice.type === 'MAX'
      )
    )
  }

  if (
    (
      !bucket.planChoice
      || bucket.planChoice === 'Renew on different plan' && !bucket.plan
      || bucketData == {}
    )
    || ((
      isMinMax()
      && (
        ((bucketData.array || bucketData.bucketChoice.array)
          && checkMinMaxData(bucketData))
      )
    )
      || !isMinMax() && checkBucketRow(bucketData)
    )
  ) {
    return true
  }
}

export const checkBuckets = (buckets) => {
  let _error = false

  // Check thresholds
  if (buckets.length > 1) {
    buckets.forEach((bucket, index) => {
      if (index !== 0) {
        if (bucket.min < buckets[index - 1].max) {
          _error = true
        }
      }
    })
  }

  // Check bucket data
  if (buckets === {} || !buckets.length) {
    if (!_error) _error = true
  } else {
    buckets.forEach(bucket => {
      if (bucket.planChoice !== 'Do not renew') {
        if (checkBucket(bucket)) {
          _error = true
        }
      }
    })
  }
  return _error
}

// get bucket amount to display
export const bucketAmountToDisplay = (modifier, bucketAmount) => {
  let displayAmount
  if (modifier == '+ dollar') {
    displayAmount = `${bucketAmount} dollars/month`
  }
  if (modifier === '+ rate') {
    displayAmount = `${Number(bucketAmount) * 100} cents/kWh`
  }
  if (modifier === 'x') {
    displayAmount = `${Number(bucketAmount) * 100} percent`
  }
  return <span className='ml-2'>{displayAmount ? displayAmount : ''}</span>
}

// Rate Cap methods

export const hasRateCapCheck = (treatment_config) => {
  return treatment_config.type === 'min' &&
    treatment_config.array &&
    treatment_config.array.length > 1 &&
    treatment_config.array[0].base === 'static_rate'
}

export const setupRateCapForUI = (treatment) => {
  const rateCap = treatment.treatment_config.array[0]
  const rateCapModifierLabel = (Object.keys(rateCap))
    .find(key => key === 'rate_adder' || key === 'dollar_adder')
  const rateCapModifier = modifiersForStaticRateCalc
    .find(modifier => modifier.label === rateCapModifierLabel).name
  const planData = formatPlanData(rateCap.plan_treatment_rule)
  return {
    rateCap: rateCap[rateCapModifierLabel],
    rateCapModifier,
    plan: planData.plan || null,
    planChoice: planData.planChoice
  }
}

export const setupRateCapForApi = (rateCapData) => {
  const { rateCap, rateCapModifier, planChoice, plan } = rateCapData
  const rateCapLabel = modifiersForCalc.find(modifier => modifier.name === rateCapModifier).label

  let rateCapArrayObj = {
    base: 'static_rate',
    [rateCapLabel]: Number(rateCap),
    type: 'calc',
    plan_treatment_rule: configurePlan({ planChoice, plan })
  }

  return rateCapArrayObj
}

export const validateRateCapData = (rateCapData) => {
  let isValid = true
  if (!rateCapData.hasOwnProperty('rateCap') || !rateCapData.rateCap) isValid = false
  if (!rateCapData.hasOwnProperty('rateCapModifier') || !rateCapData.rateCapModifier) isValid = false
  if (!rateCapData.hasOwnProperty('planChoice') || !rateCapData.planChoice) isValid = false
  if (rateCapData.hasOwnProperty('planChoice') && rateCapData.planChoice === 'Renew on different plan' && rateCapData.plan === null) isValid = false
  return isValid
}
