import React, { useState, useEffect } from 'react'
import ApproverSearch from './ApproverSearch'
import { getApprovers } from '../../../apis/approvers'
import { useDispatch } from 'react-redux'
import { changeModule } from '../../../actions/changeModule'

const ApproversContainer = () => {
  const dispatch = useDispatch()
  const [approvers, setApprovers] = useState([])

  useEffect(() => {
    dispatch(changeModule('Approvers'))
    getApprovers(setApprovers)
  }, [])

  return (
    <>
      <h1>Approvers</h1>
      {approvers.map(approver =>
        <div key={approver.account_payable_approver_id}>
          <ApproverSearch
            approver={approver}
          />
        </div>
      )}
    </>
  )
}

export default ApproversContainer
