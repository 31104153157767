import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import getVendorFulfillmentChannels from '../../../actions/rewards/getVendorFulfillmentChannels'
import getProducts from '../../../actions/rewards/getProducts'
import { getChannelPartners } from '../../../actions/channels/getChannelPartners'
import history from '../../../history'
import CreateRewardForm from './CreateRewardForm'

class CreateReward extends Component {
  constructor() {
    super()
    this.state = {
      rewardType: '',
    }
  }

  componentDidMount = () => {
    this.props.getChannelPartners()
    this.props.getVendorFulfillmentChannels()
    this.props.getProducts()
  }

  rewardTypeDescription() {
    if (!this.state.rewardType) return null

    switch (this.state.rewardType) {
    case 'promo':
      return 'Promos are one-time rewards with pre-defined reward amounts (ex. $50 dollar gift card after 3 months).'
    case 'loyaltyProgram':
      return 'Loyalty programs can be one-time or recurring rewards where the reward amount is calculated dynamically based off of customer invoices (ex. 3% cash back after 12 months or first 2 months free).'
    default:
      return `Invalid reward type ${this.state.rewardType}`
    }
  }

  onSetRewardType = (rewardType) => {
    this.setState({ rewardType })
  }

  onCancel = () => {
    history.push('/rewards')
  }

  render() {
    return (
      <div className="col-md-12 mx-auto text-center">
        <h2 className="text-center">Create Reward</h2>
        <div className="card mb-3">
          <div className="card-body text-left">
            <div className="row">
              <div className="col-3">
                <label>Reward Type</label>
                <select className="form-control form-control-md" value={this.state.rewardType} onChange={e => this.onSetRewardType(e.target.value) }>
                  <option disabled value="">Choose a type</option>
                  <option value="promo">Promo</option>
                  <option value="loyaltyProgram">Loyalty Program</option>
                </select>
              </div>
              <div className="col-9">
                <br />
                {this.rewardTypeDescription()}
              </div>
            </div>
            <div className="row">
              <div className="col-12">
                {this.state.rewardType && <CreateRewardForm rewardType={this.state.rewardType} />}
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-6">
            <button className="btn btn-primary float-left" onClick={this.onCancel}>Cancel</button>
          </div>
        </div>
      </div>
    )
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ getChannelPartners, getVendorFulfillmentChannels, getProducts }, dispatch)
}

export default connect(null, mapDispatchToProps)(CreateReward)
