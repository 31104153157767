import React, { Component } from 'react'
import { connect } from 'react-redux'
import history from '../../../history'
import qs from 'qs'
import Select from 'react-select'
import isEmpty from 'lodash/isEmpty'
import { changeModule } from '../../../actions/changeModule'
import getPredicates from '../../../actions/segments/getPredicates'
import getSegmentsContext from '../../../actions/segments/getSegmentsContext'
import getAgentContext from '../../../actions/segments/getAgentContext'
import getCampaignContext from '../../../actions/campaigns/getCampaignContext'
import getExperimentContext from '../../../actions/experiments/getExperimentContext'
import SegmentsContext from './SegmentsContext'
import CampaignContext from './CampaignContext'
import ExperimentContext from './ExperimentContext'
import CampaignContextInfo from './CampaignContextInfo'
import AgentContext from './AgentContext'
import LowerPriorityCampaignsContext from './LowerPriorityCampaignsContext'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAngleDoubleDown, faAngleDoubleUp } from '@fortawesome/free-solid-svg-icons'

class Context extends Component {
  state = {
    showOtherPredicates: false,
    predicates: [],
    effectiveDate: null,
    type: { label: 'acquisition', value: 'available_for_acquisition' }
  }

  componentDidMount() {
    this.props.changeModule('Campaigns Context')
    const params = qs.parse(this.props.location.search.split('?')[1])
    const predicateState = {}
    Object.keys(params).forEach(param => {
      predicateState[param] = params[param]
    })
    this.props.getPredicates()
    this.getContext(predicateState)
    this.setState({ predicates: predicateState })
  }

  componentDidUpdate(prevProps) {
    if (prevProps.segments !== this.props.segments) {
      this.props.getCampaignContext(this.props.segments, this.state.predicates['market_code'])
    }
    if (this.props.campaigns[0] && prevProps.campaigns[0] !== this.props.campaigns[0]) {
      this.props.getExperimentContext(this.props.segments, this.props.campaigns[0])
    }
  }

  onChooseOption = (predicate, event, input_type) => {
    let value
    if (input_type === 'range') {
      value = parseInt(event.value, 10)
    }
    if (input_type === 'select') {
      value = event ? event.value : null
    } else {
      value = event.target.value
    }
    const predicateState = this.state.predicates
    if (value) {
      predicateState[predicate.predicate] = value
    } else {
      delete predicateState[predicate.predicate]
    }
    history.push(`/campaigns_context?${qs.stringify(predicateState, { arrayFormat: 'repeat' })}`)
    this.setState({ predicates: predicateState })
  }

  setEffectiveDate = (event) => {
    this.setState({ effectiveDate: event.target.value })
  }

  setContext = (state) => {
    this.getContext(state)
    this.setState({ predicates: state })
  }

  getContext(state) {
    let contextState = state
    if (this.state.effectiveDate) {
      contextState['effective_date'] = this.state.effectiveDate
    }
    this.props.getSegmentsContext(contextState)
    if (!isEmpty(this.state.predicates)) {
      this.props.getAgentContext(contextState)
    }
  }

  onGetContext = () => {
    this.getContext(this.state.predicates)
  }

  otherCampaigns() {
    return this.props.campaigns.filter((campaign, i) => i !== 0)
  }

  options(predicateInfo) {
    return this.props.predicates.find(p => p.segment_predicate.predicate === predicateInfo.segment_predicate.predicate).options.map(p => (
      { label: `${p.label_column} (${p.value_column})`, value: p.value_column }
    ))
  }

  renderOtherCampaigns() {
    return this.otherCampaigns().map(campaign => <CampaignContextInfo key={`campaign-${campaign.campaign_id}`} campaign={campaign} />)
  }

  getPredicatesBySection(section) {
    let predicatesToShow
    let filtered_predicates
    let rolloff_v_acq = this.state.type?.value

    // this defaults to showing available_for_acquisition predicates.
    filtered_predicates =
      (rolloff_v_acq === 'available_for_rolloff') ?
        this.props.predicates.filter(predicateInfo => predicateInfo.segment_predicate.available_for_rolloff) :
        this.props.predicates.filter(predicateInfo => predicateInfo.segment_predicate.available_for_acquisition)

    if (section === 'default') {
      predicatesToShow = filtered_predicates.filter(predicateInfo => !predicateInfo.segment_predicate.inferable)
    }

    if (section === 'other') {
      predicatesToShow = filtered_predicates.filter(predicateInfo => predicateInfo.segment_predicate.inferable)
    }

    if (section === 'rolloff') {
      const rolloff_options = [{ label: "acquisition", value: "available_for_acquisition" }, { label: "rolloff", value: "available_for_rolloff" }]
      return (
        <div className="col-6 mb-4">
          <strong>Rolloff or Acquisition</strong>
          <Select
            options={rolloff_options}
            value={this.state.type}
            onChange={(value) => this.updateSegments(value)}
            clearable={false}
          />
        </div>
      )
    }

    return predicatesToShow.map(predicateInfo => (
      <div key={predicateInfo.segment_predicate.predicate} className="col-6 mb-4">
        <strong>{predicateInfo.segment_predicate.description}</strong>
        {['text', 'range', 'date'].includes(predicateInfo.segment_predicate.input_type) ? (
          <input
            className="form-control form-control-sm"
            placeholder={predicateInfo.segment_predicate.description}
            style={{ height: '36px' }}
            type={predicateInfo.segment_predicate.input_type === 'range' ? 'number' : 'text'}
            value={this.state.predicates[predicateInfo.segment_predicate.predicate]}
            onChange={(value) => this.onChooseOption(predicateInfo.segment_predicate, value, predicateInfo.segment_predicate.input_type)}
          />
        ) : (
          <Select
            options={this.options(predicateInfo)}
            placeholder={predicateInfo.segment_predicate.description}
            value={this.state.predicates[predicateInfo.segment_predicate.predicate]}
            onChange={(value) => this.onChooseOption(predicateInfo.segment_predicate, value, 'select')}
          />
        )}
      </div>
    ))
  }

  updateSegments = (value) => {
    this.setState({ type: value })
  }

  togglePredicates() {
    this.setState({ showOtherPredicates: !this.state.showOtherPredicates })
  }

  effectiveDateInput = () => {
    return (
      <div key='efffectiveDate' className="col-6 mb-4">
        <strong>Effective Date</strong>
        <input
          className="form-control form-control-sm"
          placeholder='Effective Date'
          style={{ height: '36px' }}
          type='date'
          value={this.state.effectiveDate}
          onChange={(value) => this.setEffectiveDate(value)}
        />
      </div>
    )
  }

  render() {
    let defaultPredicates = this.getPredicatesBySection('default')
    defaultPredicates.push(this.effectiveDateInput())
    const otherPredicates = this.getPredicatesBySection('other')
    const rolloffPredicates = this.getPredicatesBySection('rolloff')

    return (
      <div className="container-fluid">
        <div className="row">
          <h4 className="my-3">Campaigns Context</h4>
        </div>
        <div className="card mb-4">
          <div className="card-header">
            <strong>Context</strong>
          </div>
          <div className="card-body">
            <div className="row">{rolloffPredicates}</div>
            <div className="row">{defaultPredicates}</div>
            <h4 className="my-3" onClick={() => this.togglePredicates()}>
              Other{' '}
              <FontAwesomeIcon className="feather h-50" icon={this.state.showOtherPredicates ? faAngleDoubleUp : faAngleDoubleDown} />
            </h4>
            {this.state.showOtherPredicates ? <div className="row">{otherPredicates}</div> : null}
            <div className="row mt-4">
              <div className="col-4 d-flex align-items-center">
                <button
                  className="btn btn-primary mr-3"
                  onClick={this.onGetContext}
                >
                  Get Result
                </button>
              </div>
            </div>
          </div>
        </div>
        {this.props.segments.length > 0 && (
          <div>
            <CampaignContext campaign={this.props.campaigns[0] || {}} loading={this.props.loadingCampaigns} />
            <LowerPriorityCampaignsContext campaigns={this.renderOtherCampaigns()} loadingCampaigns={this.props.loadingCampaigns} />
            <AgentContext context={this.state.predicates} setContext={this.setContext} />
            <SegmentsContext loading={this.props.loadingSegments} segments={this.props.segments} />
            <ExperimentContext campaign={this.props.campaigns[0]} experiment={this.props.experiment} loading={this.props.loadingExperiment} />
          </div>
        )}
      </div>
    )
  }
}

function mapStateToProps(state) {
  return {
    predicates: Object.values(state.predicates.info),
    segments: state.context.segments,
    campaigns: state.context.campaigns,
    experiment: state.context.experiment,
    loadingSegments: state.context.loading.segments,
    loadingCampaigns: state.context.loading.campaigns,
    loadingExperiment: state.context.loading.experiment,
  }
}

const mapDispatchToProps = {
  changeModule,
  getPredicates,
  getSegmentsContext,
  getCampaignContext,
  getExperimentContext,
  getAgentContext,
}

export default connect(mapStateToProps, mapDispatchToProps)(Context)
