import axios from 'axios'

export const getZipCode = (input) => {
  return { type: 'GET_ZIP_CODE', payload: input }
}

export const getTracts = (input) => {
  return { type: 'GET_TRACTS', payload: input }
}

export function uploadCsvList() {
  return function(dispatch, getState) {
    const { zipCode } = getState().territory
    dispatch({ type: 'CREATE_LIST', payload: 'LOADING' })
    axios.post(`${process.env.REACT_APP_GARCON_API}/territory/csv/zip`, { zip_codes: zipCode })
      .then((response) => {
        if (response.status === 200) {
          dispatch({ type: 'CREATE_LIST', payload: 'SUCCESS' })
        } else {
          dispatch({ type: 'CREATE_LIST', payload: 'FAIL' })
        }
      })
      .catch((error) => {
        dispatch({ type: 'CREATE_LIST', payload: 'FAIL' })
        throw error
      })
  }
}

export function getCount() {
  return function(dispatch, getState) {
    const { tracts } = getState().territory
    axios.get(`${process.env.REACT_APP_GARCON_API}/territory/count?tracts=${tracts}`)
      .then((response) => {
        dispatch({ type: 'GET_COUNT', payload: response.data })
      })
      .catch((error) => {
        throw error
      })
  }
}

export function uploadTractList() {
  return function(dispatch, getState) {
    const { tracts, listName, organizationId } = getState().territory
    dispatch({ type: 'CREATE_LIST', payload: 'LOADING' })
    axios.post(`${process.env.REACT_APP_GARCON_API}/territory/lists`, {
      tracts,
      list_name: listName,
      organization_id: organizationId,
    })
      .then((response) => {
        if (response.status === 200) {
          dispatch({ type: 'CREATE_LIST', payload: 'SUCCESS' })
        } else {
          dispatch({ type: 'CREATE_LIST', payload: 'FAIL' })
        }
      })
      .catch((error) => {
        dispatch({ type: 'CREATE_LIST', payload: 'FAIL' })
        throw error
      })
  }
}
