import axios from 'axios'
import { getUser } from './getUser'

export default (accessToken, email) => {
  return async (dispatch) => {
    try {
      const response = await axios.post(`${process.env.REACT_APP_GARCON_API}/tokens`, {}, {
        headers: { Authorization: `Bearer ${accessToken}` },
      })
      localStorage.setItem('idToken', response.data.token)
      axios.defaults.headers.common['Authorization'] = 'Bearer ' + response.data.token
      if (response.data.token){
        dispatch(getUser(email))
      }
    } catch (error) {
      dispatch({ type: 'SHOW_ERROR', payload: { response: error.response, message: 'An error getting access token' } })
      throw error
    }
  }
}
