export default (options, defaultTableColumns) => {
  const optionValues = options.map(option => option.value)
  // keep the order of the original columns
  let updatedTableColumns = []
  defaultTableColumns.forEach(column => {
    optionValues.forEach(value => {
      if (column.id === value.id) updatedTableColumns.push(column)
    })
  })
  return updatedTableColumns
}
