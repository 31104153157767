import axios from 'axios'
import { getOffices } from './getOffices'

export const updateOffice = (input) => {
  return async(dispatch) => {
    try {
      const params = {
        'office_code' : input.selected_office_code ,
        'call_recording_required': input.call_recording_required,
        'is_active': input.is_active,
      }
      const response = await axios.put(`${process.env.REACT_APP_GARCON_API}/sales/office/${input.selected_office_code}`, params)
      if (response){
        dispatch(getOffices(input.selected_office_code))
      }
    } catch (error) {
      dispatch({ type: 'SHOW ERROR', payload: { message:'An error occured updating office.' } })
      throw error
    }
  }
}
