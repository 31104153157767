import React, { useState, useEffect } from 'react'
import ReactTable from 'react-table'
import matchSorter from 'match-sorter'
import { useDispatch } from 'react-redux'
import history from '../../../history'
import { getRolloffExperiments } from '../../../apis/rolloffExperiments'
import Loading from '../../Shared/Loading'
import { changeModule } from '../../../actions/changeModule'

const RolloffExperiments = () => {
  const dispatch = useDispatch()
  const [experiments, setExperiments] = useState(null)
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    dispatch(changeModule('Experiments'))
    getRolloffExperiments(setExperiments)
      .then(() => {
        setLoading(false)
      })
  }, [])

  const columns = [
    {
      Header: 'Name',
      accessor: 'experiment_name',
      filterMethod: (filter, rows) => (
        matchSorter(rows, filter.value, { keys: ['experiment_name'] })
      ),
      filterAll: true,
      minWidth: 200
    },
    {
      Header: 'ID',
      accessor: 'experiment_id',
      filterMethod: (filter, rows) => (
        matchSorter(rows, filter.value, { keys: ['experiment_id'] })
      ),
      filterAll: true,
      minWidth: 150
    },
    {
      Header: 'Description',
      accessor: 'experiment_desc',
      filterMethod: (filter, rows) => (
        matchSorter(rows, filter.value, { keys: ['experiment_desc'] })
      ),
      filterAll: true,
      minWidth: 250
    },
    {
      Header: 'Start Date',
      id: 'experiment_start_dt',
      accessor: props => new Date(props.experiment_start_dt).toISOString().slice(0, 10),
      filterMethod: (filter, rows) => (
        matchSorter(rows, filter.value, { keys: ['experiment_start_dt'] })
      ),
      filterAll: true,
    },
    {
      Header: 'End Date',
      id: 'experiment_end_dt',
      accessor: props => props.experiment_end_dt ? new Date(props.experiment_end_dt).toISOString().slice(0, 10) : '',
      filterMethod: (filter, rows) => (
        matchSorter(rows, filter.value, { keys: ['experiment_start_dt'] })
      ),
      filterAll: true,
    },
  ]

  const handleRowSelect = (experiment) => {
    history.push(`/rolloff_experiments/experiment/${experiment.experiment_id}`)
  }

  return (
    <div className="container-fluid">

      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <h4 className="my-3">
          Rolloff Experiments
        </h4>
        <button className="btn btn-primary btn-md mb-2" onClick={() => history.push('/rolloff_experiments/new')}>
          New Experiment
        </button>
      </div>

      {experiments &&
        <ReactTable
          filterable
          className="-striped -highlight mb-3"
          columns={columns}
          data={experiments}
          defaultPageSize={100}
          keyField="experiment_id"
          style={{ height: '500px' }}
          getTdProps={(s, r, c) => {
            if (c.id !== '_selector') {
              return {
                onClick: () => handleRowSelect(r.original),
                style: {
                  cursor: 'pointer',
                },
              }
            } else {
              return {}
            }
          }}
        />
      }

      {loading && <Loading />}
    </div>
  )
}

export default RolloffExperiments
