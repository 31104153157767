import React, { useState } from 'react';
import { createShopper } from '../../../apis/createShopper';

const ShopperCreation = () => {
  const [file, setFile] = useState(null)
  const [response, setResponse] = useState(null)
  const [error, setError] = useState('Please attach a .csv file')

  const onFileChange = (e) => {
    const selectedFile = e.target.files[0]

    if (selectedFile && selectedFile.name.endsWith('.csv')) {
      setError(null)
      setResponse(null)
      setFile(selectedFile)
    } else {
      setFile(null)
      setError('Please attach a valid .csv file')
    }
  }

  const attachFile = () => {
    if (file) {
      setError(null)
      createShopper(file, setResponse, setError)
    } else {
      setError('Please attach a .csv file')
    }
  }

  return (
    <>
      <h1 className='text-primary '>Shopper Creation</h1>

      <div className='w-100 mb-3'>
        <input className='w-100 mt-2 mb-3' id='file-input' type='file' accept='.csv' onChange={onFileChange} />
        <button disabled={!file} className='btn btn-sm btn-primary rounded' type='button' onClick={attachFile}>Add Attachment</button>
      </div>
      {response && 
        <div>
          <p>Your file has been successfully uploaded to s3.</p> 
          <p>Please check the #inspa-ops slack channel for a link to the file with generated shortkeys.</p>
        </div>
      }
      {error &&
        <p className='text-danger'>{error}</p>
      }
    </>
  )
}

export default ShopperCreation
