import axios from 'axios'

export const getPTC = () => {
  return async(dispatch) => {
    return axios.get(`${process.env.REACT_APP_GARCON_API}/pricing/rates/ptc`)
      .then((response) => {
        dispatch({ type: 'GET_PTC', payload: response.data })
      })
      .catch((error) => {
        dispatch({ type: 'SHOW_ERROR', payload: { response: error.response, message: 'An error occured getting price to compare rates.' } })
        throw error
      })
  }
}


export const getPTCForMarket = (marketCode) => {
  return async(dispatch) => {
    return axios.get(`${process.env.REACT_APP_GARCON_API}/pricing/rates/ptc/all/market/${marketCode}`)
      .then((response) => {
        dispatch({ type: 'GET_PTC_FOR_MARKET', payload: response.data })
      })
      .catch((error) => {
        dispatch({ type: 'SHOW_ERROR', payload: { response: error.response, message: 'An error occured getting price to compare rates.' } })
        throw error
      })
  }
}
