import React, { Component } from 'react'
import { connect } from 'react-redux'
import history from '../../../history'
import createStaticMarketRate from '../../../actions/staticMarketRates/createStaticMarketRate'
import Modal from '../../Modal'
import PlanSelect from '../../Shared/PlanSelect'
import Select from 'react-select'
import getMarkets  from '../../../actions/getMarkets'
import { getMarketRatesForMarket } from '../../../actions/pricing/getMarketRates'
import isEmpty from 'lodash/isEmpty'
import { Link } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSignInAlt } from '@fortawesome/free-solid-svg-icons'

class CreateStaticMarketRateModal extends Component {

  state = {
    market_code: null,
    revenue_class_code: null,
    plan_code: null,
    rate: null,
  }

  componentDidMount() {
    this.props.getMarkets()
  }

  componentDidUpdate(prevProps) {
    if (prevProps.saving !== this.props.saving && !this.props.saving && this.props.savedRateId) {
      this.closeModal()
    }
  }

  onChange = (key, value) => {
    this.setState({ [key]: value })
  }

  onChangeMarketCode = (key, value) => {
    this.setState({ [key]: value })
    this.props.getMarketRatesForMarket(value)
  }

  saveStaticMarketRate = () => {
    this.props.createStaticMarketRate(this.state)
  }

  closeModal() {
    history.goBack()
  }

  validStaticMarketRate() {
    const requiredFields = ['market_code', 'revenue_class_code', 'plan_code', 'rate']
    return requiredFields.every(field => !!this.state[field])
  }

  marketRateOptions() {
    return this.props.marketRatesForMarket.map(rate => (
      { label: rate.rate, value: rate.rate }
    ))
  }

  marketCodeOptions() {
    return this.props.allMarkets.map(m => (
      { label: m.market_code, value: m.market_code }
    ))
  }

  options() {
    return this.props.plans.map(option => (
      { value: option.plan_code, label: `${option.plan_name} - ${option.plan_code}` }
    ))
  }


  render() {
    return (
      <div>
        <Modal  closeModal={this.closeModal} title="New Static Market Rate" visible={true}>
          <div className="mt-4">
            <div className="my-3 col-6">
              <div className="row form-group">
                <label className="font-weight-bold mr-2">
                  Market Code
                </label>
                <div className="col">
                  <Select
                    label="Market Code"
                    options={this.marketCodeOptions()}
                    value={this.state.market_code}
                    onChange={(event) =>  event ? this.onChangeMarketCode('market_code', event.value) : this.onChangeMarketCode('market_code', null) }
                  />
                </div>
              </div>
              <div className="row form-group">
                <label className="font-weight-bold mr-2">
                  Revenue Class Code
                </label>
                <div className="col">
                  <Select
                    label="Revenue Class Code"
                    options={[ { label: 'RESI', value: 'RESI' }, { label: 'SMALL-COM', value: 'SMALL-COM' }]}
                    value={this.state.revenue_class_code}
                    onChange={(event) =>  event ? this.onChange('revenue_class_code', event.value) : this.onChange('revenue_class_code', null) }
                  />
                </div>
              </div>
              <div className="row form-group">
                <label className="font-weight-bold mr-2">
                  Plan
                  {this.state.plan_code &&
                    <span>
                      &nbsp;<Link className="text-primary" target="_blank" to={`/plans/${this.state.plan_code}`}><FontAwesomeIcon icon={faSignInAlt} /></Link>
                    </span>
                  }
                </label>
                <div className="col">
                  <PlanSelect
                    writeAccess
                    planCode={this.state.plan_code}
                    onChange={(value) => this.onChange('plan_code', value)}
                  />
                </div>
              </div>
              <div className="row form-group">
                <label className="font-weight-bold mr-2">
                  Rate
                </label>
                <div className="col">
                  { isEmpty(this.state.market_code) ?   (
                    <p className="rateMessage"> Please select a market code to see available rates </p>
                  ) : (
                    <Select className="market-rate-select"
                      label="Rate"
                      noResultsText={'Contact Account Operations to add a new rate for this market.'}
                      options={this.marketRateOptions()}
                      value={this.state.rate}
                      onChange={(event) =>  event ? this.onChange('rate', event.value) : this.onChange('rate', null) }
                    />
                  )}
                </div>
              </div>
            </div>
            <button className="btn btn-primary" disabled={!this.validStaticMarketRate()} onClick={this.saveStaticMarketRate}>Save</button>
          </div>
        </Modal>
      </div>
    )
  }
}

function mapStateToProps(state) {
  return {
    loading: state.staticMarketRates.loading,
    saving: state.staticMarketRates.saving,
    savedRateId: state.staticMarketRates.savedRateId,
    allMarkets: state.markets.all,
    marketRatesForMarket: state.marketRates.ratesForMarket,
  }
}

const mapDispatchToProps = {
  createStaticMarketRate,
  getMarkets,
  getMarketRatesForMarket,
}

export default connect(mapStateToProps, mapDispatchToProps)(CreateStaticMarketRateModal)
