import axios from 'axios'
import getCase from './getCase'
import { default as FormData } from 'form-data'

export default (case_guid, file) => {
  return async (dispatch, getState) => {
    try {
      const user = getState().user
      var formData = new FormData()
      formData.append('file', file)
      formData.append('user_id', user.userId)
      const response = await axios.post(`${process.env.REACT_APP_GARCON_API}/cases/${case_guid}/attach`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
      if (response) {
        dispatch(getCase(case_guid))
      }
    } catch (error) {
      dispatch({ type: 'SHOW_ERROR', payload: { response: error.response, message: 'An error occured adding attachment.' } })
      throw error
    }
  }
}
