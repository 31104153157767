import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import moment from 'moment'
import cloneDeep from 'lodash/cloneDeep'
import SectionSelect from '../../Shared/SectionSelect'
import SectionInput from '../../Shared/SectionInput'
import Conditions from '../CompRunScheduleFormPaymentRules/Conditions'
import { getCompRunSchedules } from '../../../actions/Compensation/getCompRunSchedules'
import getCompAttributeValues from '../../../actions/Compensation/getCompAttributeValues'

const PREDICATES = [
  { value: 'has', description: 'is', types: ['bool', 'string', 'integer'] },
  { value: 'has', description: 'has', types: ['array'] },
  { value: 'less', description: 'Before', types: ['date'] },
  { value: 'greater', description: 'On or After', types: ['date'] },
  { value: 'less', description: '<', types: ['integer'] },
  { value: 'greater', description: '>=', types: ['integer'] },
]

const PAYMENT_TIERS = [
  'CHANNEL_PARTNER',
  'OFFICE',
  'SALES_AGENT',
  'SOURCE'
]

// Allowed props:
// compRunScheduleBonusRule
// readOnly (true/false)
// onUpdateField (method takes value, field name)
class CompBonusDetailsForm extends Component {
  componentDidMount() {
    this.props.getCompRunSchedules()
    this.props.getCompAttributeValues(true)
  }

  renderRescissionContent() {
    const bonus = this.props.compRunScheduleBonusRule
    let rescissionContent = null
    const active = <h5 className={'text-info'} style={{ padding: '10px' }}>This bonus is active</h5>
    const activeWithRescission = <h5 className={'text-warning'} style={{ padding: '10px' }}>We will continue to look for sales for this bonus through {moment(bonus.rescission_end_dt).format('L')}</h5>
    const inactive = <h5 className={'text-danger'} style={{ padding: '10px' }}>This bonus has ended</h5>
    const activeInFuture = <h5 className={'text-info'} style={{ padding: '10px' }}>This bonus has not started yet</h5>
    const deactivatedFromThresholdReached = <h5 className={'text-danger'} style={{ padding: '10px' }}>This bonus was deactivated after the one-time bonus was created</h5>

    if (this.props.readOnly) {
      if (bonus.is_active) {
        if (bonus.rescission_end_dt) {
          rescissionContent = activeWithRescission
        } else {
          rescissionContent = active
        }
      } else if (bonus.is_active_in_future) {
        // Inactive bonus - in future
        rescissionContent = activeInFuture
      } else {
        // Inactive bonus - not in future
        if (bonus.is_deactivated_from_threshold_payment) {
          rescissionContent = deactivatedFromThresholdReached
        } else {
          rescissionContent = inactive
        }
      }
    }

    return rescissionContent
  }

  requiresOrderThreshold = () => {
    return this.props.compRunScheduleBonusRule.tier_name !== 'SALES_AGENT'
  }

  deleteCondition = (index) => {
    const bonus_rules = cloneDeep(this.props.compRunScheduleBonusRule.bonus_rules)
    bonus_rules.conditions.splice(index, 1)
    this.props.onUpdateField(bonus_rules, 'bonus_rules')
  }

  updateCondition = (index, key, value) => {
    const bonus_rules = cloneDeep(this.props.compRunScheduleBonusRule.bonus_rules)
    bonus_rules.conditions[index][key] = value
    this.props.onUpdateField(bonus_rules, 'bonus_rules')
  }

  addCondition = () => {
    const bonus_rules = cloneDeep(this.props.compRunScheduleBonusRule.bonus_rules)
    bonus_rules.conditions.push(
      {
        object: '',
        subject: '',
        predicate: '',
      }
    )
    this.props.onUpdateField(bonus_rules, 'bonus_rules')
  }

  updateBonusAmount = (value) => {
    const bonus_rules = cloneDeep(this.props.compRunScheduleBonusRule.bonus_rules)
    bonus_rules.bonus_amount = value
    this.props.onUpdateField(bonus_rules, 'bonus_rules')
  }

  render() {
    const endDate = this.props.compRunScheduleBonusRule.bonus_rule_end_dt || !this.props.readOnly ? <SectionInput label="End Date" readonly={this.props.readOnly} type="date" value={this.props.compRunScheduleBonusRule.bonus_rule_end_dt} labelColClass="text-left" onChange={(value) => this.props.onUpdateField(value, 'bonus_rule_end_dt') } /> : null
    const deactivatedDate = this.props.compRunScheduleBonusRule.deactivated_dt ? <SectionInput label="Deactivated Date" readonly={true} type="date" value={this.props.compRunScheduleBonusRule.deactivated_dt} labelColClass="text-left" /> : null

    return (
      <div>
        <div className="row form-group">
          <div className="col-4 text-left">
            <label className="font-weight-bold mr-2 mb-0">
              Comp Run Schedule Code
            </label>
          </div>
          <div className="col">
            <select
              className="form-control form-control-md w-100"
              disabled={this.props.readOnly}
              value={this.props.compRunScheduleBonusRule.comp_run_schedule_code || ''}
              onChange={(event) => this.props.onUpdateField(event.target.value, 'comp_run_schedule_code')}
            >
              <option value="" />
              {this.props.schedules.map(option => (
                <option key={option['comp_run_schedule_code']} label={option['comp_run_schedule_name']} value={option['comp_run_schedule_code']} />
              ))}
            </select>
          </div>
        </div>

        <div className="row form-group">
          <div className="col-4 text-left">
            <label className="font-weight-bold mr-2 mb-0">
              Payment Tier
            </label>
          </div>
          <div className="col">
            <select
              className="form-control form-control-md w-100"
              disabled={this.props.readOnly}
              value={this.props.compRunScheduleBonusRule.tier_name || ''}
              onChange={(event) => this.props.onUpdateField(event.target.value, 'tier_name')}
            >
              <option value="" />
              {PAYMENT_TIERS.map(tier_name => (
                <option key={tier_name} label={tier_name} value={tier_name} />
              ))}
            </select>
          </div>
        </div>

        <div className="row form-group">
          <div className="col-4 text-left">
            <label className="font-weight-bold mr-2 mb-0">
              Recurring?
            </label>
          </div>
          <div className="col">
            <input
              type="checkbox"
              className="ml-2"
              style={{ transform: 'scale(2)' }}
              disabled={this.props.readOnly}
              checked={this.props.compRunScheduleBonusRule.recurring}
              onChange={(event) => this.props.onUpdateField(event.target.checked, 'recurring')}
            />
          </div>
        </div>

        <SectionInput label="Description" readonly={this.props.readOnly} type="textarea" value={this.props.compRunScheduleBonusRule.bonus_rule_desc || ''} labelColClass="text-left" onChange={(event) => this.props.onUpdateField(event.target.value, 'bonus_rule_desc') } />
        <SectionInput label="Bonus Amount" readonly={this.props.readOnly} type="number" value={this.props.compRunScheduleBonusRule.bonus_rules.bonus_amount} labelColClass="text-left" onChange={(event) => this.updateBonusAmount(event.target.value) } />
        <SectionInput label="Bonus After Order Threshold" readonly={this.props.readOnly} type="number" value={this.props.compRunScheduleBonusRule.bonus_after_order_threshold} labelColClass="text-left" required={this.requiresOrderThreshold()} onChange={(event) => this.props.onUpdateField(event.target.value, 'bonus_after_order_threshold') } />
        <SectionInput label="Start Date" readonly={this.props.readOnly} type="date" value={this.props.compRunScheduleBonusRule.bonus_rule_start_dt} labelColClass="text-left" onChange={(value) => this.props.onUpdateField(value, 'bonus_rule_start_dt') } />
        {endDate}
        {deactivatedDate}
        {this.renderRescissionContent()}
        { !this.props.readOnly &&
          <button className="btn btn-primary mb-3" onClick={this.addCondition}>+ Add Condition</button>
        }
        { this.props.compRunScheduleBonusRule.bonus_rules.conditions.length > 0 &&
          <Conditions
            deleteCondition={this.deleteCondition}
            predicates={PREDICATES}
            readOnly={this.props.readOnly}
            rules={this.props.compRunScheduleBonusRule.bonus_rules.conditions}
            uniqueId={0}
            updateCondition={this.updateCondition}
          />
        }
      </div>
    )
  }
}

function mapStateToProps({ compRunScheduleBonusRules, compRunSchedules }) {
  return {
    loading: compRunScheduleBonusRules.loading,
    schedules: compRunSchedules.compRunSchedules,
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ getCompAttributeValues, getCompRunSchedules }, dispatch)
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CompBonusDetailsForm)
