export const variationsErrors = ({ control, total, variations, formData }) => {
  return [
    {
      condition: total != 1,
      message: 'Variations have to total 1.'
    },
    {
      condition: !control.amount || !control.treatment_id,
      message: 'Cannot create experiment without champion data.'
    },
    {
      condition: !!variations.find(variant => !variant.treatment_id),
      message: 'Missing treatment for variant.'
    },
    {
      condition: formData.name.length <= 0 || formData.startDate <= 0,
      message: 'Form details missing.'
    }
  ]
}

export const saveIsDisabled = (variationsErrors) => variationsErrors.find(error => error.condition)

export const getVariationsTotal = (control, variations) => {
  let runningTotal = Number(control['amount'])
  variations.forEach(variant => {
    runningTotal = ((runningTotal * 100) + (Number(variant.amount) * 100)) / 100
  })
  return runningTotal
}
