import axios from 'axios'

export const getRolloffTreatments = async () => {
  try {
    const response = await axios.get(`${process.env.REACT_APP_GARCON_API}/internal/rolloff_treatments`)
    return response.data
  } catch (error) {
    throw error
  }
}

export const getRolloffTreatment = async (treatment_id, callback) => {
  try {
    const response = await axios.get(`${process.env.REACT_APP_GARCON_API}/internal/rolloff_treatments/${treatment_id}`)
    callback(response.data)
  } catch (error) {
    throw error
  }
}

export const createRolloffTreatment = async (treatment, callback) => {
  try {
    const response = await axios.post(`${process.env.REACT_APP_GARCON_API}/internal/rolloff_treatments`, treatment)
    callback(response.data)
    return response?.data
  } catch (error) {
    throw error
  }
}

export const updateRolloffTreatment = async (treatment_id, treatment) => {
  try {
    await axios.put(`${process.env.REACT_APP_GARCON_API}/internal/rolloff_treatments/${treatment_id}`, treatment)
  } catch (error) {
    throw error
  }
}

export const getRolloffTreatmentImplementations = async (treatment_id, callback) => {
  try {
    const response = await axios.get(`${process.env.REACT_APP_GARCON_API}/internal/rolloff_treatments/${treatment_id}/usage`)
    callback(response.data)
  } catch (error) {
    throw error
  }
}
