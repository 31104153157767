import axios from 'axios'
import { getMarketGeos } from './getMarketGeos'

export const updateMarketGeo = (marketGeo, status) => {
  return async (dispatch) => {
    const params = {
      verification_status: status
    }
    try {
      const response = await axios.put(`${process.env.REACT_APP_GARCON_API}/offers/market_geos/${marketGeo.market_geo_id}`, params)
      dispatch(getMarketGeos())
    } catch (error) {
      dispatch({ type: 'SHOW_ERROR', payload: { message: 'An error occured updating market geo.' } })
      throw error
    }
  }
}
