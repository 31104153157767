import axios from 'axios'

export const getRolloffRulesets = async (callback) => {
  try {
    const response = await axios.get(`${process.env.REACT_APP_GARCON_API}/internal/rolloff_rulesets`)
    callback(response.data)
  } catch (error) {
    throw error
  }
}

export const getRolloffRuleset = async (ruleset_id, callback) => {
  try {
    const response = await axios.get(`${process.env.REACT_APP_GARCON_API}/internal/rolloff_rulesets/${ruleset_id}`)
    callback(response.data)
  } catch (error) {
    throw error
  }
}

export const getRolloffRulesetActivity = async (ruleset_id, callback) => {
  try {
    const response = await axios.get(`${process.env.REACT_APP_GARCON_API}/internal/rolloff_rulesets/${ruleset_id}/history`)
    callback(response.data)
  } catch (error) {
    throw error
  }
}

export const updateRolloffRuleset = async (ruleset_id, ruleset) => {
  try {
    await axios.put(`${process.env.REACT_APP_GARCON_API}/internal/rolloff_rulesets/${ruleset_id}`, ruleset)
  } catch (error) {
    throw error
  }
}
