import React, { useState, useEffect } from 'react'
import { getRolloffExperiments } from '../../../apis/rolloffExperiments'
import { getRolloffTreatments } from '../../../apis/rolloffTreatments'
import FuzzySelect from '../../Shared/FuzzySelect'

const SelectRule = ({ addRule, lastResort, showLastResortDropdown = false, setHasLastRule = null }) => {
  const [experiments, setExperiments] = useState(null)
  const [treatments, setTreatments] = useState(null)
  const [ruleOption, setRuleOption] = useState('')
  const [selectedExperiment, setSelectedExperiment] = useState(null)
  const [selectedTreatment, setSelectedTreatment] = useState(null)

  useEffect(() => {
    getRolloffExperiments(setExperiments)
    getRolloffTreatments()
      .then((treatments) => {
        setTreatments(treatments)
      })
  }, [])

  const getExperimentOptions = () => {
    return Object.values(experiments).map(experiment => (
      { value: experiment.experiment_id, label: experiment.experiment_name }
    ))
  }

  const getTreatmentOptions = () => {
    return treatments && Object.values(treatments).map(treatment => (
      { value: treatment.rolloff_treatment_id, label: treatment.treatment_name }
    ))
  }

  const selectRule = async (type, value) => {
    if (type === 'experiment') {
      setSelectedExperiment(value)
      const experiment = experiments.find(item => item.experiment_id === value)
      await addRule('experiment', experiment, lastResort)
      setSelectedExperiment(null)
    } else {
      setSelectedTreatment(value)
      if (lastResort) setHasLastRule(true)
      const treatment = treatments.find(item => item.rolloff_treatment_id === value)
      await addRule('treatment', treatment, lastResort)
      setSelectedTreatment(null)
    }
    setRuleOption('')
  }

  return (
    <>
      {experiments && treatments ?
        <>
          {
            !showLastResortDropdown ?
              <div className="col-sm-6" style={{ display: 'flex', justifyContent: 'flex-end' }}>
                <select
                  className="form-control"
                  value={ruleOption}
                  onChange={e => setRuleOption(e.target.value)}
                >
                  <option disabled value="">Add rule - choose type</option>
                  <option value="experiment">Experiment</option>
                  <option value="treatment">Treatment</option>
                </select>
                {ruleOption === 'experiment' &&
                  <div className="col-sm-6">
                    <FuzzySelect
                      placeholder="Select experiment"
                      options={getExperimentOptions()}
                      value={selectedExperiment}
                      onChange={(e) => selectRule('experiment', e.value)}
                    />
                  </div>
                }
                {ruleOption === 'treatment' &&
                  <div className="col-sm-6">
                    <FuzzySelect
                      placeholder="Select treatment"
                      options={getTreatmentOptions()}
                      value={selectedTreatment}
                      onChange={(e) => selectRule('treatment', e.value)}
                    />
                  </div>
                }
              </div>
              :
              <div className="col-sm-6">
                <FuzzySelect
                  placeholder="Select treatment"
                  options={getTreatmentOptions()}
                  value={selectedTreatment}
                  onChange={(e) => selectRule('treatment', e.value)}
                />
              </div>
          }
        </>
        : <div className="col-sm-6">Loading...</div>
      }
    </>
  )
}

export default SelectRule
