import React, { useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const ViewTreatmentBucket = ({
  bucket,
  index,
}) => {
  const [collapsed, setCollapsed] = useState(true)

  const getBucketRange = () => {
    if (index === 0) {
      if (bucket.min === null && bucket.max === null) {
        return <span>( -Infinity : Infinity )</span>
      } else {
        return <span>( {bucket.max} : Infinity )</span>
      }
      // the last bucket
    } else if (bucket.max === null) {
      return <span>( -Infinity : {bucket.min} )</span>
    } else {
      return <span>( {bucket.max} : {bucket.min} )</span>
    }
  }

  const isMinMax = (bucket.bucketData.bucketChoice === 'MIN' || bucket.bucketData.bucketChoice === 'MAX')

  const bucketUI = (bucketData) => {
    return <div className="p mt-2">
      <span className="mr-3">{bucketData.bucketChoice}</span>
      <span className="mr-3">{bucketData.modifier}</span>
      {bucketData.modifier != 'no modifier' &&
        <span className="mr-3">{bucketData.bucketAmount}</span>}
    </div>
  }

  return (
    <div className="mb-2">
      <div className="row">
        <div className="col-4 font-weight-bold">Bucket {index + 1} {getBucketRange()} </div>
        <div className="col-4">
          {bucket.planChoice}
          {bucket.planChoice != 'Do not renew' &&
            <a className="ml-4" onClick={() => setCollapsed(!collapsed)}>
              <FontAwesomeIcon
                className="mr-2"
                icon={collapsed ? 'chevron-up' : 'chevron-down'}
                style={{ width: '12px', color: '#B31066' }}
              />
            </a>}
        </div>
      </div>
      {!collapsed &&
        <div className="mt-2">
          {bucket.planChoice === 'Renew on different plan' && <div>Plan: {bucket.plan}</div>}
          {!isMinMax &&
            bucketUI(bucket.bucketData)
          }
          {isMinMax &&
            <div className="row">
              <div className="col-1">{bucket.bucketData.bucketChoice}</div>
              <div>
                {bucket.bucketData.array.map((item, index) => {
                  return (
                    <React.Fragment key={index}>
                      {bucketUI(item)}
                    </React.Fragment>
                  )
                })}
              </div>
            </div>}
        </div>
      }
    </div>
  )
}

export default ViewTreatmentBucket
