import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { searchAgent } from '../../actions/agents/searchAgent'
import AgentsTable from './AgentsTable'

class SearchContainer extends Component {
  constructor() {
    super()
    this.state = {
      search: '',
    }
  }

  searchAgent = (e) => {
    e.preventDefault()
    this.props.searchAgent(this.state.search)
  }

  render() {
    return (
      <div>
        <h4 className="text-center">Edit Sales Agent</h4>
        <div className="input-group mb-3">
          <input aria-describedby="basic-addon2" aria-label="Search Agent" className="form-control" placeholder="Search Agent" type="text" value={this.state.search} onChange={e => this.setState({ search: e.target.value })} />
          <div className="input-group-append">
            <button className="btn btn-primary rounded" type="button" onClick={e => this.searchAgent(e)}>Search</button>
          </div>
        </div>
        { this.props.agents.length > 0 &&
          <AgentsTable />
        }
      </div>
    )
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ searchAgent }, dispatch)
}

function mapStateToProps(state) {
  return {
    agents: state.salesAgents.all,
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(SearchContainer)
