export function urlRedirects(state = [], action){
  switch (action.type) {
  case 'GET_URL_REDIRECTS':
    return action.payload
  default:
    return state
  }
}

export function urlRedirect(state =[], action){
  switch (action.type) {
  case 'GET_URL':
    return action.payload
  default:
    return state
  }
}

