import axios from 'axios'

export const getSalesRoles = () => {
  return async(dispatch) => {
    return axios.get(`${process.env.REACT_APP_GARCON_API}/users/roles`)
      .then((response) => {
        const filteredRoles = response.data.filter(role => role.name === 'SalesAgent' || role.name === 'SalesPartnerExec' || role.name === 'SalesManager' || role.name === 'SalesPartnerManager')
        dispatch({ type: 'GET_SALES_ROLES', payload: filteredRoles })
      })
      .catch((error) => {
        dispatch({ type: 'SHOW_ERROR', payload: { response: error.response, message: 'There was an error getting agents' } })
        throw error
      })
  }
}
