import axios from 'axios'

export const getCampaignsForExperiment = async (experiment_id, callback) => {
  try {
    const response = await axios.get(`${process.env.REACT_APP_GARCON_API}/internal/campaign_experiments/${experiment_id}`)
    callback(response.data)
  } catch (error) {
    throw error
  }
}
