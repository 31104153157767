import React, { useState, useEffect } from 'react'
import ReactTable from 'react-table'
import matchSorter from 'match-sorter'
import history from '../../../history'
import { useDispatch } from 'react-redux'
import { getRolloffTreatments } from '../../../apis/rolloffTreatments'
import Loading from '../../Shared/Loading'
import { changeModule } from '../../../actions/changeModule'

const Treatments = () => {
  const dispatch = useDispatch()
  const [treatments, setTreatments] = useState(null)
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    dispatch(changeModule('Treatments'))
    getRolloffTreatments()
      .then((treatments) => {
        const filteredTreatments = treatments.map((treatment) => {
          const status = treatment.active ? 'Active' : 'Inactive'
          const updatedTreatment = treatment
          updatedTreatment.status = status
          return updatedTreatment
        })
        setTreatments(filteredTreatments)
        setLoading(false)
      })
  }, [])

  const columns = [
    {
      Header: 'Name',
      accessor: 'treatment_name',
      filterMethod: (filter, rows) => (
        matchSorter(rows, filter.value, { keys: ['treatment_name'] })
      ),
      filterAll: true,
      minWidth: 300
    },
    {
      Header: 'ID',
      accessor: 'rolloff_treatment_id',
      filterMethod: (filter, rows) => (
        matchSorter(rows, filter.value, { keys: ['rolloff_treatment_id'] })
      ),
      filterAll: true,
      minWidth: 300
    },
    {
      Header: 'Description',
      accessor: 'notes',
      filterMethod: (filter, rows) => (
        matchSorter(rows, filter.value, { keys: ['notes'] })
      ),
      filterAll: true,
      minWidth: 300
    },
    {
      Header: 'Created',
      id: 'created_at',
      accessor: props => new Date(props.created_at).toISOString().slice(0, 10),
      filterMethod: (filter, rows) => (
        matchSorter(rows, filter.value, { keys: ['created_at'] })
      ),
      filterAll: true,
      minWidth: 100
    },
    {
      Header: 'Updated',
      id: 'updated_at',
      accessor: props => new Date(props.updated_at).toISOString().slice(0, 10),
      filterMethod: (filter, rows) => (
        matchSorter(rows, filter.value, { keys: ['updated_at'] })
      ),
      filterAll: true,
      minWidth: 100
    },
    {
      Header: 'Status',
      accessor: 'status',
      filterMethod: (filter, rows) => (
        matchSorter(rows, filter.value, { keys: ['status'] })
      ),
      filterAll: true,
      minWidth: 100
    },
  ]

  const handleRowSelect = (treatment) => {
    history.push(`/rolloff_treatments/treatment/${treatment.rolloff_treatment_id}`)
  }

  return (
    <div className="container-fluid">
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <h4 className="my-3">
          Rolloff Treatments
        </h4>
        <button className="btn btn-primary btn-md mb-2" onClick={() => history.push('/rolloff_treatments/new')}>
          New Treatment
        </button>
      </div>
      {treatments &&
        <ReactTable
          filterable
          className="-striped -highlight mb-3"
          columns={columns}
          data={treatments}
          defaultPageSize={100}
          keyField="rolloff_treatment_id"
          style={{ height: '500px' }}
          getTdProps={(s, r, c) => {
            if (c.id !== '_selector') {
              return {
                onClick: () => handleRowSelect(r.original),
                style: {
                  cursor: 'pointer',
                },
              }
            } else {
              return {}
            }
          }}
        />
      }
      {loading && <Loading />}
    </div>
  )
}

export default Treatments
