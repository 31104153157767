import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { filteredRoutes } from '../routes'
import isEmpty from 'lodash/isEmpty'

export class Sidebar extends Component {
  constructor(props) {
    super (props)
    this.state = {
      selected: '',
    }
  }

  toggleOpen = (label) => {
    if (this.state.selected === label){
      this.setState({ selected: '' })
    } else {
      this.setState({ selected: label })
    }
  }

  isActive(name) {
    return this.props.activeModule === name ? 'active' : ''
  }

  render() {
    let routes = []
    const roles = this.props.user.roles
    if (roles.length > 0) {
      const roleNames = roles.map(role => role.name)
      routes = filteredRoutes.map(group => {
        const groupedRoutes = group.routes.filter(route => {
          return roleNames.some(roleName => route.roles.some(routeRole => roleName === routeRole))
        })
        return {
          label: groupedRoutes.length === 0 ? '' : group.label,
          icon: groupedRoutes.length === 0 ? '' : group.icon,
          routes: groupedRoutes,
        }
      })
    }
    if (routes.length === 0) return ''

    return (
      <div>
        {this.props.getToggled && isEmpty(this.state.selected) ?
          <nav className="px-2 d-none d-md-block bg-light sidebar d-print-none" id="sidebar-wrapper">
            <ul className="nav flex-column ml-2">
              {routes.map((group, i) => (
                <div key={`route-${i}-${group.label}`} className="mb-3">
                  <a data-target={group.label} href="#anchor" role="button" onClick={() => this.toggleOpen(group.label)}>
                    <li className="nav-link font-weight-bold">
                      <FontAwesomeIcon className="feather mr-1" icon={group.icon} />
                      {this.state.selected === group.label && group.label}
                    </li>
                  </a>
                  <ul className="list-unstyled ml-2">
                    {group.routes.map((route) => (
                      <li key={route.name} className="sub-nav-item">
                        <div className={this.state.selected === group.label ? 'collapse show' : 'collapse'} id={group.label}>
                          <Link className={'sub-nav-link ' + this.isActive(route.name)} to={route.path}>
                            {route.name}
                          </Link>
                        </div>
                      </li>
                    ))}
                  </ul>
                </div>
              ))}
            </ul>
          </nav>
          :
          <nav className="col-2 px-2 d-none d-md-block bg-light sidebar toggle-open d-print-none" id="sidebar-wrapper">
            <div className="sidebar-sticky">
              <ul className="nav flex-column ml-2">
                {routes.map((group, i) => (
                  <div key={`route-${i}-${group.label}`} className="mb-3">
                    <a data-target={group.label} href="#anchor" role="button" onClick={() => this.toggleOpen(group.label)}>
                      <li className="nav-link font-weight-bold">
                        <FontAwesomeIcon className="feather mr-1" icon={group.icon} />
                        {group.label}
                      </li>
                    </a>
                    <ul className="list-unstyled ml-2">
                      {group.routes.map((route) => (
                        <li key={route.name} className="sub-nav-item">
                          <div className={this.state.selected === group.label ? 'collapse show' : 'collapse'} id={group.label}>
                            <Link className={'sub-nav-link ' + this.isActive(route.name)} to={route.path}>
                              {route.name}
                            </Link>
                          </div>
                        </li>
                      ))}
                    </ul>
                  </div>
                ))}
              </ul>
            </div>
          </nav>
        }
      </div>
    )
  }
}

function mapStateToProps(state) {
  return {
    user: state.user,
    activeModule: state.activeModule,
    toggled: state.toggled,
  }
}

export default connect(mapStateToProps)(Sidebar)
