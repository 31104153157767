/* global Blob: false */ // eslint-disable-line
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link, useParams } from 'react-router-dom'
import axios from 'axios'
import isEmpty from 'lodash/isEmpty'
import uniqBy from 'lodash/uniqBy'
import history from '../../history'
import { usePrevious } from '../../hooks'
import getCorrespondenceTemplate from '../../actions/correspondence/getCorrespondenceTemplate'
import cloneCorrespondenceTemplate from '../../actions/correspondence/cloneCorrespondenceTemplate'
import SectionInput from '../Shared/SectionInput'
import SectionSelect from '../Shared/SectionSelect'

const PRODUCT_TYPE_CODES = [
  {
    label: 'Electric',
    value: 'Electric',
  },
  {
    label: 'Natural Gas',
    value: 'NaturalGas',
  },
]

const CorrespondenceTemplateClone = () => {
  const dispatch = useDispatch()
  const correspondenceTemplate = useSelector(state => state.correspondenceTemplates.currentCorrespondenceTemplate)

  const { correspondenceTemplateId } = useParams()

  const [states, setStates] = useState([])
  const [params, setParams] = useState({ state: null, productTypeCode: null })
  const prevCorrespondenceTemplateId = usePrevious(correspondenceTemplate.correspondence_template_id)

  useEffect(() => {
    getMarkets()

    if (!isEmpty(correspondenceTemplate)) return

    dispatch(getCorrespondenceTemplate(correspondenceTemplateId))
  }, [])

  useEffect(() => {
    if (prevCorrespondenceTemplateId === correspondenceTemplate.correspondence_template_id) return

    history.push(`/correspondence_templates/${correspondenceTemplate.correspondence_template_id}`)
  }, [correspondenceTemplate.correspondence_template_id])

  const getMarkets = async () => {
    const getMarketsResponse = await axios.get(`${process.env.REACT_APP_GARCON_API}/offers/markets`, { params: { exclude_sop: true } })
    const uniqStates = uniqBy(getMarketsResponse.data.map(market => { return { label: market.state_code, value: market.state_code } }), 'label')
    setStates(uniqStates)
  }

  const onSetParams = (key, value) => {
    setParams({ ...params, [key]: value })
  }

  const preview = () => {
    const file = new Blob([correspondenceTemplate.liquid_html], { type: 'text/html' })
    return URL.createObjectURL(file)
  }

  const onSave = () => {
    dispatch(cloneCorrespondenceTemplate(correspondenceTemplateId,  params.productTypeCode, params.state))
  }

  return (
    <div>
      <Link
        className="btn btn-outline-dark btn-sm mb-1"
        to={`/correspondence_templates/${correspondenceTemplate.correspondence_template_id}`}
      >
        Back
      </Link>
      <h3 className="mb-5">Cloning {correspondenceTemplate.description}</h3>
      <div className="card mb-4">
        <div className="card-header">
          <strong>New Correspondence</strong>
        </div>
        <div className="card-body">
          <div className="row">
            <div className="col-6">
              <SectionInput
                readonly
                label="Correspondence Type"
                type="text"
                value={correspondenceTemplate.correspondence_type_code}
              />
              <SectionSelect
                label="State"
                labelKey="label"
                options={states}
                value={params.state}
                valueKey="value"
                onChange={(event) => onSetParams('state', event.target.value) }
              />
              <SectionSelect
                label="Energy Product"
                labelKey="label"
                options={PRODUCT_TYPE_CODES}
                value={params.productTypeCode}
                valueKey="value"
                onChange={(event) => onSetParams('productTypeCode', event.target.value) }
              />
              <div className="d-flex justify-content-end">
                <button
                  className="btn btn-primary"
                  disabled={!params.state || !params.productTypeCode}
                  onClick={onSave}>
                  Save
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="card mb-4">
        <div className="card-header bg-warning">
          This is a preview of the HTML that is going to be copied to the new correspondence. Once it is copied you can make edits.
        </div>
        <div className="embed-responsive embed-responsive-1by1">
          <iframe className="embed-responsive-item" src={preview()} title="preview" />
        </div>
      </div>
    </div>
  )
}


export default CorrespondenceTemplateClone
