import axios from 'axios'

export default function getDisclosureDetail(disclosureId){ 
  return function(dispatch){
    axios.get(`${process.env.REACT_APP_GARCON_API}/correspondence/disclosures/${disclosureId}/batches`)
      .then((response) => {
        dispatch({ type:'GET_DISCLOSURE', payload: response.data })
      })
      .catch((error) => {
        dispatch({ type: 'SHOW_ERROR', payload:{ message: 'An error occured getting that account correspondence batch detail for that disclosure.' } })
        throw error
      })
  }
}
