import React from "react";

export const columns = [
  {
    Header: 'Date Sent',
    accessor: 'created_at',
    width: 230
  },
  {
    Header: 'Recipient',
    accessor: 'recipient',
    width: 230
  },
  {
    Header: 'Subject',
    accessor: 'subject',
    width: 500
  },
  {
    Header: 'Type',
    accessor: 'type',
    width: 90
  },
  {
    Header: 'View Message',
    accessor: 'body',
    width: 150,
    Cell: (e) => (
      <div className="row justify-content-md-center">
        <button className="btn btn-primary btn-md mr-2" type="button" onClick={() => openMessage(e.row.original.body)}>View</button>
      </div>
    )
  }
]


const decodeBody = (body) => {
  return body.replace(/\\u003c/g, '<').replace(/\\u003e/g, '>').replace(/\\n/g, '').replace(/\\t/g, '').replace(/' '/g, '').replace(/\\u0026/g, "'").replace(/#39;/g, '').replace(/[â|€|Œ|Â]/g, '').replace(/\\"/g, '"')
}

export const mapCsvData = (data) => {
  const rows = data.slice(1).filter(row => row.length > 1)
  const parsedData = rows.map(row => {
      return {
        created_at: row[0],
        recipient: row[1],
        body: decodeBody(row[2]),
        subject: row[3],
        type: row[4]
      }
    }
  )
  return parsedData
}

const openMessage = (e) => {
  const winUrl = URL.createObjectURL(
    new Blob([e], { type: "text/html" })
  )

  return window.open(
    winUrl,
    "win",
    `width=800,height=400,screenX=200,screenY=200`
  )
}

export const sleep = (ms) => {
  return new Promise(resolve => setTimeout(resolve, ms))
}
