import React from 'react'
import ReactTable from 'react-table'

export default ({ data, columns }) => (<ReactTable
  className="-striped -highlight mb-3"
  columns={columns}
  data={data}
  defaultPageSize={200}
  minRows={7}
  pagination={false}
  showPagination={false}
  style={{ height: '300px', width: '1500px', overflow: 'scroll' }}
/>)


