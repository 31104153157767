import React from 'react'
import Rodal from 'rodal'


const WarningModal = ({ show, warningMessage, warningData, confirmAction, cancelAction }) => {

  return (
    <Rodal
      height={350}
      visible={show}
      width={500}
      onClose={cancelAction}
    >
      <div className="text-center d-flex h-100 align-items-center">
        <div className="w-100">
          <h4>{warningMessage}</h4>
          <p>Are you sure you want to do this?</p>
          <button className="btn btn-block btn-outline-primary btn-md mb-2" onClick={() => confirmAction(warningData)}>Yes</button>
          <button className="btn btn-block btn-outline-secondary btn-md" onClick={() => cancelAction()}>Cancel</button>
        </div>
      </div>
    </Rodal>
  )
}


export default WarningModal
