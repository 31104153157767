import axios from 'axios'

export default function startECLImportJob(ignoreThresholdAlert) {
  return function(dispatch) {
    dispatch({ type: 'LOADING_ECL_IMPORT_JOB', loading: true })
    axios.post(`${process.env.REACT_APP_GARCON_API}/workers/ecl/import`, { ignore_threshold_alert: ignoreThresholdAlert })
      .then(() => {
        dispatch({ type: 'LOADING_ECL_IMPORT_JOB', loading: false })
      })
      .catch((error) => {
        dispatch({ type: 'SHOW_ERROR', payload: { response: error.response, message: error.response.data.error } })
        dispatch({ type: 'LOADING_ECL_IMPORT_JOB', loading: false })
      })
  }
}
