import axios from 'axios'

export const updateAgent = (key, input) => {
  return { type: 'UPDATE_AGENT', payload: { [key]: input } }
}

export const updateAgentDropDowns = (key, input) => {
  const value = input ? input.value : ''
  return { type: 'UPDATE_AGENT', payload: { [key]: value } }
}

export function saveUpdateSalesAgent(salesAgent, agentRoles){
  return async(dispatch) => {
    delete salesAgent.sales_agent_id
    delete salesAgent.order_id
    const params = {
      ...salesAgent,
      roles: agentRoles,
    }
    return await axios.put(`${process.env.REACT_APP_GARCON_API}/sales/agent/${salesAgent.agent_id}`, params)
      .catch((error) => {
        dispatch({ type: 'SHOW_ERROR', payload: { response: error.response, message: 'An error occured updating this sales agent.' } })
        throw error
      })
  }
}

export function clearAgent(){
  return (dispatch) => {
    dispatch({ type: 'CLEAR_SALES_AGENT' })
  }
}
