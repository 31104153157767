import axios from 'axios'

export function getPartners() {
  const channels = ['RETAIL', 'EVENT']
  let partners = []

  return function(dispatch) {
    channels.forEach(eventType => {
      axios.get(`${process.env.REACT_APP_GARCON_API}/users/sources/channels/${eventType}`)
        .then((res) => {
          partners = partners.concat(res.data)
          dispatch({ type: 'GET_PARTNERS', payload: partners })
        })
        .catch((error) => {
          dispatch({ type: 'SHOW_ERROR', payload: 'An error occured getting partners.' })
          throw error
        })
    })
  }
}
