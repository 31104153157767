import axios from 'axios'

export const createRedirect = (input) => {
  return async(dispatch) => {
    try {
      const params = {
        'url': input.url,
        'destination': input.destination,
      }
      const response = await axios.post(`${process.env.REACT_APP_GARCON_API}/cms/url_redirect`, params)
      return response
    } catch (error) {
      dispatch({ type: 'SHOW ERROR', payload: { message: 'An error occured creating URL Redirect' } })
      throw error
    }
  }
}
