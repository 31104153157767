import React, { Component } from 'react'
import Select from 'react-select'
import Modal from '../../Modal'

export default class RewardField extends Component {
  constructor() {
    super()
    this.state = {
      showModal: false,
    }
  }

  renderInput(required) {
    switch (this.props.fieldConfig.type) {
    case 'text':
    case 'number':
      return <input className="form-control" disabled={!this.props.fieldConfig.editable} required={required} type={this.props.fieldConfig.type} value={this.props.value || ''} onChange={e => this.props.onChangeField(this.props.fieldConfig.column, e.target.value)} />
    case 'checkbox':
      return <input checked={this.props.value || false} className="form-control" disabled={!this.props.fieldConfig.editable} required={required} style={{ transform : 'scale(1.5)', marginLeft: '5px' }} type={this.props.fieldConfig.type} onChange={e => this.props.onChangeField(this.props.fieldConfig.column, e.target.checked)} />
    case 'select':
      return <Select disabled={!this.props.fieldConfig.editable || this.props.fieldConfig.options.length === 0} options={this.props.fieldConfig.options} required={required} value={this.props.value || ''} onChange={(object) => this.props.onChangeField(this.props.fieldConfig.column, object ? object.value : null)} />
    case 'textarea':
      return <textarea className="form-control" disabled={!this.props.fieldConfig.editable} required={required} rows="10" value={this.props.value || ''} onChange={e => this.props.onChangeField(this.props.fieldConfig.column, e.target.value)} />
    default:
      return `Invalid input type ${this.props.fieldConfig.type}`
    }
  }

  onToggleShowDescription = () => {
    this.setState({ showModal: !this.state.showModal })
  }

  render() {
    const warningMessage = this.props.fieldConfig.warningMessage ? this.props.fieldConfig.warningMessage(this.props.value) : null
    const required = typeof this.props.fieldConfig.required === 'function' ? this.props.fieldConfig.required() : this.props.fieldConfig.required

    return (<div key={this.props.fieldConfig.column} className={this.props.fieldConfig.class || 'col-md-6 col-lg-4 col-xl-3'}>
      <label>
        {this.props.fieldConfig.name} {required ? <small>(Required)</small> : null}
      </label>
      {this.props.fieldConfig.description && <button className="btn-xs btn btn-link p-1" type="button" onClick={this.onToggleShowDescription}>?</button>}
      <Modal closeModal={this.onToggleShowDescription} customStyles={null} overflowStyle={null} title={this.props.fieldConfig.name} visible={this.state.showModal}>
        {this.props.fieldConfig.description}
      </Modal>
      {this.renderInput(required)}
      {warningMessage ? <div className="alert alert-warning p-1 mt-2"><b>Warning:</b> {warningMessage}</div> : null}
    </div>)
  }
}
