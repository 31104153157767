import React from 'react'
import { useSelector } from 'react-redux'
import ReactTableV7 from '../ReactTableV7'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlusCircle, faMinusCircle } from '@fortawesome/pro-light-svg-icons'
import  moment from 'moment'
import ManageButton from './ManageButton'

export const PriceToCompareContainer = () => {
  const ptcRates = useSelector(state => state.ptc.all)
  const markets = useSelector(state => state.markets.all)

  const formatRate = (number, decimalCount) => number.toLocaleString('en-US', {
    minimumFractionDigits: decimalCount,
    maximumFractionDigits: decimalCount
  })

  // reformat PTC data to nest historical and upcoming PTCs into sub rows so that table is expandable
  const formatPTCData = () => {
    return ptcRates.reduce((newPtcArray, ptc) => {
      for (const key in ptc) {
        if (key === 'market_code' || key === 'state_code') continue

        newPtcArray.push({
          market_code: ptc.market_code,
          state_code: ptc.state_code,
          is_active: markets.find(market => market.market_code === ptc.market_code).is_active ? 'TRUE' : 'FALSE',
          revenue_class_code: `${key} (Current Rate)`,
          rate: ptc[key]?.current_price_to_compare?.rate ? `${formatRate(ptc[key].current_price_to_compare.rate, 5)}/${ptc[key]?.current_price_to_compare?.rate_units}` : 'N/A',
          from_dt: ptc[key]?.current_price_to_compare?.from_dt ? `${moment(ptc[key]?.current_price_to_compare?.from_dt).format('MM-DD-YYYY')}` : 'N/A',
          to_dt: ptc[key]?.current_price_to_compare?.to_dt ? `${moment(ptc[key]?.current_price_to_compare?.to_dt).format('MM-DD-YYYY')}` : 'N/A',
          manage: <ManageButton marketCode={ptc.market_code} />,
          subRows: formatSubRows(key, ptc)
        })
      }

      return newPtcArray
    }, [])
  }

  const formatSubRows = (revenueClassCode, ptcRevenueClassData) => {
    // pluck historical prices (index 0 is always the current ptc) and concatinate the upcoming ptc
    const historicalAndUpcomingPtc = ptcRevenueClassData[revenueClassCode]?.prices.slice(1, 4).concat({ ...ptcRevenueClassData[revenueClassCode]?.upcoming_price_to_compare, upcoming_price_to_compare: true })

    return historicalAndUpcomingPtc.map((ptcData) => {
      return {
        market_code: ptcRevenueClassData.market_code,
        state_code: ptcRevenueClassData.state_code,
        is_active: markets.find(market => market.market_code === ptcRevenueClassData.market_code).is_active ? 'TRUE' : 'FALSE',
        revenue_class_code: ptcData.upcoming_price_to_compare ? `Upcoming PTC (${revenueClassCode})` : '',
        rate: ptcData.rate ? `${formatRate(ptcData.rate, 5)}/${ptcData.rate_units}` : 'Not Available',
        from_dt: ptcData?.from_dt  ? `${moment(ptcData.from_dt).format('MM-DD-YYYY')}` : 'Not Available',
        to_dt: ptcData?.to_dt ? `${moment(ptcData.to_dt).format('MM-DD-YYYY')}` : 'Not Available',
      }
    })
  }

  const tableColumns = [
    {
      // Build expander column
      id: "expander", // must have an ID
      Header: ({ getToggleAllRowsExpandedProps, isAllRowsExpanded }) => (
        <span {...getToggleAllRowsExpandedProps()}>
          {isAllRowsExpanded ? (
            <FontAwesomeIcon icon={faMinusCircle} />
          ) : (
            <b><FontAwesomeIcon icon={faPlusCircle}/> Expand for Historical PTC Data</b>
          )}
        </span>
      ),
      Cell: ({ row }) =>
        // Use the row.canExpand and row.getToggleRowExpandedProps prop getter
        // to build the toggle for expanding a row
        row.canExpand ? (
          <span
            {...row.getToggleRowExpandedProps({
              style: {
                paddingLeft: `${row.depth * 3}rem`,
              },
            })}
          >
            {row.isExpanded ? (
            <FontAwesomeIcon icon={faMinusCircle} />
          ) : (
            <b><FontAwesomeIcon icon={faPlusCircle} /></b>
          )}
          </span>
        ) : null,
      maxWidth: 250,
      width: 120,
      minWidth: 120
    },
    {
      Header: 'Market Code',
      accessor: 'market_code',
      Cell: (props) => (
        <>
        {props.row.canExpand ? <b>{props.value}</b> : props.value }
        </>
      ),
    },
    {
      Header: 'State',
      accessor: 'state_code',
      Cell: (props) => (
        <>
        {props.row.canExpand ? <b>{props.value}</b> : props.value }
        </>
      ),
    },
    {
      Header: 'Active',
      accessor: 'is_active',
      Cell: (props) => (
        <>
        {props.row.canExpand ? <b>{props.value}</b> : props.value }
        </>
      ),
    },
    {
      Header: 'Start Date',
      accessor: 'from_dt',
      sortType: (a, b) => {
        return new Date(b.values.from_dt) - new Date(a.values.from_dt);
      },
      Cell: (props) => (
        <>
        {props.row.canExpand ? <b>{moment(props.value).utcOffset(true).format('l')}</b> : moment(props.value).utcOffset(true).format('l') }
        </>
      )
    },
    {
      Header: 'End Date',
      accessor: 'to_dt',
      sortType: (a, b) => {
        return new Date(b.values.to_dt) - new Date(a.values.to_dt);
      },
      Cell: (props) => (
        <>
        {props.row.canExpand ? <b>{moment(props.value).utcOffset(true).format('l')}</b> : moment(props.value).utcOffset(true).format('l') }
        </>
      )
    },
    {
      Header: 'Revenue Class Code',
      accessor: 'revenue_class_code',
      Cell: (props) => (
        <>
        {props.row.canExpand ? <b>{props.value}</b> : props.value }
        </>
      ),
      maxWidth: 400,
      width: 200,
      minWidth: 180
    },
    {
      Header: 'Rate',
      accessor: 'rate',
      Cell: (props) => (
        <>
        {props.row.canExpand ? <b>{props.value}</b> : props.value }
        </>
      ),
      maxWidth: 300,
      width: 120,
      minWidth: 80
    },
    {
      Header: 'Manage',
      accessor: 'manage',
    }
  ]

  return (
    <div className="col-md-12 mx-auto">
      <h2 className="text-left w-100">Price to Compare</h2>
      <ReactTableV7
        columns={tableColumns}
        data={formatPTCData()}
        canHover={true}
      />
    </div>
  )
}

export default PriceToCompareContainer;
