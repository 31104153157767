import React from 'react'
import Modal from '../../../Modal'

const ClonePlanModal = ({ cloneSMR, closeModal, onClonePlan, customStyles, plan, showModal, toggleCloneSMR }) => {

  return (
    <div>
      <Modal closeModal={closeModal} customStyles={customStyles} title="Clone Plan Confirmation" visible={showModal}>
        <div className=" mt-4">
          <h5>Are you sure you want to clone plan:
            <span className="text-primary font-weight-bold"> {plan.plan_name}?</span> 
          </h5>
        </div>
        <div className="mt-4">
          <label className="font-weight-bold mr-2"> Clone Static Market Rates for this Plan </label>
          <input
            checked={cloneSMR}
            type="checkbox"
            onChange={() => toggleCloneSMR(!cloneSMR)}
          />
        </div>
        <div>
          <button className="btn btn-block btn-primary btn-md mt-5" onClick={() =>onClonePlan()}>Clone Plan</button>
        </div>
      </Modal>
    </div>
  )
}

export default ClonePlanModal
