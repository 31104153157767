const defaultRewardFieldConfigs = [
  {
    column: 'promo_name',
    rewardType: 'promo',
    name: 'Promo Name',
    description: 'This field will be displayed to customers at time of enrollment and throughout customer communications.',
    type: 'text',
    required: true,
    editable: true,
  },
  {
    column: 'promo_desc',
    rewardType: 'promo',
    name: 'Promo Description',
    description: 'This field will be displayed to customers at time of enrollment and throughout customer communications. This field can be edited once the promo has been created.',
    type: 'text',
    required: true,
    editable: true,
  },
  {
    column: 'promo_bonus',
    rewardType: 'promo',
    name: 'Promo Bonus',
    description: 'When the reward is a physical item, this bonus number should reflect the number of items provided to the member. These should be whole numbers like 1 or 2. When the reward is a giftcard, this bonus number should reflect the dollar value of the giftcard.',
    type: 'number',
    required: true,
    editable: true,
    warningMessage: (value) => {
      return value > 500 ? 'This may be a high reward amount!' : null
    },
  },
  {
    column: 'promo_measure',
    rewardType: 'promo',
    name: 'Promo Measure',
    description: 'Number of months before promo will be able to vest.',
    type: 'number',
    required: true,
    editable: true,
  },
  {
    column: 'can_adhoc',
    rewardType: 'promo',
    name: 'Can Adhoc?',
    description: 'Rewards that are setup as can adhoc true enable the Ops team to manually add a reward to an account when needed. In order for this to work, the fulfillment vendor needs to have already been set up as adhocable.',
    type: 'checkbox',
    required: false,
    editable: true,
  },
  {
    column: 'loyalty_program_name',
    rewardType: 'loyaltyProgram',
    name: 'Loyalty Program Name',
    description: 'This field will be displayed to customers at time of enrollment and throughout customer communications.',
    type: 'text',
    required: true,
    editable: true,
  },
  {
    column: 'loyalty_program_desc',
    rewardType: 'loyaltyProgram',
    name: 'Loyalty Program Description',
    description: 'This field will be displayed to customers at time of enrollment and throughout customer communications. This field can be edited once the loyalty program has been created.',
    type: 'text',
    required: true,
    editable: true,
  },
  {
    column: 'loyalty_program_type',
    rewardType: 'loyaltyProgram',
    name: 'Loyalty Program Type',
    description: 'This is the type of loyalty program being created. This determines the algorithm used to calculate the reward amount.',
    type: 'select',
    options: [
      { label: 'Cash Back', value: 'Earn' },
      { label: 'X Month(s) Free', value: 'free_month' },
    ],
    required: true,
    editable: true,
  },
  {
    column: 'loyalty_program_measure',
    rewardType: 'loyaltyProgram',
    name: 'Loyalty Program Measure',
    description: 'Number of months before initial reward will be able to vest.',
    type: 'number',
    required: true,
    editable: true,
  },
  {
    column: 'max_reward_amount',
    rewardType: 'loyaltyProgram',
    name: 'Maximum Reward Amount',
    description: 'Annual (or per term length) cap for how much this reward can be redeemed for',
    type: 'number',
    required: false,
    editable: true,
  },
  {
    column: 'loyalty_program_multiplier',
    rewardType: 'loyaltyProgram',
    name: 'Loyalty Program Multiplier',
    description: 'Decimal value that is multiplied by the customer\'s invoice amount to calculate the reward amount. For example, for 3% cash back this would be 0.03 and then 1.0 would be used for First Month Free.',
    type: 'number',
    required: true,
    editable: true,
    warningMessage: (value) => {
      return value > 1 ? 'Multiplier should usually be <= 1' : null
    },
  },
  {
    column: 'max_accruals',
    rewardType: 'loyaltyProgram',
    name: 'Maximum Number Of Rewards',
    description: 'Only used for loyalty programs where there is a max number of rewards to create. For recurring programs like cash back, this should be left blank. For X Month Free Programs, this field should be set to the number of free months.',
    type: 'number',
    required: false,
    editable: true,
  },

  {
    column: 'notify_on_fulfillment',
    rewardType: 'both',
    name: 'Notify On Fulfillment?',
    description: 'This is almost always set to false. This sends a communication to let the customer know that their reward is being fulfilled.',
    type: 'checkbox',
    required: false,
    editable: true,
  },
  {
    column: 'redemption_required',
    rewardType: 'both',
    name: 'Redemption Required?',
    description: 'If set to true, requires the customer to redeem the reward before it is fulfilled.',
    type: 'checkbox',
    required: false,
    editable: true,
  },
  {
    column: 'approval_required',
    rewardType: 'both',
    name: 'Approval Required?',
    description: 'If set to true, requires the Ops team to approve a before it is fulfilled.',
    type: 'checkbox',
    required: false,
    editable: true,
  },
  {
    column: 'fulfillment_vendor_code',
    rewardType: 'both',
    name: 'Fulfillment Vendor',
    description: 'The vendor through which the promo will be fulfilled.',
    type: 'select',
    options: [
      { label: 'Virtual Incentives', value: 'VIRTUAL_INCENTIVES' },
      { label: 'Virtual Incentives (Amazon)', value: 'VIRTUAL_INCENTIVES_AMAZON' },
      { label: 'Virtual Incentives (DoorDash)', value: 'VIRTUAL_INCENTIVES_DOORDASH' },
      { label: 'Virtual Incentives (REI)', value: 'VIRTUAL_INCENTIVES_REI' },
      { label: 'Virtual Incentives (Starbucks)', value: 'VIRTUAL_INCENTIVES_STARBUCKS' },
      { label: 'Virtual Incentives (Target)', value: 'VIRTUAL_INCENTIVES_TARGET' },
      { label: 'Inspire', value: 'INSPIRE' },
      { label: 'Contemporary Graphic Solutions (CGS)', value: 'CGS' },
      { label: 'Groupo Rewards', value: 'GROUPO' },
      { label: 'Bill.com (Used For X Month Free)', value: 'BILL_COM' },
    ],
    required: true,
    editable: true,
  },
  {
    column: 'fulfillment_channel',
    rewardType: 'both',
    name: 'Fulfillment Channel',
    description: 'How the reward is actually sent: OFFLINE = physical, EMAIL = online, BOTH = system will decide OFFLINE/EMAIL based on valid email address, VENDOR = 3rd party will handle it, POS = point of sale.',
    type: 'select',
    options: [], // Filled in async
    required: true,
    editable: true,
  },
  {
    column: 'channel_partner_code',
    rewardType: 'both',
    name: 'Channel Partner',
    description: 'If reward is being sent due to a partnership, indicate the channel sales partner here. Usually this field is left blank unless its marked required.',
    type: 'select',
    options: [], // Filled in async
    editable: true,
  },
  {
    column: 'product_code',
    rewardType: 'both',
    name: 'Product',
    description: 'This is the type of item that is being presented to the customer. This should default to Dollars.',
    type: 'select',
    options: [], // Fill in async,
    required: true,
    editable: true,
  },
  {
    column: 'fulfillment_process',
    rewardType: 'both',
    name: 'Fulfillment Process',
    type: 'text',
    editable: false,
  },
  {
    column: 'loyalty_program_bonus',
    rewardType: 'loyaltyProgram',
    name: 'Loyalty Program Bonus',
    type: 'text',
    editable: false,
  },
  {
    column: 'promo_type',
    rewardType: 'promo',
    name: 'Promo Type',
    type: 'text',
    editable: false,
  },
  {
    column: 'promo_measure_from_field',
    rewardType: 'Promo Measure From Field',
    name: 'Promo Measure From Field',
    type: 'text',
    editable: false,
  },
  {
    column: 'promo_terms_desc',
    rewardType: 'promo',
    name: 'Promo Terms Description',
    description: 'This is the Regulatory language presented in the Universal Rewards Terms of Service. This language is provided by the Regulatory Team.',
    type: 'textarea',
    required: true,
    editable: true,
    class: 'col-12',
  },
  {
    column: 'loyalty_program_terms_desc',
    rewardType: 'loyaltyProgram',
    name: 'Loyalty Program Terms Description',
    description: 'This is the Regulatory language presented in the Universal Rewards Terms of Service. This language is provided by the Regulatory Team.',
    type: 'textarea',
    required: true,
    editable: true,
    class: 'col-12',
  },
]


export default defaultRewardFieldConfigs
