import axios from 'axios'

export const getOffers = async (callback, params) => {
  try {
    const response = await axios.get(`${process.env.REACT_APP_GARCON_API}/offers/generation_payloads`, { params })
    callback(response.data)
  } catch (error) {
    throw error
  }
}

export const getOfferKeys = async (callback) => {
  const getOfferKeyOptions = (offerKeys) => {
    return offerKeys.map(offerKey => {
      return { name: offerKey.key_name }
    })
  }
  try {
    const response = await axios.get(`${process.env.REACT_APP_GARCON_API}/offers/generation_payloads/find_id/available_search_keys`)
    callback(getOfferKeyOptions(response.data))
  } catch (error) {
    throw error
  }
}

export const searchOffers = async (callback, params) => {
  try {
    const response = await axios.get(`${process.env.REACT_APP_GARCON_API}/offers/generation_payloads/find_id`, { params })
    callback(response.data)
  } catch (error) {
    throw error
  }
}
