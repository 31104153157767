import axios from 'axios'

export const getAgent = (agentId) => {
  return async(dispatch) => {
    return axios.get(`${process.env.REACT_APP_GARCON_API}/sales/agents/${agentId}`)
      .then((response) => {
        dispatch({ type: 'GET_SALES_AGENT', payload: response.data })
        return response.data
      })
      .catch((error) => {
        dispatch({ type: 'SHOW_ERROR', payload: { response: error.response, message: `An error occured getting sales agent ${agentId}.` } })
        throw error
      })
  }
}


