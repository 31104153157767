import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import history from '../../../history'
import { getRolloffExperiment, updateRolloffExperiment } from '../../../apis/rolloffExperiments'
import Loading from '../../Shared/Loading'
import SectionInput from '../../Shared/SectionInput'
import RolloffVariationsContainer from './RolloffVariationsContainer'
import {
  getVariationsTotal,
  variationsErrors,
  saveIsDisabled
} from './helpers'

const EditExperiment = () => {
  const [experiment, setExperiment] = useState(null)
  const [formData, setFormData] = useState({
    name: '',
    startDate: '',
    endDate: '',
    description: ''
  })
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState(null)
  const { experiment_id } = useParams()

  const [control, setControl] = useState({ amount: 0, treatment_id: '', name: '' })
  const [variations, setVariations] = useState([{ amount: 0, index: 1, treatment_id: '', name: '' }])
  const [total, setTotal] = useState(0)

  useEffect(() => {
    getRolloffExperiment(experiment_id, setExperiment)
      .then(() => {
        setLoading(false)
      })
  }, [])

  useEffect(() => {
    if (experiment) {
      setFormData({
        name: experiment.experiment_name,
        startDate: experiment.experiment_start_dt,
        endDate: experiment.experiment_end_dt,
        description: experiment.experiment_desc,
      })

      const updatedControl = {
        amount: experiment.control.percent_allocation,
        treatment_id: experiment.control.config.rolloff_treatment_id,
        name: experiment.control.variation_name
      }
      setControl(updatedControl)
      const updatedVariations = experiment.variations.map((variant, index) => {
        return {
          amount: variant.percent_allocation,
          treatment_id: variant.config.rolloff_treatment_id,
          index: index + 1,
          name: variant.variation_name
        }
      })
      setVariations(updatedVariations)
    }
  }, [experiment])

  useEffect(() => {
    const runningTotal = getVariationsTotal(control, variations)
    setTotal(runningTotal)
  }, [control, variations])

  const onChange = (key, value) => {
    setFormData({ ...formData, [key]: value })
  }

  const onSubmit = (e) => {
    e.preventDefault()

    const controlData = {
      ...experiment.control,
      config: { rolloff_treatment_id: control.treatment_id },
      percent_allocation: control.amount,
      variation_name: control.name
    }

    const variationsData = variations.map((variant) => {
      const variantData = experiment.variations.find(expVariants => expVariants.config.rolloff_treatment_id === variant.treatment_id)
      return {
        ...variantData,
        config: { rolloff_treatment_id: variant.treatment_id },
        percent_allocation: variant.amount,
        variation_name: variant.name
      }
    })

    const experimentPayload = {
      ...experiment,
      experiment_name: formData.name,
      experiment_desc: formData.description,
      experiment_start_dt: formData.startDate,
      experiment_end_dt: formData.endDate,
      control: controlData,
      variations: variationsData
    }

    updateRolloffExperiment(experiment_id, experimentPayload)
      .then(() => {
        history.push(`/rolloff_experiments/experiment/${experiment_id}`)
      })
      .catch((error) => {
        setError(error)
      })
  }

  const errors = variationsErrors({ control, total, variations, formData })

  return (
    <div className="container-fluid">
      {experiment &&
        <form>
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <h4 className="my-3">
              {experiment?.experiment_name}
            </h4>
            <button
              className="btn btn-primary btn-md mb-2"
              onClick={(e) => onSubmit(e)}
              disabled={saveIsDisabled(errors)}
            >
              Save Changes
            </button>
          </div>

          <div className="card mb-4">
            <div className="card-header">
              <strong>Details</strong>
            </div>
            <div className="card-body">
              <p style={{ color: 'red' }}>{error?.message}</p>
              <div className="col-8">
                <SectionInput
                  label="Experiment Name"
                  type="text"
                  value={formData.name}
                  onChange={(e) => onChange('name', e.target.value)}
                />
                <SectionInput
                  label="Start Date"
                  type="date"
                  value={formData.startDate}
                  onChange={(date) => onChange('startDate', date)}
                />
                <SectionInput
                  label="End Date"
                  type="date"
                  value={formData.endDate}
                  onChange={(date) => onChange('endDate', date)}
                />
                <SectionInput
                  label="Description"
                  type="textarea"
                  value={formData.description}
                  onChange={(e) => onChange('description', e.target.value)}
                />
              </div>
            </div>
            <RolloffVariationsContainer
              control={control}
              controlEditable={experiment.control_editable}
              variations={variations}
              variationsErrors={errors}
              setControl={setControl}
              setVariations={setVariations}
              total={total}
            />
          </div>
        </form>
      }
      {loading && <Loading />}
    </div>
  )
}

export default EditExperiment
