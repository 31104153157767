import React from 'react'
import { useAuth0 } from '@auth0/auth0-react'
import { useDispatch } from 'react-redux'
import { clearUser } from '../actions/clearUser'

const Login = () => {
  const dispatch = useDispatch()
  const { error, isAuthenticated, logout } = useAuth0()

  if (error) {
    return <div>Oops... {error.message}</div>
  }

  const clear = () => {
    dispatch(clearUser())
    logout({ returnTo: window.location.origin })
  }

  return (
    <div className="col-12">
      <div className="text-center">
        {isAuthenticated && <button className="btn btn-link text-light" onClick={clear}>Log Out</button>}
      </div>
    </div>
  )
}

export default Login
