import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import Select from 'react-select'
import getDynamicPricingAlgorithms from '../../actions/plans/getDynamicPricingAlgorithms'
import getChannels from '../../actions/channels/getChannels'
import getRevenueClasses from '../../actions/getRevenueClasses'
import { getDefaultData } from '../../actions/volt/defaultData'
import clearVoltData from '../../actions/volt/clearVoltData'
import clearDefaultData from '../../actions/volt/clearDefaultData'
import getMarkets from '../../actions/getMarkets'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import VoltDataForm from './VoltDataForm'
import VoltAccountCard from './VoltAccountCard'


const accountStatusGroups = [
  { value: 'Pending', label: 'Pending' },
  { value: 'Enrolled', label: 'Enrolled' },
  { value: 'Active', label: 'Active' },
  { value: 'Dropped', label: 'Dropped' },
  { value: 'Cancelled', label: 'Cancelled' },
]

const contractTypes = [
  { value: 'Fixed', label: 'Fixed' },
  { value: 'Subscription', label: 'Subscription' },
]

const VoltContainer = () => {

  useEffect(() => {
    dispatch(getMarkets())
    dispatch(getDynamicPricingAlgorithms())
    dispatch(getChannels())
    dispatch(getRevenueClasses())
  }, [])

  const [accountStatus, setAccountStatus] = useState('')
  const [contractType, setContractType] = useState('')
  const [market, setMarket] = useState('')
  const [channelCode, setChannelCode] = useState('')
  const [dynamicPricingAlgorithm, setDynamicPricingAlgorithm] = useState('')
  const [revenueClass, setRevenueClass] = useState('')
  const revenueClasses = useSelector(state => state.revenueClasses.all)
  const channels = useSelector(state => state.channels)
  const dynamicPricingAlgorithms = useSelector(state => state.planFeatures.pricingAlgorithms)
  const markets = useSelector(state => state.markets.all)
  const editableData = useSelector(state => state.defaultData.editable)
  const generatingData = useSelector(state => state.defaultData.generatingData)
  const dataGenerated = useSelector(state => state.defaultData.dataGenerated)
  const creatingVoltData = useSelector(state => state.voltData.creatingData)
  const voltDataCreated = useSelector(state => state.voltData.dataCreated)
  const dispatch = useDispatch()

  const handleChangeStatus = (e) => {
    if (!e) {
      setAccountStatus('')
    } else {
      setAccountStatus(e.value)
    }
  }

  const handleChangeContractType = (e) => {
    if (!e) {
      setContractType('')
    } else {
      setContractType(e.value)
    }
  }

  const handleChangeMarket = (e) => {
    if (editableData.account_number) {
      return market
    } else {
      setMarket(e.value)
    }
  }

  const marketOptions = () => {
    return markets.map(m => ({
      value: m.market_code,
      label: m.market_code,
    }))
  }

  const dynamicPricingOptions = () => {
    return dynamicPricingAlgorithms.map(algorithm => ({
      value: algorithm.dynamic_pricing_algorithm_code,
      label: algorithm.algorithm_name,
    }))
  }

  const handleChangePricing = (e) => {
    if (!e) {
      setDynamicPricingAlgorithm('')
    } else {
      setDynamicPricingAlgorithm(e.value)
    }
  }

  const channelOptions = () => {
    return channels.map(c =>({
      value: c.channel_code,
      label: c.channel_name,
    }))
  }
  const handleChangeChannel = (e) => {
    if (!e) {
      setChannelCode('')
    } else {
      setChannelCode(e.value)
    }
  }

  const revenueOptions = () => {
    return revenueClasses.map(r => ({
      value: r.revenue_class_code,
      label: r.revenue_class_name,
    }))
  }

  const handleChangeRevenueClass = (e) => {
    if (!e) {
      setRevenueClass('')
    } else {
      setRevenueClass(e.value)
    }
  }

  const generateDefaultData = () => {
    dispatch(getDefaultData(contractType, market, channelCode, revenueClass, dynamicPricingAlgorithm))
  }

  const resetForm = () => {
    dispatch(clearDefaultData())
    dispatch(clearVoltData())
    setAccountStatus('')
    setMarket('')
    setContractType('')
    setDynamicPricingAlgorithm('')
    setChannelCode('')
    setRevenueClass('')
  }

  return (
    <div className="container pt-5">
      <div className="text-center">
        <h2 className="mb-4">Volt Data Generator</h2>
      </div>
      <form id="necessary-parameters">
        <div className="row">
          <div className="col-4">
            <div className="form-group">
              <label htmlFor="statusGroup">Account Status</label>
              <Select
                options={accountStatusGroups}
                value={accountStatus}
                onChange={(e) => handleChangeStatus(e)}
              />
            </div>
          </div>
          <div className="col-4">
            <div className="form-group">
              <label htmlFor="contractType">Contract Types</label>
              <Select
                options={contractTypes}
                value={contractType}
                onChange={(e) => handleChangeContractType(e)}
              />
            </div>
          </div>
          <div className="col-4">
            <div className="form-group">
              <label htmlFor="markets">Markets</label>
              <Select
                options={marketOptions()}
                value={market}
                onChange={(e) => handleChangeMarket(e)}
              />
            </div>
          </div>
        </div>
      </form>
      <form id="optional-parameters">
        <div className="row">
          <div className="col-4">
            <div className="form-group">
              <label htmlFor="statusGroup">Dynamic Pricing Algorithm (optional)</label>
              <Select
                options={dynamicPricingOptions()}
                value={dynamicPricingAlgorithm}
                onChange={(e) => handleChangePricing(e)}
              />
            </div>
          </div>
          <div className="col-4">
            <div className="form-group">
              <label htmlFor="contractType">Channel Code (optional)</label>
              <Select
                options={channelOptions()}
                value={channelCode}
                onChange={(e) => handleChangeChannel(e)}
              />
            </div>
          </div>
          <div className="col-4">
            <div className="form-group">
              <label htmlFor="contractType">Revenue Class Code (optional)</label>
              <Select
                options={revenueOptions()}
                value={revenueClass}
                onChange={(e) => handleChangeRevenueClass(e)}
              />
            </div>
          </div>
        </div>
      </form>
      {!dataGenerated && !voltDataCreated &&
        <button className="btn btn-block btn-primary mb-2" onClick={generateDefaultData}>Submit</button>
      }
      {generatingData &&
        <div className="mt-4 text-center">
          <FontAwesomeIcon pulse color="#000" icon="spinner" size="2x" />
          <h4> Generating Default Data </h4>
        </div>
      }
      {dataGenerated &&
        <VoltDataForm
          accountStatus={accountStatus}
        />
      }
      {creatingVoltData &&
        <div className="mt-4 text-center">
          <FontAwesomeIcon pulse color="#000" icon="spinner" size="2x" />
          <h4> Creating Data </h4>
        </div>
      }
      {voltDataCreated &&
        <>
          <VoltAccountCard />
          <button className="btn btn-block btn-primary mt-4" onClick={() => resetForm()}>Reset Form</button>
        </>
      }
    </div>
  )
}

export default VoltContainer
