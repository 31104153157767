import React, { useEffect } from 'react'
import { changeModule } from '../../actions/changeModule'
import UrlRedirectsContainer from './UrlRedirectsContainer'
import { useDispatch } from 'react-redux'
import { getUrlRedirects } from '../../actions/urlRedirects/getUrlRedirects'
import CreateRedirect from './CreateRedirect'

const UrlRedirects = () => {
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(changeModule('URL Redirects'))
    dispatch(getUrlRedirects())
  }, [])

  return (
    <div className="container-fluid">
      <CreateRedirect />
      <br />
      <UrlRedirectsContainer />
    </div>
  )
}

export default UrlRedirects
