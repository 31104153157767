import { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { useAuth0 } from '@auth0/auth0-react'
import getGarconToken from '../actions/Users/getToken'
import history from '../history'

const Callback = () => {
  const { user, isLoading, getAccessTokenSilently } = useAuth0()
  const dispatch = useDispatch()

  useEffect(() => {
    if (user && !isLoading){
      getToken()
    }
  }, [user, isLoading])

  const getToken = async () => {
    const token = await getAccessTokenSilently()
    dispatch(getGarconToken(token, user.email))
    history.replace('/')
  }

  return (null)
}

export default Callback
