import axios from 'axios'

export const getMarketRatesForMarket = (marketCode) => {
  return async(dispatch) => {
    dispatch({ type: 'GETTING_MARKET_RATES', payload: true })
    return axios.get(`${process.env.REACT_APP_GARCON_API}/pricing/market_rates/${marketCode}`)
      .then((response) => {
        dispatch({ type: 'GET_MARKET_RATES_FOR_MARKET', payload: response.data })
        dispatch({ type: 'GETTING_MARKET_RATES', payload: false })
      })
      .catch((error) => {
        dispatch({ type: 'SHOW_ERROR', payload: { response: error.response, message: 'An error occured getting static market rates.' } })
        dispatch({ type: 'GETTING_MARKET_RATES', payload: false })
        throw error
      })
  }
}
