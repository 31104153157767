
export function newMarketRequiredFields(state = { tables: [] }, action) {
  switch (action.type) {
  case 'GET_NEW_MARKET_REQUIRED_FIELDS':
    return { ...state, tables: action.payload }
  default:
    return state
  }
}

export function newMarketImportJob(state = { loading: false, validationOptions: {} }, action) {
  switch (action.type) {
  case 'LOADING_NEW_MARKET_IMPORT_JOB':
    return { ...state, loading: action.loading }
  case 'GET_VALIDATION_OPTIONS':
    return { ...state, validationOptions: action.payload }
  default:
    return state
  }
}

export function eclImportJob(state = { loading: false }, action ) {
  switch (action.type) {
  case 'LOADING_ECL_IMPORT_JOB':
    return { ...state, loading: action.loading }
  default:
    return state
  }
}
