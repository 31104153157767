import React, { useEffect, useState } from 'react'
import SectionSelect from '../../Shared/SectionSelect'

const bucketTypes = [
  { name: 'buckets' },
  { name: 'percent_bucket' }
]

const options = [
  { name: 'Current Price' },
  { name: 'Current PTC' },
  { name: 'Next Contract CTS' }
]

const TreatmentMethodology = ({ bucketDelta, setBucketDelta }) => {
  const [bucketType, setBucketType] = useState('')
  const [deltaTo, setDeltaTo] = useState('')
  const [deltaFrom, setDeltaFrom] = useState('')
  const [deltaToOptions, setDeltaToOptions] = useState(options)
  const [deltaFromOptions, setDeltaFromOptions] = useState(options)

  useEffect(() => {
    setBucketType(bucketDelta.bucket_type)
    setDeltaTo(bucketDelta.delta_to)
    setDeltaFrom(bucketDelta.delta_from)
  }, [])

  const updateField = (key, value) => {
    if (key === 'bucket_type') {
      setBucketType(value)
      setBucketDelta({...bucketDelta, bucket_type: value})
    }
    if (key === 'delta_to') {
      setDeltaTo(value)
      setBucketDelta({ ...bucketDelta, delta_to: value })
      const dropdownOptions = options.filter(option => option['name'] !== value)
      setDeltaFromOptions(dropdownOptions)
    }

    if (key === 'delta_from') {
      setDeltaFrom(value)
      setBucketDelta({ ...bucketDelta, delta_from: value })
      const dropdownOptions = options.filter(option => option['name'] !== value)
      setDeltaToOptions(dropdownOptions)
    }
  }
  return (
    <div className="d-flex justify-content-start">
      <strong>METHODOLOGY</strong> <div className="col-2"><SectionSelect
        labelKey="name"
        options={bucketTypes}
        type="text"
        value={bucketType}
        valueKey="name"
        onChange={(e) => updateField('bucket_type', e.target.value)}
      /></div>
      <div className="col-3"><SectionSelect
        labelKey="name"
        options={deltaFromOptions}
        type="text"
        value={deltaFrom}
        valueKey="name"
        onChange={(e) => updateField('delta_from', e.target.value)}
      /> </div> - <div className="col-3"><SectionSelect
        labelKey="name"
        options={deltaToOptions}
        type="text"
        value={deltaTo}
        valueKey="name"
        onChange={(e) => updateField('delta_to', e.target.value)}
      /></div> {bucketType === 'percent_bucket' ? <div>{`/ ${deltaTo}`}</div> : ''}
    </div>
  )
}

export default TreatmentMethodology
