import React, { Component } from 'react'
import { connect } from 'react-redux'
import BooleanToggle from '../../../Shared/BooleanToggle'
import FuzzySelect from '../../../Shared/FuzzySelect'
import OverlayTrigger from 'react-bootstrap/OverlayTrigger'
import Popover from 'react-bootstrap/Popover'
import { Link } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCopy, faInfoCircle, faSignInAlt } from '@fortawesome/free-solid-svg-icons'

class CampaignPlans extends Component {
  constructor(props) {
    super(props)
    this.state = {
      plansRecentChecked: false
    }
  }

  options() {
    return this.props.plans.filter(
      plan => !this.state.plansRecentChecked || plan.is_recent === this.state.plansRecentChecked
    ).map(plan => (
      { value: plan.plan_code, label: this.planLabel(plan) }
    ))
  }

  planLabel(plan) {
    return `${plan.plan_name} - ${plan.plan_code}`
  }

  renderPlan(plan, i) {
    const options = this.options()
    const { is_dynamic_rate, plan_code } = plan
    const totalPlan = this.props.campaign.campaign_plans_attributes.find(campaign => campaign.plan_code === plan_code)

    const popover = (<Popover>
      <Popover.Header>{totalPlan?.plan_name ? totalPlan?.plan_name : "N/A"}</Popover.Header>
      <Popover.Body><strong>Plan Code</strong> {plan_code}</Popover.Body>
      <Popover.Body><strong>Contract Duration</strong> {totalPlan?.contract_duration ? totalPlan?.contract_duration : "N/A"}</Popover.Body>
      <Popover.Body><strong>Dynamic Pricing Algorithm Name</strong> {totalPlan?.dynamic_pricing_algorithm_name ? totalPlan?.dynamic_pricing_algorithm_name : "N/A"}</Popover.Body>
      <Popover.Body><strong>Dynamic Pricing Algorithm Code</strong> {totalPlan?.dynamic_pricing_algorithm_code ? totalPlan?.dynamic_pricing_algorithm_code : "N/A"}</Popover.Body>
      <Popover.Body><strong>Reward Info</strong> {totalPlan?.rewards_description ? totalPlan?.rewards_description : "N/A"}</Popover.Body>
    </Popover>)

    return (
      <div key={`${plan_code}-${i}`}>
        <div className="row mb-2 align-items-center">
          <div className="col-7 form-group">
            <div className="row align-items-center">
              <div className="col-2 text-right">
                <label className="font-weight-bold mr-2 mb-0">
                  Plan
                </label>
              </div>
              <div className="col-9">
                <FuzzySelect
                  disabled={!this.props.writeAccess}
                  options={this.options()}
                  value={plan_code}
                  onChange={(event) => this.props.onChangeCampaignPlan(i, event ? event.value : plan_code)}
                />
              </div>
              {options.length ? <div className="row">
                <OverlayTrigger trigger="click" rootClose placement="auto-start" overlay={popover}>
                  <div className="flex-column mt-2 ml-2">
                    <Link className="text-primary">
                      <FontAwesomeIcon className="text-primary" icon={faInfoCircle} />
                    </Link>
                  </div>
                </OverlayTrigger>
                <div className="flex-column ml-2">
                  <div className="mr-1">
                    <Link className="text-primary" target="_blank" to={`/plans/${plan_code}`}><FontAwesomeIcon icon={faSignInAlt} /></Link>
                  </div>
                  <Link className="text-primary" onClick={() => { navigator.clipboard.writeText(plan_code) }} to={`/campaigns/${this.props.campaign.campaign_id}`}><FontAwesomeIcon icon={faCopy} /></Link>
                </div>
              </div> : null}
            </div>
          </div>
          <div className="col-2 form-group">
            <div className="row">
              <BooleanToggle disabled={!this.props.writeAccess} falseLabel="Static" trueLabel="Dynamic" value={is_dynamic_rate} onToggle={(value) => this.props.onChangeCampaignPlanValue(i, 'is_dynamic_rate', value)} />
            </div>
          </div>
          <div className="col-2 form-group d-flex justify-content-end">
            {(this.props.campaign.campaign_plans_attributes.length > 1 && this.props.writeAccess) &&
              <a className="text-primary" onClick={() => this.props.onRemovePlan(i)}>Remove Plan</a>
            }
          </div>
        </div>
        <hr />
      </div>
    )
  }

  render() {
    return (
      <div className="card">
        <div className="card-header">
          <div className="mb-2"><strong>Campaign Plans</strong></div>
          <div className="row form-group">
            <div className="col-2" title="Plans created or utilized by a shopper in the last 45 days">
              <label className="font-weight-bold mr-2 form-check-label">Recent</label>
              <input checked={this.state.plansRecentChecked} type="checkbox" onChange={(event) => this.setState({ plansRecentChecked: event.target.checked })} />
            </div>
          </div>
        </div>
        <div className="card-body">
          <div className="row">
            <div className="col">
              {this.props.loadingPlans ? (
                <h5>Loading {this.props.campaign.product_type_code} plans...</h5>
              ) : (
                <div>
                  {this.props.campaign.campaign_plans_attributes.map((plan, i) => (this.renderPlan(plan, i)))}
                  {this.props.writeAccess && (
                    <div className="col-10">
                      <button className="badge" onClick={this.props.onAddPlan}>Add Plan</button>
                    </div>
                  )}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    )
  }
}

function mapStateToProps(state) {
  return {
    campaign: state.campaign.info,
    plans: Object.values(state.campaignPlans.info),
    loadingPlans: state.campaignPlans.loading,
  }
}

export default connect(mapStateToProps)(CampaignPlans)
