export function marketRates(state = { ratesForMarket: [], loading: false }, action) {
  switch (action.type) {
  case 'GETTING_MARKET_RATES':
    return { ...state, loading: action.payload }
  case 'GET_MARKET_RATES_FOR_MARKET':
    return { ...state, ratesForMarket: action.payload }
  default:
    return state
  }
}
