import axios from 'axios'
import { getPTCForMarket } from './getPTC'

export const updatePTC = (ptc, newEndDate, marketCode) => {
  return async (dispatch) => {
    try {
      const payload = {
        to_dt: newEndDate
      }
      await axios.put(`${process.env.REACT_APP_GARCON_API}/pricing/rates/ptc/${ptc.price_to_compare_id}`, payload)
        dispatch({ type: 'SHOW_ERROR', payload: { headline: 'SUCCESS!', message: 'PTC Successfully edited'} })
        dispatch(getPTCForMarket(marketCode))
    } catch (error) {
        dispatch({ type: 'SHOW_ERROR', payload: { response: error.response, message: 'An error occured updating the end date for that PTC.' } })
        dispatch(getPTCForMarket(marketCode))
        throw error
    }
  }
}
