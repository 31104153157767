import React, { useEffect, useState } from 'react'
import history from '../../../history'
import { createRolloffTreatment } from '../../../apis/rolloffTreatments'
import SectionInput from '../../Shared/SectionInput'
import TreatmentBuckets from './TreatmentBuckets'
import {
  checkBuckets,
  configureTreatmentBucketsForApi,
  setupRateCapForApi,
  validateRateCapData
} from './helpers'

const CreateTreatment = () => {
  const [formData, setFormData] = useState({
    treatment_name: '',
    notes: ''
  })
  const [treatment, setTreatment] = useState(null)
  const [hasRateCap, setHasRateCap] = useState(false)
  const [rateCapData, setRateCapData] = useState({
    rateCap: '',
    rateCapModifier: null,
    plan: null,
    planChoice: null
  })
  const [bucketDelta, setBucketDelta] = useState({ bucket_type: '', delta_to: '', delta_from: '' })
  const [buckets, setBuckets] = useState([{ index: 1, min: '', max: '', planChoice: null, plan: null, bucketData: {} }])
  const [error, setError] = useState(null)

  const onSubmit = (e) => {
    e.preventDefault()

    const bucketError = checkBuckets(buckets)
    const rateCapDataIsValid = hasRateCap ? validateRateCapData(rateCapData) : true
    const missingBucketDelta = buckets.length > 1 && (!bucketDelta.bucket_type || !bucketDelta.delta_to || !bucketDelta.delta_from)
    if (bucketError || missingBucketDelta || !rateCapDataIsValid) {
      setError({ message: 'Invalid bucket data' })
    } else {
      setError(null)

      const { treatment_name, notes } = formData
      let treatment_config = configureTreatmentBucketsForApi(buckets, bucketDelta)
      if (hasRateCap) {
        const rateCapArrayObj = setupRateCapForApi(rateCapData)

        treatment_config = {
          type: 'min',
          array: [
            rateCapArrayObj,
            { ...treatment_config }
          ]
        }
      }

      createRolloffTreatment({
        treatment_name,
        notes,
        treatment_config
      }, setTreatment)
        .catch((error) => {
          setError(error)
        })
    }
  }

  useEffect(() => {
    if (treatment) {
      history.push(`/rolloff_treatments/treatment/${treatment.rolloff_treatment_id}`)
    }
  }, [treatment])

  const onChange = (key, value) => {
    setFormData({ ...formData, [key]: value })
  }

  const updateRateCap = (data) => {
    setRateCapData({ ...rateCapData, ...data })
  }

  return (
    <div className="container-fluid">
      <form>
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <h4 className="my-3">
            New Treatment
          </h4>
          <button
            className="btn btn-primary btn-md mb-2"
            onClick={(e) => onSubmit(e)}
            disabled={!formData.treatment_name}
          >
            Save Treatment
          </button>
        </div>

        <div className="card mb-4">
          <div className="card-header">
            <strong>Details</strong>
          </div>
          <div className="card-body">
            <p style={{ color: 'red' }}>{error?.message}</p>
            <div className="col-8">
              <SectionInput
                label="Treatment Name"
                type="text"
                value={formData.treatment_name}
                onChange={(e) => onChange('treatment_name', e.target.value)}
              />
              <SectionInput
                label="Notes"
                type="textarea"
                value={formData.notes}
                onChange={(e) => onChange('notes', e.target.value)}
              />
            </div>
          </div>
        </div>

        <TreatmentBuckets
          bucketDelta={bucketDelta}
          buckets={buckets}
          setHasRateCap={setHasRateCap}
          updateRateCap={updateRateCap}
          setBuckets={setBuckets}
          setBucketDelta={setBucketDelta}
        />
      </form>
    </div>
  )
}

export default CreateTreatment
