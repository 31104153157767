import axios from 'axios'
import getCampaignPhoneNumbers from './getCampaignPhoneNumbers'

export default function addCampaignPhoneNumber(input) {
  return async (dispatch) => {
    if (!input){
      dispatch({ type: 'SHOW_ERROR', payload: { response: { headers: {} }, message: 'The phone number and confirm phone number fields must match' } })
    } else if (input.length !== 10 ) {
      dispatch({ type: 'SHOW_ERROR', payload: { response: { headers: {} }, message: 'Phone numbers must be 10 characters long' } })
    } else {
      dispatch({ type: 'ADDING_CAMPAIGN_PHONE_NUMBER', payload: true })
      try {
        const phoneNumber = {
          phone_number_raw: input,
        }
        const response = await axios.post(`${process.env.REACT_APP_GARCON_API}/campaigns/phone_numbers`, phoneNumber)
        if (response){
          dispatch(getCampaignPhoneNumbers())
          dispatch({ type: 'CAMPAIGN_PHONE_NUMBER_ADDED', payload: false })
        }
      } catch (error) {
        dispatch({ type: 'SHOW_ERROR', payload: { response: error.response, message: 'An error occured adding this phone number.' } })
        dispatch({ type: 'CAMPAIGN_PHONE_NUMBER_ADDED', payload: false })
        throw error
      }
    }
  }
}
