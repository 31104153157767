import React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import ReactTable from 'react-table'
import matchSorter from 'match-sorter'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTrash, faWrench } from '@fortawesome/free-solid-svg-icons'
import history from '../../history'
import { deleteUrl } from '../../actions/urlRedirects/deleteUrl'

const UrlRedirectsContainer = () => {
  const urls = useSelector(state => state.urlRedirects)
  const dispatch = useDispatch()

  const goToManage = (input) => {
    history.push('/url_redirects/manage', {
      manage: input,
    })
  }

  const handleDelete = async(input) => {
    dispatch(deleteUrl(input))
  }

  const columns = [{
    Header: 'URL',
    accessor: 'url',
    width: 300,
    filterMethod: (filter, rows) => (
      matchSorter(rows, filter.value, { keys: ['url'] })
    ),
    filterAll: true,
  },{
    Header: 'Destination',
    accessor: 'destination',
    filterMethod: (filter, rows) => (
      matchSorter(rows, filter.value, { keys: ['destination'] })
    ),
    filterAll: true,
  },{
    Header: 'Manage URL',
    accessor: 'manage',
    width: 125,
    Cell: row => (
      <div className="row justify-content-md-center">
        <button className="btn btn-primary btn-md mr-2" type="button" onClick={() => goToManage(row.original)}><FontAwesomeIcon icon={faWrench} /></button>
      </div>
    ),
  }, {
    Header: 'Delete URL',
    accessor: 'remove',
    width: 125,
    Cell: row => (
      <div className="row justify-content-md-center">
        <button className="btn btn-warning btn-md mr-2" type="button" onClick={() => handleDelete(row.original)}><FontAwesomeIcon icon={faTrash} /></button>
      </div>
    ),
  }]

  return (
    <div className="col-md-12 mx-auto">
      <div className="d-flex">
        <div />
        <h2 className="text-left w-100">URL Redirects</h2>
      </div>
      <ReactTable
        filterable
        className="-striped -highlight mb-3"
        columns={columns}
        data={urls}
        defaultPageSize={10}
        getTdProps={(c, r) => {
          if (c.id === 'manage') {
            return {
              onClick: () => this.goToManage(r.original),
              style: {
                cursor: 'pointer',
              },
            }
          } else if (c.id === 'remove') {
            return {
              onClick: () => this.handleDelete(r.original),
              style: {
                cursor: 'pointer',
              },
            }
          } else {
            return {}
          }
        }}
        keyField="url"
      />
    </div>
  )
}

export default UrlRedirectsContainer
