import moment from 'moment'

const RulesetActivity = ({ rulesetActivity }) => {
  return (
    <div className="card-body">
      {rulesetActivity.map((activity, index) => {
        return (
          <div key={index}>
            {activity.user_name || `User ${activity.user_id}`} on {moment(activity.updated_at).format('MMM Do YYYY, h:mm a')}
          </div>
        )
      })
      }
    </div>
  )
}

export default RulesetActivity
