import axios from 'axios'

export function updateCompRunStatus(key, input){
  return { type: 'UPDATE_COMP_RUN_STATUS', payload: { [key]: input } }
}

export function submitCompRunStatusUpdate(compRunId, statusCode, statusNotes) {
  return async(dispatch, getState) => {
    try {
      const user = getState().user
      const params = {
        comp_run_id: compRunId,
        comp_run_status_code: statusCode,
        notes: statusNotes,
        audit: {
          user_name: user.email,
        },
      }
      const response = await axios.post(`${process.env.REACT_APP_GARCON_API}/compensation/runs/run/${compRunId}/status`, params)
      if (response.data.status === 'ERROR') {
        dispatch({ type: 'SHOW_ERROR', payload: { response, message: 'An error occured updating compRunStatus.' } })
        response.status = 500
        return response
      } else {
        return response
      }
    } catch (error){
      dispatch({ type: 'SHOW_ERROR', payload: { response: error.response, message: 'An error occured updating compRunStatus.' } })
      throw error
    }
  }
}
