import axios from 'axios'
import mapKeys from 'lodash/mapKeys'

export const getPredicates = async (callback) => {
  try {
    const response = await axios.get(`${process.env.REACT_APP_GARCON_API}/segments/predicates`)
    const payload = mapKeys(response.data, data => data.segment_predicate.predicate)
    callback(payload)
  } catch (error) {
    throw error
  }
}
