import axios from 'axios'

export default function getRevenueClasses() {
  return function(dispatch) {
    axios.get(`${process.env.REACT_APP_GARCON_API}/products/revenue_classes`)
      .then((response) => {
        dispatch({ type: 'GET_REVENUE_CLASSES', payload: response.data })
      }).catch((error) => {
        dispatch({ type: 'SHOW_ERROR', payload: { response: error.response, message: 'An error occured getting revenue classes.' } })
        throw error
      })
  }
}
