import { useEffect, useRef } from 'react'

// Custom hook to compare previous vs current props/state
export default (value) => {
  const ref = useRef()
  useEffect(() => {
    ref.current = value
  })

  if (ref.current === undefined) return value

  return ref.current
}
