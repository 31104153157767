import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import Main from './components/Main'
import AuthBtn from './components/AuthBtn'
import Sidebar from './components/Sidebar'

import './App.css'
import 'rodal/lib/rodal.css'
import 'react-table/react-table.css'
import './stylesheets/create-schedule-modal.css'

// We import this directly because the import from the package is blocked by our CSP
import '@fortawesome/fontawesome-svg-core/styles.css'

import { library } from '@fortawesome/fontawesome-svg-core'
import {
  faUserClock,
  faStore,
  faTimes,
  faHandshake,
  faClock,
  faCheck,
  faGlobe,
  faFlask,
  faUserAstronaut,
  faBullhorn,
  faChartPie,
  faMoneyBill,
  faSlidersH,
  faSpinner,
  faChevronDown,
  faChevronUp,
  faHome,
  faDollarSign,
  faTrash,
  faClipboardList,
  faQuestionCircle,
  faTools,
  faUsers,
  faAddressCard,
  faBuilding,
  faKey,
  faCalendar,
  faMapMarker,
  faFlag,
  faCoins,
  faHandHoldingUsd,
  faFile,
  faStreetView,
  faSitemap,
  faCommentDollar,
  faHeadset,
  faFunnelDollar,
  faParachuteBox,
  faMailBulk,
  faFileAlt,
  faGavel,
  faBalanceScale,
  faWrench,
  faAirFreshener,
  faSubway,
  faCartPlus,
  faDatabase,
  faLink,
  faScrewdriver,
  faSignInAlt,
  faThumbsUp,
  faLightbulbDollar,
  faMapPin,
  faEdit,
  faDiscDrive
} from '@fortawesome/pro-solid-svg-icons'

library.add(
  faUserClock,
  faStore,
  faTimes,
  faHandshake,
  faClock,
  faCheck,
  faGlobe,
  faFlask,
  faUserAstronaut,
  faBullhorn,
  faChartPie,
  faMoneyBill,
  faSlidersH,
  faSpinner,
  faChevronDown,
  faChevronUp,
  faHome,
  faDollarSign,
  faTrash,
  faClipboardList,
  faQuestionCircle,
  faTools,
  faUsers,
  faAddressCard,
  faBuilding,
  faKey,
  faCalendar,
  faMapMarker,
  faFlag,
  faCoins,
  faHandHoldingUsd,
  faFile,
  faStreetView,
  faSitemap,
  faCommentDollar,
  faHeadset,
  faFunnelDollar,
  faParachuteBox,
  faMailBulk,
  faFileAlt,
  faGavel,
  faBalanceScale,
  faWrench,
  faAirFreshener,
  faSubway,
  faCartPlus,
  faDatabase,
  faLink,
  faScrewdriver,
  faSignInAlt,
  faThumbsUp,
  faLightbulbDollar,
  faMapPin,
  faEdit,
  faDiscDrive
)

class App extends Component {
  constructor () {
    super()
    this.state = {
      toggled: false,
    }
  }

  toggleSidebar = () => {
    this.setState((prevState) => ({ toggled: !prevState.toggled }))
  }

  render() {
    return (
      <div>
        <nav className="navbar navbar-dark bg-dark flex-md-nowrap shadow fixed-top">
          <div className="navbar-brand">
            <img alt="logo" className="logo m-0 pr-2 py-1" src="/inspire_logo_white.png" onClick={this.toggleSidebar} />
            <Link className="navbar-brand" to="/">
              <p className="navbar-brand pr-4 m-0">Lighthouse</p>
            </Link>
          </div>
          <ul className="navbar-nav px-3">
            <li className="nav-item text-nowrap">
              <AuthBtn />
            </li>
          </ul>
        </nav>
        <Sidebar getToggled={this.state.toggled} />
        <div className="container-fluid">
          <div className="row">
            <Main>
              {this.props.children}
            </Main>
          </div>
        </div>
      </div>
    )
  }
}

export default App
