import React from 'react'
import { Link } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCopy, faSignInAlt } from '@fortawesome/free-solid-svg-icons'

const ViewVariations = ({ experiment }) => {
  return (
    <>
      <div className="row">
        <div className="col-2">Champion</div>
        <div className="col-4">{experiment?.control?.variation_name}</div>
        <div className="col-2">Applied to {Math.floor(experiment?.control?.percent_allocation * 100)}%</div>
        <div className="col-4">
          {experiment?.control?.config.rolloff_treatment_id}
          <Link
            className="text-primary ml-2"
            onClick={() => { navigator.clipboard.writeText(experiment?.control?.config?.rolloff_treatment_id) }}
            to={`/rolloff_experiments/experiment/{experiment.experiment_id}`}
          >
            <FontAwesomeIcon icon={faCopy} />
          </Link>
          <Link
            className="text-primary ml-2"
            target="_blank"
            to={`/rolloff_treatments/treatment/${experiment?.control?.config?.rolloff_treatment_id}`}
          >
            <FontAwesomeIcon icon={faSignInAlt} />
          </Link>
        </div>
      </div>
      <div className="row">
        {experiment?.variations?.map((variant, index) => {
          return (
            <React.Fragment key={index}>
              <div className="col-2">Variant {index + 1}</div>
              <div className="col-4">{variant.variation_name}</div>
              <div className="col-2" key={index}>Applied to {Math.floor(variant.percent_allocation * 100)}%</div>
              <div className="col-4">
                {variant.config?.rolloff_treatment_id}
                <Link className="text-primary ml-2" onClick={() => { navigator.clipboard.writeText(variant.config?.rolloff_treatment_id) }} to={`/rolloff_experiments/experiment/{experiment.experiment_id}`}>
                  <FontAwesomeIcon icon={faCopy} />
                </Link>
                <Link
                  className="text-primary ml-2"
                  target="_blank"
                  to={`/rolloff_treatments/treatment/${variant.config?.rolloff_treatment_id}`}
                >
                  <FontAwesomeIcon icon={faSignInAlt} />
                </Link>
              </div>
            </React.Fragment>
          )
        })}
      </div>
    </>
  )
}

export default ViewVariations
