import React, { useEffect, useState } from 'react'
import SectionSelect from '../../Shared/SectionSelect'
import MinMaxConfigContainer from './MinMaxConfigContainer'
import { bucketAmountToDisplay, modifiers, staticRateModifiers } from './helpers'

const TreatmentConfig = ({ bucket, updateRow }) => {
  const primaryBucketChoices = [
    { name: 'Current Price' },
    { name: 'Current PTC' },
    { name: 'Next Contract CTS' },
    { name: 'Static Rate' },
    { name: 'MAX' },
    { name: 'MIN' },
  ]

  const [bucketChoice, setBucketChoice] = useState(null)
  const [modifier, setModifer] = useState(null)
  const [bucketAmount, setBucketAmount] = useState(null)
  const [minMaxBuckets, setMinMaxBuckets] = useState([{ bucketChoice: '', modifier: '', index: 1 }])

  useEffect(() => {
    if (bucket) {
      const { bucketData } = bucket
      setBucketChoice(bucketData.bucketChoice)
      setModifer(bucketData.modifier)
      setBucketAmount(bucketData.bucketAmount)
      if (bucketData.bucketChoice == 'MAX' || bucketData.bucketChoice == 'MIN') {
        setMinMaxBuckets(bucketData.array)
      }
    }
  }, [])

  const updateConfigField = (field, value) => {
    if (field === 'bucketChoice') {
      setBucketChoice(value)
      setModifer(null)
      setBucketAmount(null)
    }
    if (field === 'modifier') setModifer(value)
    if (field === 'bucketAmount') setBucketAmount(value)
    updateRow(field, value)
  }

  const minOrMaxBucket = bucketChoice && bucketChoice == 'MIN' || bucketChoice == 'MAX'
  const bucketModifiers = bucketChoice && bucketChoice == 'Static Rate' ? staticRateModifiers : modifiers

  return (
    <div className="row mb-4">
      <div className="col-4">
        <SectionSelect
          labelKey="name"
          options={primaryBucketChoices}
          type="text"
          value={bucketChoice}
          valueKey="name"
          onChange={(e) => updateConfigField('bucketChoice', e.target.value)}
        />
      </div>
      {minOrMaxBucket &&
        <MinMaxConfigContainer
          bucketChoice={bucketChoice}
          minMaxBuckets={minMaxBuckets}
          setMinMaxBuckets={setMinMaxBuckets}
          updateRow={updateRow}
        />
      }
      {bucketChoice && !minOrMaxBucket &&
        <div className="col-3">
          <SectionSelect
            labelKey="name"
            options={bucketModifiers}
            type="text"
            value={modifier}
            valueKey="name"
            onChange={(e) => updateConfigField('modifier', e.target.value)}
          />
        </div>
      }
      {modifier && modifier !== 'no modifier' &&
        <div className="col-2">
          <input
            className="form-control rounded"
            type="number"
            value={bucketAmount}
            onChange={(e) => updateConfigField('bucketAmount', e.target.value)}
          />
          {bucketAmount && bucketAmountToDisplay(modifier, bucketAmount)}
        </div>
      }

    </div >
  )
}

export default TreatmentConfig
