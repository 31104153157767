import React from 'react'
import { Button } from 'react-bootstrap'
import history from '../../history'

const ManageButton = ({ marketCode }) => {
  return (
    <div className='p-2'>
      <Button onClick={() => history.push(`/price_to_compare/${marketCode}`)}>
        Manage
      </Button>
    </div>
  )
}

export default ManageButton;
