import React from 'react'
import ReactTable from 'react-table'
import matchSorter from 'match-sorter'
import moment from 'moment'
import { Link } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSignInAlt } from '@fortawesome/free-solid-svg-icons'

const PlanCampaignsTable = ({ campaigns }) => {

  const columns =[{
    Header: 'Campaign Name',
    accessor: 'campaign_name',
    filterMethod: (filter, rows) => (
      matchSorter(rows, filter.value, { keys: ['campaign_name'] })
    ),
    filterAll: true,
    Cell: (e) =>(
      <div className="row justify-content-md-center overflow-auto">
        { e.value ? <Link className="text-primary" target="_blank" to={`/campaigns/${e.original.campaign_id}`}> {e.value} <FontAwesomeIcon icon={faSignInAlt} /> </Link>: null }
      </div>
    ),
  },{
    Header: 'Campaign ID',
    accessor: 'campaign_id',
    Cell: (e) =>(
      <div className="row justify-content-md-center">
        { e.value ? e.value : null }
      </div>
    ),
  },{
    Header: 'Date Created',
    accessor: 'created_at',
    filterMethod: (filter, rows) => (
      matchSorter(rows, filter.value, { keys: ['created_at'] })
    ),
    filterAll: true,
    width: 200,
    Cell: (e) =>(
      <div className="row justify-content-md-center">
        { e.value ? <span>{moment(e.value).format('MM-DD-YYYY')}</span> : null }
      </div>
    ),
  }]

  return (
    <div>
      <ReactTable
        filterable
        className="-striped -highlight mb-1"
        columns={columns}
        data={campaigns}
        minRows={0}
      />
    </div>
  )
}

export default PlanCampaignsTable
