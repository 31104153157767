import React from 'react'

export default ({ rewards, rewardType }) => (
  <table className="table table-sm mt-5 mb-5">
    <tr>
      <th colSpan="8">
        <h5>Similar Existing Rewards</h5>
      </th>
    </tr>
    { rewardType === 'promo' ?
      (
        <tbody>
          <tr>
            <th>Description</th>
            <th>Promo Bonus</th>
            <th>Promo Measure</th>
            <th>Fulfillment Vendor</th>
            <th>Fulfillment Channel</th>
            <th>Channel Partner</th>
            <th />
          </tr>
          {
            rewards.map(promo => {
              return (<tr key={promo.promo_code} className="table-warning">
                <td>{promo.promo_desc}</td>
                <td>{promo.promo_bonus}</td>
                <td>{promo.promo_measure}</td>
                <td>{promo.fulfillment_vendor.vendor_name}</td>
                <td>{promo.fulfillment_channel}</td>
                <td>{promo.channel_partner.channel_partner_name}</td>
                <td><a href={`/rewards/editPromo/${promo.promo_code}`} rel="noreferrer" target="_blank">View</a></td>
              </tr>)
            })
          }
        </tbody>
      )
      :
      (
        <tbody>
          <tr>
            <th>Description</th>
            <th>Loyalty Program Type</th>
            <th>Loyalty Program Measure</th>
            <th>Loyalty Program Multiplier</th>
            <th>Max Number Of Rewards</th>
            <th>Maximum Reward Amount</th>
            <th>Fulfillment Vendor</th>
            <th>Fulfillment Channel</th>
            <th>Channel Partner</th>
            <th />
          </tr>
          {
            rewards.map(loyaltyProgram => {
              return (<tr key={loyaltyProgram.loyalty_program_code} className="table-warning">
                <td>{loyaltyProgram.loyalty_program_desc}</td>
                <td>{loyaltyProgram.loyalty_program_type}</td>
                <td>{loyaltyProgram.loyalty_program_measure}</td>
                <td>{loyaltyProgram.loyalty_program_multiplier}</td>
                <td>{loyaltyProgram.max_accruals}</td>
                <td>{loyaltyProgram.max_reward_amount}</td>
                <td>{loyaltyProgram.fulfillment_vendor.vendor_name}</td>
                <td>{loyaltyProgram.fulfillment_channel}</td>
                <td>{loyaltyProgram.channel_partner.channel_partner_name}</td>
                <td><a href={`/rewards/editLoyaltyProgram/${loyaltyProgram.loyalty_program_code}`} rel="noreferrer" target="_blank">View</a></td>
              </tr>)
            })
          }
        </tbody>
      )
    }
  </table>
)
