import React, { useEffect, useState } from 'react'
import FuzzySelect from '../../Shared/FuzzySelect'
import { getPlans } from "../../../apis/plans"

const PlanSection = ({ index, plan, updateBuckets }) => {
  const [plans, setPlans] = useState(null)
  const [selectedPlan, setSelectedPlan] = useState({ value: '', label: '' })

  useEffect(() => {
    getPlans(setPlans)
    setSelectedPlan(plan)
  }, [])

  const getOptions = () => {
    return Object.values(plans).map(option => (
      { value: option.plan_code, label: `${option.plan_name} - ${option.plan_code}` }
    ))
  }

  const updatePlan = (plan) => {
    setSelectedPlan(plan)
    updateBuckets({ plan }, index)
  }

  return (
    <div className="row">
      {!plans && <div className="col-4">Loading...</div>}
      {plans && <div className="col-8">
        <div className="row align-items-center mb-2">
          <div className="col-2 text-right">
            <label className="font-weight-bold mr-2 mb-0">
              Plan
            </label>
          </div>
          <div className="col-9">
            <FuzzySelect
              options={getOptions()}
              value={selectedPlan}
              onChange={(e) => updatePlan(e.value)}
            />
          </div>
        </div>
      </div>}
    </div>
  )
}

export default PlanSection
