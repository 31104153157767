import React from 'react'
import startCase from 'lodash/startCase'
import ReactTable from 'react-table'
import PromosSelect from '../../Shared/PromosSelect'
import SectionCheckbox from '../../../../Shared/SectionCheckbox'
import { Link } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCopy, faSignInAlt } from '@fortawesome/free-solid-svg-icons'

const PlanPromos = ({
  readonly,
  plan,
  onUpdatePromos,
}) => {
  const getPlanPromos = () => {
    if (plan.plan_promos && plan.plan_promos.length > 0) {
      return plan.plan_promos
    } else {
      return []
    }
  }

  const planPromos = getPlanPromos()

  const updateRequiresInstallation = (promo, event) => {
    return plan.plan_promos.map(p => {
      if (p.promo_code === promo.promo_code) p.requires_installation = event.target.checked
      return p
    })
  }

  const renderPromoCode = (cellInfo) => {
    return (
      <div>
        {cellInfo ?
          <span>
            {cellInfo.original.promo_code}
            <Link className="ml-2 text-primary" onClick={() => { navigator.clipboard.writeText(cellInfo.original.promo_code) }} to={`/plans/${plan.plan_code}`}>
              <FontAwesomeIcon icon={faCopy} />
            </Link>
            <Link className="ml-2 text-primary" target="_blank" to={`/rewards/editPromo/${cellInfo.original.promo_code}`}>
              <FontAwesomeIcon icon={faSignInAlt} />
            </Link>
          </span>
          : null
        }
      </div>
    )
  }

  const tableColumns = () => {
    const columns = []
    Object.keys(planPromos[0]).forEach(column => {
      const tableColumn = {
        Header: startCase(column),
        id: column,
        accessor: column,
      }
      if (column === 'promo_code') tableColumn.Cell = renderPromoCode
      columns.push(tableColumn)
    })
    return columns
  }

  const renderPromos = () => {
    if (!readonly) {
      return (
        <div className="row">
          <div className="col-6">
            <PromosSelect
              value={planPromos}
              onUpdatePromos={(value) => onUpdatePromos('plan_promos', value)}
            />
          </div>
          <div className="col-6">
            {planPromos.map(promo => (
              <SectionCheckbox
                key={`requires-installation-${promo.promo_code}`}
                checked={promo.requires_installation}
                label={`Does ${promo.promo_desc} require installation?`}
                onChange={(event) => onUpdatePromos('plan_promos', updateRequiresInstallation(promo, event))}
              />
            ))}
          </div>
        </div>
      )
    }

    if (planPromos.length === 0) return <p>None</p>

    return (
      <ReactTable
        className="-striped -highlight mb-3"
        columns={tableColumns()}
        data={planPromos}
        defaultPageSize={5}
        keyField="promo_name"
        style={{ height: '300px' }}
      />
    )
  }

  return (
    <div className="mb-4">
      <div className="mb-2">
        <h5 className="mb-0">Promos</h5>
        <small>Promos attached to this plan</small>
      </div>
      {renderPromos()}
    </div>
  )
}

export default PlanPromos
