import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import { updatePTC } from '../../actions/pricing/updatePTC'
import SectionInput from '../Shared/SectionInput'

const EditPTCEndDate = ({ endDate, ptc, marketCode }) => {
  const [newEndDate, setNewEndDate] = useState(new Date(endDate))
  const [showDatePicker, setShowDatePicker] = useState(false)
  const dispatch = useDispatch()

  const handleEditEndDate = (updateDate) => {
    setNewEndDate(updateDate)
    dispatch(updatePTC(ptc, updateDate, marketCode))
  }

  return (
    <div>
      {showDatePicker ?
        <>
          <SectionInput
            type="date"
            value={newEndDate}
            dateFormat={"MM/DD/YYYY"}
            onChange={(e) => handleEditEndDate(e._d)}
          />
        </>
      : <span onClick={() => setShowDatePicker(true)}>{endDate}</span>
      }
    </div>
  )
}

export default EditPTCEndDate;
