import React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import ReactTable from 'react-table'
import matchSorter from 'match-sorter'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTrash } from '@fortawesome/free-solid-svg-icons'
import Select from 'react-select'
import { updateMarketGeo } from '../../actions/marketGeos/updateMarketGeo'
import { deleteMarketGeo } from '../../actions/marketGeos/deleteMarketGeo'

const MarketGeosContainer = () => {
  const marketGeos = useSelector(state => state.marketGeos)
  const dispatch = useDispatch()

  const handleStatus = async(value, row) => {
    dispatch(updateMarketGeo(row, value))
  }

  const columns = [{
    Header: 'Market Code',
    accessor: 'market_code',
    filterMethod: (filter, rows) => (
      matchSorter(rows, filter.value, { keys: ['market_code'] })
    ),
    filterAll: true,
  },{
    Header: 'State Code',
    accessor: 'state_code',
    filterMethod: (filter, rows) => (
      matchSorter(rows, filter.value, { keys: ['state_code'] })
    ),
    filterAll: true,
  },{
    Header: 'Zip Code',
    accessor: 'zip_code',
    filterMethod: (filter, rows) => (
      matchSorter(rows, filter.value, { keys: ['zip_code'] })
    ),
    filterAll: true,
  },{
    Header: 'City',
    accessor: 'city',
    filterMethod: (filter, rows) => (
      matchSorter(rows, filter.value, { keys: ['city'] })
    ),
    filterAll: true,
  },{
    Header: 'County',
    accessor: 'county',
    filterMethod: (filter, rows) => (
      matchSorter(rows, filter.value, { keys: ['county'] })
    ),
    filterAll: true,
  },{
    Header: 'Verification Status',
    accessor: 'verification_status',
    style: { 'overflow': 'visible' },
    Cell: row => (
      <Select
        options={[
          { value: 'VERIFIED', label: 'Verified' },
          { value: 'DISPUTED', label: 'Disputed' },
          { value: 'PENDING', label: 'Pending' },
        ]}
        value={row.original.verification_status}
        onChange={(option) => handleStatus(option.value, row.original)}
      />
    ),
    filterMethod: (filter, rows) => (
      matchSorter(rows, filter.value, { keys: ['verification_status'] })
    ),
    filterAll: true,
  },{
    Header: 'Delete',
    accessor: 'remove',
    width: 125,
    Cell: row => (
      <div className="row justify-content-md-center">
        <button className="btn btn-warning btn-md mr-2" type="button" onClick={() => handleDelete(row.original)}><FontAwesomeIcon icon={faTrash} /></button>
      </div>
    ),
  }]

  return (
    <div className="col-md-12 mx-auto">
      <div className="d-flex">
        <div />
        <h2 className="text-left w-100">Market Geos</h2>
      </div>
      <ReactTable
        filterable
        className="-striped -highlight mb-3"
        columns={columns}
        data={marketGeos}
        defaultPageSize={10}
        getTdProps={(c, r) => {
          if (c.id === 'remove') {
            return {
              onClick: () => dispatch(deleteMarketGeo(r.original)),
              style: {
                cursor: 'pointer',
              },
            }
          } else {
            return {}
          }
        }}
        keyField="market_geo_id"
      />
    </div>
  )
}

export default MarketGeosContainer
