import React, {useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import HistoricalPricesTables from './HistoricalPricesTables'
import CreatePriceToCompare from './CreatePriceToCompare'
import { getPTCForMarket } from '../../actions/pricing/getPTC'

const ManagePriceToCompareContainer = () => {
  const dispatch = useDispatch()
  const { market_code } = useParams()

  useEffect(() => {
    dispatch(getPTCForMarket(market_code))
  }, [market_code])

  return (
    <div className="col-md-12 mx-auto">
    <h2>Manage PTC Rates for {market_code} </h2>
      <CreatePriceToCompare marketCode={market_code} />
      <HistoricalPricesTables marketCode={market_code} />
    </div>
  )
}

export default ManagePriceToCompareContainer;
