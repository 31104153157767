import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import moment from 'moment'
import startCase from 'lodash/startCase'
import isEmpty from 'lodash/isEmpty'
import matchSorter from 'match-sorter'
import { getAddressOfferCounts } from '../../../../apis/prospects'
import { CAMPAIGNS } from '../../../../utils/roles'
import history from '../../../../history'
import getCampaigns from '../../../../actions/campaigns/getCampaigns'
import getCampaignSegments from '../../../../actions/segments/getCampaignSegments'
import { changeModule } from '../../../../actions/changeModule'
import NavTabs from '../../../Shared/NavTabs'
import SectionSelect from '../../../Shared/SectionSelect'
import CampaignsTableView from './CampaignsTableView'
import CampaignsGraphView from './CampaignsGraphView'
import tableColumnsChange from '../../../../utils/tableColumnsChange'

const tabs = [
  {
    key: 'all',
    label: 'All',
  },
  {
    key: 'active',
    label: 'Active',
  },
  {
    key: 'inactive',
    label: 'Inactive',
  },
]

class CampaignsContainer extends Component {

  state = {
    tab: 'active',
    filterBlankPriority: true,
    contentType: 'table',
    offersData: null,
    show: false,
    allTableColumns: [],
    selectedTableColumns: []
  }

  async componentDidMount() {
    this.props.getCampaigns()
    this.props.getCampaignSegments()
    this.props.changeModule('Campaigns')

    const offersData = await getAddressOfferCounts()
    this.setState({ offersData })
  }

  componentDidUpdate(prevProps) {
    if (prevProps.campaigns !== this.props.campaigns && this.props.campaigns) {
      const allColumns = this.tableColumns()
      const columnsToHideByDefault = ['end_dt', 'campaign_phone_number_id', 'parent_campaign']
      const initialDefaultColumns = allColumns.filter(column => !columnsToHideByDefault.includes(column.id))
      this.setState({ allTableColumns: allColumns, selectedTableColumns: initialDefaultColumns })
    }
  }

  parentCampaign(childCampaign) {
    if (!childCampaign.parent_campaign_id) {
      return null
    }
    return Object.values(this.props.campaigns).find(campaign => campaign.campaign_id === childCampaign.parent_campaign_id)
  }

  filteredCampaigns() {
    const campaigns = Object.values(this.props.campaigns)
    const filter = (c) => { return !this.state.filterBlankPriority || !!c.preference_sequence === this.state.filterBlankPriority }
    if (this.state.tab === 'all') {
      return campaigns.filter(c => filter(c))
    } else if (this.state.tab === 'inactive') {
      return campaigns.filter(c => (moment(c.end_dt) < moment(new Date()) || moment(c.start_dt) > moment(new Date())) && filter(c))
    } else {
      return campaigns.filter(c => (moment(c.start_dt) < moment(new Date()) && (!c.end_dt || moment(c.end_dt) > moment(new Date()))) && filter(c))
    }
  }

  tableData() {
    return this.filteredCampaigns().map(campaign => {
      const offersDataForCampaign = this.state.offersData?.find(data => data.campaign_id === campaign.campaign_id)
      const offers_7_days = offersDataForCampaign?.offers_7_days
      const most_recently_used_dt =
        offersDataForCampaign?.most_recently_used_dt ? moment(offersDataForCampaign?.most_recently_used_dt).format('MM-DD-YYYY') : ''
      const parentCampaign = this.parentCampaign(campaign)
      const parentCampaignName = parentCampaign ? parentCampaign.campaign_name : null
      const modified = { ...campaign, parent_campaign: parentCampaignName, offers_7_days, most_recently_used_dt }
      delete modified.parent_campaign_id
      return modified
    })
  }

  onChangeTab = (tab) => {
    this.setState({ tab })
  }

  createCampaign = () => {
    history.push('/campaigns/new')
  }

  managePhoneNumbers = () => {
    history.push('/campaign-phone-numbers')
  }

  handleSectionSelect = (campaign) => {
    window.open(`/campaigns/${campaign.campaign_id}`, '_blank', 'noopener, noreferrer')
  }

  onFilterBlankPriority = (event) => {
    const filterBlankPriority = event.target.checked
    this.setState({ filterBlankPriority })
  }

  header(column) {
    if (column === 'preference_sequence') {
      return 'Priority'
    } else if (column === 'start_dt') {
      return 'Start'
    } else if (column === 'end_dt') {
      return 'End'
    } else if (column === 'cc_code') {
      return 'cc_code'
    } else if (column === 'most_recently_used_dt') {
      return 'Most Recent Use'
    } else if (column === 'offer_7_days') {
      return 'Offers for Last 7 Days'
    } else {
      return startCase(column)
    }
  }

  tableColumns() {
    const columns = []
    const columnsToHide = ['campaign_id', 'phone_number', 'created_at', 'updated_at']

    Object.keys(this.tableData()[0]).forEach(column => {
      if (!columnsToHide.includes(column)) {
        const tableColumn = {
          Header: this.header(column),
          id: column,
          accessor: column,
          filterMethod: (filter, rows) => (
            matchSorter(rows, filter.value, { keys: [column] })
          ),
          filterAll: true,
        }
        if (column === 'campaign_name' || column === 'campaign_description') tableColumn.minWidth = 300
        if (column === 'cc_code') tableColumn.minWidth = 300
        columns.push(tableColumn)
      }
    })
    return columns
  }

  tableColumnOptions(columns) {
    return columns.map(column => {
      return {
        label: column.Header,
        value: column
      }
    })
  }

  onColumnsChange = (options) => {
    const updatedTableColumns = tableColumnsChange(options, this.state.allTableColumns)
    this.setState({ selectedTableColumns: updatedTableColumns })
  }

  writeAccess() {
    return this.props.roles.some(role => CAMPAIGNS.WRITE.includes(role.name))
  }

  renderContent() {
    if (isEmpty(this.filteredCampaigns())) {
      return <p>No {this.state.tab} campaigns</p>
    }

    if (this.state.contentType === 'table') {
      return <CampaignsTableView columns={this.state.selectedTableColumns} data={this.tableData()} onSelect={this.handleSectionSelect} />
    } else {
      return <CampaignsGraphView campaigns={this.filteredCampaigns()} segments={this.props.campaignSegments || []} />
    }
  }

  render() {
    if (this.props.loading) return null

    return (
      <div className="container-fluid">
        <div className="col-md-12 mx-auto">
          <div className="row">
            <h4 className="my-3">Campaigns</h4>
          </div>
          <div className="row align-items-center mb-4 mr-4">
            <div className="col-6 d-flex align-items-center">
              <NavTabs currentTab={this.state.tab} tabs={tabs} onChangeTab={this.onChangeTab} />
              <input checked={this.state.filterBlankPriority} className="ml-3 mr-1" type="checkbox" onChange={this.onFilterBlankPriority} />Filter blank priority
            </div>
            <div style={{ flexGrow: 1 }} />

            <div className="btn-group btn-group-toggle" data-toggle="buttons">
              <label key="select-tree-view" className={`btn btn-primary ${this.state.contentType === 'tree' ? 'active' : ''}`}>
                <input autoComplete="off" id="option1" name="options" type="radio" onChange={() => this.setState({ contentType: 'tree' })} />
                Tree View
              </label>
              <label key="select-table-view" className={`btn btn-primary ${this.state.contentType === 'table' ? 'active' : ''}`}>
                <input autoComplete="off" id="option2" name="options" type="radio" onChange={() => this.setState({ contentType: 'table' })} />
                List View
              </label>
            </div>
          </div>

          {this.renderContent()}

          <SectionSelect
            multi
            label="Show Columns"
            labelKey="label"
            options={this.tableColumnOptions(this.state.allTableColumns)}
            value={this.tableColumnOptions(this.state.selectedTableColumns)}
            valueKey="value"
            onChange={(value) => this.onColumnsChange(value)}
          />

          {this.writeAccess() && <button className="btn btn-primary" onClick={this.createCampaign}>Create Campaign</button>}
          <button className="btn btn-primary ml-2" onClick={this.managePhoneNumbers}>Manage Campaign Phone Numbers</button>
        </div>
      </div>
    )
  }
}

function mapStateToProps(state) {
  return {
    campaigns: state.campaigns,
    campaignSegments: state.campaignSegments.info,
    roles: state.user.roles,
    loading: state.user.loading,
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ changeModule, getCampaigns, getCampaignSegments }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(CampaignsContainer)
