import axios from 'axios'

export default function startNewMarketImportJob(marketCode, validationOptions) {
  return function(dispatch) {
    dispatch({ type: 'LOADING_NEW_MARKET_IMPORT_JOB', loading: true })
    axios.post(`${process.env.REACT_APP_GARCON_API}/offers/markets/import/${marketCode}`, {validation_options: validationOptions})
      .then((response) => {
        dispatch({ type: 'START_NEW_MARKET_IMPORT_JOB', payload: response.data })
        dispatch({ type: 'LOADING_NEW_MARKET_IMPORT_JOB', loading: false })
      })
      .catch((error) => {
        dispatch({ type: 'SHOW_ERROR', payload: { response: error.response, message: error.response.data.error } })
        dispatch({ type: 'LOADING_NEW_MARKET_IMPORT_JOB', loading: false })
        throw error
      })
  }
}
