import React, { useEffect, useState } from 'react'
import ReactTable from 'react-table'
import isEmpty from 'lodash/isEmpty'
import sortBy from 'lodash/sortBy'
import startCase from 'lodash/startCase'
import SectionSelect from '../../../Shared/SectionSelect'
import tableColumnsChange from '../../../../utils/tableColumnsChange'

const COLUMNS = [
  'plan_code',
  'plan_name',
  'isActive',
  'contract_type_code',
  'contract_duration',
]

const PlansTable = ({
  tab,
  loadingPlans,
  plans,
  deactivateAllPlans,
}) => {
  const [defaultTableColumns, setDefaultTableColumns] = useState([])
  const [selectedTableColumns, setSelectedTableColumns] = useState([])

  const header = (column) => {
    if (column === 'is_active') {
      return 'Active'
    } else if (column === 'contract_type_code') {
      return 'Structure'
    } else if (column === 'contract_duration') {
      return 'Term Length'
    } else {
      return startCase(column)
    }
  }

  const tableColumns = () => {
    const columns = []
    const sortedColumns = sortBy(Object.keys(plans[0]), (element) => {
      const rank = {
        plan_code: 1,
        plan_name: 2,
        contract_type_code: 3,
        contract_duration: 4,
        is_default: 5,
        isActive: 6,
      }
      return rank[element]
    })
    sortedColumns.forEach(column => {
      if (COLUMNS.includes(column)) {
        let tableColumn = {
          Header: header(column),
          id: column,
          accessor: column,
          filterAll: true,
        }
        if (column === 'plan_code' || column === 'plan_name') tableColumn = { ...tableColumn, width: 400 }
        columns.push(tableColumn)
      }
    })
    return columns
  }

  useEffect(() => {
    if (!isEmpty(plans)) {
      const columns = tableColumns()
      setDefaultTableColumns(columns)
      setSelectedTableColumns(columns)
    }
  }, [plans])

  const tableColumnOptions = (columns) => {
    return columns.map(column => {
      return {
        label: column.Header,
        value: column
      }
    })
  }

  const onColumnsChange = (options) => {
    const updatedTableColumns = tableColumnsChange(options, defaultTableColumns)
    setSelectedTableColumns(updatedTableColumns)
  }

  const handleSectionSelect = (plan) => {
    window.open(`/plans/${plan.plan_code}`, '_blank', 'noopener, noreferrer')
  }

  const style = { height: '500px' }

  if (isEmpty(plans) && !loadingPlans) {
    return <h5 className="mb-4">No plans found</h5>
  }

  if (isEmpty(plans) || loadingPlans) {
    return <h5 className="mb-4">Loading {tab} plans...</h5>
  }

  return (
    <div className="mb-4">
      <h5>Found {plans.length} plans.</h5>
      <ReactTable
        className="-striped -highlight mb-3"
        columns={selectedTableColumns}
        data={plans}
        defaultPageSize={100}
        getTdProps={(s, r, c) => {
          if (c.id === 'is_active') {
            return {
              style: {
                cursor: 'pointer',
              },
            }
          } else if (c.id !== '_selector') {
            return {
              onClick: () => handleSectionSelect(r.original),
              style: {
                cursor: 'pointer',
              },
            }
          } else {
            return {}
          }
        }}
        keyField="plan_code"
        style={style}
      />
      <div className="mb-3"><button className="btn btn-danger" onClick={deactivateAllPlans}>Deactivate plans</button></div>
      <SectionSelect
        multi
        label="Show Columns"
        labelKey="label"
        options={tableColumnOptions(defaultTableColumns)}
        value={tableColumnOptions(selectedTableColumns)}
        valueKey="value"
        onChange={(value) => onColumnsChange(value)}
      />
    </div>
  )
}

export default PlansTable
