/*

The purpose of this function is to create the filter options for display in the drop down options.
Each condition is made up of a subject, predicate, and object.
First we're creating an array of just conditions, separate from the rules they belong to making it easier to loop through the data.
Then we loop through each condition, adding them to a lookup map to be referenced in the Filter component.

Structure of conditionLookup:
{
	subject: {
		predicate: [objects],
	},
}

*/

const createFilter = (compRules) => {
	const conditionLookup = {}
	const allConditions = compRules.map((rule, idx) => rule.rules).flat()

	allConditions.forEach((condition) => {
		const subjectKey = conditionLookup[condition.subject] // find current condition key in conditionLookup, otherwise null

		let conditionObject = Array.isArray(condition.object) ? condition.object : [String(condition.object)] // object starts Array, Boolean, Number, Null, or String

		if (!subjectKey) { // if we do not have a value for the current subject in our lookup, create it
			conditionLookup[condition.subject] = {
				[condition.predicate]: conditionObject
			}
		} else {
			const predicate = subjectKey[condition.predicate]

			conditionObject.forEach(object => { 
				if (predicate && !predicate.includes(object)) { // this removes duplicates from object array if predicate key already exists
				  predicate.push(object)
				} else if (!predicate) { // if predicate doesn't exist, create it and set it to the original conditionObject
					subjectKey[condition.predicate] = conditionObject
				}
			  })
		}
	})

	return conditionLookup
}

export { createFilter }