import axios from 'axios'
import { getMarketGeos } from './getMarketGeos'

export const deleteMarketGeo = (marketGeo) => {
  return async (dispatch) => {
    const params = {
      market_geo_id: marketGeo.market_geo_id
    }
    try {
      await axios.delete(`${process.env.REACT_APP_GARCON_API}/offers/market_geos/${marketGeo.market_geo_id}`, params)
        dispatch({ type: 'SHOW_ERROR', payload: { headline: 'SUCCESS!', message: 'Market Geo has been deleted' } })
        dispatch(getMarketGeos())
    } catch (error) {
      dispatch({ type: 'SHOW_ERROR', payload: { message: 'An error occured deleting market geo. Zip code may be in use.' } })
      throw error
    }
  }
}
