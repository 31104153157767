import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useParams } from 'react-router-dom'
import CompRunDataColumns from './CompRunDataColumns'
import { getCompRun, exportCompRun } from '../../actions/Compensation/getCompRun'
import { getCompRunData } from '../../actions/Compensation/getCompRunData'
import CompStatusHistoryModal from './CompStatusHistoryModal'
import ReactTable from 'react-table'


const CompRunContainer = () => {
  const selectedCompRun = useParams()
  const compRun = useSelector(state => state.compRun)
  const compRunDataRaw = useSelector(state => state.compRunData)
  const dispatch = useDispatch()
  const [statusModal, setStatusModal] = useState(false, true)
  const [message, setMessage] = useState('')

  const sourceOrChannelPartner = compRun.channel_partner_code ? 'CHANNEL_PARTNER_ORDER' : 'ORDER'
  const typeDisplay = compRun.channel_partner_code ? 'Channel Partner Order' : 'Order'
  const statsJson = compRun.stats_json

  useEffect(() => {
    dispatch(getCompRun(selectedCompRun.compRunId))
    dispatch(getCompRunData(selectedCompRun.compRunId))
  }, [])

  const compRunData = () => {
    const modified = compRunDataRaw.map(p => {
      if (p.comp_rule != null) {
        p.comp_rule_stringified = p.comp_rule.rules.map(r => r.subject + ' ' + r.predicate + ' ' + r.object + ',  \n')
      }
      else {
        p.comp_rule_stringified = ''
      }
      return p
    })
    return modified
  }

  const payablePercentage = () => {
    return Math.round(statsJson?.[sourceOrChannelPartner].pay_percent * 100) || 0
  }

  const handleExport = () => {
    dispatch(exportCompRun(compRun.comp_run_id))
  }

  const openStatusHistoryModal = () => {
    setMessage('')
    setStatusModal(true)
  }

  const closeStatusHistoryModal = () => {
    setMessage('')
    setStatusModal(false)
  }
  const updateMessage = () => {
    setMessage('Updating Status...')
  }

  const successMessage = () => {
    setMessage('Succesfully Updated Comp Run Status!')
  }

  const errorMessage = () => {
    setMessage('Unable to update status')
  }

  return (
    <div>
      <div className="row">
        <div className="col-md-6">
          <h2>Compensation Run {compRun.comp_run_id}</h2>
          <h3>Inspire Energy Holdings, LLC</h3>
          <div>Status: {compRun.comp_run_status_code}</div>
          <p>Sales Compensation Report</p>
        </div>
        <div className="col-md-3">
          <div className="font-weight-bold">Channel Partner: {compRun.comp_run_schedule_name}</div>
          <div className="font-weight-bold">Comp Type: </div>
          <div>Total {typeDisplay} Comps: {statsJson?.[sourceOrChannelPartner].total_comps}</div>
          <div>Payable {typeDisplay} Comps: {statsJson?.[sourceOrChannelPartner].total_payable_comps}</div>
          <div>Total Comp Amount: ${compRun.total_comp_amt}</div>
        </div>
        <div className="col-md-3">
          <div>{typeDisplay} Pay %: {payablePercentage()}</div>
          <div>Average: {compRun.avg_comp_amt}</div>
          <div>Average Annual Usage: {compRun.avg_annual_usage}</div>
          <div>$ / kWh : { compRun.total_comp_amt / compRun.avg_annual_usage } / kWh </div>
          <div>{typeDisplay} Clawbacks: {statsJson?.[sourceOrChannelPartner].total_clawbacks}</div>
        </div>
      </div>
      <div className="row">
        <div className="col-md-12">
          <div className="text-right">
            <button className="btn btn-primary btn-md mr-2" type="button" onClick={() => openStatusHistoryModal()}> Show Status History </button>
            <button className="btn btn-primary btn-md mr-2" type="button" onClick={() => handleExport()}>Export </button>
          </div>
        </div>
      </div>

      <ReactTable
        filterable
        className="-striped -highlight mt-3 mb-3 mr-4"
        columns={CompRunDataColumns}
        data={compRunData()}
        defaultPageSize={20}
        keyField="comp_run_id"
      />

      {statusModal &&
      <CompStatusHistoryModal
        closeStatusHistoryModal={closeStatusHistoryModal}
        compRun={compRun}
        errorMessage={errorMessage}
        message={message}
        show={statusModal}
        successMessage={successMessage}
        updateMessage={updateMessage}
      />
      }
    </div>
  )
}

export default CompRunContainer
