import React, { useEffect } from 'react'
import { changeModule  } from '../../actions/changeModule'
import { useDispatch } from 'react-redux'
import CaseTypesContainer from './CaseTypesContainer'
import { getCaseTypes } from '../../actions/caseTypes/getCaseTypes'

const CaseTypes = () => {
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(changeModule('Case Types'))
    dispatch(getCaseTypes())
  }, [])

  return (
    <div className="container-fluid">
      <CaseTypesContainer />
    </div>
  )
}

export default CaseTypes
