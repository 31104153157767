import React, { useState, useEffect } from 'react'
import Select from 'react-select'
import { getRewardStatusCodes } from '../../apis/rewards'
import { updateRewardStatusCodes } from '../../apis/rewards'
import {changeModule} from "../../actions/changeModule"
import { useDispatch } from 'react-redux'


const RewardStatus = () => {
  const dispatch = useDispatch()
  const [error, setError] = useState(null)
  const [success, setSuccess] = useState(null)
  const [rewardIds, setRewardIds] = useState([])
  const [rewardStatusCode, setRewardStatusCode] = useState("")
  const [rewardStatusCodes, setRewardStatusCodes] = useState([])

  useEffect(() => {
    dispatch(changeModule('Reward Status'))
    getRewardStatusCodes(setRewardStatusCodes)
  }, [])

  const submitForm = async (event) => {
    event.preventDefault()

    const rewardIdArray = rewardIdsToArray(rewardIds)

    const response = await updateRewardStatusCodes(rewardIdArray, rewardStatusCode)
      .catch((error) => {
        setSuccess(false)
        setError(error)
        return error
      })

    if (response.status === 200) {
      setRewardIds("")
      setRewardStatusCode("")
      setError(null)
      setSuccess(true)
    }
  }

  function rewardIdsToArray(rewardIds) {
    return rewardIds.split(/[^\d]/).filter(function(item) {
      return /\d/.test(item)
    })
  }

  return (
    <>
      <h1>Reward Status</h1>
        <div className="mt-2 mb-2">
          <h6 className="mt-4">Reward Status Code</h6>
          <Select
            options={rewardStatusCodes.map(rewardStatusCode => ({ label: rewardStatusCode, value: rewardStatusCode })).sort((a, b) => a.label.localeCompare(b.label))}
            onChange={event => setRewardStatusCode(event.value)}
            value={rewardStatusCode}
          />
          <h6 className="mt-4">Reward IDs (Separated by any non-numeric character)</h6>
            <form>
              <div className="mb-4 input-group">
                <input
                  className="form-control"
                  type="textarea"
                  placeholder="Type one or more Reward IDs"
                  onChange={event => setRewardIds(event.target.value)}
                  value={rewardIds}
                />
              </div>
            </form>
          <span className="mr-4 input-group-btn">
            <button className="btn btn-primary" onClick={submitForm}>Update Reward Status</button>
          </span>
          {error ? <span>Error: {error.message}</span> : null}
          {success ? <span>Success: Reward IDs updated!</span> : null}
        </div>
    </>
  )
}

export default RewardStatus