import React from 'react'
import Modal from '../Modal'

const InvalidPTCModal = ({ closeModal, show, handleInvalidatePTC  }) => {

  return (
    <div>
      <Modal closeModal={closeModal} customStyles={{ width: '40%', height: '30%' }} title="Make PTC Invalid Confirmation" visible={show}>
        <div className="mt-4">
          <h5>Are you sure you want to make this PTC Rate Invalid?</h5>
        </div>
        <div>
          <button className="btn btn-block btn-primary btn-md mt-5" onClick={() => handleInvalidatePTC()}>Yes, I'm sure!</button>
        </div>
      </Modal>
    </div>
  )
}

export default InvalidPTCModal;
