export function disclosures(state = { all: [], disclosureDetail: [], memberCount: 1, sending: false }, action){
  switch (action.type) {
  case 'GET_DISCLOSURES':
    return { ...state, all: action.payload }
  case 'GET_DISCLOSURE':
    return { ...state, disclosureDetail: action.payload }
  case 'SET_SENDING':
    return { ...state, sending: action.payload }
  case 'GET_MEMBERCOUNT':
    return { ...state, memberCount: action.payload.count }
  case 'CLEAR_DISCLOSURE':
    return { ...state, disclosureDetail: [], memberCount: 1 }
  default:
    return state
  }
}
