import React from 'react'
import MinMaxConfig from './MinMaxConfig'

const MinMaxConfigContainer = ({
  bucketChoice,
  minMaxBuckets,
  setMinMaxBuckets,
  updateRow
}) => {

  const addRow = (e) => {
    e.preventDefault()

    const updatedArray = minMaxBuckets.concat({
      index: minMaxBuckets.length + 1,
      bucketChoice: '',
      modifier: ''
    })
    setMinMaxBuckets(updatedArray)
  }

  const removeRow = (e, index) => {
    e.preventDefault()

    const updatedBuckets =
      minMaxBuckets.filter((bucket) => bucket.index !== index)
        .map((_bucket, _index) => {
          return { ..._bucket, index: _index + 1 }
        })

    updateRow('bucketChoice', bucketChoice, updatedBuckets)
    setMinMaxBuckets(updatedBuckets)
  }

  const updateMinMaxBuckets = (field, value, index) => {
    const updatedBuckets = minMaxBuckets.map(bucket => {
      if (bucket.index === index) {
        if (field === 'modifier' && value === 'no modifier' && bucket.bucketAmount) {
          return { bucketChoice: bucket.bucketChoice, [field]: value, index }
        } else {
          return { ...bucket, [field]: value }
        }
      } else {
        return bucket
      }
    })

    updateRow('bucketChoice', bucketChoice, updatedBuckets)
    setMinMaxBuckets(updatedBuckets)
  }

  return (
    <div>
      {
        minMaxBuckets.map((minMaxBucket, index) => {
          return (
            <MinMaxConfig
              key={index}
              minMaxBucket={minMaxBucket}
              removeRow={removeRow}
              updateMinMaxBuckets={updateMinMaxBuckets}
            />
          )
        })
      }
      <p className="mt-3">
        <button
          className="btn mb-2"
          onClick={(e) => addRow(e)}
        >
          + Add Row
        </button>
      </p>
    </div>
  )
}

export default MinMaxConfigContainer
