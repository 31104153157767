import React, { useEffect } from 'react'
import { changeModule } from '../../actions/changeModule'
import { useDispatch } from 'react-redux'
import { getPTC } from '../../actions/pricing/getPTC'
import getMarkets from '../../actions/getMarkets'
import PriceToCompareContainer from './PriceToCompareContainer'

const PriceToCompare = () => {
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(changeModule('Price to Compare'))
    dispatch(getPTC())
    dispatch(getMarkets())
  }, [])

  return (
    <div className="container-fluid">
      <PriceToCompareContainer />
    </div>
  )
}

export default PriceToCompare;
