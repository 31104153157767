import React, { Component } from 'react'
import { connect } from 'react-redux'
import { getCompRules } from '../../actions/Compensation/getCompRules'
import CompRule from './CompRule'
import { Link } from 'react-router-dom'
class CompRunScheduleContainer extends Component {
  constructor(props) {
    super(props)
  }
  componentDidMount() {
    if (this.props.match.params.compRunScheduleCode) {
      this.props.getCompRules(this.props.match.params.compRunScheduleCode)
    }
  }

  render() {
    return (
      <div>
        <h1>COMPENSATION SCHEDULE FOR {this.props.match.params.compRunScheduleCode}</h1>
        <Link className="btn btn-primary text-decoration-none py-1 px-4 mb-4" to={`/compensation/schedules/${this.props.match.params.compRunScheduleCode}/edit`}>Edit</Link>
        {(this.props.compRules || []).map((compRule, index) => (
          <div key={index}>
            <CompRule compRule={compRule} />
          </div>
        ))}
      </div>
    )
  }
}

function mapStateToProps(state) {
  return {
    compRules: state.compRules,
  }
}

const mapDispatchToProps = {
  getCompRules,
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CompRunScheduleContainer)
