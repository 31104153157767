import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import moment from 'moment'
import history from '../../../history'
import { getRolloffExperiment } from '../../../apis/rolloffExperiments'
import Loading from '../../Shared/Loading'
import ViewVariations from './ViewVariations'

const ViewExperiment = () => {
  const [experiment, setExperiment] = useState(null)
  const [loading, setLoading] = useState(true)
  const { experiment_id } = useParams()

  useEffect(() => {
    getRolloffExperiment(experiment_id, setExperiment)
      .then(() => {
        setLoading(false)
      })
  }, [])

  return (
    <div className="container-fluid">
      {experiment &&
        <>
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <h4 className="my-3">
              {experiment?.experiment_name}
            </h4>
            <button
              className="btn btn-primary btn-md mb-2"
              onClick={() => history.push(`/rolloff_experiments/edit/${experiment_id}`)}
            >
              Edit
            </button>
          </div>

          <div className="card mb-4">
            <div className="card-header" style={{ display: 'flex', justifyContent: 'space-between' }}>
              <strong>Details</strong>
            </div>
            <div className="card-body">
              <div className="row">
                <div className="col-4">ID: {experiment?.experiment_id}</div>
                <div className="col-4">Created Date: {moment(experiment?.created_at).format("MM/DD/YYYY")}</div>
              </div>
              <div className="row mt-4">
                <div className="col-4">Start Date: {moment(experiment?.experiment_start_dt).format("MM/DD/YYYY")}</div>
                <div className="col-4">End Date: {experiment?.experiment_end_dt ? moment(experiment?.experiment_end_dt).format("MM/DD/YYYY") : ''}</div>
              </div>
              <div className="row mt-4">
                <div className="col-12">Description: {experiment?.experiment_desc}</div>
              </div>
            </div>

            <div className="card-header">
              <strong>Variations</strong>
            </div>
            <div className="card-body">
              <ViewVariations experiment={experiment} />
            </div>
          </div>
        </>
      }
      {loading && <Loading />}
    </div >
  )
}

export default ViewExperiment
