import axios from 'axios'

export function getCompRun(compRunId){
  return function(dispatch){
    axios.get(`${process.env.REACT_APP_GARCON_API}/compensation/runs/run/${compRunId}`)
      .then((response) => {
        dispatch({ type: 'COMP_RUN', payload: response.data })
      })
      .catch((error) => {
        dispatch({ type: 'COMP_SHOW_ERROR', payload: { message: 'An error occured getting getCompRun data.' } })
        throw error
      })
  }
}

export function exportCompRun(compRunId) {
  return function(dispatch, getState){
    const user = getState().user
    axios.get(`${process.env.REACT_APP_GARCON_API}/compensation/runs/run/${compRunId}/export/excel?to_email=${user.email}`)
      .then((response) => {
        dispatch({ type: 'SHOW_ERROR', payload: { response, headline: 'Creating Excel File', message: `Processing comp run export. We'll send the completed comp run to ${user.email} within the next few minutes. Status: ${response.status}` } })
      })
      .catch((error) => {
        dispatch({ type: 'SHOW_ERROR', payload: { response: error.response, message: 'An error occured exporting this comp run.' } })
        throw error
      })
  }
}
