import axios from 'axios'

export default function getSegmentCampaigns(segmentId) {
  return function(dispatch) {
    dispatch({ type: 'GETTING_SEGMENT_CAMPAIGNS', payload: true })
    axios.get(`${process.env.REACT_APP_GARCON_API}/internal/segments/${segmentId}/campaigns`)
      .then((response) => {
        dispatch({ type: 'GET_SEGMENT_CAMPAIGNS', payload: response.data })
        dispatch({ type: 'GETTING_SEGMENT_CAMPAIGNS', payload: false })
      })
      .catch((error) => {
        dispatch({ type: 'SHOW_ERROR', payload: { response: error.response, message: 'An error occured getting campaigns.' } })
        dispatch({ type: 'GETTING_SEGMENT_CAMPAIGNS', payload: false })
        throw error
      })
  }
}
