import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { getOffices } from '../../actions/getOffices'
import ReactTable from 'react-table'
import matchSorter from 'match-sorter'
import { updateOffice } from '../../actions/updateOffice'

class OfficeTable extends Component {
  constructor (props) {
    super(props)
    this.state = {
      editable: false,
      selected_office_code: '',
      call_recording_required: false,
      is_active: true,
    }
  }

  componentDidMount = () => {
    this.props.getOffices()
  }

  saveEdit = () => {
    this.props.updateOffice(this.state)
    this.setState({ editable: false })
  }

  cancelEdit = () => {
    this.setState({ editable: false })
  }

  edit = (row) => {
    this.setState({
      editable: true,
      selected_office_code : row.office_code,
      is_active: row.is_active,
      call_recording_required: row.call_recording_required,
    })
  }

  render() {
    const columns = [{
      Header: 'Office Name',
      accessor: 'office_code',
      filterMethod: (filter, rows) => (
        matchSorter(rows, filter.value, { keys: ['office_code'] })
      ),
      filterAll: true,
    }, {
      Header: 'Source Code',
      accessor: 'source_code',
      filterMethod: (filter, rows) => (
        matchSorter(rows, filter.value, { keys: ['source_code'] })
      ),
      filterAll: true,
    },{
      Header: 'Status',
      id: 'is_active',
      accessor: row => {
        if (this.state.editable && row.office_code === this.state.selected_office_code){
          return (
            <select required className="custom-select w-100" value={this.state.is_active} onChange={e => this.setState({ is_active: e.target.value })}>
              <option value={true}>Active</option>
              <option value={false}>Inactive</option>
            </select>)
        }
        return row.is_active ? 'Active' : 'Inactive' },
      filterMethod: (filter, rows) => (
        matchSorter(rows, filter.value, { keys: ['is_active'] })
      ),
      filterAll: true,
    }, {
      Header: 'Call Recording Required',
      id: 'call_recording_required',
      accessor: row => {
        if (this.state.editable && row.office_code === this.state.selected_office_code){
          return (
            <select required className="custom-select w-100" value={this.state.call_recording_required} onChange={e => this.setState({ call_recording_required: e.target.value })}>
              <option value={true}>True</option>
              <option value={false}>False</option>
            </select>)
        }
        return row.call_recording_required ? 'Yes' : 'No' },
      filterMethod: (filter, rows) => (
        matchSorter(rows, filter.value, { keys: ['call_recording_required'] })
      ),
      filterAll: true,
    }, {
      Header :'Edit',
      id: 'editRow',
      accessor: row => { return this.state.editable && row.office_code === this.state.selected_office_code ?
        <div>
          <button className="btn btn-primary rounded mr-2" type="button" onClick={this.saveEdit}>Save</button>
          <button className="btn btn-secondary rounded" type="button" onClick={this.cancelEdit}>Cancel</button>
        </div>
        :
        <button className="btn btn-primary-outline rounded" type="button" onClick={() => this.edit(row)}>Edit</button>
      },
      filterable: false ,
    },
    ]

    return (
      <div className="office-table col-md-10 mx-auto">
        <ReactTable filterable className="-striped -highlight mb-3" columns={columns} data={this.props.offices} defaultPageSize={50} keyField="office_code" />
      </div>
    )
  }
}

function mapStateToProps(state) {
  return {
    offices: state.offices,
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ updateOffice, getOffices }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(OfficeTable)
