import React from 'react'
import TreatmentBucket from './TreatmentBucket'
import TreatmentMethodology from './TreatmentMethodology'
import RateCap from './RateCap'

const TreatmentBuckets = ({
  bucketDelta,
  buckets,
  hasRateCap,
  rateCapData,
  setHasRateCap,
  updateRateCap,
  setBuckets,
  setBucketDelta
}) => {
  const addBucket = (e) => {
    e.preventDefault()

    setBuckets(
      buckets.concat({
        index: buckets.length + 1,
        min: '',
        max: '',
        planChoice: null,
        plan: null,
        bucketData: {}
      }))
  }

  const removeBucket = (e, index) => {
    e.preventDefault()

    const updatedBuckets =
      buckets.filter((bucket) => bucket.index !== index)
        .map((_bucket, _index) => {
          return { ..._bucket, index: _index + 1 }
        })

    setBuckets(updatedBuckets)
  }

  const updateBuckets = (payload, index, minMaxBuckets) => {
    const updatedBuckets = buckets.map((bucket) => {
      if (bucket.index === index) {
        const field = Object.keys(payload)[0]
        const value = payload[field]
        if (minMaxBuckets) {
          return {
            ...bucket, bucketData: {
              bucketChoice: { type: value, array: minMaxBuckets }
            }
          }
        } else if (field === 'bucketChoice' || field === 'modifier' || field === 'bucketAmount') {
          return {
            ...bucket, bucketData: { ...bucket.bucketData, [field]: value }
          }
        } else {
          return { ...bucket, ...payload }
        }
      } else {
        return bucket
      }
    })

    // Handling ranges for buckets, i.e. ( -Infinity - Infinity )
    const field = Object.keys(payload)[0]
    if (field === 'min' || field === 'max') {
      const _updatedBuckets = updatedBuckets.map(bucket => {
        let currentMax;
        // previous bucket
        if (bucket.index === index - 1) {
          if (field && field === 'min') {
            currentMax = currentMax && payload.min > currentMax ? payload.min : currentMax ? currentMax : payload.min
            return { ...bucket, max: payload.min }
          }
        }
        // current bucket
        else if (bucket.index === index) {
          currentMax = field === 'max' ? payload.max : bucket.max
          return { ...bucket, ...payload }
        }
        else if (bucket.index > index) {
          // future buckets
          if (field === 'max') {
            return { ...bucket, min: currentMax > payload.max ? currentMax : payload.max }
          }
        }
        return bucket
      })
      setBuckets(_updatedBuckets)
    } else {
      setBuckets(updatedBuckets)
    }
  }

  return (
    <div className="card mb-4">
      <div className="card-header">
        <strong>Treatment Buckets</strong>
      </div>
      <div className="card-body">
        <p className="small">Create any number of buckets by entering values between -infinity and inifity below.
          Each buckets needs a plan and price treatment.</p>
        <p className="small">Please note that Next Contract CTS does not apply to natural gas.</p>
        {buckets.length > 1 &&
          <TreatmentMethodology
            bucketDelta={bucketDelta}
            setBucketDelta={setBucketDelta}
          />
        }

        <RateCap
          hasRateCap={hasRateCap}
          rateCapData={rateCapData}
          setHasRateCap={setHasRateCap}
          updateRateCap={updateRateCap}
        />

        {buckets && buckets.map((bucket, index) => {
          return (
            <TreatmentBucket
              bucket={bucket}
              amountOfBuckets={buckets.length}
              key={index}
              removeBucket={removeBucket}
              updateBuckets={updateBuckets}
            />)
        })}

        <p className="mt-3">
          <button
            className="btn mb-2"
            onClick={(e) => addBucket(e)}
          >
            + Add Bucket
          </button>
        </p>
      </div>
    </div>
  )
}

export default TreatmentBuckets
