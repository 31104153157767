import { useEffect } from 'react'
import { useAuth0 } from '@auth0/auth0-react'
import { useDispatch } from 'react-redux'
import { clearUser } from '../actions/clearUser'

const Logout = () => {
  const dispatch = useDispatch()
  const { loginWithRedirect } = useAuth0()

  useEffect(() => {
    dispatch(clearUser())
    loginWithRedirect()
  }, [])

  return (null)
}

export default Logout
