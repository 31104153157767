import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { uploadCsvList, getZipCode } from '../../actions/territory'
import { changeModule } from '../../actions/changeModule'

class ZipCodeContainer extends Component {

  componentDidMount() {
    this.props.changeModule('Territory')
  }

  render() {
    const loading = this.props.status === 'LOADING'
    return (
      <div className="container-fluid">
        <h5>Create CSV Territory</h5>
        <div className="row pb-3">
          <div className="col-md-6 mb-3">
            <label>Zip Code</label>
            <input
              className="form-control"
              disabled={loading}
              maxLength="5"
              placeholder="Zip Code"
              type="text"
              value={this.props.zipCode}
              onChange={(event) => this.props.getZipCode(event.target.value)}
            />
          </div>
        </div>
        <button className={loading ? 'btn' : 'btn btn-primary'} disabled={loading} onClick={this.props.uploadCsvList}>
          {loading ? 'Uploading List...' : 'Create CSV Download'}
        </button>
      </div>
    )
  }
}

function mapStateToProps(state) {
  return {
    status: state.territory.status,
    zipCode: state.territory.zipCode,
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ changeModule, uploadCsvList, getZipCode }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(ZipCodeContainer)
