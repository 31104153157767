import React from 'react'
import { useTable, useFilters, usePagination, useSortBy, useResizeColumns, useExpanded, useBlockLayout } from 'react-table-v7'
import Table from 'react-bootstrap/Table'
import { Row, Col, Button } from 'react-bootstrap'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faArrowDown, faArrowUp, faArrowsAltV } from "@fortawesome/pro-light-svg-icons"
import { DefaultColumnFilter } from '../utils/filters'

const ReactTableV7 = ({ columns, data, handleClick, canHover }) =>  {

  const filterTypes = React.useMemo(
    () => ({
      // Add a new fuzzyTextFilterFn filter type.
      // Or, override the default text filter to use
      // "startWith"
      text: (rows, id, filterValue) => {
        return rows.filter(row => {
          const rowValue = row.values[id]
          return rowValue !== undefined
            ? String(rowValue)
                .toLowerCase()
                .startsWith(String(filterValue).toLowerCase())
            : true
        })
      },
      multiple: (rows, id, filterValue) => {
        // id is the columns header ex: 'case_type_description', 'category_name', 'channel'
        // filterValue is the array of selected options from the multi select
        if (filterValue === undefined || filterValue.length === 0) {
          return rows
        }
        // this returns something like ["other", "refund", "pricing"]
        // row.values[id] gets the value for the column
        // this returns all rows whos value matched the filterValue
        const lowerCaseFilterValues = filterValue.map(val => String(val.value).toLowerCase())
        return rows.filter(row => {
          const rowValue = String(row.values[id]).toLowerCase()
          return rowValue !== undefined
            ? lowerCaseFilterValues.includes(rowValue)
            : true
        })
      },
    }),
    []
  )


  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state: { pageIndex, pageSize },
    } = useTable(
    {
    columns: columns,
    data: data,
    initialState: {
      pageIndex: 0,
      pageSize: 20,
    },
    filterTypes,
    defaultColumn: { Filter: DefaultColumnFilter }
    },
    useFilters,
    useBlockLayout,
    useResizeColumns,
    useSortBy,
    useExpanded,
    usePagination,
  )

  return (
    <>
      <Table striped bordered hover={canHover} responsive size="sm" {...getTableProps()}>
        <thead>
          {headerGroups.map(headerGroup => (
            <tr {...headerGroup.getHeaderGroupProps()}>
            {headerGroup.headers.map(column => (
              <th scope="col" {...column.getHeaderProps([
                { className: column.className,
                  style: column.style,
                },
              ])}>
                <div {...column.getSortByToggleProps()} >
                  {column.render('Header')}
                  <span>&nbsp;
                    {column.isSorted
                      ? column.isSortedDesc
                        ? <FontAwesomeIcon icon={faArrowUp} fixedWidth />
                        : <FontAwesomeIcon icon={faArrowDown} fixedWidth />
                      : <FontAwesomeIcon icon={faArrowsAltV} fixedWidth />
                    }
                  </span>
                </div>
                <div className={`resizer ${
                    column.isResizing ? 'isResizing' : ''
                  }`}
                  {...column.getResizerProps()}
                />
                <div>
                  {column.canFilter ? column.render('Filter') : null}
                </div>
              </th>
            ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {page.map(row => {
            prepareRow(row)
            return (
              <tr {...row.getRowProps( { style: { cursor: "pointer" }})}
                onClick={() => handleClick && handleClick(row.original)}>
                {row.cells.map(cell => {
                  return (
                    <td {...cell.getCellProps()}>
                    {cell.render('Cell')}
                    </td>
                  )
                })}
              </tr>
            )
          })}
        </tbody>
      </Table>
      <div>
        <Row>
          <Col>
            <Button onClick={() => gotoPage(0)} disabled={!canPreviousPage}>
              {'<<'}
            </Button>{' '}
            <Button onClick={() => previousPage()} disabled={!canPreviousPage}>
              {'<'}
            </Button>{' '}
            <Button onClick={() => nextPage()} disabled={!canNextPage}>
              {'>'}
            </Button>{' '}
            <Button onClick={() => gotoPage(pageCount - 1)} disabled={!canNextPage}>
              {'>>'}
            </Button>{' '}
          </Col>

          <Col>
            <span >
              Page{' '}
              <strong>
                {pageIndex + 1} of {pageOptions.length}
              </strong>{' '}
            </span>
            <span >
              | Go to page:{' '}
              <input
                className="form-control"
                type="number"
                defaultValue={pageIndex + 1}
                onChange={e => {
                  const page = e.target.value ? Number(e.target.value) - 1 : 0
                  gotoPage(page)
                }}
                style={{ width: '50px', display: "inherit", lineHeight: "1.5" }}
              />
            </span>{' '}
          </Col>
          <Col>
            <select
              className="custom-select d-block"
              style={{ lineHeight: "0.75", padding: "0rem 1.75rem 0rem 0.75rem"  }}
              value={pageSize}
              onChange={e => {
                setPageSize(Number(e.target.value))
              }}
            >
            {[10, 20, 30, 40, 50].map(pageSize => (
              <option key={pageSize} value={pageSize}>
                Show {pageSize}
              </option>
            ))}
            </select>
          </Col>
        </Row>
      </div>
    </>
  )
}

export default ReactTableV7
