import React, { Component } from 'react'
import { updateCaseType } from '../../actions/caseTypes/updateCaseType'
import history from '../../history'
import Select from 'react-select'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'


class UpdateCaseType extends Component {
  constructor (props) {
    super(props)
    this.defaultState = {
      case_type_category_id: '',
      category_name: '',
      definition: '',
      is_active: true,
    }
    this.state = this.defaultState
  }

  componentDidMount = async () => {
    const selectedCaseType = await this.props.history.location.state.edit
    this.setState(selectedCaseType)
  }

  cancelUpdate = () => {
    history.push('/case_types')
  }

  handleUpdate = async(e) => {
    e.preventDefault()
    const response = await this.props.updateCaseType(this.state)
    if (response.status === 200) {
      this.setState({ message: 'Case type updated!' })
      this.setState(this.defaultState)
      setTimeout(() => {history.push('/case_types')}, 3000)
    }
  }

  render() {
    return (
      <div className="container-fluid col-md-10 mx-auto">
        <form>
          <h4 className="text-center">Update Case Type</h4>
          <br />
          <div className="row">
            <div className="col-md-3 mb-3">
              <label>Case Type Category ID</label>
              <input className="form-control" disabled={true} value={this.state.case_type_category_id} />
            </div>
            <div className="col-md-6 mb-3">
              <label>Case Type Category Name</label>
              <input className="form-control" disabled={true} value={this.state.category_name} />
            </div>
          </div>
          <div className="row">
            <div className="col-md-9 mb-3">
              <label>Definition</label>
              <input required className="form-control" value={this.state.definition} onChange={e => this.setState({ definition: e.target.value })} />
            </div>
            <div className="col-md-3 mb-3">
              <label>Status</label>
              <Select
                options={[
                  { value: true, label: 'Active' },
                  { value: false, label: 'Inactive' },
                ]}
                value={this.state.is_active}
                onChange={e => this.setState({ is_active: e.value })} />
            </div>
          </div>
          <div className="row">
            <div className="col-md-4">
              <button className="btn btn-secondary btn-md btn-block mt-4" type="button" onClick={this.cancelUpdate}>Cancel Update</button>
            </div>
            <div className="col-md-8">
              <button className="btn btn-primary btn-md btn-block mt-4" type="button" onClick={this.handleUpdate}>Update Case Type</button>
              <div className="valid-feedback d-block text-center mt-2">
                {this.state.message}
              </div>
            </div>
          </div>
        </form>
      </div>
    )
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ updateCaseType }, dispatch)
}
export default connect(null, mapDispatchToProps) (UpdateCaseType)
