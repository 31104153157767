const initialState = {
  vendorFulfillmentChannels: {},
  products: [],
}

export function rewards(state = initialState, action) {
  switch (action.type){
  case 'GET_VENDOR_FULFILLMENT_CHANNELS':
    return { ...state, vendorFulfillmentChannels: action.payload }
  case 'GET_PRODUCTS':
    return { ...state, products: action.payload }
  default:
    return state
  }
}
