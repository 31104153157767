import axios from 'axios'

export default function createLoyaltyProgram(payload) {
  return function (dispatch) {
    dispatch({ type: 'CREATING_REWARD', payload: true })
    axios.post(`${process.env.REACT_APP_GARCON_API}/loyalty_programs`, payload)
      .then((response) => {
        dispatch({ type: 'CREATE_LOYALTY_PROGRAM', payload: response.data })
      })
      .catch((error) => {
        dispatch({ type: 'CREATING_REWARD', payload: false })
        dispatch({ type: 'SHOW_ERROR', payload: { response: error.response, message: `An error occurred creating the loyalty program: ${error.response.data.error}` } })
      })
  }
}
