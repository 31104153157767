import React  from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { sendBatch } from '../../actions/disclosures/sendBatch.js'
import  Modal from '../Modal'
import moment from 'moment'
import LoadingDots from '../Animation/LoadingDots'

const DisclosureModal = ({ customStyles, deleteModal, disclosure, recipientCount, show }) => {

  const batchDetail = useSelector(state => state.disclosures.disclosureDetail)
  const sending = useSelector(state => state.disclosures.sending)
  const dispatch = useDispatch()
  const badgeStyle = { borderRadius: '1em', paddingLeft: '.8em', paddingRight: '.8em' }
  const success = { color: 'green' }
  const fail = { color: 'red' }

  const findUser = (data) => {
    if (data['sent_by_user']){
      return data['sent_by_user']['external_id'] || data['sent_by_user']['email']
    } else {
      return 'Unknown User'
    }
  }
  const sendDisclosure = (data) => {
    dispatch(sendBatch(data))
  }

  return (
    <Modal  closeModal={() => deleteModal(false)} customStyles={customStyles} title="Disclosure Detail" visible={show}>
      <div className="d-flex mt-3">
        <div className="mr-5">
          <h2>{disclosure['state']['state_name']} {disclosure['correspondence_type']['correspondence_type_name']}</h2>
          <span className="last-sent-span"> Sent {moment(disclosure['last_sent_dt']).fromNow(true)} ago </span>
          <hr />
          <h4>Preview {disclosure['date_range'] && <small> (Data for {disclosure['date_range']})</small> } </h4>
          <p><a href={disclosure['url']}>{disclosure['url']}</a></p>
          <br />
          <h5>History <span className="badge badge-secondary" style={badgeStyle}>{batchDetail.length}</span> </h5> 
          <table className="table" id="Disclosure History">
            <tbody>
              { batchDetail.length > 0 &&
              batchDetail.map((b, index) => (
                <tr key={index}>
                  <th scope="row">Sent {moment(b['sent_dt']).fromNow(true)} ago by {findUser(b)}</th>
                  <td style={success}><small>{b['sent_count']} Successful</small></td>
                  <td style={fail}><small>{b['failed_count']} Failed</small></td>
                </tr>
              ))
              }
            </tbody>
          </table>
          <button className="btn btn-primary btn-md mr-2" onClick={() => deleteModal(false)}>
          Close
          </button>
          { batchDetail &&
          <button className="btn btn-primary btn-md mr-2" onClick={() => sendDisclosure(disclosure)}>
            {sending &&
            <span>
              Sending <LoadingDots /> <span className="badge badge-light" style={badgeStyle}>{recipientCount} </span> </span>
            }
            {!sending &&
              <span> Send  <span className="badge badge-light" style={badgeStyle}>{recipientCount}</span> </span>}
          </button>
          }
        </div>
      </div>
    </Modal>
  )}

export default DisclosureModal
