import React from 'react'
import { useSelector } from 'react-redux'
import ReactTable from 'react-table'
import matchSorter from 'match-sorter'
import history from '../../history'
import moment from 'moment'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faScrewdriver } from '@fortawesome/free-solid-svg-icons'

const CaseTypesContainer = () => {
  const caseTypes = useSelector(state => state.caseTypes)

  const goToUpdate = (input) => {
    history.push('/case_types/update', {
      edit: input,
    })
  }

  const columns = [{
    Header: 'Date Created',
    accessor: 'created_at',
    width: 150,
    filterMethod: (filter, rows) => (
      matchSorter(rows, filter.value, { keys: ['created_at'] })
    ),
    filterAll: true,
    Cell: (e) =>(
      <div className="row justify-content-md-center">
        <span>{moment(e.value).format('MM-DD-YYYY')}</span>
      </div>
    ),
  },{
    Header: 'Category Name',
    accessor: 'category_name',
    width: 300,
    filterMethod: (filter, rows) => (
      matchSorter(rows, filter.value, { keys: ['category_name'] })
    ),
    filterAll: true,
  },{
    Header: 'Case Type Code',
    accessor: 'case_type_code',
    width: 200,
    filterMethod: (filter, rows) => (
      matchSorter(rows, filter.value, { keys: ['case_type_code'] })
    ),
    filterAll: true,
  },{
    Header: 'Department Code',
    accessor: 'department_code',
    width: 150,
    filterMethod: (filter, rows) => (
      matchSorter(rows, filter.value, { keys: ['department_code'] })
    ),
    filterAll: true,
  },{
    Header: 'Definition',
    accessor: 'definition',
    filterMethod: (filter, rows) => (
      matchSorter(rows, filter.value, { keys: ['definition'] })
    ),
    filterAll: true,
  },{
    id: 'is_active',
    Header: 'Status',
    accessor: row => { return row.is_active ? 'Active' : 'Inactive' },
    width: 100,
    filterMethod: (filter, rows) => (
      matchSorter(rows, filter.value, { keys: ['active'] })
    ),
    filterAll: true,
  },{
    Header: 'Update',
    accessor: 'edit',
    width: 80,
    Cell: row => (
      <div className="row justify-content-md-center">
        <button className="btn btn-primary btn-md mr-2" type="button" onClick={() => goToUpdate(row.original)}><FontAwesomeIcon icon={faScrewdriver} /> </button>
      </div>
    ),
  }]

  return (
    <div className="col-md-12 mx-auto">
      <div className="d-flex">
        <div />
        <h2 className="text-left w-100">Case Type Categories</h2>
        <button className="btn btn-primary rounded mb-3 mr-3" type="button" onClick={() => history.push('/case_types/create')}>Create Case Type</button>
      </div>
      <ReactTable
        filterable
        className="-striped -highlight mb-3"
        columns={columns}
        data={caseTypes}
        defaultPageSize={20}
        getTdProps={(s,r,c) => {
          if (c.id === 'edit') {
            return {
              onClick: () => goToUpdate(r.original),
              style: {
                cursor: 'pointer',
              },
            }
          } else {
            return {}
          }
        }}
        keyField="case_type_category_id"
      />
    </div>
  )
}

export default CaseTypesContainer
